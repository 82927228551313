import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BranchCreditCardRequest } from 'src/app/shared/services/API/requests/user/branch-credit-card.request';
import { BranchDeliveryAdressRequest } from 'src/app/shared/services/API/requests/user/branch-delivery-address.request';
import { CreateBranchCreditCardResponse } from 'src/app/shared/services/API/responses/user/create-branch-credit-card.response';
import { BranchCreditCardService } from 'src/app/shared/services/API/services/user/branch-credit-card.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Component({
  selector: 'app-credit-card-modal',
  templateUrl: './credit-card-modal.component.html',
  styleUrls: ['./credit-card-modal.component.css']
})
export class CreditCardModalComponent {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<CreditCardModalComponent>,
    private formBuilder: UntypedFormBuilder,
    private maskService: MaskService,
    private branchCreditCardService: BranchCreditCardService,
    private alertService: AlertService,
  ){
    matDialogRef.disableClose = true;
  }

  public model: UntypedFormGroup;
  public isLoading: boolean = false;
  public masks:Masks;
  public isUpdate: boolean = false;
  public idBranchCreditCard: number;
  public request: BranchCreditCardRequest;
  public listMonth;
  public listYear: number[];

  ngOnInit(): void {
    this.populateDateSelect();

    this.model = this.formBuilder.group({
      cardName: ['', [Validators.required]],
      cardNumber: ['', [Validators.required]],
      ccv: ['', Validators.required],
      // installments: ['', [Validators.required]],
      expirationMonth: ['', [Validators.required]],
      expirationYear: ['', [Validators.required]],
      holderName: ['', Validators.required],
      cpfCnpj: ['', [Validators.required]],
      email: ['', [Validators.required]],
      zipCode: ['', [Validators.required]],
      number: ['', Validators.required],
      complement: ['', [Validators.required]],
      phone: ['', [Validators.required]],
    });
    
    this.request = new BranchCreditCardRequest();

    this.masks = this.maskService.getMasks();
    if(this.data.idBranchCreditCard)
      this.idBranchCreditCard = this.data.idBranchCreditCard
    
    this.isUpdate == this.data.isUpdate;
    
    if(this.idBranchCreditCard != null){
      this.isUpdate = true;
    }
  }

  clickCancel(){
    this.matDialogRef.close();
  }

  submit(){
    if(this.model.invalid) {
      this.alertService.show("Erro", "Preencha corretamente os campos em vermelho para prosseguir", AlertType.error);
      return;
    }
    if(this.isLoading) return;

    this.isLoading = true;

    this.request.creditCardName = this.model.get('cardName').value;
    this.request.creditCardDigits = this.model.get('cardNumber').value;
    this.request.ccv = this.model.get('ccv').value;
    this.request.expirationMonth = this.model.get('expirationMonth').value;
    this.request.expirationYear = this.model.get('expirationYear').value;
    this.request.holderName = this.model.get('holderName').value;
    this.request.cpfCnpj = this.model.get('cpfCnpj').value;
    this.request.email = this.model.get('email').value;
    this.request.cep = this.model.get('zipCode').value;
    this.request.addressNumber = this.model.get('number').value.toString();
    this.request.addressComplement = this.model.get('complement').value;
    this.request.phoneNumber = this.model.get('phone').value;
    this.request.remoteIp = "192.168.0.1"
    this.createCreditCard();
  }

  createCreditCard(){
    this.branchCreditCardService.Post(this.request).subscribe({
      next:(response: CreateBranchCreditCardResponse)=>{
        if(response.isError == true){
          this.alertService.show("Erro", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show("Sucesso!", "Cartão atualizado com sucesso!", AlertType.success);
        this.isLoading = false;
        this.matDialogRef.close({creditCard: response.branchCreditCard});
      },
      error:(error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
        return;
      }
    });
  }

  populateDateSelect() {
    this.listYear = [];
    let todayDate = new Date();
    let year = todayDate.getFullYear();
    for (let _i = 0; _i < 10; _i++) {
      this.listYear.push(year+_i);
    }

    this.listMonth = [
      {name:'Janeiro', value:1}, {name:'Fevereiro', value:2},
      {name:'Março', value:3}, {name:'Abril', value:4}, 
      {name:'Maio', value:5}, {name:'Junho', value:6},
      {name:'Julho', value:7}, {name:'Agosto', value:8},
      {name:'Setembro', value:9}, {name:'Outrubro', value:10},
      {name:'Novembro', value:11}, {name:'Dezembro', value:12},
    ];
  }
}
