<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body" *ngIf="branchDeliveryAdressResponse != null">
  <div class="col-4">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  <div class="row">
    <div class="white-body">
      <div class="header" *ngIf="screenWidth >= 650">
        <div>
            <h2>Endereços de Entrega</h2>
        </div>
        <div>
          <div class="buttonUser">
            <button mat-button color="primary" id="add-cart" (click)="openAddressModal(null)" type="button">
              <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
              Cadastrar Novo Endereço
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="screenWidth < 650">
        <div>
            <h2>Endereços de Entrega</h2>
        </div>
        <div>
          <div class="buttonUser">
            <button mat-button color="primary" id="add-cart" (click)="openAddressModal(null)" type="button">
              <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
              Cadastrar Novo Endereço
            </button>
          </div>
        </div>
      </div>
      <div class="row table-responsive" *ngIf="screenWidth >= 650">
        <table class="basic-table table-color">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th>Nome</th>
              <th>Endereço</th>
              <th>Editar</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body" *ngFor="let item of branchDeliveryAdressResponse.listBranchDeliveryAddress">
            <ng-container *ngIf="item.merosUser != true">
              <tr class="basic-table__body__row">
                <td class="truncate">{{item.addressName}}</td>
                <td class="truncate">{{item.street}}, {{item.number}} - {{item.neighborhood}} - {{item.city}}</td>
                <td class="truncate">
                  <button mat-icon-button aria-label="Example icon button with a home icon"
                    (click)="openAddressModal(item, true)">
                    <mat-icon>launch</mat-icon>
                  </button>
                  <button mat-icon-button aria-label="Example icon button with a home icon"
                    (click)="openDialogDelete(item)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="screenWidth < 650">
        <div class="row card-spaccing" *ngFor="let item of branchDeliveryAdressResponse.listBranchDeliveryAddress">
          <div class="col-sm-6 w-100">
            <div class="card">
              <div class="card-body">
                <div class="row card-title">
                  <ul class="ul">
                    <li class="title">
                      <span class="second-title">Nome: </span>
                      <span>{{item.addressName}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul>
                    <li class="title">
                      <span class="second-title">Endereço: </span>

                      <span>{{item.street}}, {{item.number}} - {{item.neighborhood}} - {{item.city}}</span>

                    </li>
                  </ul>
                </div>
                <div class="row button-card">
                  <div class="button-os">
                    <div class="button" mat-dialog-actions>
                      <button style="background-color: #001F38; color: white;" id="button-mobile" mat-button type="button"
                        class="btn-primary" color="primary" (click)="openAddressModal(item, true)">
                        Editar
                      </button>
                      <button style="background-color: red; color: white;" id="button-mobile" mat-button type="button" class="btn-primary"
                        color="primary" (click)="openDialogDelete(item)">
                        Deletar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
