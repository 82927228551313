import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SelectVehicleVersionModalComponent } from 'src/app/shared/components/select-vehicle-version-modal/select-vehicle-version-modal.component';
import { ServiceOrderRequest } from 'src/app/shared/services/API/requests/serviceOrder/service-order.request';
import { CreatePlateRequest } from 'src/app/shared/services/API/requests/autoparts/create-update-plate.request';
import { SearchPlateRequest } from 'src/app/shared/services/API/requests/autoparts/search-plate.request';
import { ServiceOrderResponse } from 'src/app/shared/services/API/responses/serviceOrder/service-order.response';
import { ServiceOrderService } from 'src/app/shared/services/API/services/serviceOrder/service-order.service';
import { VehicleService } from 'src/app/shared/services/API/services/autoparts/vehicle.service';
import { VersionModelStruct } from 'src/app/shared/services/API/structs/autoparts/version-model.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { SearchFipePlateRequest } from 'src/app/shared/services/API/requests/autoparts/search-fipe-plate.request';

@Component({
  selector: 'app-modal-service-order-new',
  templateUrl: './modal-service-order-new.component.html',
  styleUrls: ['./modal-service-order-new.component.css']
})
export class ModalServiceOrderNewComponent {
  
  constructor(
    public matDialogRef: MatDialogRef<ModalServiceOrderNewComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private vehicleService: VehicleService, 
    private serviceOrderService:ServiceOrderService,
    private maskService: MaskService){
  }
  
  public model: FormGroup;
  public isLoading:boolean;
  public isLoadingNext:boolean;
  public vehicleVersion: VersionModelStruct = new VersionModelStruct();
  public year:number;

  public masks = this.maskService.getMasks();
  

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      plateNumberSearch: [''],
      plateNumber: ['', [Validators.required]],
      idBrand: [''],
      idVehicleModel: [''],
      year: [''],
      idVersion: [''],
      mileage:[''],
      cpfCnpj:[''],
      clientName:['', [Validators.required]],
      phone:[''],
      whatsAppPhone:[''],
      email:[''],
      idPlate: [''],

    });

    this.isLoading = false;
    this.isLoadingNext = false;
    this.vehicleVersion = null;
  }
  
  goBack(){
    this.matDialogRef.close({openSearchService: true}); 
  }

  goNext(){
    if(this.model.invalid) return;

    if(this.isLoadingNext) return;
    
    let serviceOrderRequest:ServiceOrderRequest = new ServiceOrderRequest(); 
    serviceOrderRequest.idPlate = this.model.get("idPlate").value;
    serviceOrderRequest.clientName = this.model.get("clientName").value;
    serviceOrderRequest.cpfCnpj = this.model.get("cpfCnpj").value?.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');
    serviceOrderRequest.phone = this.model.get("phone").value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    serviceOrderRequest.whatsAppPhone = this.model.get("whatsAppPhone").value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    serviceOrderRequest.email = this.model.get("email").value;
   

    this.isLoadingNext = true;
    this.serviceOrderService.post(serviceOrderRequest).subscribe({
      next: (serviceOrderResponse:ServiceOrderResponse) => {
        this.isLoadingNext = false;
        
        if (serviceOrderResponse.isError) {
          this.alertService.show('Erro', serviceOrderResponse.errorDescription, AlertType.error)
          return;
        }

        this.alertService.show('Sucesso', "Nova OS cadastrada com sucesso", AlertType.success);

        this.matDialogRef.close({idServiceOrder: serviceOrderResponse.idServiceOrder}); 
      },
      error: (error) => {
        this.isLoadingNext = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }
 
  searchPlate() {
    if(this.model.get('plateNumberSearch').value == '') return;
    if(this.isLoading) return;

    this.isLoading = true;
    let searchPlateRequest: SearchPlateRequest = new SearchPlateRequest();
    searchPlateRequest.plate = this.model.get('plateNumberSearch').value;

    this.vehicleService.searchPlate(searchPlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }

        let listVersion = response.version;

        if(!listVersion || listVersion.length ==0){
          this.alertService.show('Alerta!', "Nenhum veículo encontrado", AlertType.warning);
          return
        }
        else if(listVersion  && listVersion.length >1){
          this.openSelectVersionModal(listVersion)
          this.isLoading = false;
          return
        }
        else{
          this.createUpdatePlate(listVersion[0].idVersion)
        }
        let version = listVersion[0]

        if(!version){
          this.alertService.show('Aviso', "Placa não encontrada", AlertType.warning)
        } 
        
        this.vehicleVersion = version;
        this.year = new  Date(this.vehicleVersion.modelDate).getFullYear()

        this.model.get('idBrand').setValue(version.idBrand);
        this.model.get('idVehicleModel').setValue(version.idVehicleModel);
        this.model.get('year').setValue(new Date(version.modelDate).getFullYear());
        this.model.get('idVersion').setValue(version.idVersion);
        this.model.get('plateNumber').setValue( this.model.get('plateNumberSearch'));
        

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }
 

  openSelectVersionModal(listVersion){
    const dialogRef = this.dialog.open(SelectVehicleVersionModalComponent, {
      data: {
        listVersion : listVersion,
      },
    })
    dialogRef.beforeClosed().subscribe({
      next: result =>{
        if (result && result.vehicleVersion){
          this.SearchPlateRequest(result.fipe, result.modelYear, result.state, result.chassisNumber, result.color, result.origin)
        }
      }
    })

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.vehicleVersion){
          this.model.get('idBrand').setValue(result.vehicleVersion.idBrand);
          this.model.get('idVehicleModel').setValue(result.vehicleVersion.idVehicleModel);
          this.model.get('year').setValue(new Date(result.vehicleVersion.modelDate).getFullYear());
          this.model.get('idVersion').setValue(result.vehicleVersion.idVersion);
          this.model.get('plateNumber').setValue( this.model.get('plateNumberSearch'));

          this.vehicleVersion = result.vehicleVersion;
          this.year = new  Date(this.vehicleVersion.modelDate).getFullYear()
        }
      }
    });
  }

  SearchPlateRequest(fipeCode: string, modelYear: string, state?: string, chassisNumber?: string, color?: string, origin?: string) {
    this.isLoading = true;
    let searchPlateRequest: SearchFipePlateRequest = new SearchFipePlateRequest();
    searchPlateRequest.plate = this.model.get('plateNumber').value;
    searchPlateRequest.fipeCode = fipeCode;
    searchPlateRequest.modelYear = modelYear;
    searchPlateRequest.state = state;
    searchPlateRequest.chassisNumber = chassisNumber;
    searchPlateRequest.color = color;
    searchPlateRequest.origin = origin;

    this.vehicleService.SearchFipePlate(searchPlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }       
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  createUpdatePlate(idVersion: number) {
    this.isLoading = true;
    let createUpdatePlateRequest: CreatePlateRequest = new CreatePlateRequest();
    createUpdatePlateRequest.plate = this.model.get('plateNumberSearch').value;
    createUpdatePlateRequest.idVersion = idVersion;

    this.vehicleService.createUpdatePlate(createUpdatePlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }       
        this.model.get("idPlate").setValue(response.idPlate);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  catchValue(event){
    this.model.get('mileage').setValue(event);
  }
  
}

