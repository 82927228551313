<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div i18n class="head-modal-list">
            <h2>Selecione a versão do veículo</h2>
        </div>
         
        <div i18n class="body-modal-list">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Selecione a versão</mat-label>
                    <mat-select [(ngModel)]="version">
                        <mat-option *ngFor="let item of filteredListVersion" [value]="item">{{item.vehicleModelName}}
                        </mat-option>
                        <div class="col-12 align-center">
                            <button mat-button (click)="toggleList()">Mostrar {{ showAll ? 'menos' : 'mais' }}</button>
                        </div>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div i18n class="footer-modal-list">
             <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                        <span>Selecionar</span>
                    </button>
                </div>
            </div> 
             <div class="row">
                <div class="col-12">
                    <button mat-flat-button color="warn" mat-dialog-close="true" class="btn-block">
                        <span>Cancelar</span>
                    </button>
                </div>
            </div> 
        </div>
    </div>
</div>