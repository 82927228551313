import { AfterViewInit, Component, NgZone, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationResult, PopupRequest, SilentRequest } from '@azure/msal-browser';
import { error } from 'console';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { BranchStruct } from 'src/app/shared/services/API/structs/user/branch.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { environment } from 'src/environments/environment';
declare var google: any;

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private zone: NgZone,
    private utilService: UtilService,
  ) { }

  public model: FormGroup;
  public isLoading: boolean;
  public hide: boolean = false;
  public accent ="primary" as ThemePalette;
  public listBranch: BranchStruct[];
  public ip: string = '192.168.0.2';
  public timeoutIp;
  public state: boolean;
  public modules: any[];

  @Output() 


  ngOnInit(): void {
    this.isLoading = false;

    this.model = this.formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      idBranch: [null, [Validators.required]]
    });

    this.timeoutIp = setTimeout(() => { this.getIP(); }, 1000);

    this.state = true;
  }

  ngOnDestroy(){
    clearTimeout(this.timeoutIp);
  }
  
  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if(response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  signIn(){
    if(this.isLoading) return;
    if (this.model.invalid){
      this.alertService.show("Erro", 'O campo de email é obrigatório', AlertType.error);
      return;
    }
    
    this.isLoading = true;

    this.authService.signIn(this.model.get('email').value, this.model.get('password').value, this.model.get('idBranch').value, this.ip).subscribe({next:(response) => {
      if (response.isError) {
        if(response.errorCode == 1){
          this.isLoading = false;
          this.listBranch = response.listBranch
          return;
        }
        this.alertService.show("Erro!", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      if (response.isFirstAccess) {
        //this.openModal(this.model.get('email').value);
        alert("PRIMEIRO ACESSO")
        this.isLoading = false;
        return;
      }
        
      this.authService.updateToken(response);
      this.isLoading = false;

      response.listModule.forEach(module => {
        if (module.isModulePermitted && module.nameModule == 'Oficina') {
          sessionStorage.setItem("module", MenuModuleEnum.oficina.toString());
          return;
        } else if (module.isModulePermitted && module.nameModule == 'Fornecedor') {
          sessionStorage.setItem("module", MenuModuleEnum.fornecedor.toString());
        }
      });

      response.listModule.forEach(module => {
        if (module.isModulePermitted) {
          this.router.navigate([module.uri]);
          return;
        }
      });
    }, error: (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    }});
  }

  getBranches(){
    if(this.isLoading) return;
    if (this.model.get('email').invalid){
      // this.alertService.show("Erro", 'O campo de email é obrigatório', AlertType.error);
      return;
    }
    
    this.isLoading = true;

    this.authService.getBranches(this.model.get('email').value).subscribe({next:(response) => {
      if (response.isError) {
        this.alertService.show("Erro!", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }

      this.listBranch = response.listBranch

      if (response.listBranch.length == 1) {
        this.model.get('idBranch').setValue(response.listBranch[0].idBranch);
      }

      this.isLoading = false;
      this.state = false;
    }, error: (error) => {
      this.alertService.show("Erro inesperado", error, AlertType.error);
      this.isLoading = false;
    }});
  }

  public changeState(){
    this.state = true;
    this.listBranch = null;
    this.model.get('idBranch').setValue(null);
    this.model.get('password').setValue(null);
  }
}
