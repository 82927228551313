<app-spinner *ngIf="isLoading"></app-spinner>
<div class="mat-dialog-container">
  <div class="mat-dialog-tittle">
    <h2>CHAMAR ENTREGADOR</h2>
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="alert alert-primary d-flex align-items-center" role="alert">
    <mat-icon>where_to_vote</mat-icon>
    <div class="text-delivery">
      <span>Chamar Entregador? Favor conferir os endereços de retirada e entrega dos produtos</span>
    </div>
  </div>
  <div class="container-delivery">
    <div id="format-table" *ngIf="getSupplierResponse != null">
      <tbody class="basic-table_body">
        <tr class="basic_body_row">
          <td class="change">
            <strong>
              RETIRADA
            </strong>
          </td>
          <td class="info">
            {{address?.street}}, {{address?.houseNumber}}
          </td>
          <td class="info">
            {{address?.neighborhood}} - {{getCSCSupplierResponse?.details?.state}}
          </td>
          <td class="info">
            {{getCSCSupplierResponse?.details?.city}}, {{address?.zipCode}}
          </td>
        </tr>
      </tbody>
    </div>
    <div id="format-table-delivery">
      <tbody class="basic-table_body">
        <tr class="basic_body_row delivery">
          <td class="change">
            <strong>
              ENTREGA
            </strong>
          </td>
          <td class="info">
            {{this.street}}, {{this.number}}
          </td>
          <td class="info">
            {{this.neighborhood}} - {{this.state}}
          </td>
          <td class="info">
            {{this.city}}, {{this.zipcode}}
          </td>
        </tr>
      </tbody>
    </div>
  </div>

  <div class="title">
    <h2>Selecione o Tipo de Veículo</h2>
  </div>

  <div>
    <button id="button-delivery" (click)="handleButtonClick('Meros Moto')">
      <div id="format-table-vehicle">
        <img src="assets/images/lalaMove/moto.svg" class="" alt="...">
        <tbody class="basic-table_body">
          <tr class="basic_body_row">
            <td class="change">
              <strong>Meros Moto</strong>
            </td>
            <td class="info">
              Ideal para pacotes com dimensões:
            </td>
            <td class="info">
              0.3 x 0.4 x 0.3 Metros - Até 20kg
            </td>
          </tr>
        </tbody>
      </div>  
    </button>
    <button id="button-delivery" (click)="handleButtonClick('Meros Hatch')">
      <div id="format-table-vehicle">
        <img src="assets/images/lalaMove/car.svg" class="" alt="...">
        <tbody class="basic-table_body">
          <tr class="basic_body_row">
            <td class="change">
              <strong>Meros Hatch</strong>
            </td>
            <td class="info">
              Ideal para pacotes com dimensões:
            </td>
            <td class="info">
              1.0 x 0.7 x 0.6 Metros - Até 200kg
            </td>
          </tr>
        </tbody>
      </div>
    </button>
  </div>



  <div mat-dialog-actions [align]="'end'">
    <button color="warn" mat-raised-button mat-dialog-close="true">Cancelar</button>
    <button id="button-confirm" mat-raised-button (click)="confirmDelivery()" color="primary">Chamar motorista</button>
  </div>

</div>