<app-menu [menuModuleEnum]="menuModuleEnum" [menuFunctionalityEnum]="menuFunctionalityEnum" [hasSecondaryHeader]=false>
</app-menu>
<div class="blue-background-container">
  <div class="container-body">
    <div class="row">
      <h1 class="mainTitle">Pesquise pelo produto filtrando por Placa ou Veículo</h1>
    </div>
    <div i18n class="white-body">
      <div class="row titleSpacing">
        <div class="col-12 title">
          <h1 (click)="setIsProduto()" class="title" [ngClass]="{'selected-tab':isProduto}">Produto</h1>
          <h1 (click)="setIsPlate()" class="title margin" [ngClass]="{'selected-tab':isPlate}">Placa</h1>
          <h1 (click)="setVehicle()" class="title margin" [ngClass]="{'selected-tab':isVehicle}">Veículo</h1>
          <!-- <h1 (click)="setIsBusca()" class="title margin" [ngClass]="{'selected-tab':isBusca}">Busca Geral</h1> -->
        </div>
      </div>
      <form class="form" [formGroup]="model" (ngSubmit)="submit()">
        <div class="row">
          <div class="col-12" *ngIf="isProduto">
            <mat-form-field appearance="outline">
              <mat-label>Pesquise por código ou descrição do produto</mat-label>
              <input matInput type="text" #inputSearchProduto formControlName="description" (keyup.enter)="onKeySearch($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="row isplate" *ngIf="isPlate">
          <div class="col-lg-4 col-md-6 col-sm-6 col-8">
            <mat-form-field appearance="outline" class="plate-field">
              <mat-label>Placa</mat-label>
              <input matInput type="text" style="text-align:center" (input)="onKeySearch($event)" #inputSearchPlaca formControlName="plateNumber"
                [mask]="masks.plateNumber" oninput="this.value = this.value.toUpperCase()">
            </mat-form-field>
          </div>
          <div class="col-8" *ngIf="isDescriptionVisible">
            <mat-form-field appearance="outline">
              <mat-label>Pesquise por código ou descrição do produto</mat-label>
              <input matInput type="text" formControlName="description" (keyup.enter)="onKeySearch($event)" #inputDescriptionPlaca>
            </mat-form-field>
          </div>
          <div *ngIf="isVersion" class="row">
            <ul>
              <li class="title-car">Placa indicada: </li>
              <li>{{version.brandName}} - {{version.modelName}}</li>
            </ul>
          </div>
        </div>
        <div class="row" *ngIf="isVehicle">
          <div class="col-12 col-sm-6 col-md-3">
            <app-select-2 #select2Brand [formControlField]="'idBrand'" [list]="listBrand"
              (selectedValue)="brandSelect($event)" [multiple]="false" [idField]="'id'" [nameField]="'name'"
              [labelField]="'Montadora'" [hasNullSelectField]="true">
            </app-select-2>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <app-select-2 #select2Model [formControlField]="'idVehicleModel'" [list]="listVehicleModel"
              (selectedValue)="vehicleModelSelect($event)" [multiple]="false" [idField]="'id'" [nameField]="'name'"
              [labelField]="'Veículo'" [hasNullSelectField]="true">
            </app-select-2>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <app-select-2 #select2Year [formControlField]="'year'" [list]="listYear"
              (selectedValue)="yearSelect($event)" [multiple]="false" [idField]="'id'" [nameField]="'name'"
              [labelField]="'Ano'" [hasNullSelectField]="true">
            </app-select-2>
          </div>
          <div class="col-12 col-sm-6 col-md-3">
            <app-select-2 #select2Version [formControlField]="'idVersion'" [list]="listVersion" [multiple]="false"
              [idField]="'id'" [nameField]="'name'" [labelField]="'Versão'" [hasNullSelectField]="true">
            </app-select-2>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Pesquise por código ou descrição do produto</mat-label>
                <input matInput type="text" formControlName="description" (keyup.enter)="onKeySearch($event)">
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- <div *ngIf="isBusca">
          <div class="row">
            <div class="col-2">
              <mat-form-field appearance="outline">
                <mat-label>Placa</mat-label>
                <input matInput type="text" formControlName="plateNumberAll" (blur)="onKeySearch($event)" (keyup.enter)="onKeySearch($event)"
                  [mask]="masks.plateNumber" oninput="this.value = this.value.toUpperCase()">
              </mat-form-field>
            </div>
            <div class="col-2">
              <app-select-2 #select2Brand [formControlField]="'idBrand'" [list]="listBrand"
                (selectedValue)="brandSelect($event)" [multiple]="false" [idField]="'id'" [nameField]="'name'"
                [labelField]="'Montadora'" [hasNullSelectField]="true">
              </app-select-2>
            </div>
            <div class="col-3">
              <app-select-2 #select2Model [formControlField]="'idVehicleModel'" [list]="listVehicleModel"
                (selectedValue)="vehicleModelSelect($event)" [multiple]="false" [idField]="'id'" [nameField]="'name'"
                [labelField]="'Veículo'" [hasNullSelectField]="true">
              </app-select-2>
            </div>
            <div class="col-1">
              <app-select-2 #select2Year [formControlField]="'year'" [list]="listYear"
                (selectedValue)="yearSelect($event)" [multiple]="false" [idField]="'id'" [nameField]="'name'"
                [labelField]="'Ano'" [hasNullSelectField]="true">
              </app-select-2>
            </div>
            <div class="col-4">
              <app-select-2 #select2Version [formControlField]="'idVersion'" [list]="listVersion" [multiple]="false"
                [idField]="'id'" [nameField]="'name'" [labelField]="'Versão'" [hasNullSelectField]="true">
              </app-select-2>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>Pesquise por código ou descrição do produto</mat-label>
                <input matInput type="text" formControlName="descriptionAll" (keyup.enter)="onKeySearch($event)">
              </mat-form-field>
            </div>
          </div>
        </div> -->
        <div class="row actionButtons">
          <div class="col-6 align-right">
            <button mat-button type="button" class="btn btn-primary" color="primary" (click)="clearFilters()">
              <span>Limpar</span>
            </button>
          </div>
          <div class="col-6">
            <button mat-button type="submit" class="btn btn-primary" color="primary">
              <span *ngIf="!isLoading">Buscar</span>
              <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
