import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { SearchPlateResponse } from '../../responses/autoparts/Vehicle/search-plate.response';
import { SearchPlateRequest } from '../../requests/autoparts/search-plate.request';
import { PopulateSelectResponse } from '../../responses/autoparts/Vehicle/populate-select.response';
import { CreateUpdatePlateResponse } from '../../responses/autoparts/create-update-plate.response';
import { CreatePlateRequest } from '../../requests/autoparts/create-update-plate.request';
import { GetInfosResponse } from '../../responses/autoparts/Vehicle/get-infos.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { BranchDeliveryAdressRequest } from '../../requests/user/branch-delivery-address.request';
import { BranchDeliveryAdressResponse } from '../../responses/user/branch-delivery-address.response';
import { CreateBranchDeliveryAdressResponse } from '../../responses/user/create-branch-delivery-address.response';

@Injectable({
  providedIn: 'root'
})
export class BranchDeliveryAdressService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<BranchDeliveryAdressResponse> {

    let url = `BranchDeliveryAddress/GetAll` 

    return this.httpClient.get<BranchDeliveryAdressResponse>(environment.urlApiUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public post(body: BranchDeliveryAdressRequest): Observable<CreateBranchDeliveryAdressResponse> {

    let url = `BranchDeliveryAddress`

    return this.httpClient.post<CreateBranchDeliveryAdressResponse>(environment.urlApiUser + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public put(body: BranchDeliveryAdressRequest): Observable<CreateBranchDeliveryAdressResponse> {

    let url = `BranchDeliveryAddress`

    return this.httpClient.put<CreateBranchDeliveryAdressResponse>(environment.urlApiUser + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public delete(idBranchDeliveryAdress:number): Observable<GetInfosResponse> {

    let url = `BranchDeliveryAddress/idBranchDeliveryAdress/${idBranchDeliveryAdress}`

    return this.httpClient.delete<GetInfosResponse>(environment.urlApiUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }
}
