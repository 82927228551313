<div class="spinner" *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
  </div>
<div *ngIf="!isLoading">
    <div class="mat-dialog-tittle">
      <h2 *ngIf="idBuyOrderStatus === 2">CONFIRMAR ENTREGA DO PEDIDO?</h2>
</div>

<div mat-dialog-content>
    <div class="alert alert-danger d-flex align-items-center" role="alert">
        <mat-icon class="icon-delete" color="warn">warning</mat-icon>
        <div class="text" *ngIf="idBuyOrderStatus === 2">
            <span>
                Essa ação não pode ser desfeita. O status do pedido será alterado para <strong>Entregue</strong>. 
            </span>
            <span><strong>Verifique antes de prosseguir.</strong>
            </span>
        </div>
    </div>
</div>

<div mat-dialog-actions [align]="'center'">
    <button mat-raised-button mat-dialog-close="false" color="warn">Cancelar</button>
    <button mat-raised-button (click)="confirmBuyOrder()" id="button-confirm" color="primary">Confirmar Entrega</button>
  </div>
</div>
