import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { GetServiceOrderDetailsResponse } from '../../responses/serviceOrder/get-service-order-details-response';
import { ServiceOrderPutRequest } from '../../requests/serviceOrder/service-order-put.request';

@Injectable({
  providedIn: 'root'
})
export class ServiceOrderDetailsService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(idServiceOrder:number): Observable<GetServiceOrderDetailsResponse> {

    let url = `ServiceOrderDetails/idServiceOrder/${idServiceOrder}`

    return this.httpClient.get<GetServiceOrderDetailsResponse>(environment.urlApiServiceOrder + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public put(serviceOrderPutRequest:ServiceOrderPutRequest): Observable<GetServiceOrderDetailsResponse> {

    let url = `ServiceOrderDetails`

    return this.httpClient.put<GetServiceOrderDetailsResponse>(environment.urlApiServiceOrder + url, serviceOrderPutRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public close(idServiceOrder:number): Observable<GetServiceOrderDetailsResponse> {

    let url = `ServiceOrderDetails/Close/idServiceOrder/` + idServiceOrder;

    return this.httpClient.put<GetServiceOrderDetailsResponse>(environment.urlApiServiceOrder + url, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public reopen(idServiceOrder:number): Observable<GetServiceOrderDetailsResponse> {

    let url = `ServiceOrderDetails/Reopen/idServiceOrder/` + idServiceOrder;

    return this.httpClient.put<GetServiceOrderDetailsResponse>(environment.urlApiServiceOrder + url, null, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public delete(idServiceOrder:number): Observable<GetServiceOrderDetailsResponse> {

    let url = `ServiceOrderDetails/idServiceOrder/` + idServiceOrder;

    return this.httpClient.delete<GetServiceOrderDetailsResponse>(environment.urlApiServiceOrder + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
