import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ServiceOrderResponse } from '../../responses/serviceOrder/service-order.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetClientAprovalQuoteServiceResponse } from '../../responses/serviceOrder/get-client-aproval-quote-service.response';
import { ClientAprovalQuoteServiceRequest } from '../../requests/serviceOrder/client-aproval-quote-service.request';
import { GetQuoteServiceResponse } from '../../responses/serviceOrder/get-quote-service.response';

@Injectable({
  providedIn: 'root'
})
export class ClientQuoteService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(idQuoteService: number): Observable<GetQuoteServiceResponse> {

    let url = `ClientQuoteService/idQuoteService/${idQuoteService}`

    return this.httpClient.get<GetQuoteServiceResponse>(environment.urlApiServiceOrder + url, this.addHeaderTokenUntorizred())
    .pipe(
      catchError(this.handleError)
    )
  }

  public put(idQuoteService:number, idQuoteServiceStatus:number): Observable<ReturnStruct> {

    let url = `ClientQuoteService/idQuoteService/${idQuoteService}/idQuoteServiceStatus/${idQuoteServiceStatus}`
    return this.httpClient.put<ReturnStruct>(environment.urlApiServiceOrder + url, this.addHeaderTokenUntorizred())
    .pipe(
      catchError(this.handleError)
    )
  }
}
