import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-purchase-details-modal',
  templateUrl: './purchase-details-modal.component.html',
  styleUrls: ['./purchase-details-modal.component.css']
})
export class PurchaseDetailsModalComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<PurchaseDetailsModalComponent> 
  ) {}

  ngOnInit() {
  }
}
