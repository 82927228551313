import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
declare var google;
@Component({
  selector: 'app-google-place-input',
  templateUrl: './google-place-input.component.html',
  styleUrls: ['./google-place-input.component.css']
})
export class GooglePlaceInputComponent {
  @Input() adressType: string;
  @Input() autocompleteInput: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext', { static: true }) addresstext!: ElementRef;

  queryWait: boolean;
  public google: any;

  
  constructor() {
  }
  
  ngOnInit() {
  }
  
  ngAfterViewInit() {
    this.loadGooglePlaces();
  }

  private loadGooglePlaces() {
    const checkGoogleLoaded = setInterval(() => {
      if (typeof google !== 'undefined' && google.maps && google.maps.places) {
        clearInterval(checkGoogleLoaded);
        this.getPlaceAutocomplete();
      }
    }, 1000);
  }
  
  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
    {
      componentRestrictions: { country: 'BR' },
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }
    
  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }
    
}