export class VehicleVersion{
    public idVersion: number;
    public versionName: string;
    public idVehicleType: number;
    public idBrand: number;
    public idVehicleModel: number;
    public manufactureDate: Date;
    public modelDate: Date;
    public year: Date;
    public fipeCode: string;
    public passengers: number;
    public engine: string;
    public engineCapacity: number;
    public horsePower: number;
    public fuel: string;
    public transmission: string;
    public valves: number;
    public cillinders: number;
    public datetimeInclusion: Date;
}