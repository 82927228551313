import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { SearchPlateResponse } from '../../responses/autoparts/Vehicle/search-plate.response';
import { SearchPlateRequest } from '../../requests/autoparts/search-plate.request';
import { PopulateSelectResponse } from '../../responses/autoparts/Vehicle/populate-select.response';
import { CreateUpdatePlateResponse } from '../../responses/autoparts/create-update-plate.response';
import { CreatePlateRequest } from '../../requests/autoparts/create-update-plate.request';
import { GetInfosResponse } from '../../responses/autoparts/Vehicle/get-infos.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { BranchCreditCardResponse } from '../../responses/user/branch-credit-card.response';
import { BranchCreditCardRequest } from '../../requests/user/branch-credit-card.request';
import { CreateBranchCreditCardResponse } from '../../responses/user/create-branch-credit-card.response';



@Injectable({
  providedIn: 'root'
})
export class BranchCreditCardService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<BranchCreditCardResponse> {

    let url = `BranchCreditCard/`

    return this.httpClient.get<BranchCreditCardResponse>(environment.urlApiUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public Post(body: BranchCreditCardRequest): Observable<CreateBranchCreditCardResponse> {

    let url = `BranchCreditCard/`

    return this.httpClient.post<CreateBranchCreditCardResponse>(environment.urlApiUser + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public Delete(idBranchCreditCard: number): Observable<BranchCreditCardResponse> {

    let url = `BranchCreditCard/idBranchCreditCard/${idBranchCreditCard}`;
  
      return this.httpClient.delete<BranchCreditCardResponse>(environment.urlApiUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

  public Put(idBranchCreditCard: number): Observable<ReturnStruct> {

    let url = `BranchCreditCard/idBranchCreditCard/${idBranchCreditCard}`;
  
      return this.httpClient.put<ReturnStruct>(environment.urlApiUser + url, null, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }
}
