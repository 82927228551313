<div mat-dialog-tittle class="dialog-tittle">
  <h2 class="tittle">CADASTRO USUÁRIO</h2>
</div>

<div mat-dialog-content class="dialog-content">
  <!-- <h2>Dados Pessoais</h2> -->
  <form [formGroup]="model" class="form">
    <div class="row forms-profile">
      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="userName">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>CPF</mat-label>
          <input matInput type="text" formControlName="cpf" [mask]="masks.cpf">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Telefone</mat-label>
          <input matInput type="text" formControlName="phone" [mask]="masks.phone">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput type="text" formControlName="email">
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Senha</mat-label>
          <input matInput #password type="password" [type]="hide ? 'text' : 'password'" formControlName="password" required>
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field appearance="outline">
          <mat-label>Descrição da Função</mat-label>
          <input matInput type="text" formControlName="job">
        </mat-form-field>
      </div>
    </div>
<!-- 
    <div>
      <h2>Configurações de Perfil</h2>
    </div>

    <div class="functions" *ngFor="let item of checkboxes">
      // <input type="checkbox" >
      <mat-checkbox [checked]="item.selected" (change)="checkboxChanged(item)">{{item.tittle}}
        <mat-icon mat-raised-button matTooltip={{item.descript}}
          aria-label="Button that displays a tooltip when focused or hovered over">
          info
        </mat-icon>
      </mat-checkbox>
    </div> -->
  </form> 



</div>

<div mat-dialog-actions [align]="'center'">
  <button class="button-cancel" mat-raised-button mat-dialog-close="false">Cancelar</button>
  <button id="button-register" mat-raised-button (click)="registerProfile()">Cadastrar</button>
</div>
