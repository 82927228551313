
export class StockPart{
    public idStockPart:number;
    public idQuoteService:number;
    public productName:string
    public productCode:string;
    public productBrand:string;
    public price:number;
    public amount:number;
    public datetimeInclusion:Date;
}