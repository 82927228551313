
<mat-form-field formGroupName="firstChildGroup" appearance="outline">
    <mat-label>{{labelField}}</mat-label>
    <mat-select (selectionChange)="onChangeSelect($event)" (openedChange)="focusInput()" [multiple]="multiple" [(value)]="selected" [formControlName]="formControlField" >
        <input class="inputSearchSelect" #inputSearch type="text"
            (keyup)="onKey($event.target.value)" placeholder="Pesquisar" autocomplete="off" (keydown)="$event.stopPropagation()">
        <mat-option *ngIf="hasNullSelectField" ngValue="null">
            Selecione
        </mat-option>
        <mat-option [disabled]="preSelectedBlock && preSelectedlist[i] && item[idField] == preSelectedlist[i][idField]" *ngFor="let item of selectedList; let i = index" [value]="item[idField]">
            {{item[nameField]}}
        </mat-option>
    </mat-select>
</mat-form-field>