import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { GetPurchaseDataResponse } from '../../responses/orchestrator-purchase/get-purchase-data.response';
import { PurchaseRequest } from '../../requests/orchestrator-purchase/purchase.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { FinishPurchaseRequest } from '../../requests/orchestrator-purchase/finish-purchase.request';
import { FinishPurchaseResponse } from '../../responses/orchestrator-purchase/finish-purchase.response';
@Injectable({
  providedIn: 'root'
})
export class FinishPurchaseService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public finishBurchase(body: FinishPurchaseRequest): Observable<FinishPurchaseResponse> {

    let url = `FinishPurchase`

    return this.httpClient.post<FinishPurchaseResponse>(environment.urlApiOrchestratorPurchase + url, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
