import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { GetProfileResponse } from '../../responses/profile/get-profile.reponse';
import { ProfileRequest } from '../../requests/user/profile.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
    providedIn: 'root'
  })

export class GetProfileService extends CommonService{

    constructor(
        private httpClient: HttpClient) {
        super();
      }

    public get(idProfile: number): Observable<GetProfileResponse> {

        let url = `Profile/{idProfile}/${idProfile}`
    
        return this.httpClient.get<GetProfileResponse>(environment.urlApiUser + url, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }

    public delete(idProfile: number): Observable<ReturnStruct> {

      let url = `Profile/idProfile/${idProfile}`;
    
        return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + url, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }

    public Post(body: ProfileRequest): Observable<ReturnStruct> {

        let url = `Profile/`
    
        return this.httpClient.post<ReturnStruct>(environment.urlApiUser + url, body, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }

    public Put(body: ProfileRequest, idProfile: number): Observable<ReturnStruct> {

        let url = `Profile/idProfile/${idProfile}`;
    
        return this.httpClient.put<ReturnStruct>(environment.urlApiUser + url, body, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }
}