<header>
  <div class="logo-infos">
    <div class="logo">
      <img src="/assets/logos/Black logo - no background.png" (click)="redirectHome(moduleStorage)">
    </div>
    <div class="page-info">
      <h1>{{nameModule}}</h1>
      <h1 class="h1-small">{{userLoginResponse.branch.branchName}}</h1>
    </div>
  </div>


  <div class="user-infos">
    <div class="menu">
      <ul>
        <ng-container *ngFor="let item of listFunctionality; let i = index">
          <li *ngIf="item.idFunctionality != 2">
            <a (click)="redirect(item.uri)">
              <span class="material-symbols-outlined">
                {{item.iconClass}}
              </span>
              {{item.functionalityName}}
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div class="infos" (click)="userInfos(true)">
      <label class="user-name">{{userLoginResponse.userName}}</label>
      <label>{{nameModule}}</label>
    </div>
    <div class="imgs" (click)="userInfos(true)">
      <div class="user-first-letter">
        <span>m</span>
      </div>
    </div>
  </div>

</header>

<nav class="menu-small">
  <ul>
    <ng-container *ngFor="let item of listFunctionality; let i = index">
      <li *ngIf="item.idFunctionality != 2">
        <a (click)="redirect(item.uri)">
          <span class="material-symbols-outlined">
            {{item.iconClass}}
          </span>
        </a>
      </li>
    </ng-container>
  </ul>
</nav>

<app-user-infos [isModulePermitted]="this.isModulePermitted" [nameStore]="userLoginResponse.branch.branchName"
  [email]="userLoginResponse.email" [nameUser]="userLoginResponse.userName" [nameModule]="this.nameModule"
  *ngIf="openUserInfos === true" (closeUserInfos)="userInfos(false)"></app-user-infos>

<app-secondary-header #secondaryHeader *ngIf="hasSecondaryHeader" class="secondary-header"
  [idServiceOrder]="idServiceOrder">
</app-secondary-header>
