<div class="spinner" *ngIf="!modalAberto">
  <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
</div>

<div *ngIf="loadedData" class="modal-content mat-dialog-container">
  <div class="mat-dialog-title" *ngIf="supplierOrdersDetailResponse != null && supplierOrdersDetailResponse != null">
    <h2><strong>Pedido #{{supplierOrdersDetailResponse?.idBuyOrder}}</strong></h2>
    <button mat-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <app-button-waiting-delivery (refreshInfo)="populatePage()" *ngIf="idBuyOrderStatus === 9" [oficina]="this.oficina"
    [entrega]="this.entrega" [idBuyOrderStatus]="this.idBuyOrderStatus" [idBuyOrder]="idBuyOrder"
    [lalamoveLink]="this.lalamoveLink"></app-button-waiting-delivery>
  <app-button-delivery-man-arrived (refreshInfo)="populatePage()" *ngIf="status === 'Entregador chegou'"
    [oficina]="this.oficina" [entrega]="this.entrega" [idBuyOrderStatus]="this.idBuyOrderStatus"
    [idBuyOrder]="idBuyOrder"></app-button-delivery-man-arrived>
  <app-searching-for-driver (refreshInfo)="populatePage()" *ngIf="idBuyOrderStatus === 3" [oficina]="this.oficina"
    [entrega]="this.entrega" [idBuyOrderStatus]="this.idBuyOrderStatus" [idBuyOrder]="idBuyOrder">
  </app-searching-for-driver>

  <div class="preview-container" *ngIf="(idBuyOrderStatus === 9 || idBuyOrderStatus == 4) && this.entrega =='Meros'">
    <iframe id="website-preview" [src]="this.lalamoveLink" frameborder="0"></iframe>
  </div>

  <div mat-dialog-content>
    <div id="format-table">
      <tbody class="basic-table_body">
        <tr class="basic_body_row">
          <td class="info">
            <strong>Oficina</strong>{{supplierOrdersDetailResponse?.businessName}} -
            {{supplierOrdersDetailResponse?.branchName}}
          </td>
          <td class="info" [ngClass]="getStatusColor(supplierOrdersDetailResponse?.idBuyOrderStatus)">
            <strong>Status</strong>{{supplierOrdersDetailResponse?.buyOrderStatusName}}
          </td>
          <td class="info">
            <strong>Método de Entrega</strong>{{supplierOrdersDetailResponse?.deliveryTypeName}}
          </td>
          <td class="info">
            <strong>Forma de Pagamento</strong>Faturamento
          </td>
        </tr>
      </tbody>
      <tbody class="basic-table_body">
        <tr class="basic_body_row">
          <td class="info text-end">
            <strong>Data</strong>{{supplierOrdersDetailResponse?.orderDateTimeInclusion | date: 'd MMMM y, HH:mm'}}
          </td>
          <td class="info text-end">
            <strong>Endereço</strong>
          </td>
          <td class="info text-end">{{supplierOrdersDetailResponse?.branchDeliveryAddress?.street}},
            {{supplierOrdersDetailResponse?.branchDeliveryAddress?.number}}</td>
          <td class="info text-end">{{supplierOrdersDetailResponse?.branchDeliveryAddress?.neighborhood}},
            {{supplierOrdersDetailResponse?.branchDeliveryAddress?.city}}</td>
          <td class="info text-end">{{supplierOrdersDetailResponse?.branchDeliveryAddress?.state}} -
            {{supplierOrdersDetailResponse?.branchDeliveryAddress?.zipcode}} </td>
        </tr>
      </tbody>
    </div>
    <div *ngIf="screenWidth >= 650">
      <table class="basic-table aplication overflow-auto">
        <thead class="basic-table__head">
          <tr class="truncate table format">
            <th></th>
            <th>Produto</th>
            <th>Marca</th>
            <th>Part Number</th>
            <th>Cod Interno</th>
            <th>Pço Unidade</th>
            <th>Quantidade</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody class="basic-table__body" *ngFor="let item of listProductIdOrder">
          <tr class="basic-table__body__row">
            <td class="truncate">
              <div class="img">
                <img src="assets/images/no-image.png" class="img-size" alt="..." *ngIf="item.partImage64 == null">
                <img *ngIf="item.partImage64 != null" class="img-part"
                  [src]="'data:image/jpg;base64,' + item.partImage64" />
              </div>
            </td>
            <td class="truncate format">{{item.productName}}</td>
            <td class="truncate format">{{item.brandName}}</td>
            <td class="truncate format">{{item.partNumber}}</td>
            <td class="truncate format">{{item.internalCode}}</td>
            <td class="truncate format">{{item.priceInvoice | currency: 'BRL'}}</td>
            <td class="truncate format">{{item.totalQuantity}}</td>
            <td class="truncate format">{{item.priceInvoice * item.totalQuantity | currency: 'BRL'}}</td>
          </tr>
        </tbody>
      </table>
      <div class="priceTotal text-end" [align]="'end'">
        <h3><strong>Total ............ {{totalPrice | currency: 'BRL'}}</strong></h3>
      </div>
    </div>
    <div *ngIf="screenWidth < 650">
      <div class="row card-spaccing" *ngFor="let item of listProductIdOrder; let i = index">
        <div class="col-sm-6 w-100">
          <div class="card">
            <div class="card-body">
              <div class="row card-title">
                <ul class="ul">
                  <li>
                    <span class="second-title">
                      Produto:
                    </span>
                    <span class="title">{{item.productName}}</span>
                  </li>
                  <li>
                    <span class="second-title">
                      Marca:
                    </span>
                    <span class="title">
                      {{item.brandName}}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="row">
                <ul class="ul">
                  <li>
                    <span class="second-title">
                      PartNumber:
                    </span>
                    <span class="title">
                      {{item.partNumber}}
                    </span>
                  </li>
                  <li>
                    <span class="second-title">
                      Código Interno:
                    </span>
                    <span class="title">
                      {{item.internalCode}}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="row">
                <ul class="ul">
                  <li>
                    <span class="second-title">
                      Quantidade:
                    </span>
                    <span class="title">
                      {{item.totalQuantity}}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="row">
                <ul class="ul">
                  <li>
                    <span class="second-title">
                      Preço Unidade
                    </span>
                    <span class="title">
                      {{item.priceInvoice | currency: 'BRL'}}
                    </span>
                  </li>
                  <li>
                    <span class="second-title">
                      Total
                    </span>
                    <span class="title">
                      {{item.priceInvoice * item.totalQuantity | currency: 'BRL'}}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="priceTotal text-end" [align]="'end'">
        <h3><strong>Total ............ {{totalPrice | currency: 'BRL'}}</strong></h3>
      </div>
    </div>
  </div>

  <app-button-in-separation-no-register (refreshInfo)="populatePage()"
    *ngIf="idBuyOrderStatus === 1 || idBuyOrderStatus === 8" [entrega]="this.entrega"
    [idBuyOrderStatus]="this.idBuyOrderStatus" [idBuyOrder]="idBuyOrder"></app-button-in-separation-no-register>
  <app-button-in-separation (refreshInfo)="populatePage()" *ngIf="idBuyOrderStatus === 2" [oficina]="this.oficina"
    [idSupplier]="this.idSupplier" [street]="this.street" [neighborhood]="this.neighborhood" [state]="this.state"
    [zipcode]="this.zipcode" [number]="this.number" [city]="this.city" [entrega]="this.entrega"
    [idBuyOrderStatus]="this.idBuyOrderStatus" [idBuyOrder]="idBuyOrder"></app-button-in-separation>
    <app-button-confirm-delivery (refreshInfo)="populatePage()" *ngIf="idBuyOrderStatus === 4 && this.entrega !='Meros'" [entrega]="this.entrega"
    [idBuyOrderStatus]="this.idBuyOrderStatus" [idBuyOrder]="idBuyOrder"></app-button-confirm-delivery>
</div>
