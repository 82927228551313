import { Component, Inject, EventEmitter, ViewChild, ElementRef, Output, Input, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { BranchStruct } from 'src/app/shared/services/API/structs/user/branch.struct';
import { Router } from '@angular/router';
import { MenuModuleEnum } from '../../menu.module.enum';
import { UtilService } from 'src/app/shared/services/util.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-modal-change-branch',
  templateUrl: './modal-change-branch.component.html',
  styleUrls: ['./modal-change-branch.component.css']
})
export class ModalChangeBranchComponent implements OnInit{

  @ViewChild('password') passwordElement: ElementRef;
  @Input() email: string;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private formBuilder: UntypedFormBuilder,
  private authService: AuthService,
  private router: Router,
  private utilService: UtilService,
  private alertService: AlertService,
  private location: Location){

    this.dataArray = data.dataArray;
    this.email = data.email;
  }

  
  public dataArray
  public hide: boolean = false;
  public state: boolean;
  public isLoading: boolean;
  public model: FormGroup;
  public ip: string = '192.168.0.2';
  public formGroup: UntypedFormGroup;
  public listBranch: BranchStruct[];

  ngOnInit(): void{
    this.isLoading = false;

    this.model = this.formBuilder.group({
      emailLogin: [this.email, [Validators.required]],
      password: ['', [Validators.required]],
      idBranch: [null, [Validators.required]]
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if(response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  changeBranches(){
    this.signIn()
  }

  signIn(){
    const url = this.router.url;
    this.isLoading = true;

    this.authService.signIn(this.model.get('emailLogin').value, this.model.get('password').value, this.model.get('idBranch').value, this.ip).subscribe({next:(response) => {
      if (response.isError) {
        if(response.errorCode == 1){
          this.isLoading = false;
          this.listBranch = response.listBranch
          return;
        }
        this.alertService.show("Erro!", response.errorDescription, AlertType.error);
        this.isLoading = false;
        return;
      }
        
      this.authService.updateToken(response);
      url === "/supplier/order" ? sessionStorage.setItem("module", MenuModuleEnum.fornecedor.toString()) : sessionStorage.setItem("module", MenuModuleEnum.oficina.toString());
      
      response.listModule.forEach(module => {
        if (module.isModulePermitted) {
          this.router.navigate([module.uri]);
          return;
        }
      });
    }, error: (error) => {
      this.alertService.show("Ação não concluída.", error, AlertType.error);
      this.isLoading = false;
    }});
  }
}
