import { VehicleVersion } from "../../models/auto-parts/version-version.model";

export class VersionModelStruct extends VehicleVersion{
    public idVersion: number;
    public idPlate: number;
    public idModelVersion: number;
    public vehicleModelName: string;
    public vehiclePicture64: string;
    public modelName: string;
    public brandName: string;
    public idVehicleType:number;
    public valves:number;
    public cylinders:number;
    public idBrand:number;
    public score:number;
    public modelYear:string;
    public state:string;
    public chassisNumber:string;
    public color:string;
    public origin:string;
    public plateNumber: string;
    public fipeCode: string;
    public engineDisplacement: number;
    public horsePower: number;
}