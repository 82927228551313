import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Contact } from 'src/app/shared/services/API/models/serviceOrder/contact.model';
import { ClientAprovalQuoteServiceRequest } from 'src/app/shared/services/API/requests/serviceOrder/client-aproval-quote-service.request';
import { GetClientAprovalQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-client-aproval-quote-service.response';
import { ClientAprovalQuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/client-aproval-quote-service.service';

import { ServiceOrderService } from 'src/app/shared/services/API/services/serviceOrder/service-order.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService } from 'src/app/shared/services/mask.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Component({
  selector: 'app-modal-service-order-approval',
  templateUrl: './modal-service-order-approval.component.html',
  styleUrls: ['./modal-service-order-approval.component.css']
})
export class ModalServiceOrderApprovalComponent implements OnInit{
  constructor(public matDialogRef: MatDialogRef<ModalServiceOrderApprovalComponent>,
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private serviceOrderService:ServiceOrderService,
    private clientAprovalQuoteServiceService:ClientAprovalQuoteServiceService,
    private maskService: MaskService,
    @Inject(MAT_DIALOG_DATA) public data: any){

  }

  public model: FormGroup;
  public isLoading:boolean;
  public idQuoteService:number;

  public masks = this.maskService.getMasks();
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      listContact: this.formBuilder.array([]),
      name: ['', [Validators.required]],
      cpfCnpj: [''],
      phone: ['', [Validators.required]],
      whatsAppPhone: [''],
      email: [''],
      isMandatoryApproval: []
    }); 

    this.isLoading = false;
    this.idQuoteService = this.data.idQuoteService;
    this.populateModal();

  }

  populateModal(){
    this.clientAprovalQuoteServiceService.get(this.idQuoteService).subscribe({
      next: (getClientAprovalQuoteServiceResponse: GetClientAprovalQuoteServiceResponse) => {

        if (getClientAprovalQuoteServiceResponse.isError) {
          this.alertService.show('Erro', getClientAprovalQuoteServiceResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.model.get("name").setValue(getClientAprovalQuoteServiceResponse.contactOwner.name)
        this.model.get("cpfCnpj").setValue(getClientAprovalQuoteServiceResponse.contactOwner.cpfCnpj)
        this.model.get("phone").setValue(getClientAprovalQuoteServiceResponse.contactOwner.phone)
        this.model.get("whatsAppPhone").setValue(getClientAprovalQuoteServiceResponse.contactOwner.whatsAppPhone)
        this.model.get("email").setValue(getClientAprovalQuoteServiceResponse.contactOwner.email)
        
        getClientAprovalQuoteServiceResponse.listContact.forEach(element => {
          this.addListContact(element);
        });

      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });

    
  }

  addListContact(contact:Contact){
    (this.model.controls['listContact'] as FormArray).push(
      this.newListContact(contact)
    );
  }

  newListContact(contact:Contact){
    return this.formBuilder.group({
      name: new FormControl(contact?.name, Validators.required),
      cpfCnpj: new FormControl(contact?.cpfCnpj),
      phone: new FormControl(contact?.phone, Validators.required),
      whatsAppPhone: new FormControl(contact?.whatsAppPhone),
      email: new FormControl(contact?.email)
    })
  }

  removeContact(index){
    (this.model.controls['listContact'] as FormArray).removeAt(index);
  }

  close(){
    this.matDialogRef.close();
  }

  save(){
    if(this.isLoading) return;
    if(this.model.invalid) return;

    let clientAprovalQuoteServiceRequest:ClientAprovalQuoteServiceRequest = new ClientAprovalQuoteServiceRequest();

    clientAprovalQuoteServiceRequest.idQuoteService = this.idQuoteService;
    clientAprovalQuoteServiceRequest.isMandatoryApproval = this.model.get("isMandatoryApproval").value;
    
    if(clientAprovalQuoteServiceRequest.isMandatoryApproval == null)
    clientAprovalQuoteServiceRequest.isMandatoryApproval = false;


    clientAprovalQuoteServiceRequest.contactOwner = new Contact();
    clientAprovalQuoteServiceRequest.contactOwner.cpfCnpj = this.model.get("cpfCnpj").value?.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');
    clientAprovalQuoteServiceRequest.contactOwner.name = this.model.get("name").value;
    clientAprovalQuoteServiceRequest.contactOwner.email = this.model.get("email").value;
    clientAprovalQuoteServiceRequest.contactOwner.phone = this.model.get("phone").value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    clientAprovalQuoteServiceRequest.contactOwner.whatsAppPhone = this.model.get("whatsAppPhone").value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');

    clientAprovalQuoteServiceRequest.listContact = [];
    this.model.get('listContact').value.forEach(x => {
      let contact:Contact = new Contact();

      contact.cpfCnpj = x.cpfCnpj?.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');
      contact.name = x.name;
      contact.email = x.email;
      contact.phone = x.phone.value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
      contact.whatsAppPhone = x.whatsAppPhone.value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
      
      clientAprovalQuoteServiceRequest.listContact.push(contact);
    });
  
    this.clientAprovalQuoteServiceService.post(clientAprovalQuoteServiceRequest).subscribe({

      next: (returnStruct: ReturnStruct) => {

        if (returnStruct.isError) {
          this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Orçamento enviado para o cliente', AlertType.success);
        this.close();

      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  
  }

}
