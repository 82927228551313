export class SearchPartsRequest{
  public idApplicabilityLine: number;
  public idBrand: number;
  public idApplicabilityModel: number;
  public applicabilityYear: number;
  public idApplicabilityModelType: number;
  public idApplicabilityVersion: number;
  public idVehicleVersion: number;
  public partName: string;
  public skip: number;
  public take: number;
  public listIdPartBrand: number[];
  public rangeStartPrice: number;
  public rangeEndPrice: number;
}
