import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-validate-purchase-modal',
  templateUrl: './validate-purchase-modal.component.html',
  styleUrls: ['./validate-purchase-modal.component.css']
})
export class ValidatePurchaseModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ValidatePurchaseModalComponent>,
  ){
    this.matDialogRef.disableClose = true;
  }

  public validateMessage: string;

  ngOnInit(){
    this.validateMessage = this.data.validationMessage;
  }
  reload(){
    this.matDialogRef.close();
  }
}
