import { Component, OnDestroy, OnInit, ViewChild, Input, Output, HostListener } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseService } from 'src/app/shared/services/API/services/purchase/purchase.service';
import { ListPurchaseReponse } from 'src/app/shared/services/API/responses/purchase/list-purchase.response';
import { ModalDeleteComponent } from 'src/app/supplier/pages/order-details-modal/modal-delete/modal-delete.component';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { GetPurchaseHistoryResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.response';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseHistoryStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/purchase-history.struct';
import { PurchaseHistoryDetailsService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history-details.service';
import { GetPurchaseHistoryDetailsResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.details.response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ListPendingPurchaseRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/list-pending-purchase.request';
import { PurchaseStatus } from 'src/app/shared/services/API/structs/orchestrator-purchase/purchase-status.struct';


@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.css']
})
export class PurchaseHistoryComponent implements OnInit{

  constructor(private dialogRef: MatDialog,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private supplierService: SupplierService,
    private purchaseService: PurchaseService,
    private purchaseHistoryService: PurchaseHistoryService,
    private purchaseHistoryDetailsService: PurchaseHistoryDetailsService,
    private sanitizer: DomSanitizer) {

      this.onResize();
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public setDetails: Boolean = false;
  public getPurchase: any = [];
  public isLoading: boolean = false;
  public idPurchase: number;
  public idUser: number;
  public alertService: AlertService;
  public isDetails: boolean[] = [];
  public matPaginatorModule: MatPaginatorModule;
  public listPurchaseReponse: ListPurchaseReponse;
  public getPurchaseHistoryResponse: GetPurchaseHistoryResponse = null;
  public getPurchaseHistoryResponseFinished: GetPurchaseHistoryResponse = null;
  public getPurchaseHistoryDetailsResponse:GetPurchaseHistoryDetailsResponse;
  public getSupplierResponse: GetSupplierResponse;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public matDatepickerModule:MatDatepickerModule;
  public matFormFieldModule:MatFormFieldModule;
  public matInputModule:MatInputModule;
  public length: any;
  public lengthMonth: any;
  public data: any;
  public buyOrder: any;
  public buyOrderList: any;
  public filteredBuyOrder: any;
  public totalPrice: any;
  public pageSize: number = 5;
  public idBuyOrder: number;
  public model: FormGroup;
  public screenWidth: number;
  public modalAberto = false;
  public loading: boolean = false;
  public array: any;
  public distinctSuppliers: any;
  public populateInterval;
  public isPendingPurchase: boolean = true
  public purchaseTransit: any
  public lalamoveLink: SafeResourceUrl;
  public pageSizeOptions: number[] = [5, 10, 25, 100];
  public idBranchList: any;



  ngOnInit(): void {

    this.model = this.formBuilder.group({
      start: [''],
      end: [''],
      text:[''],
      status:['']
    });

    this.populateInterval = setInterval(() => {
      if(!this.isPendingPurchase){
        this.populatePageFinished()
      }else{
        this.populatePagePending();
      }
      this.resetPaginator();
    }, 30000);

    
    this.model.get('status').valueChanges.subscribe(newValue => {
      if(!this.isPendingPurchase){
        this.populatePageFinished();
      }else{
        this.populatePagePending();
      }
    });
    this.model.get('start').valueChanges.subscribe(newValue => {
      if(!this.isPendingPurchase){
        this.populatePageFinished();
      }else{
        this.populatePagePending();
      }
    });
    this.model.get('end').valueChanges.subscribe(newValue => {
      if(!this.isPendingPurchase){
        this.populatePageFinished();
      }else{
        this.populatePagePending();
      }
    });
    this.model.get('text').valueChanges.subscribe(() => {
      if(!this.isPendingPurchase){
        this.populatePageFinished();
      }else{
        this.populatePagePending();
      }
    });

    this.populatePagePending();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<PurchaseHistoryStruct>([]);
  dataSourceFinished = new MatTableDataSource<PurchaseHistoryStruct>([]);
  slicedData: PurchaseHistoryStruct[] = [];
  slicedDataFinished: PurchaseHistoryStruct[] = [];
  listStatus: PurchaseStatus[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnDestroy(){
    clearInterval(this.populateInterval);
  }


  resetPaginator() {
    this.pageSize = 5;  // Sempre define o tamanho da página como 5
    this.paginator.pageSize = this.pageSize;  // Atualiza o paginator com o novo pageSize
    this.paginator.pageIndex = 0;  // (Opcional) Reinicia para a primeira página
    this.sliceData();  // Reexecuta a lógica de slice para refletir o novo pageSize
  }

  sliceData(pageEvent?: PageEvent) {
    const pageIndex = pageEvent ? pageEvent.pageIndex : this.paginator.pageIndex;
    const pageSize = pageEvent ? pageEvent.pageSize : this.paginator.pageSize;

    if (this.isPendingPurchase) {
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      this.slicedData = this.dataSource.data.slice(startIndex, endIndex);
    } else {
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      this.slicedDataFinished = this.dataSourceFinished.data.slice(startIndex, endIndex);
    }
  }

  clearFields(){
    this.model.get('start').setValue(null);
    this.model.get('end').setValue(null);
    this.model.get('text').setValue(null);
    this.model.get('status').setValue(null);
  }

  clearFilter(){
    this.clearFields();

    if(this.isPendingPurchase){
      setTimeout(() => {
        this.populatePagePending();
      }, 1000);
    }else{
      setTimeout(() => {
        this.populatePageFinished();
      }, 1000);
    }
  }

  setRefrash(){
    if(this.isPendingPurchase){
      const end = this.pageSize;
      this.populatePagePending(end)
    }else{
      const end = this.pageSize;
      this.populatePageFinished(end)
    }
  }

  populatePageFinished(end?: number) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.loading = true;
    this.listStatus = [];
  
    const listPendingPurchaseRequest: ListPendingPurchaseRequest = {
      minDateTimeOrder: this.model.get('start').value || null,
      maxDateTimeOrder: this.model.get('end').value || null,
      textSearch: this.model.get('text').value || null,
      idBuyOrderStatus: this.model.get('status').value || null,
      idBranchList: this.idBranchList,
      pageIndex: 0,
      itemsPerPage: 1000
    };

      this.purchaseHistoryService.PostAllFinishedPurchases(listPendingPurchaseRequest).subscribe({
        next: (getPurchaseHistoryResponse: GetPurchaseHistoryResponse) => {
          if (getPurchaseHistoryResponse.isError) {
            this.isLoading = false;
            return;
          }
  
          this.isLoading = false;
          this.getPurchaseHistoryResponseFinished = getPurchaseHistoryResponse;
          this.buyOrder = this.getPurchaseHistoryResponseFinished.listPurchase;
          this.buyOrderList = this.getPurchaseHistoryResponseFinished.listPurchase;

          this.totalPrice = this.buyOrder.listBuyOrderPart;
          this.idBuyOrder = this.buyOrder.idPurchase;
          this.data = this.buyOrderList;
          this.length = this.buyOrderList.length;
          this.lengthMonth = this.buyOrder.length;
          this.modalAberto = true;  
          this.dataSourceFinished.data = this.buyOrderList;
          this.listStatus = this.getPurchaseHistoryResponseFinished.listStatus;

          if (end) {
            this.slicedDataFinished = this.dataSourceFinished.data.slice(0, end);
          } else {
            this.slicedDataFinished = this.dataSourceFinished.data.slice(0, this.pageSize);
          }
          
          this.modalAberto = true;
        },
        error: (err) => {
          console.error('Erro ao buscar compras finalizadas:', err);
          this.alertService.show('Ação não concluída.', err, AlertType.error);
          this.isLoading = false;
        }
      });
  }
  

  populatePagePending(end?: number) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.loading = true;
    this.listStatus = [];

    const listPendingPurchaseRequest: ListPendingPurchaseRequest = {
      minDateTimeOrder: this.model.get('start').value || null,
      maxDateTimeOrder: this.model.get('end').value || null,
      textSearch: this.model.get('text').value || null,
      idBuyOrderStatus: this.model.get('status').value || null,
      idBranchList: this.idBranchList,
      pageIndex: 0,
      itemsPerPage: 1000
    };
    
    this.purchaseHistoryService.PostAllPendingPurchases(listPendingPurchaseRequest).subscribe({
        next: (getPurchaseHistoryResponse: GetPurchaseHistoryResponse) => {       
          if (getPurchaseHistoryResponse.isError) {
                this.isLoading = false;
                return;
            }

            this.isLoading = false;
            this.getPurchaseHistoryResponse = getPurchaseHistoryResponse;
            this.buyOrderList = this.getPurchaseHistoryResponse.listPurchase;
            this.length = this.buyOrderList.length;
            this.listStatus = this.getPurchaseHistoryResponse.listStatus;

            // Verifica se a lista de compras contém dados antes de acessar o `lalamoveLink`
            if (this.buyOrderList && this.buyOrderList.length > 0) {
              this.buyOrder = this.buyOrderList[0]; // Atribui a primeira compra ou ajuste conforme necessário
              if (this.buyOrder.lalamoveLink) {
                  this.lalamoveLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.buyOrder.lalamoveLink);
              }
          }

            this.totalPrice = this.buyOrderList.reduce((sum: number, purchase: any) => sum + purchase.listBuyOrderPart, 0);
            this.idBuyOrder = this.buyOrderList.map((purchase: any) => purchase.idPurchase);
            this.data = this.buyOrderList;
            this.length = this.buyOrderList.length;
            this.lengthMonth = this.buyOrderList.length;
            this.modalAberto = true;
            this.dataSource.data = this.buyOrderList;

            if (end) {
                this.slicedData = this.dataSource.data.slice(0, end);
            } else {
                this.slicedData = this.dataSource.data.slice(0, this.pageSize);
            }

            this.modalAberto = true;
        },
        error: (err) => {
            console.error('Error fetching purchase history:', err);
            this.alertService.show('Ação não concluída.', err, AlertType.error);
            this.isLoading = false;
        }
    });
}


  setIsPurchase(){
    this.loading = false;
    this.isPendingPurchase = true;
    this.clearFields()
    setTimeout(() => {
      this.populatePagePending();
    }, 1000);
  }

  setIsFinishedPurchase(){
    this.loading = false;
    this.isPendingPurchase = false;
    this.clearFields()
    setTimeout(() => {
      this.populatePageFinished();
    }, 1000);
  }

  toggleDetails(item: any): void {
    item.isDetails = !item.isDetails;
    item.idPurchase;
  }

}
