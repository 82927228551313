<div mat-dialog-content>
    <div class="mat-dialog-content">
        <h1>Deletar OS</h1>
        <p>Você está prestes a deletar essa ordem de serviço.</p>

        <div class="row">
            <div class="col-md-6">
                <button mat-button type="button" class="btn btn-secundary btn-block" color="primary" (click)="cancel()">
                    <span>Cancelar</span>
                </button> 
            </div>
            <div class="col-md-6">
                <button mat-button type="button" class="btn btn-primary-blue delete btn-block" color="primary" (click)="delete()">
                    <span>Deletar OS</span>
                </button>  
            </div>
        </div>
        
       

    </div>
</div>