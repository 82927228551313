import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserLoginResponse } from '../services/API/responses/user/user-login.response';
import { MenuModuleEnum } from './menu.module.enum';
import { MenuFunctionalityEnum } from './menu.functionality.enum';
import { FunctionalityStruct } from '../services/API/structs/user/funcionality.struct';
import { SecondaryHeaderComponent } from '../components/secondary-header/secondary-header.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
 
  @ViewChild('secondaryHeader') secondaryHeader: SecondaryHeaderComponent;

  constructor(
    private authService:AuthService,
    private router: Router) { }

  public userLoginResponse:UserLoginResponse
  public nameModule:string;
  public isModulePermitted:any;
  public listModule:any;
  public idModule:any;
  public listFunctionality:FunctionalityStruct[];
  public openUserInfos:boolean = false;
  public moduleStorage: string;

  @Input() menuModuleEnum: MenuModuleEnum;
  @Input() menuFunctionalityEnum: MenuFunctionalityEnum;
  @Input() hasSecondaryHeader: boolean = false;

  ngOnInit(): void {
    
    this.moduleStorage = sessionStorage.getItem("module")
    this.userLoginResponse = this.authService.getTokenMenu();
    this.nameModule = this.userLoginResponse.listModule.find(c=> c.idModule == this.menuModuleEnum).nameModule;
    this.listFunctionality = this.userLoginResponse.listModule.find(c=> c.idModule == this.menuModuleEnum).listFunctionality;
  }

  redirectHome(moduleStorage){
    if (this.moduleStorage == '1') {
      this.router.navigate([
        moduleStorage = "/workshop/welcome"
      ]);
    }
    else {
      this.router.navigate([
        moduleStorage = "/supplier/order"
      ]);
    }
  }

  reloadHeader(idServiceOrder: number){
    this.secondaryHeader.reloadHeader(idServiceOrder)
  }

  userInfos(value){
    this.openUserInfos = value;
  }

  redirect(uri: string) {
    // this.router.navigate([uri], { queryParams: { menuModuleEnum: this.menuModuleEnum}});
    // this.router.navigate([uri], { state: { menuModuleEnum: this.menuModuleEnum } });
    this.router.navigate([uri]);
  }

}