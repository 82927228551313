import { Component, EventEmitter, Output, Inject } from '@angular/core';
import { FormsModule, UntypedFormBuilder, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';



@Component({
  selector: 'app-modal-user-edit',
  templateUrl: './modal-user-edit.component.html',
  styleUrls: ['./modal-user-edit.component.css']
})
export class ModalUserEditComponent {

  constructor(private formBuilder: FormBuilder,
    public maskService: MaskService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<ModalUserEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){

    this.model = this.formBuilder.group({
      userName: ['', [Validators.required]],
      cpf: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.email]],
      password: ['', [Validators.required]],
      job: ['', [Validators.required]],
    });
    
    if(data){
      this.model.patchValue({
        userName: data.userName,
        cpf: data.cpf,
        phone: data.phone,
        email: data.email,
        password: data.password,
        job: data.job,
      });
    }
  }

  public FormFieldModuel: MatFormFieldModule;
  public model: FormGroup;
  public masks: Masks;
  public hide: boolean = false;

  @Output() confirmRegister = new EventEmitter<FormGroup>();

  selectAll = false;
  checkboxes = [
    {tittle: 'Mecânico', descript: 'Função X', selected: false},
    {tittle: 'Comprador', descript: 'Função Y', selected: false},
    {tittle: 'Supervisor Técnico', descript: 'Função Z', selected: false},
  ]


  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
  }
  
  checkboxChanged(item: any) {
    item.selected = !item.selected;
  }

  registerProfile(){
    if (this.model.valid) {
      this.confirmRegister.emit(this.model.value);
    }
    else{
      this.alertService.show('Erro', "Preencha corretamente todos os campos.", AlertType.error)
    }
  }

}
