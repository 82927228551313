import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ListDeliveryAdressRequest } from '../../requests/user/list-delivery-address.request';
import { BranchDeliveryAdressResponse } from '../../responses/user/branch-delivery-address.response';

@Injectable({
    providedIn: 'root'
  })

export class ListDeliveryAddressService extends CommonService{

    constructor(
        private httpClient: HttpClient){
            super();
        }


        public post(body: number[]): Observable<BranchDeliveryAdressResponse> {

            let url = `GetPurchaseData/getData`
        
            return this.httpClient.post<BranchDeliveryAdressResponse>(environment.urlApiUser + url, body, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

}