import { PurchaseSupplierStruct } from "../../structs/orchestrator-purchase/purchase-supplier.struct";
import { PartProductStruct } from "../../structs/autoparts/part-product.struct";

export class FinishPurchaseRequest{
    public idUser: number;
    public idBranch: number;
    public idBranchCreditCard: number;
    public idBranchDeliveryAddress : number;
    public idDeliveryMethod: number;
    public listParts: PartProductStruct[];
}