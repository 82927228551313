import { Component, OnDestroy, OnInit, ViewChild, HostListener, Output, EventEmitter, NgZone } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { ModalPaymentDeleteComponent } from './modal-payment-delete/modal-payment-delete.component';
import { ModalPaymentAddCartComponent } from './modal-payment-add-cart/modal-payment-add-cart.component';
import { BranchCreditCardRequest } from 'src/app/shared/services/API/requests/user/branch-credit-card.request';
import { BranchCreditCardResponse } from 'src/app/shared/services/API/responses/user/branch-credit-card.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { CreateBranchCreditCardResponse } from 'src/app/shared/services/API/responses/user/create-branch-credit-card.response';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { Location } from '@angular/common';
import { BranchCreditCardService } from 'src/app/shared/services/API/services/user/branch-credit-card.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit{

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public FormFieldModuel: MatFormFieldModule;
  public FormsModule: FormsModule;
  public MatInputModule: MatInputModule;
  public MatRadioModule: MatRadioModule;
  public MatCheckboxModule: MatCheckboxModule;
  public MatSelectModule: MatSelectModule;
  public branchCreditCardResponse: BranchCreditCardResponse;
  public branchCreditCardRequest: BranchCreditCardRequest;
  public isLoading: boolean;
  public screenWidth: number;

  constructor(private dialogRef: MatDialog,
    private branchCreditCard: BranchCreditCardService,
    private alertService:AlertService,
    public maskService: MaskService,
    private location: Location,
    ){
      this.onResize();
    }

  public masks: Masks;

  ngOnInit(){
    this.populatePage();
    this.masks = this.maskService.getMasks();
    this.menuModuleEnum = parseInt(sessionStorage.getItem("module"));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  backPage(){
    this.location.back();
  }

  populatePage(){
    if (this.branchCreditCard) {
      this.branchCreditCard.get().subscribe({
        next: (branchCreditCard: BranchCreditCardResponse) =>{
          if(branchCreditCard.isError){
            this.alertService.show('Erro', branchCreditCard.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.branchCreditCardResponse = branchCreditCard;

          this.branchCreditCardResponse.listBranchCreditCard.sort((a, b) => {
            return b.isDefault ? 1 : -1;
          });

        }
      });
    } else {
    }
  }
  @ViewChild(MatDialog) paginator: MatDialog;



  openDialogAdd(){
    const dialogRef = this.dialogRef.open(ModalPaymentAddCartComponent,{
      height: 'auto',
      width: '60%',
    });

    dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
      const branchCreditCardRequest: BranchCreditCardRequest = new BranchCreditCardRequest();
      branchCreditCardRequest.idBranchCreditCard = 0;
      branchCreditCardRequest.creditCardDigits = formData.creditCardDigits.toString();
      branchCreditCardRequest.ccv = formData.ccv.toString();
      branchCreditCardRequest.creditCardName = formData.creditCardName;
      branchCreditCardRequest.expirationMonth = parseInt(formData.expirationMonth);
      branchCreditCardRequest.expirationYear = parseInt(formData.expirationYear);
      branchCreditCardRequest.holderName = formData.holderName;
      branchCreditCardRequest.email = formData.email;
      branchCreditCardRequest.cep = formData.cep;
      branchCreditCardRequest.addressNumber = formData.addressNumber;
      branchCreditCardRequest.addressComplement = formData.addressComplement;
      branchCreditCardRequest.phoneNumber = formData.phoneNumber.toString();
      branchCreditCardRequest.cpfCnpj = formData.cpfCnpj;
      branchCreditCardRequest.isDeleted = false;
      branchCreditCardRequest.remoteIp = ""
      branchCreditCardRequest.isDefault = false;


      this.branchCreditCard.Post(branchCreditCardRequest).subscribe({
        next: (branchCreditCard: CreateBranchCreditCardResponse) => {
          if (branchCreditCard && branchCreditCard.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', branchCreditCard.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Cartão cadastrado', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', 'Não foi possível cadastrar o Cartão', AlertType.error);
          }
          this.isLoading = false;
        }
      });
    })
  }
  
  openDialogDelete(item: any): void {
    const dialogRef = this.dialogRef.open(ModalPaymentDeleteComponent)
    const idBranchCreditCard: number = item.idBranchCreditCard;
    
    dialogRef.componentInstance.deleteConfirm.subscribe(() => {
      this.branchCreditCard.Delete(idBranchCreditCard).subscribe({
        next: (branchCreditCard: BranchCreditCardResponse) => {
          if (branchCreditCard.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', branchCreditCard.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Excluído', 'O cartão foi excluído com sucesso.', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', 'Não foi possível excluir o cartão no momento. Tente novamente mais tarde.', AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }

  setCartaoPrincipal(item: any): void{
    const idBranchCreditCard:number = item.idBranchCreditCard;

    this.branchCreditCard.Put(idBranchCreditCard).subscribe({
      next: (returnStruct: ReturnStruct) => {
        if (returnStruct.isError) {
          if (this.alertService) {
            this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
          }
          this.isLoading = false;
          return;
        }
        this.populatePage();
        if (this.alertService) {
          this.alertService.show('Sucesso', 'Cartão Principal Alterado', AlertType.success);
        }
      },
      error: (error) => {
        console.error(error);
        if (this.alertService) {
          this.alertService.show('Erro inesperado', 'Cartão Principal não Alterado.', AlertType.error);
        }
        this.isLoading = false;
      }
    });
  }
}
