export class ServiceOrder{
    public idServiceOrder: number; 
    public idPlate: number;
    public isOwner: boolean;
    public clientName: string;
    public phone: string;
    public whatsAppPhone: string;
    public email: string;
    public idBranch: number;
    public idServiceOrderStatus: number;
    public datetimeInclusion: Date;
    public vehicleName:string;
    public plateNumber:string;
}