  <div class="mat-dialog-content">
    <form class="form" [formGroup]="model" (ngSubmit)="submit()">
      <div class="body-modal-list">
        <div class="header">
            <h1>Dados do cartão</h1>
            <mat-icon mat-dialog-close="true">close</mat-icon>
        </div>
        <div class="row">
          <div class="col-12">
            <mat-form-field appearance="outline">
              <mat-label>Nome impresso no cartão</mat-label>
              <input matInput type="text" formControlName="cardName">
              <mat-error *ngIf="model.get('cardName').invalid">Informe o nome impresso no cartão</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Número do cartão</mat-label>
              <input matInput type="text" formControlName="cardNumber" [mask]="masks.creditCardNumber">
              <mat-error *ngIf="model.get('cardNumber').invalid">Informe o numero do cartão</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>CCV</mat-label>
              <input matInput type="text" formControlName="ccv" [mask]="masks.cvv">
              <mat-error *ngIf="model.get('ccv').invalid">Informe o ccv</mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="col-12 col-sm-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Parcelas</mat-label>
                            <input matInput type="text" formControlName="installments">
                            <mat-error *ngIf="model.get('installments').invalid">Informe o número de parcelas</mat-error>
                        </mat-form-field>
                    </div> -->
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Mês de expiração</mat-label>
              <mat-select formControlName="expirationMonth">
                <mat-option *ngFor="let item of listMonth" value="{{item.value}}">
                  {{item.name}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="model.get('ccv').invalid">Informe o mês de expiração</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Ano de expiração</mat-label>
              <mat-select formControlName="expirationYear">
                <mat-option *ngFor="let item of listYear" value="{{item}}">
                  {{item}}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="model.get('ccv').invalid">Informe o ano de expiração</mat-error>
            </mat-form-field>
          </div>
        </div>

        <h1>Dados do dono do cartão de credito</h1>
        <div class="row">
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Nome Completo</mat-label>
              <input matInput type="text" formControlName="holderName">
              <mat-error *ngIf="model.get('holderName').invalid">Informe o nome</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>CPF/CNPJ</mat-label>
              <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
              <mat-error *ngIf="model.get('cpfCnpj').invalid">Informe o cpf ou cnpj</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput type="text" formControlName="email">
              <mat-error *ngIf="model.get('email').invalid">Informe o email</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>CEP</mat-label>
              <input matInput type="text" formControlName="zipCode">
              <mat-error *ngIf="model.get('zipCode').invalid">Informe o cep</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Número</mat-label>
              <input matInput type="number" formControlName="number">
              <mat-error *ngIf="model.get('zipCode').invalid">Informe o número</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Complemento</mat-label>
              <input matInput type="complement" formControlName="complement">
              <mat-error *ngIf="model.get('zipCode').invalid">Informe o complemento</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-sm-6">
            <mat-form-field appearance="outline">
              <mat-label>Telefone</mat-label>
              <input matInput type="phone" formControlName="phone" [mask]="masks.phone">
              <mat-error *ngIf="model.get('zipCode').invalid">Informe o telefone</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="footer-exam-modal-list">
        <button mat-flat-button color="primary" class="btn-block" type="submit">
          <span *ngIf="isLoading == false">Salvar</span>
          <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
        </button>
        <button mat-flat-button color="accent" class="btn-block" type="button" (click)="clickCancel()">Cancelar</button>
      </div>
    </form>
  </div>
