export class ApplicabilityInfoStruct {
    public idPlate?: number;
    public idVehicleVersion?: number;
    public idApplicabilityModel: number;
    public idApplicabilityModelType: number;
    public idBrand: number;
    public applicabilityYear: number;
    public filterType: string;
    public idApplicabilityVersion: number;
    public amount: number;
}
