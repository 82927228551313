import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { Contact } from 'src/app/shared/services/API/models/serviceOrder/contact.model';
import { ServiceOrderPutRequest } from 'src/app/shared/services/API/requests/serviceOrder/service-order-put.request';
import { GetServiceOrderDetailsResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-service-order-details-response';
import { SearchPlateResponse } from 'src/app/shared/services/API/responses/autoparts/Vehicle/search-plate.response';
import { ServiceOrderDetailsService } from 'src/app/shared/services/API/services/serviceOrder/service-order-details.service';
import { ServiceOrderService } from 'src/app/shared/services/API/services/serviceOrder/service-order.service';
import { SearchPlate } from 'src/app/shared/services/API/services/autoparts/search-plate.service';
import { VehicleService } from 'src/app/shared/services/API/services/autoparts/vehicle.service';
import { VersionModelStruct } from 'src/app/shared/services/API/structs/autoparts/version-model.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ServiceOrderCloseModalComponent } from './service-order-close-modal/service-order-close-modal.component';
import { QuoteServiceStatusEnum } from 'src/app/shared/enums/serviceOrder/quote-service-status-enum';
import { ServiceOrderStatusEnum } from 'src/app/shared/enums/serviceOrder/service-order-status-enum';
import { ServiceOrderReopenModalComponent } from './service-order-reopen-modal/service-order-reopen-modal.component';
import { ServiceOrderDeleteModalComponent } from './service-order-delete-modal/service-order-delete-modal.component';
import { SearchServiceOrderService } from 'src/app/shared/services/API/services/autoparts/search-service-order.service';
import { SearchServiceOrderByPartResponse } from 'src/app/shared/services/API/responses/serviceOrder/search-service-order-by-part-response';
import { SearchServiceOrderByPartRequest } from 'src/app/shared/services/API/requests/serviceOrder/search-service-order-by-part.request';
import { QuoteServicePart } from 'src/app/shared/services/API/models/serviceOrder/quote-service-part.model';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { MaskService } from 'src/app/shared/services/mask.service';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { get } from 'http';
import { SupplierRequest } from 'src/app/shared/services/API/requests/user/supplier.request';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';

@Component({
  selector: 'app-service-order-details',
  templateUrl: './service-order-details.component.html',
  styleUrls: ['./service-order-details.component.css']
})
export class ServiceOrderDetailsComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public alertService: AlertService,
    public serviceOrderService:ServiceOrderService,
    private router: Router, 
    private searchPlate:SearchPlate,
    private serviceOrderDetailsService:ServiceOrderDetailsService,
    private vehicleService:VehicleService, 
    private searchServiceOrderService:SearchServiceOrderService,
    private maskService: MaskService,
    private supplierService:SupplierService) {
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_orcamento;

  public masks = this.maskService.getMasks();

  public model: FormGroup;  
  public isLoading:boolean = false;
  public idServiceOrder:number;
  
  public vehicleVersion:VersionModelStruct
  public year:number;

  public statusClose:boolean = false;
  public statusCancel:boolean = false;
  public statusOpen:boolean = false;

  public supplierName:string = '';

  

  public getServiceOrderDetailsResponse: GetServiceOrderDetailsResponse = null;
  @ViewChild('menu') menu: MenuComponent;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      cpfCnpj:[''],
      clientName:['', [Validators.required]],
      phone:[''],
      whatsAppPhone:[''],
      email:['',[Validators.email]],
      problem:[''],
      observation:[''],
      listContact: this.formBuilder.array([]),
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.idServiceOrder = params.idServiceOrder;
      this.populate();
    });
  }

  

  populate(){
    this.populateHeader();

    this.serviceOrderDetailsService.get(this.idServiceOrder).subscribe({
      next: (getServiceOrderDetailsResponse: GetServiceOrderDetailsResponse) => {

        if (getServiceOrderDetailsResponse.isError) {
          this.alertService.show('Erro', getServiceOrderDetailsResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.getServiceOrderDetailsResponse = getServiceOrderDetailsResponse;
        getServiceOrderDetailsResponse.listQuoteServiceStruct
        //Populate cliente
        this.model.get('cpfCnpj').setValue(getServiceOrderDetailsResponse.serviceOrder.cpfCnpj);
        this.model.get('clientName').setValue(getServiceOrderDetailsResponse.serviceOrder.clientName);
        this.model.get('phone').setValue(getServiceOrderDetailsResponse.serviceOrder.phone);
        this.model.get('whatsAppPhone').setValue(getServiceOrderDetailsResponse.serviceOrder.whatsAppPhone);
        this.model.get('email').setValue(getServiceOrderDetailsResponse.serviceOrder.email);
        this.model.get('problem').setValue(getServiceOrderDetailsResponse.serviceOrder.problem);
        this.model.get('observation').setValue(getServiceOrderDetailsResponse.serviceOrder.observation);

        getServiceOrderDetailsResponse.listContact.forEach(element => {
          this.addListContact(element);
        });

        this.statusClose = getServiceOrderDetailsResponse.serviceOrder.idServiceOrderStatus == ServiceOrderStatusEnum.fechado;
        this.statusCancel = getServiceOrderDetailsResponse.serviceOrder.idServiceOrderStatus == ServiceOrderStatusEnum.cancelado;
        this.statusOpen = getServiceOrderDetailsResponse.serviceOrder.idServiceOrderStatus == ServiceOrderStatusEnum.aberto;
        
        this.vehicleService.searchPlateById(getServiceOrderDetailsResponse.serviceOrder.idPlate).subscribe({
          next: (searchPlateResponse:SearchPlateResponse) => {
            if (searchPlateResponse.isError) {
              this.alertService.show('Erro', searchPlateResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
            }
    
            this.vehicleVersion = searchPlateResponse.version[0];
            this.year = new  Date(this.vehicleVersion.modelDate).getFullYear()
            this.isLoading = false;
          },
          error: (error) => {
            this.isLoading = false;
            console.log(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          },
        });


        let searchServiceOrderByPartRequest:SearchServiceOrderByPartRequest = new SearchServiceOrderByPartRequest();
        searchServiceOrderByPartRequest.listIdPart = [];
        getServiceOrderDetailsResponse.listQuoteServiceStruct.forEach(element => {
          let listidPart = element.listQuoteServicePart.map(c=> c.idPart);

          listidPart.forEach(element => {
            searchServiceOrderByPartRequest.listIdPart.push(element);
          });  
        });

        this.searchServiceOrderService.getByIdParts(searchServiceOrderByPartRequest).subscribe({
          next: (searchServiceOrderByPartResponse:SearchServiceOrderByPartResponse) => {
            
            if (searchServiceOrderByPartResponse.isError) {
              this.alertService.show('Erro', searchServiceOrderByPartResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
            }
    
            this.getServiceOrderDetailsResponse.listQuoteServiceStruct.forEach(element => {
              
    
              element.listQuoteServicePart.forEach(element => {
                
                element.partProductStruct = searchServiceOrderByPartResponse.listPartProductStruct.find(c=> c.idPart == element.idPart);

              });  
            });
            
            this.isLoading = false;
          },
          error: (error) => {
            this.isLoading = false;
            console.log(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          },
        });

        let supplierRequest:SupplierRequest = new SupplierRequest();
        supplierRequest.listIdSuppliers = [];
        getServiceOrderDetailsResponse.listQuoteServiceStruct.forEach(element => {
          element.listQuoteServicePart.forEach(element => {
            if(element){
              supplierRequest.listIdSuppliers.push(element.idSupplier);
            }
          });  
        });
        
        this.supplierService.post(supplierRequest).subscribe({
          next: (getSupplierResponse: GetSupplierResponse) => {
            if (getSupplierResponse.isError) {
              this.alertService.show('Erro', getSupplierResponse.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }


            this.getServiceOrderDetailsResponse.listQuoteServiceStruct .forEach(element => {                  
              element.listQuoteServicePart.forEach(element => {                
                element.supplierName = getSupplierResponse.supplier.find(c=> c.idSupplier == element.idSupplier).supplierName;
              });  
            });

            this.isLoading = false;
          },
          error: (error) => {
            this.isLoading = false;
            console.error(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          },
        });



      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  save(){
    if(this.isLoading) return;
    if(this.model.invalid) return;

    let serviceOrderPutRequest:ServiceOrderPutRequest = new ServiceOrderPutRequest();

    serviceOrderPutRequest.idServiceOrder = this.idServiceOrder;

    serviceOrderPutRequest.problem = this.model.get("problem").value;
    serviceOrderPutRequest.observation = this.model.get("observation").value;
    serviceOrderPutRequest.cpfCnpj = this.model.get("cpfCnpj").value?.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');
    serviceOrderPutRequest.clientName = this.model.get("clientName").value;
    serviceOrderPutRequest.email = this.model.get("email").value;
    serviceOrderPutRequest.phone = this.model.get("phone").value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
    serviceOrderPutRequest.whatsAppPhone = this.model.get("whatsAppPhone").value?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');



    serviceOrderPutRequest.listContact = [];
    this.model.get('listContact').value.forEach(x => {
      let contact:Contact = new Contact();

      contact.cpfCnpj = x.cpfCnpj?.replaceAll('.', '').replaceAll('-', '').replaceAll(' ', '');
      contact.name = x.name;
      contact.email = x.email;
      contact.phone = x.phone?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
      contact.whatsAppPhone = x.whatsAppPhone?.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
      contact.idContact = 0;
      contact.idServiceOrder = this.idServiceOrder;
      
      
      serviceOrderPutRequest.listContact.push(contact);
    });
  
    this.serviceOrderDetailsService.put(serviceOrderPutRequest).subscribe({

      next: (returnStruct: ReturnStruct) => {

        if (returnStruct.isError) {
          this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', 'Orçamento salvo com sucesso', AlertType.success);
        this.populateHeader();
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
        this.isLoading = false;
      }
    });
  
  }

  populateHeader() {
    setTimeout(() => {
      this.menu.reloadHeader(this.idServiceOrder);
    }, 1000);

  }

  catchValue(value){

  }

  showLine(index){ 
    this.getServiceOrderDetailsResponse.listQuoteServiceStruct[index].showDetails = !this.getServiceOrderDetailsResponse.listQuoteServiceStruct[index].showDetails ;
  }

  addListContact(contact:Contact){
    (this.model.controls['listContact'] as FormArray).push(
      this.newListContact(contact)
    );
  }

  newListContact(contact:Contact){
    return this.formBuilder.group({
      name: new FormControl(contact?.name, Validators.required),
      cpfCnpj: new FormControl(contact?.cpfCnpj),
      phone: new FormControl(contact?.phone, Validators.required),
      whatsAppPhone: new FormControl(contact?.whatsAppPhone),
      email: new FormControl(contact?.email)
    })
  }

  removeContact(index){
    (this.model.controls['listContact'] as FormArray).removeAt(index);
  }

  openModalClose(){
    const dialogRef = this.dialog.open(ServiceOrderCloseModalComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isCancel) { 
        this.serviceOrderDetailsService.close(this.idServiceOrder).subscribe({

          next: (returnStruct: ReturnStruct) => {
    
            if (returnStruct.isError) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }
    
            this.alertService.show('Sucesso', 'Orçamento fechado com sucesso', AlertType.success);

            this.router.navigate(['/workshop/service-order']);
            
          },
          error: (error) => {
            console.error(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
            this.isLoading = false;
          }
        });
      }
    });
  }

  openReopenModal(){
    const dialogRef = this.dialog.open(ServiceOrderReopenModalComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isReopen) { 
        this.serviceOrderDetailsService.reopen(this.idServiceOrder).subscribe({

          next: (returnStruct: ReturnStruct) => {
    
            if (returnStruct.isError) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }
    
            this.alertService.show('Sucesso', 'Orçamento reaberto com sucesso', AlertType.success);   
            this.populate();   

          },
          error: (error) => {
            console.error(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
            this.isLoading = false;
          }
        });
      }
    });
  }

  openDeleteModal(){
    const dialogRef = this.dialog.open(ServiceOrderDeleteModalComponent, {
      data: {},
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.isDelete) { 
        this.serviceOrderDetailsService.delete(this.idServiceOrder).subscribe({

          next: (returnStruct: ReturnStruct) => {
    
            if (returnStruct.isError) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }
    
            this.alertService.show('Sucesso', 'Orçamento deletado com sucesso', AlertType.success);

            this.router.navigate(['/workshop/service-order']);

          },
          error: (error) => {
            console.error(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
            this.isLoading = false;
          }
        });
      }
    });
  }

  newQuoteService(){
    this.router.navigate(['/workshop/quote-service'], { queryParams: { idServiceOrder: this.idServiceOrder } });
  }

  editQuoteService(idQuoteService){
    this.router.navigate(['/workshop/quote-service'], { queryParams: { idServiceOrder: this.idServiceOrder, idQuoteService:idQuoteService } });
  }
}
