export const environment = {
  production: false,
  staging: false,
  googleApiKey:"AIzaSyCeHsUbMu7P7n5D6ymzRvLQGmsYbVtp_xY",
  urlApiUser:'https://api.clusterdev.meros.com.br/meros-api-user/',
  urlApiVehicle:'https://api.clusterdev.meros.com.br/meros-api-vehicle/',
  urlApiAutoParts:'https://api.clusterdev.meros.com.br/meros-api-autoparts/',
  urlApiPurchase:'https://api.clusterdev.meros.com.br/meros-api-purchase/',
  urlApiServiceOrder:'https://api.clusterdev.meros.com.br/meros-api-service-order/',
  urlApiOrchestratorPurchase:'https://api.clusterdev.meros.com.br/meros-api-orchestrator-purchase/'
};
 
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
