import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ListSupplierOrdersResponse } from '../../responses/orchestrator-purchase/list-supplier-orders.response';
import { SupplierOrdersDetailResponse } from '../../responses/orchestrator-purchase/supplier-orders-detail.response';
import { ListPurchaseOrderRequest } from '../../requests/orchestrator-purchase/list-purchase-order.request';
import { GetPurchaseHistoryResponse } from '../../responses/orchestrator-purchase/get-purchase-history.response';
import { GetPurchaseHistoryDetailsResponse } from '../../responses/orchestrator-purchase/get-purchase-history.details.response';


@Injectable({
  providedIn: 'root'
})
export class PurchaseHistoryService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public Get(idBuyOrder: number): Observable<SupplierOrdersDetailResponse> {

    let url = `PurchaseHistory/SupplierOrderDetail/idBuyOrder/${idBuyOrder}`

    return this.httpClient.get<SupplierOrdersDetailResponse>(environment.urlApiOrchestratorPurchase + url,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public Post(body: ListPurchaseOrderRequest): Observable<ListSupplierOrdersResponse> {

    let url = `PurchaseHistory/GetSupplierPendingOrders`

    return this.httpClient.post<ListSupplierOrdersResponse>(environment.urlApiOrchestratorPurchase + url, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public PostFinished(body: ListPurchaseOrderRequest): Observable<ListSupplierOrdersResponse> {

    let url = `PurchaseHistory/GetSupplierFinishedOrders`

    return this.httpClient.post<ListSupplierOrdersResponse>(environment.urlApiOrchestratorPurchase + url, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public GetAllPendingPurchases(pageIndex: number, itemsPerPage: number): Observable<GetPurchaseHistoryResponse> {

    let url = `PurchaseHistory/GetPendingPurchase`

    return this.httpClient.get<GetPurchaseHistoryResponse>(environment.urlApiOrchestratorPurchase + url,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public GetAllFinishedPurchases(pageIndex: number, itemsPerPage: number): Observable<GetPurchaseHistoryResponse> {

    let url = `PurchaseHistory/GetFinishedPurchase`

    return this.httpClient.get<GetPurchaseHistoryResponse>(environment.urlApiOrchestratorPurchase + url,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
  
}
