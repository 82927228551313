import { Component, Inject, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchPartsRequest } from 'src/app/shared/services/API/requests/autoparts/search-parts.request';
import { SearchPartsResponse } from 'src/app/shared/services/API/responses/autoparts/Parts/search-parts.response';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.css']
})
export class SearchFilterComponent implements OnInit{

  constructor() {
  }
  
  @Input() openFilter:boolean;
  @Input() collectedParts: PartProductStruct[] = []
  @Output() clickClearFilter = new EventEmitter<any>();
  @Output() clickChangeFilter = new EventEmitter<{filterCheck: string, isChecked: boolean}>();

  ngOnInit(): void {
  }

  getUniqueParts(): PartProductStruct[] {
    const uniqueIds = new Set<string>();
    return this.collectedParts.filter(item => {
      if (uniqueIds.has(item.brandName)) {
        return false;
      } else {
        uniqueIds.add(item.brandName);
        return true;
      }
    });
  }

  onCheckboxChange(event: MatCheckboxChange, item?: PartProductStruct): void {
    let filterCheck = item.brandName
    const isChecked = event.checked;

    if (isChecked) {
    this.clickChangeFilter.emit({filterCheck, isChecked});
    } else {
      this.clickChangeFilter.emit({filterCheck, isChecked});
    }
  }

  clearFilter(){
    let checked = false
    this.clickClearFilter.emit()
  }
}
