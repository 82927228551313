import { Component, Output, HostListener, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDeleteComponent } from '../../modal-delete/modal-delete.component';
import { ModalDeliveryComponent } from '../../modal-delivery/modal-delivery.component';

@Component({
  selector: 'app-button-waiting-delivery',
  templateUrl: './button-waiting-delivery.component.html',
  styleUrls: ['./button-waiting-delivery.component.css']
})
export class ButtonWaitingDeliveryComponent {

  constructor(private dialogRef: MatDialog,){
    this.onResize();
  }

  @Input() idBuyOrder: number | undefined;
  @Input() idBuyOrderStatus: string | undefined;
  @Input() entrega: string | undefined;
  @Input() oficina: string | undefined;
  @Input() lalamoveLink: string | undefined;

  public screenWidth: number; 


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  modalDelete(){
    if(this.screenWidth >= 650){
      const dialogRef = this.dialogRef.open(ModalDeleteComponent,{
        width:'45%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          entrega: this.entrega,
        }
      })
    }else{
      const dialogRef = this.dialogRef.open(ModalDeleteComponent,{
        width:'70%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          entrega: this.entrega,
        }
      })
    }
  }

}
