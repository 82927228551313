<app-menu [menuModuleEnum]="menuModuleEnum" [menuFunctionalityEnum]="menuFunctionalityEnum" [hasSecondaryHeader]=false>
</app-menu>
<div class="container-body" *ngIf="closedQuoteResponse && closedQuoteResponse.getQuoteServiceResponse">
  <div class="budget row">
    <div class="col-md-5">
      <h2>
        Orçamento #1010 - Executado
      </h2>
    </div>
    <div class="button-actions col-md-7">
      <tr>
        <td>
          <button mat-button id="button" (click)="captureScreen()">Imprimir</button>
        </td>
        <!-- <td>
          <button mat-button id="button">Solicitar Pagamento</button>
        </td>
        <td>
          <button mat-button id="button">Emitir NF</button>
        </td> -->
      </tr>
    </div>
  </div>
  <div class="details-budget">
    <tr class="tr-budget">
      <td class="white-budget">
        <div>
          <h2>Compra</h2>
          <span>{{totalQuota | currency: 'BRL'}}</span>
        </div>
      </td>
      <td class="white-budget">
        <div>
          <h2>Estoque</h2>
          <span>{{totalStock | currency: 'BRL'}}</span>
        </div>
      </td>
      <td class="white-budget">
        <div>
          <h2>Serviço</h2>
          <span>{{totalDetail | currency: 'BRL'}}</span>
        </div>
      </td>
      <td class="white-budget">
        <div>
          <h2>Entrega</h2>
          <span>{{totalQuota | currency: 'BRL'}}</span>
        </div>
      </td>
      <td class="white-budget">
        <div>
          <h2>Total</h2>
          <span>{{totalPrice | currency: 'BRL'}}</span>
        </div>
      </td>
    </tr>
  </div>
  <div class="white-body">
    <h2>Peças</h2>
    <table class="basic-table bottom">
      <thead class="basic-table">
        <tr class="truncate">
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
      </thead>
      <tbody class="basic-table__body">
        <ng-container
          *ngFor="let item of closedQuoteResponse.getQuoteServiceResponse.listQuoteServicePart; let i = index">
          <tr class="basic-table__body__row">
            <td class="truncate">
              <img src="assets/images/search-plate.png" class="img-fluid rounded-start" alt="...">
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>{{item.partName}}</li>
                <li>{{item.partBrand}}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Preço Unidade</li>
                <li>{{item.price | currency : 'BRL' }}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Quantidade</li>
                <li>{{item.amount}}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Fornecedor</li>
                <li>{{ item.supplierName }}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Status</li>
                <li>Entregue</li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <h2>Peças do Estoque</h2>
    <table class="basic-table bottom">
      <thead class="basic-table">
        <tr class="truncate">
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
      </thead>
      <tbody class="basic-table__body">
        <ng-container *ngFor="let item of closedQuoteResponse.getQuoteServiceResponse.listStockPart; let i = index">
          <tr class="basic-table__body__row">
            <td class="truncate">
              <img src="assets/images/search-plate.png" class="img-fluid rounded-start" alt="...">
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>{{item.productName}}</li>
                <li>Bosch SP16</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Preço Unidade</li>
                <li>{{item.price | currency: 'BRL'}}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Quantidade</li>
                <li>{{item.amount}}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Fornecedor</li>
                <li>AutoBits</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Status</li>
                <li>Entregue</li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <h2>Serviço</h2>
    <table class="basic-table bottom">
      <thead class="basic-table">
        <tr class="truncate">
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
      </thead>
      <tbody class="basic-table__body">
        <ng-container *ngFor="let item of closedQuoteResponse.getQuoteServiceResponse.listServiceDetail; let i = index">
          <tr class="basic-table__body__row">
            <td class="row grid">
              <ul class="ul-format">
                <li></li>
                <li></li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>{{item.description}}</li>
                <li></li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Preço Unidade</li>
                <li>{{item.price | currency: 'BRL'}}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Quantidade</li>
                <li>{{item.amount}}</li>
              </ul>
            </td>
            <td class="row grid">
              <ul class="ul-format">
                <li>Executor</li>
                <li>AutoBits</li>
              </ul>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <h2>Detalhes</h2>
    <table class="basic-table bottom">
      <thead class="basic-table">
        <tr class="truncate">
          <th>
          </th>
          <th>
          </th>
          <th>
          </th>
      </thead>
      <tbody class="basic-table__body">
        <tr class="basic-table__body__row">
          <td class="row grid">
            <ul class="ul-format">
              <li></li>
              <li></li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li>Comprador: Nome do Usuário</li>
              <li>Placa: HNE-2853</li>
            </ul>
          </td>
          <td class="row grid">
            <ul class="ul-format">
              <li>Oficina: Nome da Oficina</li>
              <li>Veículo: Siena LX</li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="bottom">
      <h2>Entrega</h2>
      <h3>Endereço da Entrega</h3>
      <div class="delivery">
        <ul>
          <li>{{closedQuoteResponse?.branchDeliveryAddressStruct?.addressName}}</li>
          <li>
            {{closedQuoteResponse?.branchDeliveryAddressStruct?.street}},
            {{closedQuoteResponse?.branchDeliveryAddressStruct?.number}} -
            {{closedQuoteResponse?.branchDeliveryAddressStruct?.neighborhood}},
            {{closedQuoteResponse?.branchDeliveryAddressStruct?.city}}</li>
        </ul>
      </div>
      <h3>Método da Entrega</h3>
      <div class="delivery">
        <table class="basic-table">
          <thead class="basic-table">
            <tr class="truncate">
              <th>Ofinica
              </th>
              <th>Prazo da Entrega
              </th>
              <th>Valor
              </th>
          </thead>
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate">
                Entrega Meros
              </td>
              <td class="truncate">
                2 Horas
              </td>
              <td class="truncate">
                R$ 110,90
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div>
      <h2>Pagamento Peças</h2>
      <div class="bottom">
        <span>O Pagamento será efetuada pela plataforma</span>
      </div>
    </div>

    <div>
      <h2>Pagamento Entrega</h2>
      <span>O Pagamento da Entrega Meros é feito Separadamente</span>
      <div class="delivery">
        <table class="basic-table bottom">
          <thead class="basic-table">
            <tr class="truncate">
              <th>Cartão de Crédito/Débito
              </th>
              <th>Nome do Cartão
              </th>
              <th>Vencimento
              </th>
          </thead>
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate">
                {{closedQuoteResponse?.branchCreditCardResponse?.branchCreditCard?.creditCardDigits}}
              </td>
              <td class="truncate">
                {{closedQuoteResponse?.branchCreditCardResponse?.branchCreditCard?.creditCardName}}
              </td>
              <td class="truncate">
                {{closedQuoteResponse?.branchCreditCardResponse?.branchCreditCard?.expirationMonth}}/{{closedQuoteResponse?.branchCreditCardResponse?.branchCreditCard?.expirationYear}}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
