<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<!-- <app-search-header  [idServiceOrder]="idServiceOrder"  (OutputClickFilter)="clickFilter()" [modelSearch]="modelSearch" [getInfosResponse]="getInfosResponse" [plateNumber]="plateNumber" [year]="year"></app-search-header> -->


<div class="container-body" *ngIf="partsDetailsResponse != null && partsDetailsResponse != null">
  <div class="col-md-3">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>
  <div class="row product-section">
    <div class="card mb-4 div-product">
      <div class="row g-0">
        <!-- <div class="col-md-6 item-img" *ngFor="let item of imagens; let i = index" >
          <img *ngIf="imagens != null" src="{{'data:image/jpg;base64,' + item}}" />
          <img *ngIf="imagens == null" src="/assets/images/no-image.png" />
        </div> -->
        <div id="carouselExampleIndicators" class="col-md-6 carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button
              *ngFor="let item of imagens; let i = index"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              [attr.data-bs-slide-to]="i"
              [ngClass]="{'active': i === 0}" 
              [attr.aria-current]="i === 0 ? 'true' : null"
              [attr.aria-label]="'Slide ' + (i + 1)"
              class="indicator-button">
            </button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item item-img" *ngFor="let item of imagens; let i = index" [ngClass]="{'active': i === 0}">
              <img *ngIf="imagens != null" src="{{'data:image/jpg;base64,' + item}}" />
              <img *ngIf="imagens == null" src="/assets/images/no-image.png" />
            </div>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
          </button>
        </div>
        <div class="col-md-6">
          <div class="card-body">
            <h2 class="card-title" *ngIf="product?.productName != null" class="name"> {{product?.productName}}</h2>
            <h2 class="card-title" *ngIf="product?.productName == null" class="name">
              {{product?.supplierPartDescription}}</h2>
            <span *ngIf="product.partNumber != null" class="brand">{{product.brandName}} -
              {{product.partNumber}}</span>
            <span *ngIf="product.partNumber == null" class="brand">{{product.brandName}} -
              {{product.supplierPartNumber}}</span>
            <p class="card-price">{{product?.priceInvoice | currency: 'BRL'}}</p>
            <span class="card-text">Fornecedor: {{product.supplierName}}</span>
          </div>
          <form class="form" [formGroup]="model">
            <div class="amount">
              <span>Quantidade</span>
              <select formControlName="amount">
                <option *ngFor="let amount of generateAmountOptions()" [value]="amount">{{ amount }}</option>
              </select>
            </div>
            <a color="accent" type="button" class="btn btn-primary-blue" (click)="clickAddItem()">
              Adicionar ao carrinho
            </a>
          </form>
        </div>
      </div>
    </div>
    <div class="table">
      <div>
        <button (click)="setIsAplication()" [ngClass]="{'selected-tab':setAplication}" type="button" class="btn btn">
          <mat-icon>fullscreen</mat-icon>
          Aplicação
        </button>
      </div>
      <div>
        <button (click)="setIsSpecification()" [ngClass]="{'selected-tab':setSpecification}" type="button"
          class="btn btn">
          <mat-icon>assignment</mat-icon>
          Especificações Técnicas
        </button>
      </div>
      <div>
        <button (click)="setIsSimilares()" [ngClass]="{'selected-tab':setSimilares}" type="button" class="btn btn">
          <mat-icon> category</mat-icon>
          Similares
        </button>
      </div>
    </div>
    <div class="table-container">
      <table class="basic-table overflow-auto" *ngIf="setAplication">
        <thead class="basic-table__head">
          <tr class="truncate">
            <th>Montadora</th>
            <th>Veículo</th>
            <th>Modelo</th>
            <th>Motor</th>
            <th>ConfigMotor</th>
            <th>Inicio</th>
            <th>Fim</th>
            <th></th>
          </tr>
        </thead>
        <ng-container *ngFor="let item of applicability; let i = index">
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate">{{item.brandName}}</td>
              <td class="truncate">{{item.vehicleModelName}}</td>
              <td class="truncate">{{item.vehicleModelType}}</td>
              <td class="truncate">{{item.engineName}}</td>
              <td class="truncate">{{item.engineConfiguration}}</td>
              <td class="truncate">{{item.startYear}}</td>
              <td class="truncate">{{item.endYear}}</td>
              <td class="truncate"></td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="table-container">
      <table class="basic-table Specification overflow-auto" *ngIf="setSpecification">
        <thead class="basic-table__head">
          <tr class="truncate">
            <th>Fiscal</th>
            <th></th>
          </tr>
        </thead>
        <ng-container *ngFor="let item of specsFiscal; let i = index">
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate"><span>
                  {{item.descriptionName}}
                </span></td>
              <td class="truncate"><span>
                  {{item.value}}
                </span></td>
              <td class="truncate"></td>
            </tr>
          </tbody>
        </ng-container>
        <thead class="basic-table__head">
          <tr class="truncate">
            <th>Logístico</th>
            <th></th>
          </tr>
        </thead>
        <ng-container *ngFor="let item of specsLogistico; let i = index">
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate"><span>
                  {{item.descriptionName}}
                </span></td>
              <td class="truncate"><span>
                  {{item.value}}
                </span></td>
              <td class="truncate"></td>
            </tr>
          </tbody>
        </ng-container>
        <thead class="basic-table__head">
          <tr class="truncate">
            <th>Técnico</th>
            <th></th>
          </tr>
        </thead>
        <ng-container *ngFor="let item of specsTecnico; let i = index">
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate"><span>
                  {{item.descriptionName}}
                </span></td>
              <td class="truncate"><span>
                  {{item.value}}
                </span></td>
              <td class="truncate"></td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="table-container">
      <table class="basic-table overflow-auto" *ngIf="setSimilares">
        <thead class="basic-table__head">
          <tr class="truncate">
            <th>Marca Similar</th>
            <th>Código Similar</th>
            <th></th>
          </tr>
        </thead>
        <ng-container *ngFor="let item of similars; let i = index">
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate"><span>{{item.brandName}}</span></td>
              <td class="truncate"><span>{{item.partNumber}}</span></td>
              <td class="truncate"></td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
  </div>
</div>
