import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ServiceOrderDetailsService } from 'src/app/shared/services/API/services/serviceOrder/service-order-details.service';

@Component({
  selector: 'service-order-delete-modal.component',
  templateUrl: './service-order-delete-modal.component.html',
  styleUrls: ['./service-order-delete-modal.component.css']
})
export class ServiceOrderDeleteModalComponent {
  constructor(public matDialogRef: MatDialogRef<ServiceOrderDeleteModalComponent>,
    private serviceOrderDetailsService:ServiceOrderDetailsService){

  }

  cancel(){
    this.matDialogRef.close();
  }

  delete(){
    this.matDialogRef.close({isDelete:true});

  }
}
