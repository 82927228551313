import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { SpinnerService } from './shared/services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'meros-ui';
  // isLoading$: Observable<boolean>;

  constructor(private spinnerService: SpinnerService) {
    // this.isLoading$ = this.spinnerService.isLoading.asObservable();
    //this.updateClient();
    // if(updates.isEnabled){
    //   if (confirm('Nova versão disponível. Deseja atualizar?')) {
    //     this.spinnerService.show();
    //     updates.activateUpdate()
    //       .then(() => {
    //         this.spinnerService.hide();
    //         window.location.reload();
    //       })
    //       .catch(err => {
    //         this.spinnerService.hide();
    //         console.error('Erro ao ativar atualização:', err);
    //       });
    //   }
    // }
  }
  
  // updateClient() {
  //   if (!this.updates.isEnabled) {
  //     console.log('Not Enabled');
  //     return;
  //   }
  //   this.updates.available.subscribe((event) => {
  //     console.log(`current`, event.current, `available `, event.available);
  //     this.updates.activateUpdate().then(() => location.reload());
  //   });

  //   this.updates.activated.subscribe((event) => {
  //     console.log(`current`, event.previous, `available `, event.current);
  //   });
  // }
  
}
