import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMaskModule } from 'ngx-mask';
import { SchedulerModule } from 'angular-calendar-scheduler';
import { CalendarModule, DateAdapter, MOMENT } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import moment from 'moment';
import localept from '@angular/common/locales/pt';
import { GenericIndicatorDisplayComponent } from './components/generic-indicator-display/generic-indicator-display.component';
import { MenuComponent } from './menu/menu.component';
import { SelectComponent } from './components/select/select.component';
import { Select2Component } from './components/select/select2/select2.component';
import { SearchPlateModalComponent } from './components/search-plate-modal/search-plate-modal.component';
import { SecondaryHeaderComponent } from './components/secondary-header/secondary-header.component';
import { VehicleDetailsLabelComponent } from './components/vehicle-details-label/vehicle-details-label.component';
import { SelectVehicleVersionModalComponent } from './components/select-vehicle-version-modal/select-vehicle-version-modal.component';
import { PhonePipe } from './pipes/phone.pipe';
import { GooglePlaceInputComponent } from './components/google-place-input/google-place-input.component';
import { UserInfosComponent } from './menu/user-infos/user-infos.component';
import { ModalDeleteComponent } from '../supplier/pages/order-details-modal/modal-delete/modal-delete.component';
import { OrderDetailsModalComponent } from '../supplier/pages/order-details-modal/order-details-modal.component';
import { ButtonInSeparationNoRegisterComponent } from '../supplier/pages/order-details-modal/buttons/button-in-separation-no-register/button-in-separation-no-register.component';
import { SpinnerComponent } from './components/spinner/spinner/spinner.component';
import { ButtonInSeparationComponent } from '../supplier/pages/order-details-modal/buttons/button-in-separation/button-in-separation.component';
import { ModalDeliveryComponent } from '../supplier/pages/order-details-modal/modal-delivery/modal-delivery.component';
import { ButtonWaitingDeliveryComponent } from '../supplier/pages/order-details-modal/buttons/button-waiting-delivery/button-waiting-delivery.component';
import { ButtonDeliveryManArrivedComponent } from '../supplier/pages/order-details-modal/buttons/button-delivery-man-arrived/button-delivery-man-arrived.component';
import { SearchingForDriverComponent } from '../supplier/pages/order-details-modal/buttons/searching-for-driver/searching-for-driver.component';
import { ModalChangeBranchComponent } from './menu/user-infos/modal-change-branch/modal-change-branch.component';
import { ButtonConfirmDeliveryComponent } from '../supplier/pages/order-details-modal/buttons/button-confirm-delivery/button-confirm-delivery.component';
registerLocaleData(localept, 'pt');

@NgModule({
  declarations: [
    GenericIndicatorDisplayComponent,
    MenuComponent,
    SelectComponent,
    Select2Component,
    SearchPlateModalComponent,
    SecondaryHeaderComponent,
    VehicleDetailsLabelComponent,
    SelectVehicleVersionModalComponent,
    PhonePipe,
    GooglePlaceInputComponent,
    UserInfosComponent,
    ModalDeleteComponent,
    OrderDetailsModalComponent,
    ButtonInSeparationNoRegisterComponent,
    SpinnerComponent,
    ButtonInSeparationComponent,
    ModalDeliveryComponent,
    ButtonWaitingDeliveryComponent,
    ButtonDeliveryManArrivedComponent,
    SearchingForDriverComponent,
    ModalChangeBranchComponent,
    ButtonConfirmDeliveryComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    NgxMaskModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    CdkAccordionModule,
    MatProgressBarModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatDialogModule,
    MatSidenavModule,
    MatRadioModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SchedulerModule.forRoot({ locale: 'br', headerDateFormat: 'daysRange' }),
  ],
  exports: [
    GenericIndicatorDisplayComponent,
    MenuComponent,
    SelectComponent,
    Select2Component,
    SearchPlateModalComponent,
    SecondaryHeaderComponent,
    VehicleDetailsLabelComponent,
    SelectVehicleVersionModalComponent,
    PhonePipe,
    GooglePlaceInputComponent,
  ],
  providers: [
    { provide: MOMENT, useValue: moment },
    { provide: LOCALE_ID, useValue: 'pt' },
  ]
})
export class SharedModule { }
