import { Component, EventEmitter, ChangeDetectorRef, HostListener , OnInit, Output } from '@angular/core';
import { Location } from '@angular/common';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { SearchShoppingCarItemStruct, SearchShoppingCartService, SearchShoppingCartStruct } from '../search/search-shopping-cart/search-shopping-cart.service';
import { AlertService,AlertType } from 'src/app/shared/services/alert.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserLoginResponse } from 'src/app/shared/services/API/responses/user/user-login.response';
import { Router } from '@angular/router';
import { SupplierPartInfo } from 'src/app/shared/services/API/structs/autoparts/supplier-part-info.struct';

@Component({
  selector: 'app-shopping-cart-summary',
  templateUrl: './shopping-cart-summary.component.html',
  styleUrls: ['./shopping-cart-summary.component.css']
})
export class ShoppingCartSummaryComponent {
  constructor(
    private alertService: AlertService,
    public maskService: MaskService,
    private router: Router,
    private authService: AuthService,
    private searchShoppingCartService: SearchShoppingCartService,
    private location: Location,
    private cdr: ChangeDetectorRef
  ) {
    this.onResize();
  }

  public masks: Masks;
  public isLoading: boolean = false;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public cartStruct: SearchShoppingCartStruct;
  public listParts: SearchShoppingCarItemStruct[];
  public totalItems: number;
  public totalPrice: number = 0;
  // public formattedPrice: string;
  public userStruct: UserLoginResponse;
  public screenWidth: number;

  @Output() addChangeItem = new EventEmitter<any>();

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.userStruct = this.authService.getTokenMenu();
    this.cartStruct = this.searchShoppingCartService.getParts()
    this.updateListPart();
  }

  ngOnDestroy() {

  }

  generateAmountOptions(maxAmount): number[] {
    return Array.from({length: maxAmount}, (_, i) => i + 1);
  }

  onInputChange(event: any, item: any) {
    const inputValue = event.target.value;
    if (!isNaN(inputValue) && parseInt(inputValue) >= 1) {
        this.changeAmount(inputValue, item);
    } else {
        item.partProductStruct.totalQuantity = 1;
        event.target.value = 1;
    }
  }

  clearParts() {
    this.searchShoppingCartService.clearParts();
    this.listParts = [];
    this.totalItems = 0;
    this.totalPrice = 0;
    this.cdr.detectChanges();
    if (this.cdr) {
      this.alertService.show('Sucesso', 'Todos os itens foram removidos do seu carrinho.', AlertType.success);
    }
  }

  changeAmount(quantity, item){
    this.cartStruct = this.searchShoppingCartService.changeAmountPart(item.partProductStruct, quantity, this.cartStruct);
    this.updateListPart()
  }

  removeFromCart(item: SearchShoppingCarItemStruct){
    this.cartStruct = this.searchShoppingCartService.deleteaddPart(item, this.cartStruct);
    this.searchShoppingCartService.updateTotal(this.cartStruct)
    this.updateListPart()
    if (this.cartStruct) {
      this.alertService.show('Sucesso', 'Item removido do seu carrinho.', AlertType.success);
    }
  }

  saveForLater(item: SearchShoppingCarItemStruct){
    this.searchShoppingCartService.addWishListPart(item.amount,item.partProductStruct);
    this.cartStruct = this.searchShoppingCartService.deleteaddPart(item, this.cartStruct);
    this.searchShoppingCartService.updateTotal(this.cartStruct)
    this.updateListPart()
  }

  updateListPart() {
    this.totalPrice = 0;
    if (this.cartStruct && this.cartStruct.searchShoppingCarItemStruct) {
      this.listParts = this.cartStruct.searchShoppingCarItemStruct || [];
      this.totalItems = this.listParts.length;
  
      this.listParts.forEach(x => {
        this.totalPrice += (x.partProductStruct.priceInvoice * x.amount);
      });
    } else {
      this.listParts = [];
      this.totalItems = 0;
      this.totalPrice = 0;
    }
    this.cdr.detectChanges();
  }

  goBack(){
    this.location.back();
  }


}
