import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { SearchServiceOrderRequest } from '../../requests/autoparts/search-service-order.request';
import { SearchServiceOrderResponse } from '../../responses/autoparts/search-service-order.response';
import { SearchServiceOrderByPartRequest } from '../../requests/serviceOrder/search-service-order-by-part.request';
import { SearchServiceOrderByPartResponse } from '../../responses/serviceOrder/search-service-order-by-part-response';



@Injectable({
  providedIn: 'root'
})
export class SearchServiceOrderService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public post(body: SearchServiceOrderRequest): Observable<SearchServiceOrderResponse> {

    let url = `SearchServiceOrder`

    return this.httpClient.post<SearchServiceOrderResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getByIdParts(body: SearchServiceOrderByPartRequest): Observable<SearchServiceOrderByPartResponse> {

    let url = `SearchServiceOrder/idParts`

    return this.httpClient.post<SearchServiceOrderByPartResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
