<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Escolha um carro</h2>
        </div>
        
        <div class="footer-modal-list">
             <div class="row">
                <div class="col-12">
                    <!-- <button mat-flat-button color="primary" class="btn-block" (click)="clickConfirm()">
                        <span>Selecionar</span>
                    </button> -->
                </div>
            </div> 
        </div>
    </div>
</div>