<div mat-dialog-tittle class="dialog-tittle">
    <h2 class="tittle">CADASTRAR PERFIL</h2>
  
    <div mat-dialog-content class="dialog-content">
      <h2>Dados de Perfil</h2>
  <form [formGroup]="model" class="form">
    <div class="row forms-profile">
      <div class="col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Nome</mat-label>
          <input matInput type="text" formControlName="profileName">
        </mat-form-field>
      </div>
      <div class="col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>Descrição</mat-label>
          <input matInput type="text" formControlName="description">
        </mat-form-field>
      </div>
    </div>

    <div>
      <h2>Permissões</h2>
    </div>

    <div class="row options d-flex justify-content-between">
      <h3>Orçamento</h3>
      <!-- <input type="checkbox" [(ngModel)]="selectAll">Selecionar Todos -->
    </div>

    <div class="row">
      <div class="checkboxes col-md-4" *ngFor="let item of checkboxesBudget">
        <!-- <input type="checkbox" > -->
        <mat-checkbox [checked]="item.selected" (change)="checkboxChanged(item)">{{item.title}}</mat-checkbox>
      </div>
    </div>

    <div class="row options d-flex justify-content-between">
      <h3>Compra
        <!-- <input type="checkbox" [(ngModel)]="selectAll">
            Selecionar Todos -->
      </h3>
    </div>

    <div class="row">
        <div class="checkboxes col-md-4" *ngFor="let item of checkboxesBuy">
          <!-- <input type="checkbox" > -->
          <mat-checkbox [checked]="item.selected" (change)="checkboxChanged(item)">{{item.title}}</mat-checkbox>
        </div>
      </div>

    <div class="row options d-flex justify-content-between">
      <h3>Ordem de Serviço
        <!-- <input type="checkbox" [(ngModel)]="selectAll">
            Selecionar Todos -->
      </h3>

    </div>

    <div class="row">
        <div class="checkboxes col-md-4" *ngFor="let item of checkboxesService">
          <!-- <input type="checkbox" > -->
          <mat-checkbox [checked]="item.selected" (change)="checkboxChanged(item)">{{item.title}}</mat-checkbox>
        </div>
      </div>
  </form>
    </div>
  </div>
  
  <div class="button" mat-dialog-actions [align]="'end'">
    <button class="button-cancel" mat-raised-button mat-dialog-close="false">Cancelar</button>
    <button id="button-register" mat-raised-button mat-dialog-close="true" (click)="registerProfile()">Cadastrar</button>
  </div>
  
