<div class="container-body">
    <div class="vertical-aling-out">
        <div class="vertical-aling-inner">
            <div class="blue-box">
                <div class="align-center">
                    <img src="assets/logos/Yellow logo - no background.svg"/>
                </div>
                <h2>Login</h2>
                <form class="form" [formGroup]="model"  (ngSubmit)="signIn()">
                    <div class="row" *ngIf="state">   
                        <div class="col-md-12">
                            <mat-form-field appearance="outline" [color]="accent">
                                <mat-label>Email</mat-label>
                                <input matInput type="email" formControlName="email" required>
                                <mat-error *ngIf="model.get('email').invalid">Informe o email</mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <ng-container *ngIf="!state">
                        <app-verify-auth [formGroup]="model" [listBranch]="listBranch" [isLoading]="isLoading"
                            [email]="model.get('email').value" (goBack)="changeState($event)"></app-verify-auth>
                    </ng-container>
                    
                    <div class="row">
                        <div class="col-md-12 align-center" *ngIf="!listBranch">
                            <button mat-button type="submit" class="btn-primary btn-block" color="primary" (click)="getBranches()">
                                <span *ngIf="!isLoading">Entrar</span>
                                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
                            </button>                                          
                        </div>
                        <div class="align-center">
                            <!-- <p>Ainda não possui conta? <a routerLink="/register-new">Cadastre-se</a></p>    -->
                        </div>
                    </div>
                </form> 
            </div>
        </div>
    </div>
</div>