import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { getUserResponse } from '../../responses/user/get-user.response.';
import { UserRequest } from '../../requests/user/user.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
@Injectable({
    providedIn: 'root'
  })

export class UserService extends CommonService{

    constructor(
        private httpClient: HttpClient){
            super();
        }


        public get(idUser: number): Observable<getUserResponse> {

            let url = `user/idUser/${idUser}`
        
            return this.httpClient.get<getUserResponse>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

          public Post(body: UserRequest): Observable<ReturnStruct> {

            let url = `user/`
        
            return this.httpClient.post<ReturnStruct>(environment.urlApiUser + url, body, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }
          
          public Put(body: UserRequest, idUser: number): Observable<ReturnStruct> {

            let url = `user/idUser/${idUser}`
        
            return this.httpClient.put<ReturnStruct>(environment.urlApiUser + url, body, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

          public Delete(idUser: number): Observable<ReturnStruct> {

            let url = `user/idUser/${idUser}`
        
            return this.httpClient.delete<ReturnStruct>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

}