import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BranchStruct } from 'src/app/shared/services/API/structs/user/branch.struct';

@Component({
  selector: 'app-verify-auth',
  templateUrl: './verify-auth.component.html',
  styleUrls: ['./verify-auth.component.css']
})
export class VerifyAuthComponent implements OnInit {

  @Input() listBranch: BranchStruct[];
  @Input() isLoading: boolean[];
  @Input() formGroup: UntypedFormGroup;
  @Input() email: string;

  @Output() goBack = new EventEmitter();
  @ViewChild('password') passwordElement: ElementRef;

  constructor() { }

  ngOnInit(): void {
    
  }
  selectLogin(){
    this.goBack.emit();
  }
  onIdBranchChange(){
    setTimeout(()=> {
    if (this.passwordElement) {
      this.passwordElement.nativeElement.focus();
    }
   }), 200
  }
  ngOnChanges(changes: SimpleChanges): void{
    if(this.listBranch.length < 1 || this.formGroup.get('idBranch').valid || changes.formGroup.currentValue){
      this.onIdBranchChange();
    }
    
  }
}
