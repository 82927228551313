<app-menu [menuModuleEnum]="menuModuleEnum" [menuFunctionalityEnum]="menuFunctionalityEnum"></app-menu>

<div class="container-body">

  <h1>PEDIDOS</h1>
  <p>Acompanhe a chegada de novos pedidos de compra e monitore o histórico de pedidos respondidos</p>
  <hr />

  <div class="row">
    <div class="white-body">
      <div class="row">
        <div class="col-12 titleMenu">
          <h1 (click)="setIsOrders()" class="margin" [ngClass]="{'selected-tab':isPendingOrders}">Pedidos pendentes</h1>
          <h1 (click)="setIsFinishedOrders()" class="margin" [ngClass]="{'selected-tab':!isPendingOrders}">Pedidos Finalizados</h1>
        </div>
      </div>
      <div class="filter">
        <div class="row" [formGroup]="model">
          <div class="col-md-4">
            <mat-form-field>
              <mat-label>Insira um período</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
              </mat-date-range-input>
              <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status">
                <mat-option>Todos</mat-option>
                <mat-option value="1">Aguardando aprovação</mat-option>
                <mat-option value="2">Em separação</mat-option>
                <mat-option value="3">Procurando motorista</mat-option>
                <mat-option value="4">Em transito</mat-option>
                <mat-option value="5">Entregue</mat-option>
                <mat-option value="6">Negado</mat-option>
                <mat-option value="7">Sem crédito</mat-option>
                <mat-option value="8">Sem cadastro</mat-option>
                <mat-option value="9">Buscando a Peça</mat-option>
                <mat-option value="10">Cancelada</mat-option>
                <mat-option value="11">Expirada</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-4">
            <mat-form-field appearance="outline">
              <mat-label>Pesquisar Oficina ou ID</mat-label>
              <input formControlName="text" matInput type="text" (keyup.enter)="search()">
              <button matSuffix mat-icon-button aria-label="Clear" (click)="search()">
                <mat-icon>search</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="header">
            <button mat-button type="button" class="btn-primary-blue" color="primary" (click)="setRefrash()">
              <mat-icon class="material-symbols-outlined" *ngIf="!isLoading">sync</mat-icon>
              <span *ngIf="!isLoading">Atualizar</span>
              <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </button>
            <button mat-button color="primary" (click)="clearFilter()">Limpar Filtros</button>
          </div>
        </div>
      </div>
      <div class="row table-responsive" *ngIf="isPendingOrders && screenWidth >= 650">
        <table class="basic-table table-color"
          *ngIf="listSupplierOrdersResponse != null && listSupplierOrdersResponse.listSupplierOrder != null">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th class="id-format">ID</th>
              <th>Oficina</th>
              <th>Bairro</th>
              <th>Horario</th>
              <th class="status-format">Status</th>
              <th>Nº itens</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body"
            *ngFor="let item of slicedData; let i = index">
            <tr class="basic-table__body__row" (click)="modalDetails(item)">
              <td class="truncate">{{item.idBuyOrder}}</td>
              <td class="truncate">{{item.branchName}}</td>
              <td class="truncate">{{item.branchDeliveryAddress?.neighborhood}}</td>
              <td class="truncate">{{item.orderDateTimeInclusion | date: 'd MMMM y, HH:mm'}}</td>
              <td class="truncate status">
                <mat-chip-listbox>
                  <mat-chip style="background: #FFB800;" *ngIf="item.idBuyOrderStatus === 2">{{item.buyOrderStatusName}}
                  </mat-chip>
                  <mat-chip style="background: #3399FF;"
                    *ngIf="item.idBuyOrderStatus === 9 || item.idBuyOrderStatus === 1 || item.idBuyOrderStatus === 12">
                    {{item.buyOrderStatusName}}</mat-chip>
                  <mat-chip style="background: red;"
                    *ngIf="item.idBuyOrderStatus === 10 || item.idBuyOrderStatus === 6 || item.idBuyOrderStatus === 7 || item.idBuyOrderStatus === 11">
                    {{item.buyOrderStatusName}}</mat-chip>
                  <mat-chip style="background: #038E49;" *ngIf="item.idBuyOrderStatus === 5">
                    {{item.buyOrderStatusName}}</mat-chip>
                  <mat-chip style="background: orange;"
                    *ngIf="item.idBuyOrderStatus === 3 || item.idBuyOrderStatus === 4">
                    {{item.buyOrderStatusName}}</mat-chip>
                </mat-chip-listbox>
              </td>
              <td class="truncate">{{item.totalQuantity}}</td>
              <td class="truncate">{{item.totalPrice | currency: 'BRL'}}</td>
              <td class="truncate"></td>
            </tr>
            <tr *ngIf="listSupplierOrdersResponse.listSupplierOrder.length == 0">
              <td colspan="7">Não há pedidos de pendentes</td>
            </tr>
          </tbody>
        </table>
        <mat-paginator #paginator [length]="length" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="sliceData($event)"> </mat-paginator>
      </div>
      <div
        *ngIf="listSupplierOrdersResponse != null && listSupplierOrdersResponse.listSupplierOrder != null && screenWidth < 650 && isPendingOrders">
        <div class="row card-spaccing" *ngFor="let item of listSupplierOrdersResponse.listSupplierOrder">
          <div class="col-sm-6 w-100">
            <div class="card">
              <div class="card-body">
                <div class="row card-title">
                  <ul class="ul">
                    <li>
                      <span class="id second-title">ID</span>
                      <span class="title">{{item.idBuyOrder}}</span>
                    </li>
                    <li>
                      <span>{{item.orderDateTimeInclusion | date: 'd MMMM y, HH:mm'}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul class="ul">
                    <li>
                      <span class="second-title">
                        Oficina:
                      </span>
                      <span class="title">
                        {{item.branchName}}
                      </span>
                    </li>
                    <li>
                      <span class="second-title">
                        Itens:
                      </span>
                      <span class="title">
                        {{item.totalQuantity}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul>
                    <li>
                      <span class="second-title">
                        Bairro:
                      </span>
                      <span class="title">
                        {{item.branchDeliveryAddress?.neighborhood}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul class="ul">
                    <li>
                      <mat-chip-listbox>
                        <mat-chip style="background: #FFB800;" *ngIf="item.idBuyOrderStatus === 2">
                          {{item.buyOrderStatusName}}
                        </mat-chip>
                        <mat-chip style="background: #3399FF;"
                          *ngIf="item.idBuyOrderStatus === 9 || item.idBuyOrderStatus === 1 || item.idBuyOrderStatus === 12">
                          {{item.buyOrderStatusName}}</mat-chip>
                        <mat-chip style="background: red;"
                          *ngIf="item.idBuyOrderStatus === 10 || item.idBuyOrderStatus === 6 || item.idBuyOrderStatus === 7 || item.idBuyOrderStatus === 11">
                          {{item.buyOrderStatusName}}</mat-chip>
                        <mat-chip style="background: #038E49;" *ngIf="item.idBuyOrderStatus === 5">
                          {{item.buyOrderStatusName}}</mat-chip>
                        <mat-chip style="background: orange;"
                          *ngIf="item.idBuyOrderStatus === 3 || item.idBuyOrderStatus === 4">
                          {{item.buyOrderStatusName}}</mat-chip>
                      </mat-chip-listbox>
                    </li>
                    <li>
                      <span class="title-price">
                        {{item.totalPrice | currency: 'BRL'}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row a-card button-os">
                  <button class="open-purchase" (click)="modalDetails(item)">
                    <span style="color: #ffff;">VER PEDIDO</span>
                    <mat-icon style="color: #ffff;">chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row table-responsive" *ngIf="!isPendingOrders && screenWidth >= 650">
        <table
          *ngIf="listSupplierOrdersResponseFinished != null && listSupplierOrdersResponseFinished.listSupplierOrder != null">
          <thead class="basic-table__head">
            <tr class="truncate">
              <th class="id-format">ID</th>
              <th>Oficina</th>
              <th>Bairro</th>
              <th>Horario</th>
              <th class="status-format-finished">Status</th>
              <th>Nº itens</th>
              <th>Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="basic-table__body"
            *ngFor="let item of slicedDataFinished; let i = index">
            <tr class="basic-table__body__row" (click)="modalDetails(item)">
              <td class="truncate">{{item.idBuyOrder}}</td>
              <td class="truncate">{{item.branchName}}</td>
              <td class="truncate">{{item.branchDeliveryAddress?.neighborhood}}</td>
              <td class="truncate">{{item.orderDateTimeInclusion | date: 'd MMMM y, HH:mm'}}</td>
              <td class="truncate status">
                <mat-chip-listbox>
                  <mat-chip style="background: #FFB800;" *ngIf="item.idBuyOrderStatus === 2">{{item.buyOrderStatusName}}
                  </mat-chip>
                  <mat-chip style="background: #3399FF;"
                  *ngIf="item.idBuyOrderStatus === 9 || item.idBuyOrderStatus === 1 || item.idBuyOrderStatus === 12">
                  {{item.buyOrderStatusName}}</mat-chip>
                  <mat-chip style="background: red;"
                    *ngIf="item.idBuyOrderStatus === 10 || item.idBuyOrderStatus === 6 || item.idBuyOrderStatus === 7 || item.idBuyOrderStatus === 11">
                    {{item.buyOrderStatusName}}</mat-chip>
                  <mat-chip style="background: #038E49;" *ngIf="item.idBuyOrderStatus === 5">
                    {{item.buyOrderStatusName}}</mat-chip>
                  <mat-chip style="background: orange;"
                    *ngIf="item.idBuyOrderStatus === 3 || item.idBuyOrderStatus === 4">
                    {{item.buyOrderStatusName}}</mat-chip>
                </mat-chip-listbox>
              </td>
              <td class="truncate">{{item.totalQuantity}}</td>
              <td class="truncate">{{item.totalPrice | currency: 'BRL'}}</td>
              <td></td>
            </tr>
            <!-- <tr *ngIf="listSupplierOrdersResponse.listSupplierOrder.length == 0">
              <td colspan="7">Não há histórico de pedidos</td>
            </tr> -->
          </tbody>
        </table>
        <mat-paginator #paginator [length]="lengthFinished" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="sliceData($event)"> </mat-paginator>
      </div>
      <div
        *ngIf="listSupplierOrdersResponseFinished != null && listSupplierOrdersResponseFinished.listSupplierOrder != null && screenWidth < 650 && !isPendingOrders">
        <div class="row card-spaccing" *ngFor="let item of listSupplierOrdersResponseFinished.listSupplierOrder">
          <div class="col-sm-6 w-100">
            <div class="card">
              <div class="card-body">
                <div class="row card-title">
                  <ul class="ul">
                    <li>
                      <span class="id second-title">ID</span>
                      <span class="title">{{item.idBuyOrder}}</span>
                    </li>
                    <li>
                      <span>{{item.orderDateTimeInclusion | date: 'd MMMM y, HH:mm'}}</span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul class="ul">
                    <li>
                      <span class="second-title">
                        Oficina:
                      </span>
                      <span class="title">
                        {{item.branchName}}
                      </span>
                    </li>
                    <li>
                      <span class="second-title">
                        Itens:
                      </span>
                      <span class="title">
                        {{item.totalQuantity}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul>
                    <li>
                      <span class="second-title">
                        Bairro:
                      </span>
                      <span class="title">
                        {{item.branchDeliveryAddress?.neighborhood}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <ul class="ul">
                    <li>
                      <mat-chip-listbox>
                        <mat-chip style="background: #FFB800;" *ngIf="item.idBuyOrderStatus === 2">
                          {{item.buyOrderStatusName}}
                        </mat-chip>
                        <mat-chip style="background: #3399FF;"
                          *ngIf="item.idBuyOrderStatus === 9 || item.idBuyOrderStatus === 1 || item.idBuyOrderStatus === 12">
                          {{item.buyOrderStatusName}}</mat-chip>
                        <mat-chip style="background: red;"
                          *ngIf="item.idBuyOrderStatus === 10 || item.idBuyOrderStatus === 6 || item.idBuyOrderStatus === 7 || item.idBuyOrderStatus === 11">
                          {{item.buyOrderStatusName}}</mat-chip>
                        <mat-chip style="background: #038E49;" *ngIf="item.idBuyOrderStatus === 5">
                          {{item.buyOrderStatusName}}</mat-chip>
                        <mat-chip style="background: orange;"
                          *ngIf="item.idBuyOrderStatus === 3 || item.idBuyOrderStatus === 4">
                          {{item.buyOrderStatusName}}</mat-chip>
                      </mat-chip-listbox>
                    </li>
                    <li>
                      <span class="title-price">
                        {{item.totalPrice | currency: 'BRL'}}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="row a-card button-os">
                  <button class="open-purchase" (click)="modalDetails(item)">
                    <span style="color: #ffff;">VER PEDIDO</span>
                    <mat-icon style="color: #ffff;">chevron_right</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
