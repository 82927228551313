import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ListServiceOrderResponse } from '../../responses/serviceOrder/list-service-order.response';
import { SearchPartsRequest } from '../../requests/autoparts/search-parts.request';
import { SearchPartsResponse } from '../../responses/autoparts/Parts/search-parts.response';
import { PartsNamesRequest } from '../../requests/autoparts/parts-names.request';
import { PartProductStruct } from '../../structs/autoparts/part-product.struct';
import { PartsNamesResponse } from '../../responses/autoparts/parts-names.response';



@Injectable({
  providedIn: 'root'
})
export class PartsNamesService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public post(body: PartsNamesRequest): Observable<PartsNamesResponse> {

    let url = `PartsNames`

    return this.httpClient.post<PartsNamesResponse>(environment.urlApiAutoParts + url, body, this.addHeaderTokenUntorizred())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }
}
