import { Component, HostListener, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule, UntypedFormBuilder, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ListProfileService } from 'src/app/shared/services/API/services/profile/list-profile.service';
import { ListProfileReponse } from 'src/app/shared/services/API/responses/profile/list-profile.reponse';
import { GetProfileService } from 'src/app/shared/services/API/services/profile/get-profile.service';
import { GetProfileResponse } from 'src/app/shared/services/API/responses/profile/get-profile.reponse';
import { ModalProfilePermissionsEditComponent } from './modal-profile-permissions-edit/modal-profile-permissions-edit.component';
import { ModalProfilePermissionsDeleteComponent } from './modal-profile-permissions-delete/modal-profile-permissions-delete.component';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ProfileRequest } from 'src/app/shared/services/API/requests/profile/profile.request';
import { SeachProfileService } from 'src/app/shared/services/API/services/profile/seach-profile.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-profile-permissions',
  templateUrl: './profile-permissions.component.html',
  styleUrls: ['./profile-permissions.component.css'],
})

export class ProfilePermissionsComponent {

  constructor(private dialogRef: MatDialog,
    private formBuilder: FormBuilder,
    private listProfileService: ListProfileService,
    private getProfileService: GetProfileService,
    private seachProfileService: SeachProfileService,
    private activatedRoute:ActivatedRoute,
    private alertService:AlertService,
    private location: Location,){

      this.model = this.formBuilder.group({
        seachText: [''],
      });
      this.onResize();
  }

  @ViewChild(MatDialog) paginator: MatDialog;

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public FormFieldModuel: MatFormFieldModule;
  public model: FormGroup;
  public MatInputModule: MatInputModule;
  public MatRadioModule: MatRadioModule;
  public MatCheckboxModule: MatCheckboxModule;
  public ListProfileReponse: ListProfileReponse;
  public GetProfileResponse: GetProfileResponse;
  public isLoading: boolean;
  public screenWidth: number;

  ngOnInit(){
    this.populatePage()
    this.menuModuleEnum = parseInt(sessionStorage.getItem("module"));
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  backPage(){
    this.location.back();
  }

  populatePage() {
    if (this.listProfileService) {
      this.listProfileService.get().subscribe({
        next: (listProfileService: ListProfileReponse) =>{
          if(listProfileService.isError){
            this.alertService.show('Erro', listProfileService.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
            this.ListProfileReponse = listProfileService;

            this.ListProfileReponse.listProfile
        }
      });
    } else {
    }
  }

  openDialogEdit(item: any): void {
    const dialogRef = this.dialogRef.open(ModalProfilePermissionsEditComponent, {
      height: '80%',
      width: '80%',
      data: {
        profileName: item.profileName,
        description: item.description,
        idProfile: item.idProfile,
      },
    });

    dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
      const profileRequest: ProfileRequest = new ProfileRequest();
      profileRequest.description = formData.description;
      profileRequest.profileName = formData.profileName;
      profileRequest.listIdRole = [];
  
      const idProfile: number = item.idProfile;
  
      this.getProfileService.Put(profileRequest, idProfile).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            this.isLoading = false;
            return;
          }
          this.populatePage();
          this.alertService.show('Sucesso', 'Perfil editado', AlertType.success);
          this.dialogRef.closeAll();
        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Erro inesperado', error, AlertType.error);
          this.isLoading = false;
        }
      });
    });
  }

  openDialogNew() {
    const dialogRef = this.dialogRef.open(ModalProfilePermissionsEditComponent, {
      height: '80%',
      width: '80%',
    });

    dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
      const profileRequest: ProfileRequest = new ProfileRequest();
      profileRequest.description = formData.description;
      profileRequest.profileName = formData.profileName;
      profileRequest.listIdRole = [];

      this.getProfileService.Post(profileRequest).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Perfil cadastrado', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }

  openDialogDelete(item: any): void {
    const dialogRef = this.dialogRef.open(ModalProfilePermissionsDeleteComponent)
    const idProfile: number = item.idProfile;

    dialogRef.componentInstance.deleteConfirm.subscribe(() => {
      this.getProfileService.delete(idProfile).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Excluído', 'Perfil excluído', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }

  searchProfile(){
    const seachText = this.model.get('seachText').value;
    this.seachProfileService.get(seachText).subscribe({
      next: (seachProfileService: ListProfileReponse) => {
        if (seachProfileService && seachProfileService.isError) {
          if (this.alertService) {
            this.alertService.show('Erro', seachProfileService.errorDescription, AlertType.error);
          }
          return;
        }
        this.ListProfileReponse = seachProfileService;

        this.ListProfileReponse.listProfile
        // if (this.alertService) {
        //   this.alertService.show('Sucesso', 'Perfil cadastrado', AlertType.success);
        // }
      },
      error: (error) => {
        console.log(error);
        if (this.alertService) {
          this.alertService.show('Erro inesperado', error, AlertType.error);
        }
        this.isLoading = false;
      }
    });
  }

}

