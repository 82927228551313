import { Component, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  constructor(
    // private activatedRoute: ActivatedRoute,
    ){}

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public module: number;

  ngOnInit(){
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.menuModuleEnum = params.menuModuleEnum;
    // });
    this.menuModuleEnum = parseInt(sessionStorage.getItem("module"));
    this.module = parseInt(sessionStorage.getItem("module"));
  }

}
