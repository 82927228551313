<div class="conteudo-delivery">


  <div class="row">
    <div class="col-md-6">
      <span class="span-title">Selecione o Método de Entrega:</span>
    </div>
    <ng-container *ngIf="screenWidth >= 770">
      <div class="col-3 text-center">
        <span>Prazo de entrega</span>
      </div>
      <div class="col-3 text-center">
        <span>Valor</span>
      </div>
    </ng-container>
  </div>
  <hr>
  <form [formGroup]="model">
    <mat-radio-group *ngIf="screenWidth >= 770" aria-label="Select an option" formControlName="idDelivery"
      (change)="onChange()">
      <div class="row" *ngIf="!merosExpress">
        <div class="col-md-6">
          <mat-radio-button value="1">Meros</mat-radio-button>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <label>{{deliveryStandard}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <a>{{delivery.standardDeliveryCost | currency: 'BRL'}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="merosExpress">
        <div class="col-md-6">
          <mat-radio-button value="4">Meros Express</mat-radio-button>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <label>{{deliveryExpress}}</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <a>{{delivery.expressDeliveryCost | currency: 'BRL'}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-radio-button value="2">Entrega do Fornecedor</mat-radio-button>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <label>Sem estimativa</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <a>Sem estimativa</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <mat-radio-button value="3">Retirada</mat-radio-button>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <label>Sem estimativa</label>
            </div>
          </div>
        </div>
        <div class="col-md-3 text-center">
          <div class="vertical-aling-out">
            <div class="vertical-aling-inner">
              <a>Sem estimativa</a>
            </div>
          </div>
        </div>
      </div>
    </mat-radio-group>
    <mat-radio-group *ngIf="screenWidth < 770" aria-label="Select an option" formControlName="idDelivery"
      (change)="onChange()">
      <div class="row" *ngFor="let item of supplier.listSupplierDeliveryType">
        <mat-radio-button value="{{item.idDeliveryType}}">
          <label style="width: 100%;">
            <span style="font-weight: bold;">
                {{item.deliveryTypeName}}
            </span>
            <div class="vertical-aling-out">
              <div>
                <label>{{item.estimatedTimeArrival ? item.estimatedTimeArrival : 'Sem estimativa'}}</label>
              </div>
              <div class="row">
                <a>{{item.totalDeliveryFee ? this.formatedSelectedDeliveryPrice : 'Sem estimativa'}}</a>
              </div>
            </div>
          </label>
        </mat-radio-button>
      </div>
    </mat-radio-group>

  </form>
</div>
