<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="row">
            <div class="col-md-6">
                <h1>Cadastro de OS</h1>
            </div>
        </div>
      
        
        <p>Pesquise uma placa para abrir uma OS</p>
        <form class="form" [formGroup]="model" (ngSubmit)="searchPlate()">
            <mat-form-field appearance="outline">
                <mat-label>Placa</mat-label>
                <input matInput type="text" formControlName="plateNumberSearch">
            </mat-form-field>

            <button type="submit" mat-flat-button class="btn btn-primary-blue">         
                <span class="text-submit"  *ngIf="!isLoading"><mat-icon>search</mat-icon>Pesquisar</span> 
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </button>
        

            <div class="infos-os" *ngIf="vehicleVersion != null">
                <div class="row">
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'MARCA'" [content]="vehicleVersion.brandName"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'MODELO'" [content]="vehicleVersion.modelName"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'VERSÃO'" [content]="vehicleVersion.versionName"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'ANO'" [content]="year"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'MOTOR'" [content]="vehicleVersion.engine"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'CÂMBIO'" [content]="vehicleVersion.transmission"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'FIPE ID'" [content]="vehicleVersion.fipeCode"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'COMBUSTÍVEL'" [content]="vehicleVersion.fuel"></app-vehicle-details-label>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <app-vehicle-details-label [label]="'KM Atual'" [isFormField]="true" (passFormFieldValue)="catchValue($event)"></app-vehicle-details-label>
                    </div>
                </div>

                <h2>Proprietário</h2>
                <div class="row">
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome e sobrenome</mat-label>
                            <input matInput type="text" formControlName="clientName">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Telefone</mat-label>
                            <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4">
                        <mat-form-field appearance="outline">
                            <mat-label>WhatsApp</mat-label>
                            <input matInput type="text" formControlName="whatsAppPhone" [mask]="masks.phone">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>E-mail</mat-label>
                            <input matInput type="text" formControlName="email">
                        </mat-form-field>
                    </div>
                    <div class="col-md-6">
                        <mat-form-field appearance="outline">
                            <mat-label>CPF / CNPJ</mat-label>
                            <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div class="row col-md-6">
                    <a mat-flat-button class="btn btn-primary-blue goNext" (click)="goNext()">
                        <span class="text-submit"  *ngIf="!isLoadingNext">Próximo</span> 
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingNext"></mat-spinner>
                    </a>
                    <a class="goBack" mat-flat-button (click)="goBack()">Voltar</a>
                </div> -->
                <div mat-dialog-actions [align]="'end'">
                    <button class="button-cancel goBack" (click)="goBack()" mat-raised-button mat-dialog-close="false">Voltar</button>
                    <button id="button-register" *ngIf="!isLoadingNext" mat-raised-button (click)="goNext()">
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoadingNext"></mat-spinner>
                        Próximo
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>