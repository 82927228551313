<div mat-dialog-actions id="button">
    <div>
        <button id="button-cancel" mat-button color="warn" (click)="modalDelete()">Cancelar Pedido</button>
    </div>
    <span  *ngIf="entrega === 'Meros'"  class="confirm">Peças Prontas?
        <button class="confirm" id="button-confirm" mat-raised-button (click)="callDelivery()">CHAMAR ENTREGADOR
        </button>
        <button mat-button color="primary" (click)="modalConfirmDelivery()">Pedido Entregue ?</button>
    </span>
    <span *ngIf="entrega === 'Entrega própria'"  class="confirm">
        <button class="confirm" id="button-confirm" mat-raised-button (click)="callDelivery()">MARCAR COMO ENVIADO
        </button>
    </span>
    <span *ngIf="entrega === 'Retirada'"  class="confirm">
        <button class="confirm" id="button-confirm" mat-raised-button (click)="callDelivery()">PRONTO PARA RETIRADA
        </button>
    </span>
</div>
