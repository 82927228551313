import { ReturnStruct } from "src/app/shared/structs/return.struct";
import { SupplierStruct } from "../../structs/orchestrator-purchase/supplier.struct";
import { BranchDeliveryAddress } from "../../models/user/branch-delivery-address.model";
import { BranchCreditCard } from "../../models/user/branch-credit-card.model";
import { SupplierBranchStruct } from "../../structs/orchestrator-purchase/supplier-branch.struct";

export class GetPurchaseDataResponse extends ReturnStruct{
    public idUser: number;
    public userName: string;
    public branchName: string;
    public plateNumber: string;
    public vehicleModelName: string;
    public listBranchCreditCard: BranchCreditCard[];
    public listBranchDeliveryAddress: BranchDeliveryAddress[];
    public listSupplierBranchStruct: SupplierBranchStruct[];
}