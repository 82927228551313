import { Component, Output, EventEmitter, Input, HostListener } from '@angular/core';
import { MatDialog , MatDialogRef } from '@angular/material/dialog';
import { ModalDeleteComponent } from '../../modal-delete/modal-delete.component';
import { ModalDeliveryComponent } from '../../modal-delivery/modal-delivery.component';
import { ChangeStatusBuyOrderService } from 'src/app/shared/services/API/services/purchase/change-status-buy-order.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { FlagShippedBuyOrderService } from 'src/app/shared/services/API/services/purchase/flag-Shipped-buy-order.service';
import { ConfirmBuyOrderService } from 'src/app/shared/services/API/services/purchase/confirm-buy-order.service';

@Component({
  selector: 'app-button-in-separation',
  templateUrl: './button-in-separation.component.html',
  styleUrls: ['./button-in-separation.component.css']
})
export class ButtonInSeparationComponent {

  constructor(private dialog: MatDialog,
    private changeStatusBuyOrderService: ChangeStatusBuyOrderService,
    private flagShippedBuyOrderService: FlagShippedBuyOrderService,
    private confirmBuyOrderService: ConfirmBuyOrderService,
    private alertService:AlertService,){
      this.onResize();
  }

  public isLoading: boolean;
  public loadedData: boolean = false;
  public screenWidth: number; 

  @Input() idBuyOrder: number | undefined;
  @Input() idBuyOrderStatus: string | undefined;
  @Input() entrega: string | undefined;
  @Input() street: string | undefined;
  @Input() neighborhood: string | undefined;
  @Input() city: string | undefined;
  @Input() number: string | undefined;
  @Input() state: string | undefined;
  @Input() zipcode: string | undefined;
  @Input() idSupplier: number | undefined;
  @Input() oficina: string | undefined;
  @Output() refreshInfo: EventEmitter<void> = new EventEmitter<void>();


  ngOnInit(){
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  callDelivery(): void{
    if(this.entrega === 'Meros' && this.screenWidth >= 650){
      const dialogRef = this.dialog.open(ModalDeliveryComponent, { // Changed this line
        width:'40%',
        height: '90%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          idSupplier: this.idSupplier,
          entrega: this.entrega,
          street: this.street,
          neighborhood: this.neighborhood,
          number: this.number,
          state: this.state,
          zipcode: this.zipcode,
          city: this.city,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // Check result if necessary
        this.refreshInfo.emit();
      });
    }else if(this.entrega === 'Meros' && this.screenWidth < 650){
      const dialogRef = this.dialog.open(ModalDeliveryComponent, { // Changed this line
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          idSupplier: this.idSupplier,
          entrega: this.entrega,
          street: this.street,
          neighborhood: this.neighborhood,
          number: this.number,
          state: this.state,
          zipcode: this.zipcode,
          city: this.city,
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        // Check result if necessary
        this.refreshInfo.emit();
      });
    }else if(this.entrega === 'Retirada'){
      this.confirmBuyOrderService.pickUpInStore(this.idBuyOrder).subscribe({
        next: (returnStruct: ReturnStruct) =>{
          if(returnStruct && returnStruct.isError){
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.refreshInfo.emit();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Pedido Pronto para Retirada', AlertType.success);
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      })
    }else{
      this.flagShippedBuyOrderService.Get(this.idBuyOrder).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.refreshInfo.emit();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Pedido Enviado', AlertType.success);
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    }
  }

  modalDelete() {
    if(this.screenWidth >= 650){
      const dialogRef = this.dialog.open(ModalDeleteComponent,{
        width:'45%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          entrega: this.entrega,
        }
      })
    }else{
      const dialogRef = this.dialog.open(ModalDeleteComponent,{
        width:'70%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          entrega: this.entrega,
        }
      })
    }
  }
}
