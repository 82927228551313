import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatButtonModule } from '@angular/material/button';
import { QuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/quote-service.service';
import { GetQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-quote-service.response';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { SupplierRequest } from 'src/app/shared/services/API/requests/user/supplier.request';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { SupplierOrdersDetailResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/supplier-orders-detail.response';
import { GetPurchaseHistoryDetailsResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.details.response';
import { PurchaseHistoryDetailsService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history-details.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@Component({
  selector: 'app-purchase-made',
  templateUrl: './purchase-made.component.html',
  styleUrls: ['./purchase-made.component.css']
})
export class PurchaseMadeComponent {

  constructor(private purchaseHistoryDetailsService: PurchaseHistoryDetailsService,
    private supplierService: SupplierService,
    private route: ActivatedRoute,
    private alertService:AlertService,
    private location: Location,
    private router: Router,
    ){

  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public matButtonModule:MatButtonModule;
  public getPurchaseHistoryDetailsResponse:GetPurchaseHistoryDetailsResponse;
  public getSupplierResponse: GetSupplierResponse;
  public supplierOrdersDetailResponse: SupplierOrdersDetailResponse;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public isLoading: boolean;
  public idPurchase: number;
  public name: string;
  public phone: string;
  public totalQuota: number;
  public totalDetail: number;
  public totalStock: number;
  public totalPrice: number;
  public array: any;
  public pagamento: any;
  public distinctSuppliers: any;
  public loading: boolean = false;
  public modalAberto = false;
  public loadedData: boolean = false;

  ngOnInit() {
    this.route.params.subscribe(params => {
        this.idPurchase = params['idPurchase'];
        this.name = params['name'];
        this.phone = params['phone'];
    });
    this.populatePage();
  }

  direct(url){
    this.router.navigate([
      url
    ]);
  }

  populatePage() {
    this.loading = true;
    if (this.purchaseHistoryDetailsService) {
      this.purchaseHistoryDetailsService.Get(this.idPurchase).subscribe({
        next: (getPurchaseHistoryDetailsResponse: GetPurchaseHistoryDetailsResponse) =>{
          if(getPurchaseHistoryDetailsResponse.isError){
            this.alertService.show('Erro', getPurchaseHistoryDetailsResponse.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.getPurchaseHistoryDetailsResponse = getPurchaseHistoryDetailsResponse;
          this.array = this.getPurchaseHistoryDetailsResponse.purchaseDeatailsStruct;
          this.modalAberto = true;
          this.loadedData = true;

          this.distinctSuppliers = this.array.listBuyOrderPart.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.supplierName === value.supplierName
            ))
          );
        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show("Ação não concluída.", error, AlertType.error);
        }
      });
    }
  }

  convertMinutesToHours(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    return `${hours} horas`;
  }
  

  goBack(): void {
    this.location.back();
  }

  captureScreen() {
    window.print();
  }

}
