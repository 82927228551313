import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Injectable({
    providedIn: 'root'
  })

export class CancelBuyOrderService extends CommonService{

    constructor(
        private httpClient: HttpClient) {
        super();
      }

    public Get(idBuyOrder: number): Observable<ReturnStruct> {

        let url = `BuyOrder/status/cancelBuyOrder/idBuyOrder/${idBuyOrder}`
    
        return this.httpClient.get<ReturnStruct>(environment.urlApiPurchase + url, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }
}