import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteServiceStatusEnum } from 'src/app/shared/enums/serviceOrder/quote-service-status-enum';
import { StockPart } from 'src/app/shared/services/API/models/serviceOrder/stock-part.model';
import { PartsNamesRequest } from 'src/app/shared/services/API/requests/autoparts/parts-names.request';
import { GetClientAprovalQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-client-aproval-quote-service.response';
import { GetQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-quote-service.response';
import { PartsNamesResponse } from 'src/app/shared/services/API/responses/autoparts/parts-names.response';
import { ClientQuoteService } from 'src/app/shared/services/API/services/serviceOrder/client-quote-service.service';
import { QuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/quote-service.service';
import { PartsNamesService } from 'src/app/shared/services/API/services/autoparts/parts-names.service';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';

@Component({
  selector: 'app-client-quote-service',
  templateUrl: './client-quote-service.component.html',
  styleUrls: ['./client-quote-service.component.css']
})
export class ClientQuoteServiceComponent implements OnInit{
    constructor( private activatedRoute: ActivatedRoute, 
      public dialog: MatDialog,
      public alertService:AlertService,
      private router: Router,
      private partsNamesService:PartsNamesService,
      private clientQuoteService:ClientQuoteService){

    }

    public idQuoteService:number;
    public getQuoteServiceResponse:GetQuoteServiceResponse;
    public isLoading:boolean;
    public blockActions = false;

    public totalPart = 0;
    public totalService = 0;
    public totalPrice = 0;
    public isError = false;

    ngOnInit(): void {
      this.isLoading = false;

      this.activatedRoute.queryParams.subscribe(params => {
        this.idQuoteService = params.idQuoteService;
        this.populatePage();
      });
    }

    populatePage(){
      this.clientQuoteService.get(this.idQuoteService).subscribe({
        next: (getQuoteServiceResponse: GetQuoteServiceResponse) => {
  
          if (getQuoteServiceResponse.isError) {
            this.alertService.show('Erro', getQuoteServiceResponse.errorDescription, AlertType.error);
            this.isLoading = false;
            this.isError = true;
            return;
          }

          
 
          this.getQuoteServiceResponse = getQuoteServiceResponse;

          //Lista produtos nomes
          let partsNamesRequest:PartsNamesRequest = new PartsNamesRequest();
          partsNamesRequest.listPartID = getQuoteServiceResponse.listQuoteServicePart.map(c=> c.idPart);

          this.partsNamesService.post(partsNamesRequest).subscribe({
            next: (partsNamesResponse: PartsNamesResponse) => {
      
              if (partsNamesResponse.isError) {
                this.alertService.show('Erro', partsNamesResponse.errorDescription, AlertType.error);
                this.isLoading = false;
                this.isError = true;
                return;
              }
     
              //Insere todas as peças na lista de peças de estoque para imprimir na tela
              getQuoteServiceResponse.listQuoteServicePart.forEach(element => {
                let partProductStruct:PartProductStruct = partsNamesResponse.listPartProduct.find(c=> c.idPart == element.idPart);
                
                let stockPart:StockPart = new StockPart();

                stockPart.productName = partProductStruct.productName;
                stockPart.productBrand = partProductStruct.brandName;
                stockPart.price = element.price;
                stockPart.amount = element.amount;

                getQuoteServiceResponse.listStockPart.push(stockPart);
              });
      
              //Calcula os totais
              getQuoteServiceResponse.listStockPart.forEach(element => {
                let price:number = element.amount * element.price;
                this.totalPart =  this.totalPart + price;
              });

              getQuoteServiceResponse.listServiceDetail.forEach(element => {
                let price:number = element.amount * element.price;
                this.totalService =  this.totalPart + price;
              });

              this.totalPrice = this.totalPart  + this.totalService;
            },
            error: (error) => {
              console.error(error);
              this.alertService.show('Ação não concluída.', error, AlertType.error);
              this.isLoading = false;
              this.isError = true;
            }
          });
  
        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
          this.isLoading = false;
          this.isError = true;
        }
      });
    }

    aprove(){
      this.isLoading = true;
      this.clientQuoteService.put(this.idQuoteService, QuoteServiceStatusEnum.aprovado).subscribe({
        next: (returnStruct: ReturnStruct) => {
          this.isLoading = false;

          if (returnStruct.isError) {
            this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            return;
          }
 
          this.alertService.show('Sucesso', "Orçamento aprovado. Seu mecanico ja esta sendo avisado.", AlertType.success);
          this.blockActions = true;
        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }

    reject(){
      this.isLoading = true;
      this.clientQuoteService.put(this.idQuoteService, QuoteServiceStatusEnum.rejeitado).subscribe({
        next: (returnStruct: ReturnStruct) => {
          this.isLoading = false;

          if (returnStruct.isError) {
            this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            return;
          }
 
          this.alertService.show('Sucesso', "Orçamento rejeitado. Seu mecanico ja esta sendo avisado.", AlertType.warning);
          this.blockActions = true;
        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }
}
