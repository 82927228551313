<div class="mat-dialog-tittle">
    <h2>DELETAR PERFIL?</h2>
</div>

<div mat-dialog-content>
    <div class="alert alert-danger d-flex align-items-center" role="alert">
        <mat-icon color="warn">warning</mat-icon>
        <div class="icon-delete">
            Esta ação não pode ser desfeita
        </div>
      </div>
</div>

<div mat-dialog-actions [align]="'center'">
    <button mat-raised-button mat-dialog-close="false">Cancelar</button>
    <button mat-raised-button mat-dialog-close="true" color="warn" (click)="deleteProfile()">Deletar</button>
</div>
