import { QuoteServicePart } from "../../models/serviceOrder/quote-service-part.model";
import { ServiceDetail } from "../../models/serviceOrder/service-detail.model";
import { StockPart } from "../../models/serviceOrder/stock-part.model";
import { ContactRequestStruct } from "../../structs/serviceOrder/contact-request.struct";

export class PostQuoteServiceRequest{
    public idServiceOrder: number;
    public idQuoteService: number;

    public listQuoteServicePart: QuoteServicePart[];
    public listServiceDetail: ServiceDetail[];
    public listStockPart: StockPart[];
}