<div class="secundary-header desktop">
    <div class="display-flex justify-between">
        <div>            
            <div class="display-flex">
                <div>
                    <img class="max-size" [src]="carImgSource"/>
                </div>
                <div class="info-content">
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <span class="name">{{secundaryHeaderInfos ? secundaryHeaderInfos.plateNumber : 'Nenhuma placa buscada'}}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div>
                                <span class="text-bold">Veículo:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.vehicleModelName + ' - ' + secundaryHeaderInfos.brandName : '-'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <span class="text-bold">Nome Cliente:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.clientName : '-'}}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div>
                                <span class="text-bold">Telefone do cliente:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? (secundaryHeaderInfos.phone | phone): '-'}}</span>
                            </div>   
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <span class="text-bold">Data abertura OS:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? (secundaryHeaderInfos.datetimeInclusion | date: 'HH:mm - dd/MM/yyyy') : '-'}}</span>
                            </div>
                        </div>
                        
                        <div class="col-12">
                            <div>
                                <span class="text-bold">tempo de OS:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.osDuration : '-'}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div>
                                <span class="text-bold">Status:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.statusName : '-'}}</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div>
                                <span class="text-bold">OS ID:</span>
                                <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.idServiceOrder : '-'}}</span>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<mat-progress-bar class="desktop" *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>

<cdk-accordion class="accordion mobile">
    <cdk-accordion-item #accordionItem="cdkAccordionItem" class="accordion-item" role="button" tabindex="0"
        [attr.id]="'accordion-header'" [attr.aria-expanded]="accordionItem.expanded"
        [attr.aria-controls]="'accordion-body'">
        <div [ngClass]="{'accordion-item-header-expanded': accordionItem.expanded}" class="accordion-item-header"
            (click)="accordionItem.toggle()">
            <div class="name-info display-flex">
                <div class="name truncate">
                    <span class="name">{{secundaryHeaderInfos ? secundaryHeaderInfos.plateNumber : 'Nenhuma placa buscada'}}</span>
                </div>
            </div>
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="accordion-item-body" role="region" [style.display]="accordionItem.expanded ? '' : 'none'"
            [attr.id]="'accordion-body'" [attr.aria-labelledby]="'accordion-header'">
            <div class="info-content">
                <div class="line-height">
                    <span class="name">{{secundaryHeaderInfos ? secundaryHeaderInfos.plateNumber : 'Nenhuma placa buscada'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Veículo:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.vehicleModelName : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Nome Cliente:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.clientName : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Telefone do cliente:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? (secundaryHeaderInfos.phone | phone): '-'}}</span>
                </div>                    
                <div class="line-height">
                    <span class="text-bold">Data abertura OS:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? (secundaryHeaderInfos.datetimeInclusion | date: 'HH:mm - dd/MM/yyyy') : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">tempo de OS:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.osDuration : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">Status:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.statusName : '-'}}</span>
                </div>
                <div class="line-height">
                    <span class="text-bold">OS ID:</span>
                    <span class="padding-left-1">{{secundaryHeaderInfos ? secundaryHeaderInfos.idServiceOrder : '-'}}</span>
                </div>
            </div>
        </div>
    </cdk-accordion-item>
</cdk-accordion>