import { ServiceOrder } from "../../models/auto-parts/service-order.model";
import { QuoteServiceStruct } from "./quote-service-struct";

export class ServiceOrderStruct extends ServiceOrder{
    public statusName: string;
    public countQuoteOpen: number;
    public countQuoteWaitingApproval: number;
    public countQuoteApproved: number;
    public countQuoteClose: number;
    public listQuoteServiceStruct: QuoteServiceStruct[];
    public totalPrice:number;

    public vehicleModelName:string;
    public plateNumber:string;
    public isLoading:boolean;

    public showDetails:boolean;
}