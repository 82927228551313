import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetSupplierResponse } from '../../responses/user/get-supplier.response';
import { SupplierRequest } from '../../requests/user/supplier.request';


@Injectable({
  providedIn: 'root'
})
export class SupplierService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public post(body: SupplierRequest): Observable<GetSupplierResponse> {

    let url = `GetSupplier`

    return this.httpClient.post<GetSupplierResponse>(environment.urlApiUser + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

}
