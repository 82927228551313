<app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
  [hasSecondaryHeader]=false></app-menu>

<div class="container-body">
  <div class="row">
    <!-- <div class="white-body-shopping col">
      <div class="icons-white-body">
        <span>Compras em Trânsito</span>
        <h2>5</h2>
      </div>
      <mat-icon class="local_shipping_icon"> local_shipping</mat-icon>
    </div>
    <div class="white-body-shopping col middle-body">
      <div class="icons-white-body">
        <span>Orçamentos pendentes de aprovação</span>
        <h2>10</h2>
      </div>
      <mat-icon class="shopping_cart_icon">restore</mat-icon>
    </div> -->
    <div class="white-body-shopping col">
      <div class="icons-white-body">
        <span>Compras do Mês</span>
        <h2>{{this.length}}</h2>
      </div>
      <mat-icon class="package_2_icon">inbox</mat-icon>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="white-body">
        <div class="row">
          <h2>Histórico de Ordens de Serviço</h2>
          <hr>
        </div>
        <a mat-button class="btn-primary-blue" color="primary" (click)="openModalNewSO()">
          <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus">add</mat-icon>
          <span>Nova ordem de serviço</span>
        </a>

        <form class="form" [formGroup]="model" (ngSubmit)="search(false)">

          <div class="row">
            <div class="col-md-2">
              <mat-form-field>
                <mat-label>Data</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate formControlName="start" placeholder="Start date">
                  <input matEndDate formControlName="end" placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Placa</mat-label>
                <input matInput type="text" formControlName="plate">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Marca</mat-label>
                <input matInput type="text" formControlName="brand">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Modelo</mat-label>
                <input matInput type="text" formControlName="vehicleModel">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <input matInput type="text" formControlName="idStatusServiceOrder">
              </mat-form-field>
            </div>
            <div class="col-md-2">
              <mat-form-field appearance="outline">
                <mat-label>Cliente</mat-label>
                <input matInput type="text" formControlName="clientName">
              </mat-form-field>
            </div>

            <div class="header">
              <button mat-button type="submit" class="btn-primary-blue" color="primary">
                <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus">search</mat-icon>
                <span>Pesquisar</span>
              </button>
              <button mat-button color="primary" (click)="clearFilter()">Limpar Filtros</button>
            </div>
          </div>
          <div class="row" *ngIf="screenWidth >= 650">
            <table class="basic-table table-color"
              *ngIf="listServiceOrderResponse != null && listServiceOrderResponse.listServiceOrderStruct != null">
              <thead class="basic-table__head">
                <tr class="truncate">
                  <th></th>
                  <th>ID</th>
                  <th>Data de abertura</th>
                  <th>Placa</th>
                  <th>Veículo</th>
                  <th>Cliente</th>
                  <th>Status</th>
                  <th>Orçamentos</th>
                  <th>Total</th>
                  <th></th>
                </tr>
              </thead>
              <tbody class="basic-table__body" *ngFor="let item of listServiceOrderResponse.listServiceOrderStruct; let i = index">
                  <tr class="basic-table__body__row" (click)="showLine(item)">
                    <td class="truncate">
                      <mat-icon>{{ item.isDetails ? 'expand_more' : 'chevron_right' }}</mat-icon>
                    </td>
                    <td class="truncate">{{item.idServiceOrder}}</td>
                    <td class="truncate">{{item.datetimeInclusion | date: 'd MMMM y, HH:mm'}}</td>
                    <td class="truncate">{{item.plateNumber}}</td>
                    <td class="truncate">{{item.vehicleName}}</td>
                    <td class="truncate">{{item.clientName}}</td>
                    <td class="truncate">{{item.statusName}}</td>
                    <td class="truncate">
                      <mat-chip-listbox aria-label="Status orçamento">
                        <mat-chip style="background: #3399FF;" *ngIf="item.countQuoteOpen != 0">
                          {{item.countQuoteOpen}} abertos
                        </mat-chip>
                        <mat-chip style="background: #FFB800;" *ngIf="item.countQuoteWaitingApproval != 0">
                          {{item.countQuoteWaitingApproval}} aguardando aprovação
                        </mat-chip>
                        <mat-chip style="background: #FFB800;" *ngIf="item.countQuoteApproved != 0">
                          {{item.countQuoteApproved}} aprovados
                        </mat-chip>
                        <mat-chip style="background: #038E49;" *ngIf="item.countQuoteClose != 0">
                          {{item.countQuoteClose}} concluídos
                        </mat-chip>
                      </mat-chip-listbox>
                    </td>
                    <td class="truncate">{{item.totalPrice | currency: 'BRL'}}</td>
                    <td class="truncate">
                      <a (click)="redirect(item.idServiceOrder)">
                        <mat-icon style="color: #001F38;">arrow_circle_right</mat-icon>
                      </a>
                    </td>
                  </tr>
                  <tr class="basic-table__head second-head" *ngIf="item.isDetails">
                    <td colspan="10">
                      <table class="basic-table table-color"
                        *ngIf="item.listQuoteServiceStruct != null && item.listQuoteServiceStruct.length > 0">
                        <thead>
                          <tr class="truncate">
                            <th>Orçamento ID</th>
                            <th>Status</th>
                            <th>Valor carrinho</th>
                            <th>Valor estoque</th>
                            <th>Valor serviço</th>
                            <th>Total</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody class="">
                          <tr class="basic-table__body__row"
                          *ngFor="let itemservice of item.listQuoteServiceStruct; let i = index">
                            <td class="truncate">{{itemservice.idQuoteService}}</td>
                            <td class="truncate">{{itemservice.descriptionQuoteServiceStatus}}</td>
                            <td class="truncate">{{itemservice.cartPrice | currency: 'BRL'}}</td>
                            <td class="truncate">{{itemservice.stockPrice | currency: 'BRL'}}</td>
                            <td class="truncate">{{itemservice.servicePrice | currency: 'BRL'}}</td>
                            <td class="truncate">{{itemservice.totalPrice | currency: 'BRL'}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <p *ngIf="item.listQuoteServiceStruct == null || item.listQuoteServiceStruct.length == 0">
                        Não há orçamentos nesta ordem de serviço
                      </p>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="listServiceOrderResponse != null && listServiceOrderResponse.listServiceOrderStruct != null && screenWidth < 650">
            <div class="row card-spaccing" *ngFor="let item of listServiceOrderResponse.listServiceOrderStruct; let i = index">
              <div class="col-sm-6 w-100">
                <div class="card">
                  <div class="card-body">
                    <div class="row card-title">
                      <ul class="ul">
                        <li>
                          <span class="title">Ford Focus</span>
                        </li>
                        <li>
                          <span>{{item.datetimeInclusion | date: 'd MMMM y, HH:mm'}}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="row">
                      <ul>
                        <li>
                          <span class="second-title">
                            Placa: 
                          </span>
                          <span>
                            {{item.placeNumber}}
                          </span>
                        </li>
                        <li>
                          <span class="second-title">
                            Cliente: 
                          </span>
                          <span>
                            {{item.clientName}}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div class="row">
                      <ul>
                        <li></li>
                        <li>
                          <span>
                            {{item.totalPrice | currency: 'BRL'}}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div class="row a-card">
                      <div class="button-os">
                        <a (click)="redirect(item.idServiceOrder)">
                          <span style="color: #ffff;">VER OS</span>
                          <mat-icon style="color: #ffff;">chevron_right</mat-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 div-btn-next-page">
              <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
              <button mat-stroked-button (click)="search(true)" class="btn-next-page" *ngIf="!isLoading">
                Ver mais resultados
                <mat-icon class="material-symbols-outlined">expand_more</mat-icon>
              </button>
            </div>
          </div>
          <div class="spinner" *ngIf="!modalAberto">
            <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
