import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter, NgZone  } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatButtonModule } from '@angular/material/button';
import { QuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/quote-service.service';
import { GetQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-quote-service.response';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { BranchCreditCard } from 'src/app/shared/services/API/models/user/branch-credit-card.model';
import { BranchCreditCardRequest } from 'src/app/shared/services/API/requests/user/branch-credit-card.request';
import { BranchCreditCardResponse } from 'src/app/shared/services/API/responses/user/branch-credit-card.response';
import { BranchCreditCardService } from 'src/app/shared/services/API/services/user/branch-credit-card.service';
import { ClosedQuoteService } from 'src/app/shared/services/API/services/orchestrator-purchase/closed-quote.service';
import { ClosedQuoteResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/closed-quote.response';
import { SupplierRequest } from 'src/app/shared/services/API/requests/user/supplier.request';


@Component({
  selector: 'app-closed-budget',
  templateUrl: './closed-budget.component.html',
  styleUrls: ['./closed-budget.component.css']
})
export class ClosedBudgetComponent implements OnInit {

  constructor(private closedQuoteService: ClosedQuoteService,
    private ngZone: NgZone,
    private supplierService: SupplierService,){

  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public matButtonModule:MatButtonModule;
  public getQuoteServiceResponse:GetQuoteServiceResponse;
  public getSupplierResponse: GetSupplierResponse;
  public closedQuoteResponse: ClosedQuoteResponse;
  public alertService:AlertService;
  public isLoading: boolean;
  public idQuoteService:number = 1
  public idQuote:number = 1
  public totalQuota: number;
  public totalDetail: number;
  public totalStock: number;
  public totalPrice: number;
  public array: any

  ngOnInit(){
    this.populatePage();
  }

  populatePage() {
    if (this.closedQuoteService) {
      this.closedQuoteService.Get(this.idQuote).subscribe({
        next: (closedQuoteService: ClosedQuoteResponse) => {
          if (closedQuoteService.isError) {
            this.alertService.show('Erro', closedQuoteService.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }  
          this.ngZone.run(() => {
            // console.log(this.closedQuoteResponse = closedQuoteService)
            this.array = this.closedQuoteResponse = closedQuoteService;
            this.array = this.array.getQuoteServiceResponse
              
            this.totalQuota = this.array.listQuoteServicePart.reduce((total, item) => {
              return total + item.price;
            }, 0);
            this.totalDetail = this.array.listServiceDetail.reduce((total, item) => {
              return total + item.price;
            }, 0);
            this.totalStock = this.array.listStockPart.reduce((total, item) => {
              return total + item.price;
            }, 0);
            this.totalPrice = this.totalQuota + this.totalDetail + this.totalStock;
          });
          const item = this.closedQuoteResponse
        }
      });
    } else {
    }
  }

  populateSupplier(item: any){
    const listIdSupplier = [item.idSupplier];
    const supplierRequest: SupplierRequest = new SupplierRequest();
    supplierRequest.listIdSuppliers = listIdSupplier;

    if (this.supplierService) {
      this.supplierService.post(supplierRequest).subscribe({
        next: (supplierService: GetSupplierResponse) => {
          if (supplierService.isError) {
            this.alertService.show('Erro', supplierService.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.getSupplierResponse = supplierService
        }
      });
    } else {
    }
  }

  captureScreen() {
    window.print();
  }



}
