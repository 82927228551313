import { Component, EventEmitter, HostListener, Input, Output,  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SupplierBranchStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-branch.struct';
import { BusinessDeliveryTypeStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/business-delivery-type.struct';
import { SupplierStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier.struct';
import { DeliveryStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/delivery.struct';

@Component({
  selector: 'app-partner-delivery',
  templateUrl: './partner-delivery.component.html',
  styleUrls: ['./partner-delivery.component.css']
})
export class PartnerDeliveryComponent {

  constructor(private formBuilder: FormBuilder){

    this.onResize();

  }
  
  public model: FormGroup;

  @Input() delivery:DeliveryStruct;
  @Input() stock:boolean;
  @Output() change = new EventEmitter<any>();
  public formatedSelectedDeliveryPrice: string;
  public screenWidth: number;
  public isDelivery: any;
  public merosExpress: Boolean;
  public deliveryStandard: any;
  public deliveryExpress: any;

  //Receber lista de entregas possiveis para aquele parceiro

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnChanges(changes: any) {
    this.model = this.formBuilder.group({
      idDelivery: [null, [Validators.required]],
    });
    if(this.delivery.standardDeliveryTimeSeconds/60 < 120){
      this.deliveryStandard = 'Estimativa de 2 horas'
    }else{
      this.deliveryStandard = 'Estimativa de 3 horas'
    }

    if (this.delivery.expressDeliveryCost != null) {
      const hours = Math.floor(this.delivery.expressDeliveryTimeSeconds / 3600);
      const minutes = Math.floor((this.delivery.expressDeliveryTimeSeconds % 3600) / 60);
      this.deliveryExpress = `Estimativa de ${hours} e ${minutes} minutos`;
      this.merosExpress = true
    }else{
      this.merosExpress = false
    }
  }
  
  // ngOnInit(): void {
  //   this.model = this.formBuilder.group({
  //     idDelivery: [null, [Validators.required]],
  //   });


  //   if(this.delivery.standardDeliveryTimeSeconds/60 < 120){
  //     this.deliveryStandard = 'Estimativa de 2 horas'
  //   }else{
  //     this.deliveryStandard = 'Estimativa de 3 horas'
  //   }

  //   if (this.delivery.expressDeliveryCost != null) {
  //     console.log("OI")
  //     const hours = Math.floor(this.delivery.expressDeliveryTimeSeconds / 3600);
  //     const minutes = Math.floor((this.delivery.expressDeliveryTimeSeconds % 3600) / 60);
  //     this.deliveryExpress = `Estimativa de ${hours} e ${minutes} minutos`;
  //     this.merosExpress = true
  //   }else{
  //     this.merosExpress = false
  //   }
    
  //   // var merosDelivery = this.supplier.listSupplierDeliveryType.filter(x => x.idDeliveryType == 1)[0].totalDeliveryFee
  //   // this.isDelivery = this.supplier.partAllocation
  //   // this.formatedSelectedDeliveryPrice =  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(merosDelivery)
  // }

  onChange(){
    //Dar emmit para tela principal enviando a entrega selecionada e o id do parceiro
    // let selected: BusinessDeliveryTypeStruct = this.supplier.listSupplierDeliveryType.find(x => x.idDeliveryType ==  this.model.get("idDelivery").value)
    let selected = this.model.get("idDelivery").value
    this.change.emit(selected)
  }
}
