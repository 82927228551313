import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { BranchCreditCardResponse } from '../../responses/user/branch-credit-card.response';
import { BranchCreditCardRequest } from '../../requests/user/branch-credit-card.request';
import { CreateBranchCreditCardResponse } from '../../responses/user/create-branch-credit-card.response';


@Injectable({
  providedIn: 'root'
})
export class ConfigCreditCardService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(): Observable<BranchCreditCardResponse> {

    let url = `ConfigCreditCard/`

    return this.httpClient.get<BranchCreditCardResponse>(environment.urlApiUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public Post(body: BranchCreditCardRequest): Observable<CreateBranchCreditCardResponse> {

    let url = `ConfigCreditCard/`

    return this.httpClient.post<CreateBranchCreditCardResponse>(environment.urlApiUser + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

  public Delete(idBranchCreditCard: number): Observable<BranchCreditCardResponse> {

    let url = `ConfigCreditCard/idBranchCreditCard/${idBranchCreditCard}`;
  
      return this.httpClient.delete<BranchCreditCardResponse>(environment.urlApiUser + url, this.addHeaderToken())
      .pipe(
        catchError(this.handleError)
      )
    }

    public Put(idBranchCreditCard: number): Observable<ReturnStruct> {

      let url = `ConfigCreditCard/idBranchCreditCard/${idBranchCreditCard}`;
    
        return this.httpClient.put<ReturnStruct>(environment.urlApiUser + url, null, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }
}
