import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SearchPlateRequest } from 'src/app/shared/services/API/requests/autoparts/search-plate.request';
import { GetServiceOrderResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-service-order-response';
import { PlateVehicleNameResponse } from 'src/app/shared/services/API/responses/serviceOrder/plate-vehicle-name.response';
import { SearchPlateResponse } from 'src/app/shared/services/API/responses/autoparts/Vehicle/search-plate.response';
import { SerachPlateOsResponse } from 'src/app/shared/services/API/responses/autoparts/serach-plate-os.response';
import { ServiceOrderService } from 'src/app/shared/services/API/services/serviceOrder/service-order.service';
import { SearchPlate } from 'src/app/shared/services/API/services/autoparts/search-plate.service';
import { VehicleService } from 'src/app/shared/services/API/services/autoparts/vehicle.service';
import { PlateVehicleNameStruct } from 'src/app/shared/services/API/structs/autoparts/plate-vehicle-name.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-model-service-order-search',
  templateUrl: './model-service-order-search.component.html',
  styleUrls: ['./model-service-order-search.component.css']
})
export class ModelServiceOrderSearchComponent implements OnInit  {
  
  constructor( public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<ModelServiceOrderSearchComponent>,
    private formBuilder: FormBuilder,
    private searchPlate:SearchPlate,
    private alertService: AlertService,
    private location: Location,
    private serviceOrderService:ServiceOrderService){

  }

  public model: FormGroup;
  public isLoading:boolean;
  public getServiceOrderResponse:GetServiceOrderResponse;
  public page:number;
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      searchText: ['', [Validators.required]],
    });

    this.isLoading = false;
    this.getServiceOrderResponse = null;
  }

  backPage(){
    this.location.back();
  }

  searchServiceOrder(){
    if(this.isLoading) return;
    if(this.model.invalid) return;

    let searchPlateRequest:SearchPlateRequest = new SearchPlateRequest(); 

    searchPlateRequest.plate = this.model.get("searchText").value;

    this.isLoading = true;
    this.searchPlate.post(searchPlateRequest).subscribe((serachPlateOsResponse:SerachPlateOsResponse)=>{

      if (serachPlateOsResponse.isError) {
        this.alertService.show('Erro', serachPlateOsResponse.errorDescription, AlertType.error)
        this.isLoading = false;
        return;
      }      

      let idPlate = null;
      idPlate = serachPlateOsResponse.plate != null ? serachPlateOsResponse.plate.idPlate : '-1';
      
      this.serviceOrderService.get(this.model.get("searchText").value, idPlate).subscribe((getServiceOrderResponse:GetServiceOrderResponse)=>{
        this.isLoading = false;

        if (getServiceOrderResponse.isError) {
          this.alertService.show('Erro', getServiceOrderResponse.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }  

       

        //com resultado da busca obtem valores de placa e carro
        if(getServiceOrderResponse != null && getServiceOrderResponse.serviceOrderStruct != null){
          let listIdPlate:number[] = getServiceOrderResponse.serviceOrderStruct.map(c=> c.idPlate);

          this.searchPlate.get(listIdPlate).subscribe((plateVehicleNameResponse:PlateVehicleNameResponse)=>{
            if (plateVehicleNameResponse.isError) {
              this.alertService.show('Erro', plateVehicleNameResponse.errorDescription, AlertType.error)
              this.isLoading = false;
              return;
            }  

            getServiceOrderResponse.serviceOrderStruct.forEach(item => {
              let plateVehicleNameStruct:PlateVehicleNameStruct = plateVehicleNameResponse.listPlateVehicleNameStruct.filter(c=> c.idPlate == item.idPlate)[0];
              
              item.vehicleModelName = plateVehicleNameStruct.versionName;
              item.plateNumber = plateVehicleNameStruct.plateNumber;
            });

            this.getServiceOrderResponse = getServiceOrderResponse;

          },(error)=>{
            this.isLoading = false;
            console.log(error);
            this.alertService.show('Erro inesperado', error, AlertType.error);
          })
          
        }

      },(error)=>{
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      });

    },(error)=>{
      this.isLoading = false;
      console.log(error);
      this.alertService.show('Erro inesperado', error, AlertType.error);
    })
  }


  selectServiceOrder(idServiceOrder){
    this.matDialogRef.close({idServiceOrder: idServiceOrder}); 
  }

  newServiceOrder(){
    this.matDialogRef.close({openNewService: true}); 
  }

}
