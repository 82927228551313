<div>
  <div class="mat-dialog-title">
    <h2>Trocar de Filial ?</h2>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="model">
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Selecione o local de entrada</mat-label>
            <mat-select formControlName="idBranch">
              <mat-option *ngFor="let item of dataArray.listBranch; let i = index" [value]="item.idBranch">
                {{item.branchName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <mat-form-field appearance="outline">
            <mat-label>Senha</mat-label>
            <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility' : 'visibility_off'}}</mat-icon>
            <input matInput #password type="password" [type]="hide ? 'text' : 'password'" formControlName="password"
              required>
          </mat-form-field>
        </div>
      </div>
      <div mat-dialog-actions [align]="'end'" class="col-md-12 align-center" *ngIf="!listBranch">
        <button class="button-cancel" mat-raised-button mat-dialog-close="false">Cancelar</button>
        <button id="button-register" mat-raised-button mat-dialog-close="false" (click)="changeBranches()">
          <span *ngIf="!isLoading">Entrar</span>
          <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
        </button>
      </div>
    </form>
  </div>
</div>
