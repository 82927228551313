import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { SearchShoppingCartStruct } from './search-shopping-cart.service';
import { Router } from '@angular/router';
import {SearchShoppingCarItemStruct, SearchShoppingCartService } from './search-shopping-cart.service';
@Component({
  selector: 'app-search-shopping-cart',
  templateUrl: './search-shopping-cart.component.html',
  styleUrls: ['./search-shopping-cart.component.css']
})
export class SearchShoppingCartComponent implements OnInit {

  constructor(
    private router: Router,
  ){}

  public amount: number;
  public totalItems: any;
  public listParts: SearchShoppingCarItemStruct[]

  @Input() idServiceOrder:number;
  @Input() idQuoteService:number;
  @Input() searchShoppingCartStuct:SearchShoppingCartStruct;
  @Output() removeItem = new EventEmitter<number>();
  @Output() addChangeItem = new EventEmitter<any>();

  ngOnInit(): void {
  }

  generateAmountOptions(totalQuantity: number, salesMultiple: number): number[] {
    return Array.from(
      { length: Math.ceil(totalQuantity / salesMultiple) }, 
      (_, i) => (i + 1) * salesMultiple
    );
  }

  removeItemClick(part){
    this.removeItem.emit(part)
  }

  storageBuy(){
    this.router.navigate([
      '/workshop/cart-summary'
    ]);
  }

  storageBuyQuoteService(){
    if(this.idServiceOrder == undefined && this.idQuoteService == undefined){
      this.router.navigate(['/workshop/quote-service']);
    }
    else if(this.idServiceOrder != undefined && this.idQuoteService == undefined){
      this.router.navigate(['/workshop/quote-service'], { queryParams: { idServiceOrder: this.idServiceOrder}});
    }
    else if(this.idServiceOrder == undefined && this.idQuoteService != undefined){
      this.router.navigate(['/workshop/quote-service'], { queryParams: { idQuoteService:this.idQuoteService, addnewitens: true}});
    }
    else if(this.idServiceOrder != undefined && this.idQuoteService != undefined){
      this.router.navigate(['/workshop/quote-service'], { queryParams: { idServiceOrder: this.idServiceOrder,  idQuoteService:this.idQuoteService, addnewitens: true }});
    }
  }

  changeAmount(amount,SearchShoppingCarItemtStuct){
    let object = {
      searchShoppingCarItemtStuct:SearchShoppingCarItemtStuct,
      amount:Number(amount),
    }
    this.addChangeItem.emit(object)
  }
  
}
