import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { getUserResponse } from '../../responses/user/get-user.response.';
import { UserRequest } from '../../requests/user/user.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { listUserResponse } from '../../responses/user/list-user.response';


@Injectable({
    providedIn: 'root'
  })

export class SearchUserService extends CommonService{

    constructor(
        private httpClient: HttpClient){
            super();
        }


        public get(searchText?: string): Observable<listUserResponse> {

            let url = `user?searchText=${searchText}`
        
            return this.httpClient.get<listUserResponse>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }
}