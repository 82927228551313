import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { GetPurchaseDataResponse } from '../../responses/orchestrator-purchase/get-purchase-data.response';
import { PurchaseRequest } from '../../requests/orchestrator-purchase/purchase.request';
import { RecalculateDeliveryRequest } from '../../requests/orchestrator-purchase/recalculate-delivery.request';
import { RecalculateDeliveryResponse } from '../../responses/orchestrator-purchase/recalculate-delivery.response';



@Injectable({
  providedIn: 'root'
})
export class PopulatePurchaseService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public getPurchaseData(body: PurchaseRequest): Observable<GetPurchaseDataResponse> {

    let url = `PopulatePurchase/getData`

    return this.httpClient.post<GetPurchaseDataResponse>(environment.urlApiOrchestratorPurchase + url, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public recalculateDelivery(body: RecalculateDeliveryRequest): Observable<GetPurchaseDataResponse> {

    let url = `PopulatePurchase/calculateDelivery`

    return this.httpClient.post<GetPurchaseDataResponse>(environment.urlApiOrchestratorPurchase + url, body,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
