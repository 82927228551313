import { Pipe, PipeTransform } from "@angular/core"

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
    transform(value: string): string {
        if(!value) return

        let ddd = value.substring(0,2)
        let phoneP1 = value.substring(2, 7)
        let phoneP2 = value.substring(7, 11)

        return `(${ddd}) ${phoneP1}-${phoneP2}`;
    }
}