import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-payment-delete',
  templateUrl: './modal-payment-delete.component.html',
  styleUrls: ['./modal-payment-delete.component.css']
})
export class ModalPaymentDeleteComponent {

  @Output() deleteConfirm = new EventEmitter<boolean>();

  deleteProfile(){
    this.deleteConfirm.emit(true);
  }

}
