<div class="generic-indicator-display">
    <div class="generic-indicator-display__content">
        <div class="generic-indicator-display__content__info">
            <span class="main">{{mainInfo}}</span>
            <span class="description">{{description}}</span>
        </div>
        <div class="generic-indicator-display__content__icon">
            <mat-icon [ngStyle]="{'color': iconColor}">{{icon}}</mat-icon>
        </div>
    </div>
</div>