<div class="container-out" (click)="close()"></div>

<div class="container-user">
  <div class="header">
    <img src="../../../../assets/images/car-stock-img.png">
    <div>
      <h3>{{this.nameUser}}</h3>
      <span class="email">{{this.email}}</span>
    </div>
  </div>
  <ul>
    <li>{{this.nameStore}}</li>
    <li>Modo: {{nameModule}}</li>
  </ul>

  <hr>

  <div>
    <ul>
      <li>
        <a>
          <mat-icon>person</mat-icon>
          <span class="span">
            Meu Perfil
          </span>
        </a>
      </li>
      <li>
        <a (click)="redirect('/business-data')">
          <mat-icon>poll</mat-icon>
          <span class="span">
            Dados da Loja
          </span>
        </a>
      </li>
      <li>
        <a class="isMode" (click)="redirect('/supplier/order')" *ngIf="dataArray.listModule[0].isModulePermitted && this.module == 1">
          <mat-icon>build</mat-icon>
          <span class="span-Mode">Trocar para Modo: Fornecedor</span>
        </a>
        
        <a class="isMode" (click)="redirect('/workshop/welcome')" *ngIf="dataArray.listModule[1].isModulePermitted && this.module == 2">
          <mat-icon>build</mat-icon>
          <span class="span-Mode">Trocar para Modo: Oficina</span>
        </a>
      </li>
      <li *ngIf="dataLength > 1">
        <a (click)="openModal()">
          <mat-icon>cached</mat-icon>
          <span class="span-Mode">Trocar Filial Atual: {{nameStore}}</span>
        </a>
      </li>
    </ul>
  </div>

  <hr>

  <div>
    <a (click)="logout()" class="logout">
      <mat-icon class="mat-exit">exit_to_app</mat-icon>
      <span class="span">
        Sair
      </span>
    </a>
  </div>
</div>
