<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="head-modal-list">
            <h2>Cadastro de endereço</h2>
        </div>
        <form class="form" [formGroup]="model" (ngSubmit)="submit()">
            <div class="body-modal-list">
                <div class="row">   
                    <div class="col-12">
                        <mat-form-field appearance="outline">
                            <mat-label>Nome do endereço</mat-label>
                            <input matInput type="text" formControlName="name">
                            <mat-error *ngIf="model.get('name').invalid">Informe o nome</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-sm-8">
                        <app-google-place-input (setAddress)="getEstablishmentAddress($event)" [autocompleteInput]="populatedAddress"></app-google-place-input>
                    </div>
                    
                    <div class="col-12 col-sm-4">
                        <mat-form-field appearance="outline">
                            <mat-label>Complemento</mat-label>
                            <input matInput type="text" formControlName="description">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4">
                        <mat-label>Endereço padrão</mat-label>
                        <mat-slide-toggle class="toggle-margin" formControlName="isDefault"></mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="footer-exam-modal-list">
                <button mat-flat-button color="primary" class="btn-block" type="submit">
                    <span *ngIf="isLoading == false">Salvar</span>
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                </button>
                <button *ngIf="!isLoading" mat-flat-button color="accent" type="button" class="btn-block"(click)="clickCancel()">Cancelar</button>
            </div>
        </form>
    </div>
</div>

