import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { WelcomeComponent } from './pages/welcome/welcome.component';
import { SearchComponent } from './pages/search/search.component';
import { ShoppingCartSummaryComponent } from './pages/shopping-cart-summary/shopping-cart-summary.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { QuoteServiceComponent } from './pages/quote-service/quote-service.component';
import { ClientQuoteServiceComponent } from './pages/client-quote-service/client-quote-service.component';
import { ProductComponent } from './pages/product/product.component';
import { ServiceOrderListComponent } from './pages/service-order-list/service-order-list.component';
import { ServiceOrderDetailsComponent } from './pages/service-order-details/service-order-details.component';
import { ClosedBudgetComponent } from './pages/closed-budget/closed-budget.component';
import { PurchaseHistoryComponent } from './pages/purchase-history/purchase-history.component';
import { PurchaseMadeComponent } from './pages/purchase-made/purchase-made.component';



const routes: Routes = [
  { path: 'workshop/welcome', component: WelcomeComponent, canActivate: [AuthGuard]},
  { path: 'workshop/search', component: SearchComponent, canActivate: [AuthGuard]},
  { path: 'workshop/cart-summary', component: ShoppingCartSummaryComponent, canActivate: [AuthGuard]},
  { path: 'workshop/purchase', component: PurchaseComponent, canActivate: [AuthGuard]},  
  { path: 'workshop/quote-service', component: QuoteServiceComponent, canActivate: [AuthGuard]}, 
  { path: 'ordem-de-servico', component: ClientQuoteServiceComponent}, 
  { path: 'workshop/product/:partId/:supplierBusinessId', component: ProductComponent, canActivate: [AuthGuard]},
  { path: 'workshop/closed-budget', component: ClosedBudgetComponent, canActivate: [AuthGuard]},
  { path: 'workshop/service-order', component: ServiceOrderListComponent, canActivate: [AuthGuard]}, 
  { path: 'workshop/service-order/details', component: ServiceOrderDetailsComponent, canActivate: [AuthGuard]}, 
  { path: 'workshop/purchase-history', component: PurchaseHistoryComponent, canActivate: [AuthGuard]},
  { path: 'workshop/purchase-made/:idPurchase', component: PurchaseMadeComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WorkshopRoutingModule { }
