
<header>
    <div class="logo-infos">
        <div class="logo">
            <img src="/assets/logos/Black logo - no background.png">
        </div>
        <div class="page-info">
            <h1>Orçamento #{{idQuoteService}}</h1>
        </div>
    </div>
</header>


<div class="container">
    <div class="container-body" *ngIf="isError == false">
        <div class="section">
            <h1>Peças</h1>
            <table *ngIf="getQuoteServiceResponse != null && getQuoteServiceResponse.listStockPart != null">
                <thead class="basic-table__head">
                    <tr>
                        <th>Produto</th>
                        <th>Marca</th>
                        <th>Preço Unidade</th>
                        <th>Quantidade</th>
                    </tr>
                </thead>
                <tbody class="basic-table__body">
                    <tr *ngFor="let item of getQuoteServiceResponse.listStockPart"> 
                        <td>{{item.productName}}</td>
                        <td>{{item.productBrand}}</td>
                        <td>{{item.price | currency: 'BRL'}}</td>
                        <td>{{item.amount}}</td>
                    </tr>  
                </tbody>
            </table>
            <div *ngIf="getQuoteServiceResponse == null ||getQuoteServiceResponse.listStockPart == null || getQuoteServiceResponse.listStockPart.length == 0">
                <p class="text-center">Lista vazia</p>
            </div>
            <div class="price"  *ngIf="getQuoteServiceResponse != null && getQuoteServiceResponse.listStockPart != null">
                 <label class="label-price">Total em peças <span>-----------</span></label>
                 <label class="label-value"> {{totalPart | currency: 'BRL'}}</label>
            </div>
        </div>

        <div class="section" *ngIf="getQuoteServiceResponse == null ||getQuoteServiceResponse.listServiceDetail == null || getQuoteServiceResponse.listServiceDetail.length == 0">
            <h1>Detalhamento serviço</h1>
            <table *ngIf="getQuoteServiceResponse != null && getQuoteServiceResponse.listServiceDetail != null">
                <thead class="basic-table__head">
                    <tr> 
                        <th>Descrição do serviço</th>
                        <th>Preço Unidade</th>
                        <th>Quantidade</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody class="basic-table__body">
                    <tr *ngFor="let item of getQuoteServiceResponse.listServiceDetail"> 
                        <td>{{item.description}}</td>
                        <td>{{item.price | currency: 'BRL'}}</td>
                        <td>{{item.amount}}</td>
                    </tr>
                </tbody>
            </table>
            <div>
                <p class="text-center">Lista vazia</p>
            </div>
            <div class="price" *ngIf="getQuoteServiceResponse != null && getQuoteServiceResponse.listServiceDetail != null">
                <label class="label-price">Total em serviços <span>-----------</span></label>
                <label class="label-value"> {{totalService | currency: 'BRL'}}</label>
            </div>

           <div class="price total">
                <label class="label-price">Total <span>-----------</span></label>
                <label class="label-value"> {{totalPrice | currency: 'BRL'}}</label>
           </div>
        </div>

        <div class="section" *ngIf="blockActions == false && isError == false">
            <button mat-button type="button" class="btn-primary-blue btn-block" color="primary" (click)="aprove()">
                <span *ngIf="isLoading == false">Aprovar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button>   

            <button mat-button type="button" class="btn-secundary btn-block" color="primary" (click)="reject()">
                <span *ngIf="isLoading == false">Rejeitar</span>
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
            </button> 

        </div>

    </div>

 
    <div class="container-body" *ngIf="isError == true">
        <div class="section text-center">
            <h1>Esse orçamento não está disponível para aprovação.</h1>
        </div>
    </div>

</div>
