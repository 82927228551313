import { Component, Inject } from '@angular/core';
import { VersionModelStruct } from '../../services/API/structs/autoparts/version-model.struct';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertType } from '../../services/alert.service';

@Component({
  selector: 'app-select-vehicle-version-modal',
  templateUrl: './select-vehicle-version-modal.component.html',
  styleUrls: ['./select-vehicle-version-modal.component.css']
})
export class SelectVehicleVersionModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<SelectVehicleVersionModalComponent>,
  ) { 
    matDialogRef.disableClose = this.data.plateNumber ? false : true;
  }

  public listVersion: VersionModelStruct[] = [];
  public version: VersionModelStruct;
  public filteredListVersion: VersionModelStruct[] = [];
  public vehicleModelName: string;
  public showAll: boolean = false;
  public score: number;

  ngOnInit(): void {
    this.listVersion = this.data.listVersion
    this.filterTopScores();
  }

  filterTopScores() {
    const topScores = this.listVersion.sort((a, b) => b.score - a.score).slice(0, 3);
    this.filteredListVersion = topScores;
  }

  toggleList() {
    this.showAll = !this.showAll;
    this.filteredListVersion = this.showAll ? this.listVersion : this.listVersion.sort((a, b) => b.score - a.score).slice(0, 3);
  }

  clickConfirm()
  {
    if(!this.version) return
    this.matDialogRef.close({foundPlate: true, vehicleVersion: this.version, fipe: this.version.fipeCode, modelYear: this.version.modelYear, state: this.version.state, chassisNumber: this.version.chassisNumber, color: this.version.color, origin: this.version.origin});
  }

  // cancel()
  // {
  //   // if(!this.version) return
  //   // this.matDialogRef.close({cancelPlate: true});
  // }
}