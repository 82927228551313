import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-indicator-display',
  templateUrl: './generic-indicator-display.component.html',
  styleUrls: ['./generic-indicator-display.component.css']
})
export class GenericIndicatorDisplayComponent implements OnInit {

  @Input() mainInfo: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() iconColor: string;

  constructor() { }

  ngOnInit(): void {
  }

}
