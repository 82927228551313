import { Component, EventEmitter, Output, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { MenuModuleEnum } from '../menu.module.enum';
import { ModalChangeBranchComponent } from './modal-change-branch/modal-change-branch.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-infos',
  templateUrl: './user-infos.component.html',
  styleUrls: ['./user-infos.component.css']
})
export class UserInfosComponent implements OnInit {

  public hasSupplier:boolean = false;
  public isPermittedOficina: boolean;
  public isPermittedFornecedor: boolean;
  public isPermitted: boolean;
  public token;
  public module;
  public dataArray;
  public dataLength;
  public state: boolean;
  public listBranch:[];
  public isLoading: boolean;


  constructor(private router: Router, 
    private authService:AuthService,
    private dialogRef: MatDialog,){
    
    let userLoginResponse = this.authService.getTokenMenu();
    
    if(userLoginResponse.listModule.find(c=> c.idModule == MenuModuleEnum.fornecedor) != undefined)
      this.hasSupplier = true;
  }

  @Output() closeUserInfos = new EventEmitter();
  @Input() nameModule: string | undefined;
  @Input() nameUser: string | undefined;
  @Input() nameStore: string | undefined;
  @Input() email: string | undefined;

  ngOnInit(): void{
    this.state = true;

    const response = sessionStorage.getItem("token")
    const moduleStorage = sessionStorage.getItem("module")
    this.token = response;
    this.module = moduleStorage;
    this.dataArray = JSON.parse(response);
    this.dataLength = this.dataArray.listBranch.length
  }
  
  close(){
    this.closeUserInfos.emit();
    this.dialogRef.closeAll();
  }

  openModal(){
    const dialogRef = this.dialogRef.open(ModalChangeBranchComponent,{
      width:'45%',
      data:{
        dataArray: this.dataArray,
        email: this.email,
      }
    })
  }

  redirect(url){
    if (url === "/supplier/order") {
      sessionStorage.setItem("module", MenuModuleEnum.fornecedor.toString());
    }
    else {
      sessionStorage.setItem("module", MenuModuleEnum.oficina.toString());
    }
    this.router.navigate([
      url
    ]);
  }

  onModeChange(event: any) {
    const selectedOption = event.target.value;
    if (selectedOption === "/supplier/order") {
      sessionStorage.setItem("module", MenuModuleEnum.fornecedor.toString());
    }
    else {
      sessionStorage.setItem("module", MenuModuleEnum.oficina.toString());
    }
    this.redirect(selectedOption);
}

  logout(){
    this.authService.logout();
  }
}

