import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ListSupplierOrdersResponse } from '../../responses/orchestrator-purchase/list-supplier-orders.response';
import { SupplierOrdersDetailResponse } from '../../responses/orchestrator-purchase/supplier-orders-detail.response';
import { ListPurchaseOrderRequest } from '../../requests/orchestrator-purchase/list-purchase-order.request';
import { GetPurchaseHistoryResponse } from '../../responses/orchestrator-purchase/get-purchase-history.response';
import { GetPurchaseHistoryDetailsResponse } from '../../responses/orchestrator-purchase/get-purchase-history.details.response';


@Injectable({
  providedIn: 'root'
})
export class PurchaseHistoryDetailsService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public Get(idPurchase: number): Observable<GetPurchaseHistoryDetailsResponse> {

    let url = `PurchaseHistory/idPurchase/${idPurchase}`

    return this.httpClient.get<GetPurchaseHistoryDetailsResponse>(environment.urlApiOrchestratorPurchase + url,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
