import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { SearchPlateResponse } from '../../responses/autoparts/Vehicle/search-plate.response';
import { SearchPlateRequest } from '../../requests/autoparts/search-plate.request';
import { PopulateSelectResponse } from '../../responses/autoparts/Vehicle/populate-select.response';
import { CreateUpdatePlateResponse } from '../../responses/autoparts/create-update-plate.response';
import { CreatePlateRequest } from '../../requests/autoparts/create-update-plate.request';
import { SearchFipePlateRequest } from '../../requests/autoparts/search-fipe-plate.request';



@Injectable({
  providedIn: 'root'
})
export class VehicleService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public searchPlate(body: SearchPlateRequest): Observable<SearchPlateResponse> {

    const url = `Vehicle/searchPlate`

    return this.httpClient.post<SearchPlateResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public SearchFipePlate(body: SearchFipePlateRequest): Observable<SearchPlateResponse> {

    let url = `Vehicle/SearchFipePlate`

    return this.httpClient.post<SearchPlateResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public searchPlateById(idPlate:number): Observable<SearchPlateResponse> {

    let url = `Vehicle/SearchPlate/idPlate/` + idPlate;

    return this.httpClient.get<SearchPlateResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }


  public createUpdatePlate(body: CreatePlateRequest): Observable<CreateUpdatePlateResponse> {

    let url = `Vehicle/CreateUpdatePlate`

    return this.httpClient.post<CreateUpdatePlateResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public listBrand(searchText?: string): Observable<PopulateSelectResponse> {

    let url = `Vehicle/PopulateBrand?`

    if(searchText) url += `searchText=${searchText}&`

    return this.httpClient.get<PopulateSelectResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public listModel(idBrand: number,searchText?: string): Observable<PopulateSelectResponse> {

    let url = `Vehicle/PopulateModel/idBrand/${idBrand}?`

    if(searchText) url += `searchText=${searchText}&`

    return this.httpClient.get<PopulateSelectResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public listYear(idModel: number, searchText?: string): Observable<PopulateSelectResponse> {

    let url = `Vehicle/PopulateYear/idModel/${idModel}?`

    if(searchText) url += `searchText=${searchText}&`

    return this.httpClient.get<PopulateSelectResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public listVersion(idModel: number, year: number,searchText?: string): Observable<PopulateSelectResponse> {

    let url = `Vehicle/PopulateVersion/idModel/${idModel}?year=${year}&`

    if(searchText) url += `searchText=${searchText}&`

    return this.httpClient.get<PopulateSelectResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }

  public listVehicleType(idModel: number, year: number, searchText?: string): Observable<PopulateSelectResponse> {

    let url = `Vehicle/PopulateVersion/idModel/${idModel}/year/${year}`

    return this.httpClient.get<PopulateSelectResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }
}
