import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-change-vehicle',
  templateUrl: './modal-change-vehicle.component.html',
  styleUrls: ['./modal-change-vehicle.component.css']
})
export class ModalChangeVehicleComponent {
  constructor( @Inject(MAT_DIALOG_DATA) public data: any, 
  public dialog: MatDialog, 
  public matDialogRef: MatDialogRef<ModalChangeVehicleComponent>){

  }
}
 