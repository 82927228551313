<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div class="header">
            <h1>Ordem de serviço</h1>
            <button mat-button mat-dialog-close (click)="backPage()">
                Voltar
            </button>
        </div>
        <p>Selecione a OS para adicionar o orçamento ou abra uma nova</p>
        <form class="form" [formGroup]="model" (ngSubmit)="searchServiceOrder()">
            <mat-form-field appearance="outline">
                <mat-label>Pesquisar por OS ou Placa</mat-label>
                <input matInput type="text" formControlName="searchText">
            </mat-form-field>
    
            <button type="submit" mat-flat-button class="btn btn-primary-blue">         
                <span class="text-submit"  *ngIf="!isLoading"><mat-icon>search</mat-icon>Pesquisar</span> 
                <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            </button>
            <a mat-flat-button class="btn btn-primary-blue" (click)="newServiceOrder()">         
                <mat-icon>add</mat-icon>Nova OS
            </a>       
        </form>
        <table *ngIf="getServiceOrderResponse != null && getServiceOrderResponse.serviceOrderStruct != null">
            <thead class="basic-table__head">
                <tr>
                    <th>ID</th>
                    <th>Data abertura</th>
                    <th>Placa</th>
                    <th>Veículo</th>
                    <th>Cliente</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="basic-table__body">
                <tr class="basic-table__body__row"  *ngFor="let item of getServiceOrderResponse.serviceOrderStruct; let index = index;" >
                    <td>{{item.idServiceOrder}}</td>
                    <td>{{item.datetimeInclusion | date : 'dd/MM/yyyy HH:mm'}}</td>
                    <td>{{item.plateNumber}}</td>
                    <td>{{item.vehicleModelName}}</td>
                    <td>{{item.clientName}}</td>
                    <td><a class="btn-list" (click)="selectServiceOrder(item.idServiceOrder)"> <mat-icon class="material-symbols-outlined">check_circle</mat-icon> </a></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>