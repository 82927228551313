import { Component, Input, Output, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmBuyOrderService } from 'src/app/shared/services/API/services/purchase/confirm-buy-order.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-modal-confirm-delivery',
  templateUrl: './modal-confirm-delivery.component.html',
  styleUrls: ['./modal-confirm-delivery.component.css']
})
export class ModalConfirmDeliveryComponent {

  public isLoading: boolean;
  public idBuyOrder: number;
  public idBuyOrderStatus: number;
  public entrega: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private confirmBuyOrderService: ConfirmBuyOrderService,
  private alertService:AlertService,
  private dialogRef: DialogRef<ModalConfirmDeliveryComponent>) {
    this.idBuyOrder = data.idBuyOrder;
    this.idBuyOrderStatus = data.idBuyOrderStatus;
    this.entrega = data.entrega;
  }

  confirmBuyOrder(){
      this.confirmBuyOrderService.confirmDelivery(this.idBuyOrder).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.dialogRef.close();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Pedido Entregue', AlertType.success);
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    }

}
