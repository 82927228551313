import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-profile-permissions-delete',
  templateUrl: './modal-profile-permissions-delete.component.html',
  styleUrls: ['./modal-profile-permissions-delete.component.css']
})
export class ModalProfilePermissionsDeleteComponent {

  constructor(){
  }

  @Output() deleteConfirm = new EventEmitter<boolean>();

  deleteProfile(){
    this.deleteConfirm.emit(true);
  }
}
