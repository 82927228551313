import { Component, Output, Inject, EventEmitter } from '@angular/core';
import { ChangeStatusBuyOrderService } from 'src/app/shared/services/API/services/purchase/change-status-buy-order.service';
import { CancelBuyOrderService } from 'src/app/shared/services/API/services/purchase/cancel-buy-order.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { LalamoveService } from 'src/app/shared/services/API/services/orchestrator-purchase/lalamove.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { GetCscSupplierService } from 'src/app/shared/services/API/services/user/get-csc-supplier.service';
import { GetCSCSupplierResponse } from 'src/app/shared/services/API/responses/user/get-csc-supplier.response';
import { SupplierRequest } from 'src/app/shared/services/API/requests/user/supplier.request';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { Console } from 'console';


@Component({
  selector: 'app-modal-delivery',
  templateUrl: './modal-delivery.component.html',
  styleUrls: ['./modal-delivery.component.css']
})
export class ModalDeliveryComponent {

  constructor(private changeStatusBuyOrderService: ChangeStatusBuyOrderService,
    private lalamoveService: LalamoveService,
    private supplierService: SupplierService,
    private getCscSupplierService: GetCscSupplierService,
    private spinnerService: SpinnerService,
    private alertService:AlertService,
    public dialogRef: MatDialogRef<ModalDeliveryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.idBuyOrder = data.idBuyOrder;
    this.idBuyOrderStatus = data.idBuyOrderStatus;
    this.idSupplier = data.idSupplier;
    this.entrega = data.entrega;
    this.street = data.street;
    this.neighborhood = data.neighborhood;
    this.city = data.city;
    this.number = data.number;
    this.zipcode = data.zipcode;
    this.state = data.state;
  }

  public returnStruct: ReturnStruct;
  public isLoading: boolean;
  public vehicleType: number;
  public getSupplierResponse: GetSupplierResponse;
  public getCSCSupplierResponse: GetCSCSupplierResponse;
  public address: any;
  public idCity: number;
  idBuyOrder: number;
  idBuyOrderStatus: number;
  idSupplier: number;
  entrega: string;
  street: string;
  neighborhood: string;
  city: string;
  number: string;
  state: string;
  zipcode: string;

  @Output() refreshInfo: EventEmitter<void> = new EventEmitter<void>();

  ngOnInit() {
    this.populatePage()
    this.vehicleType = 1;
  }

  populatePage() {

    const listIdSupplier = [this.data.idSupplier];
    const supplierRequest: SupplierRequest = new SupplierRequest();
    supplierRequest.listIdSuppliers = listIdSupplier;

    if (this.supplierService) {
      this.supplierService.post(supplierRequest).subscribe({
        next: (supplierService: GetSupplierResponse) => {
          if (supplierService.isError) {
            this.alertService.show('Erro', supplierService.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.getSupplierResponse = supplierService;
          this.address = this.getSupplierResponse.supplier[0]
          this.idCity = this.address.idCity
          this.fetchCscSupplier();
        }
      });
    } else {
      console.error('listPurchaseService is undefined');
    }
  }

  fetchCscSupplier() {
    if (this.getCscSupplierService) {
      this.getCscSupplierService.get(this.idCity).subscribe({
        next: (getCscSupplierService: GetCSCSupplierResponse) => {
          if (getCscSupplierService.isError) {
            this.alertService.show('Erro', getCscSupplierService.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.getCSCSupplierResponse = getCscSupplierService;

          this.getCSCSupplierResponse.details
        }
      });
    } else {
      console.error('listPurchaseService is undefined');
    }
  }

  confirmDelivery(): Promise<void>{
    this.isLoading = true;
    return new Promise((resolve, reject) => {
      if (!this.lalamoveService) {
        return reject('Serviço lalamove não disponível');
      }

      this.lalamoveService.Get(this.data.idBuyOrder, this.vehicleType).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return reject('Erro na solicitação do veículo');
          }

          // Place resolve and success alert here to ensure they're called after a successful response
      
          this.alertService.show('Sucesso', 'Solicitação de veículo aceita.', AlertType.success);          
          // this.refreshInfo.emit();
          this.isLoading = false;
          this.dialogRef.close();
          resolve(); // Resolve the promise without any value unless needed
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Erro inesperado', error, AlertType.error);
          }
          reject('Erro inesperado');
        }
      });
    });
  }


  getVehicleType(type: string): number {
    switch (type) {
      case 'Meros Moto':
        return 1;
      case 'Meros Hatch':
        return 2;
      case 'Meros Carro':
        return 3;
      case 'Meros Utilitário':
        return 4;
      default:
    }
  }

  handleButtonClick(type: string) {
    this.vehicleType = this.getVehicleType(type);
  }
}
