import { Contact } from "../../models/serviceOrder/contact.model";

export class ServiceOrderPutRequest{
    public idServiceOrder: number;
    public problem: string;
    public observation: string;
    public cpfCnpj: string;
    public clientName: string;
    public phone: string;
    public whatsAppPhone: string;
    public email: string;

    public listContact: Contact[];
}