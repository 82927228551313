import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule} from '@angular/material/form-field';
import { FormsModule, FormGroup, FormBuilder} from '@angular/forms';
import { MatInputModule} from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { Location } from '@angular/common';

@Component({
  selector: 'app-business-data',
  templateUrl: './business-data.component.html',
  styleUrls: ['./business-data.component.css']
})
export class BusinessDataComponent implements OnInit {

  constructor(private dialogRef: MatDialog,
    private alertService:AlertService,
    private formBuilder: FormBuilder,
    private location: Location){

      this.model = this.formBuilder.group({
        seachText: [''],
      });

  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public FormFieldModuel: MatFormFieldModule;
  public FormsModule: FormsModule;
  public MatInputModule: MatInputModule;
  public MatRadioModule: MatRadioModule;
  public MatCheckboxModule: MatCheckboxModule;
  public isLoading: boolean;
  public model: FormGroup;

  ngOnInit(){
    this.menuModuleEnum = parseInt(sessionStorage.getItem("module"));
  }

  backPage(){
    this.location.back();
  }


}
