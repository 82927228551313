import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { FormsModule, UntypedFormBuilder, FormControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';


@Component({
  selector: 'app-modal-payment-add-cart',
  templateUrl: './modal-payment-add-cart.component.html',
  styleUrls: ['./modal-payment-add-cart.component.css']
})
export class ModalPaymentAddCartComponent {
  public FormFieldModuel: MatFormFieldModule;
  public model: FormGroup;
  public form: FormGroup;
  public expirationYears: number[] = [];


  constructor(private formBuilder: FormBuilder,
    public maskService: MaskService,
    private alertService: AlertService,){
    this.model = this.formBuilder.group({
      expirationMonth: ['', [Validators.required]],
      expirationYear: ['', [Validators.required]],
      ccv: ['', [Validators.required]],
      addressNumber: ['', [Validators.required]],
      creditCardName: ['', [Validators.required]],
      cpfCnpj: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
      cep: ['', [Validators.required]],
      addressComplement: ['', []],
      holderName: ['', [Validators.required]],
      creditCardDigits: ['', [Validators.required]],
    });

  }

  public masks: Masks;

  ngOnInit(): void {
    this.form = new FormGroup({
      expirationYear: new FormControl(''),
    });

    this.populateExpirationYears();
    this.masks = this.maskService.getMasks();

  }

  populateExpirationYears(): void {
    const currentYear: number = new Date().getFullYear();
    const numberOfYearsToShow: number = 20;

    for (let i = 0; i < numberOfYearsToShow; i++) {
      this.expirationYears.push(currentYear + i);
    }
  }

  @Output() confirmRegister = new EventEmitter<FormGroup>();

  registerProfile() {
    if (this.model.valid) {
      this.confirmRegister.emit(this.model.value);
    }
    else
    {
      this.alertService.show('Erro', "Preencha corretamente todos os campos.", AlertType.error)
    }
  }


}
