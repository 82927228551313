
export class Contact{
    public idContact:number;
    public idServiceOrder:number;
    public cpfCnpj:string
    public name:string;
    public phone:string;
    public whatsAppPhone:string;
    public email:string;
    public datetimeInclusion:Date;
}