import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ServiceOrderDetailsService } from 'src/app/shared/services/API/services/serviceOrder/service-order-details.service';

@Component({
  selector: 'app-service-order-reopen-modal',
  templateUrl: './service-order-reopen-modal.component.html',
  styleUrls: ['./service-order-reopen-modal.component.css']
})
export class ServiceOrderReopenModalComponent {
  constructor(public matDialogRef: MatDialogRef<ServiceOrderReopenModalComponent>,
    private serviceOrderDetailsService:ServiceOrderDetailsService){

  }

  cancel(){
    this.matDialogRef.close();
  }

  reopen(){
    this.matDialogRef.close({isReopen:true});

  }
}
