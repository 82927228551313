<mat-dialog-content class="dialog-content">
  <div class="row options d-flex justify-content-between">
    <h3>Dados do Cartão
    </h3>
  </div>
  <form [formGroup]="model" class="form">
    <div class="row forms-profile">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Nome Impresso no Cartão</mat-label>
          <input matInput type="text" formControlName="creditCardName">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>Número do Cartão</mat-label>
          <input matInput type="text" formControlName="creditCardDigits" [mask]="masks.creditCardNumber">
        </mat-form-field>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
        <mat-form-field appearance="outline">
          <mat-label>CVV
            <mat-icon mat-raised-button
            matTooltip="O número CVV é representado pelos três últimos dígitos no verso do seu cartão. Em cartões American Express, o CVV é um número de 4 dígitos na frente do cartão."
            aria-label="Button that displays a tooltip when focused or hovered over">
            info
          </mat-icon>
          </mat-label>
          <input matInput type="text" formControlName="ccv" [mask]="masks.cvv">
        </mat-form-field>
      </div>
    </div>
    <div class="vencimento options row">
      <h3>Vencimento</h3>
    </div>
    <div class="expiration row">
      <div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Mês</mat-label>
            <mat-select formControlName="expirationMonth">
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
              <mat-option value="7">7</mat-option>
              <mat-option value="8">8</mat-option>
              <mat-option value="9">9</mat-option>
              <mat-option value="10">10</mat-option>
              <mat-option value="11">11</mat-option>
              <mat-option value="12">12</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-lg-12 col-md-12 col-sm-12">
          <mat-form-field appearance="outline">
            <mat-label>Ano</mat-label>
            <mat-select formControlName="expirationYear">
              <mat-option *ngFor="let year of expirationYears" [value]="year">{{ year }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row options d-flex justify-content-between">
      <h3>Endereço da Cobrança
      </h3>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Nome Completo</mat-label>
          <input matInput type="text" formControlName="holderName">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <mat-form-field appearance="outline">
          <mat-label>CPF ou CNPJ</mat-label>
          <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
        </mat-form-field>
      </div>
      <div class="col-md-7">
        <mat-form-field appearance="outline">
          <mat-label>E-mail</mat-label>
          <input matInput type="text" formControlName="email">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Telefone</mat-label>
          <input matInput type="text" id="number" formControlName="phoneNumber"
            [mask]="masks.phone">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>CEP</mat-label>
          <input matInput type="text" formControlName="cep" [mask]="masks.cep">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <mat-form-field appearance="outline">
          <mat-label>Logradouro</mat-label>
          <input matInput type="text" formControlName="addressComplement">
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <mat-form-field appearance="outline">
          <mat-label>Número</mat-label>
          <input matInput type="text" formControlName="addressNumber">
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Bairro</mat-label>
          <input matInput type="text" formControlName="addressComplement">
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field appearance="outline">
          <mat-label>Complemento</mat-label>
          <input matInput type="text" formControlName="addressComplement">
        </mat-form-field>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="button" mat-dialog-actions [align]="'end'">
      <button class="button-cancel" mat-raised-button mat-dialog-close="false">Cancelar</button>
      <button id="button-register" mat-raised-button (click)="registerProfile()">Cadastrar</button>
    </div>
  </div>
</mat-dialog-content>
