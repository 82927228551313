import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { SearchPlateResponse } from '../../responses/autoparts/Vehicle/search-plate.response';
import { SearchPlateRequest } from '../../requests/autoparts/search-plate.request';
import { PopulateSelectResponse } from '../../responses/autoparts/Vehicle/populate-select.response';
import { CreateUpdatePlateResponse } from '../../responses/autoparts/create-update-plate.response';
import { CreatePlateRequest } from '../../requests/autoparts/create-update-plate.request';
import { GetServiceOrderResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-service-order-response';
import { ServiceOrderResponse } from '../../responses/serviceOrder/service-order.response';
import { ListServiceOrderResponse } from '../../responses/serviceOrder/list-service-order.response';
import { ServiceOrderSearchRequest } from '../../requests/serviceOrder/service-order-search.request';
import { ServiceOrderRequest } from '../../requests/serviceOrder/service-order.request';



@Injectable({
  providedIn: 'root'
})
export class ServiceOrderService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(searchText: string, idPlate:number): Observable<GetServiceOrderResponse> {

    let url = `ServiceOrder/serchText/${searchText}/idPlate/${idPlate}`

    return this.httpClient.get<GetServiceOrderResponse>(environment.urlApiServiceOrder + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public post(serviceOrderRequest:ServiceOrderRequest): Observable<ServiceOrderResponse> {

    return this.httpClient.post<ServiceOrderResponse>(environment.urlApiServiceOrder + "ServiceOrder", serviceOrderRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public getById(idServiceOrder:number): Observable<GetServiceOrderResponse> {

    return this.httpClient.get<GetServiceOrderResponse>(environment.urlApiServiceOrder + "ServiceOrder/idServiceOrder/" + idServiceOrder, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public listServiceOrder(body: ServiceOrderSearchRequest): Observable<ListServiceOrderResponse> {

    let url = `ServiceOrder/getAll`

    return this.httpClient.post<ListServiceOrderResponse>(environment.urlApiServiceOrder + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }
}
