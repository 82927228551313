import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { SearchShoppingCarItemStruct, SearchShoppingCartService, SearchShoppingCartStruct } from '../search/search-shopping-cart/search-shopping-cart.service';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { PopulatePurchaseService } from 'src/app/shared/services/API/services/orchestrator-purchase/populate-purchase.service';
import { PurchaseRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/purchase.request';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetPurchaseDataResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-data.response';
import { BranchDeliveryAddress } from 'src/app/shared/services/API/models/user/branch-delivery-address.model';
import { BranchCreditCard } from 'src/app/shared/services/API/models/user/branch-credit-card.model';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryAddressModalComponent } from './delivery-address-modal/delivery-address-modal.component';
import { CreditCardModalComponent } from './credit-card-modal/credit-card-modal.component';
import { BusinessDeliveryTypeStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/business-delivery-type.struct';
import { FinishPurchaseRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/finish-purchase.request';
import { FinishPurchaseService } from 'src/app/shared/services/API/services/orchestrator-purchase/finish-purchase.service';
import { SupplierStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier.struct';
import { PurchaseSupplierStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/purchase-supplier.struct';
import { RecalculateDeliveryResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/recalculate-delivery.response';
import { RecalculateDeliveryRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/recalculate-delivery.request';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { ValidatePurchaseResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/validate-purchase.response';
import { ValidatePurchaseModalComponent } from './validate-purchase-modal/validate-purchase-modal.component';
import { ListSupplierBranchStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/list-supplier.struct';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { DeliveryTypeEnum } from 'src/app/shared/enums/delivery-type.enum';
import { QuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/quote-service.service';
import { PartsNamesRequest } from 'src/app/shared/services/API/requests/autoparts/parts-names.request';
import { GetQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-quote-service.response';
import { PartsNamesResponse } from 'src/app/shared/services/API/responses/autoparts/parts-names.response';
import { PartsNamesService } from 'src/app/shared/services/API/services/autoparts/parts-names.service';
import { SupplierBranchStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-branch.struct';

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.css']
})
export class PurchaseComponent implements OnInit, OnDestroy {
  @ViewChild('menu') menu: MenuComponent;
  constructor(
    private formBuilder: FormBuilder,
    private searchShoppingCartService: SearchShoppingCartService,
    private populatePurchaseService: PopulatePurchaseService,
    private finishPurchaseService: FinishPurchaseService,
    private alertService: AlertService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public maskService: MaskService,
    private router: Router,
    private quoteServiceService: QuoteServiceService,
    private partsNamesService: PartsNamesService,
    private cdr: ChangeDetectorRef
  ) { }

  public masks: Masks;
  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;

  public model: FormGroup = null;
  public idServiceOrder: number = null;
  public idQuoteService: number = null;
  public isLoading: boolean = false;
  public getPurchaseDataResponse: GetPurchaseDataResponse = new GetPurchaseDataResponse();
  public listSupplierBranchStruct: SupplierBranchStruct[]
  public listPartProductStruct: PartProductStruct[];
  public listIdSupplier: ListSupplierBranchStruct[] = [];
  public listBranchDeliveryAddress: BranchDeliveryAddress[] = [];
  public listBranchCreditCard: BranchCreditCard[] = [];
  public validatePurchaseResponse: ValidatePurchaseResponse
  public selectedCard: BranchCreditCard;
  public selectedDeliveryPrice: number = 0;
  public selectedDelivery: BusinessDeliveryTypeStruct;
  public totalPartsPrice: number;
  public unityPartsPrice: number;
  public totalPrice: number;
  public cartStruct: SearchShoppingCartStruct;
  public listParts: SearchShoppingCarItemStruct[];
  public formattedPrice: number;
  public idBranchSupplier: boolean;
  public idBranch: any;
  public insufficientStock: boolean;
  public estimatedTimeInMinutes: number;
  public formatedSelectedDeliveryPrice: string;
  public formattedPartsPrice: string;
  public hasSecondaryHeader: boolean = false;
  public timeout;
  public idPurchase: number;
  public paymentMeros: boolean = false;
  public isMerosPart: boolean;

  ngOnInit(): void {
    this.masks = this.maskService.getMasks();
    this.getListSupplier();

    this.activatedRoute.queryParams.subscribe(params => {
      this.idServiceOrder = params.idServiceOrder;
      this.idQuoteService = params.idQuoteService;

      if (this.idServiceOrder) {
        this.hasSecondaryHeader = true;
        this.populateHeader();
      }

      this.model = this.formBuilder.group({
        phone: ['', [Validators.required]],
        name: ['', [Validators.required]],
        idBranchAddress: ['', [Validators.required]],
        idCreditCard: ['', []],
      });
      this.populateListPart();
    });
  }

  ngOnDestroy() {
    clearTimeout(this.timeout);
  }

  populateHeader() {
    setTimeout(() => {
      this.menu.reloadHeader(this.idServiceOrder);
    }, 1000);
  }

  buy() {
    if (this.paymentMeros && this.model.invalid || this.selectedDelivery == null) {
      this.alertService.show('Erro', "Todos os campos são obrigatórios.", AlertType.error)
      return;
    }

    if (this.listSupplierBranchStruct.find(x => x.selectedDeliveryType.idDeliveryType == DeliveryTypeEnum.meros_lalamove)
      && !this.model.get("idCreditCard").value) {
      this.alertService.show('Erro', "Selecione um cartão de credito válido", AlertType.error)
      return;
    }
    if (this.isLoading) return;

    this.isLoading = true;
    let finishPurchaseRequest: FinishPurchaseRequest = new FinishPurchaseRequest();

    finishPurchaseRequest.idQuoteService = this.idQuoteService;
    finishPurchaseRequest.isValidated = false;
    finishPurchaseRequest.totalValue = this.totalPrice;
    finishPurchaseRequest.deliveryPrice = this.selectedDeliveryPrice;
    finishPurchaseRequest.idBranchDeliveryAddress = this.model.get("idBranchAddress").value;
    finishPurchaseRequest.idBranchCreditCard = this.model.get("idCreditCard").value ? this.model.get("idCreditCard").value : null;
    finishPurchaseRequest.recieverName = this.model.get("name").value;
    finishPurchaseRequest.recieverPhone = this.model.get("phone").value;;

    let listPurchaseSupplierStruct: PurchaseSupplierStruct[] = [];
    this.listSupplierBranchStruct.forEach(x => {

      let purchaseSupplierStruct: PurchaseSupplierStruct = new PurchaseSupplierStruct()
      purchaseSupplierStruct.idBranchSupplier = this.listIdSupplier[0].idBranchSupplier;
      purchaseSupplierStruct.supplierName = x.branchName;
      purchaseSupplierStruct.supplierPhone = x.phone;
      purchaseSupplierStruct.deliveryFee = x.selectedDeliveryType.totalDeliveryFee;
      purchaseSupplierStruct.idQuotationLalamove = x.selectedDeliveryType.idQuotationLalamove;
      purchaseSupplierStruct.idStopSenderLalamove = x.selectedDeliveryType.idStopSenderLalamove;
      purchaseSupplierStruct.idStopRecieverLalamove = x.selectedDeliveryType.idStopRecieverLalamove;
      purchaseSupplierStruct.idDeliveryType = x.selectedDeliveryType.idDeliveryType;

      listPurchaseSupplierStruct.push(purchaseSupplierStruct);
    })

    finishPurchaseRequest.listSupplier = listPurchaseSupplierStruct;
    this.listParts.forEach(element => {
      element.partProductStruct.totalQuantity = element.amount;

      let isMerosPart = element.partProductStruct.isMerosPart == true

      if(!isMerosPart){
        element.partProductStruct.partNumber = element.partProductStruct.supplierPartNumber;
        element.partProductStruct.productName = element.partProductStruct.supplierPartDescription
      }
    });
    finishPurchaseRequest.listParts = this.listParts.map(x => x.partProductStruct);
    this.finishPurchase(finishPurchaseRequest);
  }

  changeAddress(event) {
    if (this.isLoading) return;
    this.isLoading = true;

    let recalculateDeliveryRequest: RecalculateDeliveryRequest = new RecalculateDeliveryRequest()
    recalculateDeliveryRequest.listSupplier = this.listIdSupplier
    recalculateDeliveryRequest.idBranchDeliveryAddress = this.model.get("idBranchAddress").value
    //chamar api Lalamove para recalcular os valores das entregas
    this.populatePurchaseService.recalculateDelivery(recalculateDeliveryRequest).subscribe({
      next: (response: RecalculateDeliveryResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }
        this.listSupplierBranchStruct = response.listSupplierBranchStruct;
        this.selectedDeliveryPrice = 0;
        this.formatedSelectedDeliveryPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.selectedDeliveryPrice)
        this.totalPrice = this.totalPartsPrice + this.selectedDeliveryPrice;
        this.formattedPrice = this.totalPrice;

        this.listSupplierBranchStruct.forEach(x => {
          const deliveryType = x.listSupplierDeliveryType.find(meros => meros.idDeliveryType == DeliveryTypeEnum.meros_lalamove);
          
          if (deliveryType) {
            const hasInsufficientStock = this.listParts.some(part => {
              const idBranch = part.partProductStruct.idBranchSupplier;
              const branchInfo = part.partProductStruct.supplierInternalInfo.find(supplier => supplier.idBranchSupplier == idBranch);
              return part.amount > branchInfo?.quantity;
            });
            
            if(deliveryType){
              this.estimatedTimeInMinutes = hasInsufficientStock ? 180 : 120;
            }else{
              this.estimatedTimeInMinutes = 0
            }

            deliveryType.estimatedTimeArrival = hasInsufficientStock 
              ? 'Entrega em até 3 horas.' 
              : 'Entrega em até 2 horas.';
            
            deliveryType.deliveryTypeName = 'Meros';
          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
      },
    });

  }

  changeDelivery(event) {
    this.selectedDelivery = event

    if(this.selectedDelivery.idDeliveryType == 1){
      this.paymentMeros = true
    }else{
      this.paymentMeros = false
    }

    this.selectedDeliveryPrice = 0;
    this.listSupplierBranchStruct.forEach(x => {
      x.selectedDeliveryType = x.listSupplierDeliveryType.find(y => y.idBusinessDeliveryType == this.selectedDelivery.idBusinessDeliveryType)
      this.selectedDeliveryPrice += x.selectedDeliveryType.totalDeliveryFee;

      })
    this.totalPrice = this.totalPartsPrice + this.selectedDeliveryPrice; //verificar tela
    this.formattedPrice = this.totalPrice;
    this.formatedSelectedDeliveryPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.selectedDeliveryPrice)
  }

  getListSupplier() {
    this.cartStruct = this.searchShoppingCartService.getParts();
    this.listPartProductStruct = this.cartStruct.searchShoppingCarItemStruct.map(x => x.partProductStruct);
    this.listPartProductStruct.forEach(element => {
      let idSupplierBranch = element.idBranchSupplier;
      const alreadyExists = this.listIdSupplier.some(supplier => supplier.idBranchSupplier === idSupplierBranch);
      if (!alreadyExists) {
        let listSupplierStruct: ListSupplierBranchStruct = new ListSupplierBranchStruct();
        listSupplierStruct.idBranchSupplier = idSupplierBranch;
        this.listIdSupplier.push(listSupplierStruct);
      }
    });
    this.populateData(this.listIdSupplier);
  }
  

  populateData(data: any) {
    let request: PurchaseRequest = new PurchaseRequest();
    request.listSupplier = data;
    request.idServiceOrder = this.idServiceOrder;

    this.listParts = this.cartStruct.searchShoppingCarItemStruct
      this.listParts.forEach(x => {
        this.idBranch = x.partProductStruct.idBranchSupplier;

        this.idBranchSupplier = this.idBranch == x.partProductStruct.supplierInternalInfo.find(supplier => supplier.idBranchSupplier == this.idBranch)?.idBranchSupplier;

        if(this.idBranchSupplier){

          this.insufficientStock = x.amount > x.partProductStruct.supplierInternalInfo.find(supplier => supplier.idBranchSupplier == this.idBranch)?.quantity
              
          if(this.insufficientStock){

            this.insufficientStock = x.amount > x.partProductStruct.supplierInternalInfo.find(supplier => supplier.idBranchSupplier == this.idBranch)?.quantity
          }
          }else{
            this.insufficientStock = x.amount < x.partProductStruct.supplierInternalInfo.find(x => x.quantity)?.quantity
          }
      })

      this.isLoading = true;

    this.populatePurchaseService.getPurchaseData(request).subscribe({
      next: (response: GetPurchaseDataResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }
        this.getPurchaseDataResponse = response;
        this.listSupplierBranchStruct = response.listSupplierBranchStruct;
        this.listBranchDeliveryAddress = response.listBranchDeliveryAddress;
        this.listBranchDeliveryAddress.sort((a, b) => {
          return b.isDefault ? 1 : -1;
        });
        this.listBranchCreditCard = response.listBranchCreditCard;

        if (this.listBranchDeliveryAddress.length == 1) {
          this.model.get('idBranchAddress').setValue(this.listBranchDeliveryAddress[0].idBranchDeliveryAddress);
          this.listBranchDeliveryAddress[0].isChecked = true;
        }
        else {
          this.model.get('idBranchAddress').setValue(this.listBranchDeliveryAddress.filter(x => x.isDefault === true)[0].idBranchDeliveryAddress);
          this.listBranchDeliveryAddress.filter(x => x.isDefault)[0].isChecked = true;
          // this.listBranchDeliveryAddress[0].isChecked = true;
        }

        const currentDate = new Date();

        currentDate.setHours(currentDate.getHours() + 2);
        const day = String(currentDate.getDate()).padStart(2, '0'); // Add leading zero if needed
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so add 1
        const hours = String(currentDate.getHours()).padStart(2, '0'); // Add leading zero if needed
        const minutes = String(currentDate.getMinutes()).padStart(2, '0'); // Add leading zero if needed

        const formattedDateTime = `${day}/${month} às ${hours}:${minutes}`;

        this.listSupplierBranchStruct.forEach(x => {
          const deliveryType = x.listSupplierDeliveryType.find(meros => meros.idDeliveryType == DeliveryTypeEnum.meros_lalamove);
          
          if (deliveryType) {
            const hasInsufficientStock = this.listParts.some(part => {
              const idBranch = part.partProductStruct.idBranchSupplier;
              const branchInfo = part.partProductStruct.supplierInternalInfo.find(supplier => supplier.idBranchSupplier == idBranch);
              return part.amount > branchInfo?.quantity;
            });
            
            if(deliveryType){
              this.estimatedTimeInMinutes = hasInsufficientStock ? 180 : 120;
            }else{
              this.estimatedTimeInMinutes = 0
            }

            deliveryType.estimatedTimeArrival = hasInsufficientStock 
              ? 'Entrega em até 3 horas.' 
              : 'Entrega em até 2 horas.';
            
            deliveryType.deliveryTypeName = 'Meros';
          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
      },
    });
  }

  openAddressModal(address: BranchDeliveryAddress, isUpdate: boolean = false) {
    const dialogRef = this.dialog.open(DeliveryAddressModalComponent, {
      data: {
        address: address,
        isUpdate
      },
    })
    dialogRef.componentInstance.confirmRegister.subscribe({
      next: result => {
        if (result && result.address) {
          this.listBranchDeliveryAddress.push(result.address)
        }
        this.getListSupplier()
      }
      
    });
  }

  openCreditCardModal(creditCard: BranchCreditCard, isUpdate: boolean = false) {
    const dialogRef = this.dialog.open(CreditCardModalComponent, {
      height: '80%',
      data: {
        creditCard: creditCard,
        isUpdate
      },
    })
    dialogRef.afterClosed().subscribe({
      next: result => {

        if (result && result.creditCard) {
          this.listBranchCreditCard.push(result.creditCard);
        }
      }
    });
  }

  populateListPart() {
    this.totalPartsPrice = 0;
    this.unityPartsPrice = 0;

    if (this.idQuoteService == null || this.idQuoteService == undefined) {
      this.listParts = this.cartStruct.searchShoppingCarItemStruct

      this.listParts.forEach(x => {
        this.totalPartsPrice += x.partProductStruct.priceInvoice * x.amount
        x.partProductStruct.value = this.totalPartsPrice.toString();
      })

      this.totalPrice = this.totalPartsPrice + this.selectedDeliveryPrice;
      this.formattedPartsPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.totalPartsPrice)
      this.formattedPrice = this.totalPrice;
      this.formatedSelectedDeliveryPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.selectedDeliveryPrice)
    }
    else {
      this.listParts = [];
      this.quoteServiceService.get(this.idQuoteService).subscribe({
        next: (getQuoteServiceResponse: GetQuoteServiceResponse) => {

          if (getQuoteServiceResponse.isError) {
            this.alertService.show('Erro', getQuoteServiceResponse.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          //Obtem o partProductStruct
          let partsNamesRequest: PartsNamesRequest = new PartsNamesRequest();
          partsNamesRequest.listPartID = getQuoteServiceResponse.listQuoteServicePart.map(c => c.idPart);

          this.partsNamesService.post(partsNamesRequest).subscribe({
            next: (partsNamesResponse: PartsNamesResponse) => {

              if (partsNamesResponse.isError) {
                this.alertService.show('Erro', partsNamesResponse.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
              }

              //Insere todas as peças na lista de peças de estoque para imprimir na tela
              getQuoteServiceResponse.listQuoteServicePart.forEach(element => {

                let partProductStruct: PartProductStruct = partsNamesResponse.listPartProduct.find(c => c.idPart == element.idPart);

                let searchShoppingCarItemtStuct: SearchShoppingCarItemStruct = new SearchShoppingCarItemStruct();
                searchShoppingCarItemtStuct.partProductStruct = partProductStruct;
                searchShoppingCarItemtStuct.partProductStruct.priceInvoice = element.price;
                searchShoppingCarItemtStuct.amount = element.amount;
                searchShoppingCarItemtStuct.partProductStruct.idBusinessSupplier = element.idSupplier;
                searchShoppingCarItemtStuct.partProductStruct.partImage64 = null;

                this.listParts.push(searchShoppingCarItemtStuct);

                searchShoppingCarItemtStuct.partProductStruct.priceInvoice = this.totalPartsPrice += (searchShoppingCarItemtStuct.partProductStruct.priceInvoice * searchShoppingCarItemtStuct.amount)

                this.totalPrice = this.totalPartsPrice + this.selectedDeliveryPrice;
                this.formattedPartsPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.totalPartsPrice)
                this.formattedPrice = this.totalPrice;
                this.formatedSelectedDeliveryPrice = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.selectedDeliveryPrice)
              });

            },
            error: (error) => {
              console.error(error);
              this.alertService.show('Ação não concluída.', error, AlertType.error);
              this.isLoading = false;
            }
          });
        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }

    // this.totalItems = this.listParts ? this.listParts.length : 0

  }

  finishPurchase(finishPurchaseRequest: FinishPurchaseRequest) {
    const name = this.model.get('name').value;
    const phone = this.model.get('phone').value;
    finishPurchaseRequest.estimatedDeliveryTime = this.estimatedTimeInMinutes
    this.finishPurchaseService.finishBurchase(finishPurchaseRequest).subscribe({
      next: (validatePurchaseResponse: ValidatePurchaseResponse) => {
        if (validatePurchaseResponse.isError) {
          this.alertService.show('Erro', validatePurchaseResponse.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }

        this.validatePurchaseResponse = validatePurchaseResponse
        this.idPurchase = this.validatePurchaseResponse.idPurchase
        if (validatePurchaseResponse.validationError) {
          const dialogRef = this.dialog.open(ValidatePurchaseModalComponent, {
            data: validatePurchaseResponse,
          })
          dialogRef.afterClosed().subscribe({
            //mudar cache e alterar produtos
          });
          this.alertService.show('Erro', validatePurchaseResponse.validationMessage, AlertType.error)
          this.isLoading = false;
          return;
        }
        this.alertService.show('Sucesso', "Compra finalizada com sucesso", AlertType.success);
        this.isLoading = false;
        this.searchShoppingCartService.clearParts();
        this.router.navigate(['/workshop/purchase-made', this.idPurchase], { queryParams: { idPurchase: this.idPurchase, name: name, phone: phone } });
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
      },
    });
  }
}
