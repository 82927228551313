import { PartProductStruct } from "../../structs/autoparts/part-product.struct";

export class QuoteServicePart{
    public idQuoteServicePart:number;
    public idQuoteService:number;
    public idPart:number
    public markup:number;
    public amount:number;
    public price:number;
    public idSupplier:number;
    public supplierName:string;
    public datetimeInclusion:Date;
    public partProductStruct:PartProductStruct;
}