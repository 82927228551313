import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, RequiredValidator, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SelectVehicleVersionModalComponent } from 'src/app/shared/components/select-vehicle-version-modal/select-vehicle-version-modal.component';
import { Select2Component } from 'src/app/shared/components/select/select2/select2.component';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { CreatePlateRequest } from 'src/app/shared/services/API/requests/autoparts/create-update-plate.request';
import { SearchPlateRequest } from 'src/app/shared/services/API/requests/autoparts/search-plate.request';
import { VehicleService } from 'src/app/shared/services/API/services/autoparts/vehicle.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { DeliveryAddressModalComponent } from '../purchase/delivery-address-modal/delivery-address-modal.component';
import { CreditCardModalComponent } from '../purchase/credit-card-modal/credit-card-modal.component';
import { Console } from 'console';
import { SearchFipePlateRequest } from 'src/app/shared/services/API/requests/autoparts/search-fipe-plate.request';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  @ViewChild('select2Brand') select2Brand: Select2Component;
  @ViewChild('select2Model') select2Model: Select2Component;
  @ViewChild('select2Year') select2Year: Select2Component;
  @ViewChild('select2Version') select2Version: Select2Component;
  @ViewChild('inputSearchProduto', { static: false }) inputSearchProduto: ElementRef;
  @ViewChild('inputSearchPlaca', { static: false }) inputSearchPlaca: ElementRef;
  @ViewChild('inputDescriptionPlaca', { static: false }) inputDescriptionPlaca: ElementRef;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private alertService: AlertService,
    private vehicleService: VehicleService,
    private maskService: MaskService,
  ) {}

  public menuFunctionalityEnum:MenuFunctionalityEnum;
  public menuModuleEnum:MenuModuleEnum;
  public model: FormGroup;
  public isLoading: boolean;
  public listBrand: any = [];
  public listVehicleModel: any = [];
  public listYear: any = [];
  public listVersion: any = [];
  public timeoutKeySearch: any;
  public idVehicleModel: number;
  public year: number;
  public masks: Masks;
  public isVehicle = false;
  public isPlate = false;
  public isProduto = true;
  public isBusca = false;
  public isDescriptionVisible: boolean = false;
  public isVersion: boolean = false;
  public version: any;

  ngOnInit(): void {
    this.menuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
    this.menuModuleEnum = MenuModuleEnum.oficina;
    this.masks = this.maskService.getMasks();
    this.populateBrandSelect();
    this.model = this.formBuilder.group({
      description: ['', [Validators.required]],
      plateNumber: ['', [Validators.required, Validators.pattern('^[A-Z]{3}[0-9][0-9A-Z][0-9]{2}$')]],
      // plateNumberAll: ['', [Validators.pattern('^[A-Z]{3}[0-9][0-9A-Z][0-9]{2}$')]],
      // descriptionAll: [''],
      firstChildGroup: this.formBuilder.group({
        idBrand: [null, [Validators.required]],
        idVehicleModel: [{value: null, disabled: true}, [Validators.required]],
        year: [{value: null, disabled: true}, [Validators.required]],
        idVersion: [{value: null, disabled: true}, [Validators.required]],
      }),
    });
    sessionStorage.removeItem("Plate");
  }

  ngOnDestroy(){
    clearTimeout(this.timeoutKeySearch);
  }

clearFilters() {
  const descriptionControl = this.model.get('description');
  const descriptionPlateControl = this.model.get('descriptionPlate');
  const descriptionAllControl = this.model.get('descriptionAll');
  const plateNumberControl = this.model.get('plateNumber');
  const plateNumberAllControl = this.model.get('plateNumberAll');
  const firstChildGroup = this.model.get('firstChildGroup');

  if (descriptionControl) descriptionControl.setValue(null);
  if (descriptionPlateControl) descriptionPlateControl.setValue(null);
  if (descriptionAllControl) descriptionAllControl.setValue(null);
  if (plateNumberControl) plateNumberControl.setValue(null);
  if (plateNumberAllControl) plateNumberAllControl.setValue(null);

  if (firstChildGroup) {
    const idBrandControl = firstChildGroup.get('idBrand');
    const idVehicleModelControl = firstChildGroup.get('idVehicleModel');
    const yearControl = firstChildGroup.get('year');
    const idVersionControl = firstChildGroup.get('idVersion');

    if (idBrandControl) {
      idBrandControl.setValue(null);
      idBrandControl.enable();
    }
    if (idVehicleModelControl) {
      idVehicleModelControl.setValue(null);
      idVehicleModelControl.disable();
    }
    if (yearControl) {
      yearControl.setValue(null);
      yearControl.disable();
    }
    if (idVersionControl) {
      idVersionControl.setValue(null);
      idVersionControl.disable();
    }
  } else {
    console.error('firstChildGroup is not defined in the FormGroup');
  }
}


  submit(){
    if (this.isProduto) {
        const description = this.model.get("description").value;

        if (!description) {
            this.alertService.show('Alerta', "Preencha o campo de descrição para a pesquisa", AlertType.warning);
            return;
        }

        this.router.navigate(['/workshop/search', { description }]);
    } else if (this.isPlate) {
      const plateNumber = this.model.get("plateNumber").value;
      const description = this.model.get("description").value;
  
      if (!plateNumber || !description) {
          this.alertService.show('Alerta', "Preencha os dois campos (Placa e Descrição) para a pesquisa", AlertType.warning);
          return;
      }
  
      this.router.navigate(['/workshop/search', { plateNumber, description }]);
  } else if (this.isVehicle) {
        const idBrand = this.model.get("firstChildGroup").get('idBrand').value;
        const idVehicleModel = this.model.get("firstChildGroup").get('idVehicleModel').value;
        const year = this.year;
        const idVersion = this.model.get("firstChildGroup").get('idVersion').value;
        const description = this.model.get("description").value;

        if (!idBrand && !idVehicleModel && !year && !idVersion) {
            this.alertService.show('Alerta', "Preencha pelo menos um dos campos para a pesquisa", AlertType.warning);
            return;
        }

        this.router.navigate(['/workshop/search', { idBrand, idVehicleModel, year, idVersion, description }]);
    }
    // else if(this.isBusca){
    //   let plateNumberAll = this.model.get("plateNumberAll").value;
    //   let descriptionAll = this.model.get("descriptionAll").value;

    //   if (!plateNumberAll && !descriptionAll) {
    //       this.alertService.show('Alerta', "Preencha pelo menos um dos campos para a pesquisa", AlertType.warning);
    //       return;
    //   }
    //   let idBrand = this.model.get("firstChildGroup").get('idBrand').value;
    //   let idVehicleModel = this.model.get("firstChildGroup").get('idVehicleModel').value;
    //   let year = this.model.get("firstChildGroup").get('year').value;
    //   let idVersion = this.model.get("firstChildGroup").get('idVersion').value;

    //   if (!idBrand && !idVehicleModel && !year && !idVersion) {
    //       this.alertService.show('Alerta', "Preencha pelo menos um dos campos para a pesquisa", AlertType.warning);
    //       return;
    //   }
    //   this.router.navigate(['/workshop/search', { idBrand, idVehicleModel, year, idVersion }]);
    // }
  }

  brandSelect(event){
    if(!event){
      this.model.get('firstChildGroup').get('idVehicleModel').setValue(null);
      this.model.get('firstChildGroup').get('year').setValue(null);
      this.model.get('firstChildGroup').get('idVersion').setValue(null);
      this.model.get('firstChildGroup').get('idVehicleModel').disable();
      this.model.get('firstChildGroup').get('year').disable();
      this.model.get('firstChildGroup').get('idVersion').disable();
      return
    }
    this.model.get('firstChildGroup').get('idVehicleModel').setValue(null);
    this.model.get('firstChildGroup').get('year').setValue(null);
    this.model.get('firstChildGroup').get('idVersion').setValue(null);

    this.model.get('firstChildGroup').get('idVehicleModel').enable();
    this.model.get('firstChildGroup').get('year').disable();
    this.model.get('firstChildGroup').get('idVersion').disable();
    this.populateModelSelect(event.id);
  }

  vehicleModelSelect(event){
    if(!event){
      this.model.get('firstChildGroup').get('year').setValue(null);
      this.model.get('firstChildGroup').get('idVersion').setValue(null);
      this.model.get('firstChildGroup').get('year').disable();
      this.model.get('firstChildGroup').get('idVersion').disable();
      return
    }
    this.model.get('firstChildGroup').get('year').setValue(null);
    this.model.get('firstChildGroup').get('idVersion').setValue(null);

    this.model.get('firstChildGroup').get('year').enable();
    this.model.get('firstChildGroup').get('idVersion').disable();
    this.idVehicleModel = event.id
    this.populateYearSelect(event.id);
  }

  yearSelect(event){
    if(!event){
      this.model.get('firstChildGroup').get('idVersion').setValue(null);
      this.model.get('firstChildGroup').get('idVersion').disable();
      return
    }
    this.model.get('firstChildGroup').get('idVersion').setValue(null);
    this.model.get('firstChildGroup').get('idVersion').enable();
    this.populateVersionSelect(event.name);
    this.year = event.name
  }

  searchPlate() {
    this.isLoading = true;
    this.model.get('plateNumber').disable();

    const searchPlateRequest: SearchPlateRequest = new SearchPlateRequest();
    searchPlateRequest.plate = this.model.get('plateNumber').value;

    this.vehicleService.searchPlate(searchPlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }

        const listVersion = response.version;

        if(!listVersion || listVersion.length == 0){
          this.alertService.show('Alerta!', "Nenhum veículo encontrado", AlertType.warning);
          return
        }
        else if(listVersion  && listVersion.length > 1){
          this.openSelectVersionModal(listVersion)
          this.isLoading = false;
          return
        }
        // else{
        //   this.createUpdatePlate(listVersion[0].idBrand)
        // }
        const version = listVersion[0]

        if(!version){
          this.alertService.show('Aviso', "Placa não encontrada", AlertType.warning)
        }

        this.model.get('firstChildGroup').get('idBrand').setValue(version.idBrand);
        this.model.get('firstChildGroup').get('idVehicleModel').setValue(version.idVehicleModel);
        this.model.get('firstChildGroup').get('year').setValue(new Date(version.modelDate).getFullYear());
        this.model.get('firstChildGroup').get('idVersion').setValue(version.idVersion);

        this.isDescriptionVisible = true;

        this.version = version

        this.isLoading = false;
        this.isVersion = true;
        this.model.get('plateNumber').enable();

        setTimeout(() => {
          this.inputDescriptionPlaca.nativeElement.focus();
        }, 0);

      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  onKeySearch(event){
    const value = event.target.value;
    if(value && value.length == 8){
      clearTimeout(this.timeoutKeySearch);
      this.timeoutKeySearch = setTimeout(() => {
        this.searchPlate();
      });
    }
  }

  focusInput(){
  }


  populateBrandSelect() {
    this.vehicleService.listBrand().subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }
        this.listBrand = response.list.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        });
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  populateModelSelect(idBrand: number) {
    this.vehicleService.listModel(idBrand).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }
        this.select2Model.populateList(response.list);
        this.listVehicleModel = response.list;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  populateYearSelect(idModel: number) {
    this.vehicleService.listYear(idModel).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }
        this.select2Year.populateList(response.list);
        this.listYear = response.list;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  populateVersionSelect(year:number) {
    this.vehicleService.listVehicleType(this.idVehicleModel, year).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          return;
        }
        this.select2Version.populateList(response.list);
        this.listVersion = response.list;
      },
      error: (error) => {
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  openSelectVersionModal(listVersion){
    const dialogRef = this.dialog.open(SelectVehicleVersionModalComponent, {
      data: {
        listVersion : listVersion,
      },
    })
    dialogRef.beforeClosed().subscribe({
      next: result =>{
        if (result && result.vehicleVersion){
          this.SearchPlateRequest(result.fipe, result.modelYear, result.state, result.chassisNumber, result.color, result.origin)
          this.isDescriptionVisible = true;
        }
      }
    })

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.vehicleVersion){
          this.model.get('firstChildGroup').get('idBrand').setValue(result.vehicleVersion.idBrand);
          this.model.get('firstChildGroup').get('idVehicleModel').setValue(result.vehicleVersion.idVehicleModel);
          this.model.get('firstChildGroup').get('year').setValue(new Date(result.vehicleVersion.modelDate).getFullYear());
          this.model.get('firstChildGroup').get('idVersion').setValue(result.vehicleVersion.idVersion);

          this.model.get('firstChildGroup').get('idBrand').disable();
          this.model.get('firstChildGroup').get('idVehicleModel').disable();
          this.model.get('firstChildGroup').get('year').disable();
          this.model.get('firstChildGroup').get('idVersion').disable();
        }
      }
    });
  }

  createUpdatePlate(idVersion: number) {
    this.isLoading = true;
    const createUpdatePlateRequest: CreatePlateRequest = new CreatePlateRequest();
    createUpdatePlateRequest.plate = this.model.get('plateNumber').value;
    createUpdatePlateRequest.idVersion = idVersion;

    this.vehicleService.createUpdatePlate(createUpdatePlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  SearchPlateRequest(fipeCode: string, modelYear: string, state?: string, chassisNumber?: string, color?: string, origin?: string) {
    this.isLoading = true;
    const searchPlateRequest: SearchFipePlateRequest = new SearchFipePlateRequest();
    searchPlateRequest.plate = this.model.get('plateNumber').value;
    searchPlateRequest.fipeCode = fipeCode;
    searchPlateRequest.modelYear = modelYear;
    searchPlateRequest.state = state;
    searchPlateRequest.chassisNumber = chassisNumber;
    searchPlateRequest.color = color;
    searchPlateRequest.origin = origin;

    this.vehicleService.SearchFipePlate(searchPlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
      },
    });
  }

  setIsPlate(){
    this.isProduto = false;
    this.isVehicle = false;
    this.isPlate = true;
    this.isBusca = false;
    this.model.get('plateNumber').enable();
    setTimeout(() => this.inputSearchPlaca.nativeElement.focus(), 0);
    this.clearFilters()
  }

  setIsProduto(){
    this.isPlate = false;
    this.isVehicle = false;
    this.isProduto = true;
    this.isBusca = false;
    this.isDescriptionVisible = false;
    this.isVersion = false
    setTimeout(() => this.inputSearchProduto.nativeElement.focus(), 0);
    sessionStorage.removeItem("Plate");
    this.clearFilters()
  }

  // setIsBusca(){
  //   this.isPlate = false;
  //   this.isVehicle = false;
  //   this.isProduto = false;
  //   this.isBusca = true;
  //   this.clearFilters()
  //   let timeout = setTimeout(()=> {this.select2Brand.populateList(this.listVersion)}, 500)
  //   clearTimeout(timeout)
  // }

  setVehicle(){
    this.isVehicle = true;
    this.isPlate = false;
    this.isProduto = false;
    this.isBusca = false;
    this.isDescriptionVisible = false;
    this.isVersion = false;
    this.clearFilters()
    const timeout = setTimeout(()=> {this.select2Brand.populateList(this.listVersion)}, 500)
    sessionStorage.removeItem("Plate");
    clearTimeout(timeout)
  }
}
