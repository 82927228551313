import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ServiceOrderSearchRequest } from 'src/app/shared/services/API/requests/serviceOrder/service-order-search.request';
import { ServiceOrderRequest } from 'src/app/shared/services/API/requests/serviceOrder/service-order.request';
import { SearchServiceOrderRequest } from 'src/app/shared/services/API/requests/autoparts/search-service-order.request';
import { ListServiceOrderResponse } from 'src/app/shared/services/API/responses/serviceOrder/list-service-order.response';
import { PlateVehicleNameResponse } from 'src/app/shared/services/API/responses/serviceOrder/plate-vehicle-name.response';
import { ServiceOrderResponse } from 'src/app/shared/services/API/responses/serviceOrder/service-order.response';
import { SearchServiceOrderResponse } from 'src/app/shared/services/API/responses/autoparts/search-service-order.response';
import { ServiceOrderService } from 'src/app/shared/services/API/services/serviceOrder/service-order.service';
import { SearchPlate } from 'src/app/shared/services/API/services/autoparts/search-plate.service';
import { SearchServiceOrderService } from 'src/app/shared/services/API/services/autoparts/search-service-order.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { ModalServiceOrderNewComponent } from '../quote-service/modal-service-order-new/modal-service-order-new.component';

@Component({
  selector: 'app-service-order-list',
  templateUrl: './service-order-list.component.html',
  styleUrls: ['./service-order-list.component.css']
})
export class ServiceOrderListComponent implements OnInit{
  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    public alertService: AlertService,
    public serviceOrderService:ServiceOrderService,
    public searchServiceOrderService:SearchServiceOrderService,
    private router: Router, 
    private searchPlate:SearchPlate) 
    {
      this.onResize();
    }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_orcamento;

  public model: FormGroup;  
  public isLoading:boolean = false;
  public setDetails: Boolean = false;
  modalAberto = false;
  loading: boolean = false;

  public pageIndex:number = 0;
  public pageSize:number = 20;
  public length:any;
  public screenWidth: number;

  public listServiceOrderResponse: ListServiceOrderResponse = null;

  ngOnInit(): void {
    this.model = this.formBuilder.group({
      dateSearch: [''],
      end: [''],
      start: [''],
      clientName: [''],
      idStatusServiceOrder: [''],
      plate: [''],
      brand: [''],
      vehicleModel: [''],
    });

    this.search(false);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  clearFields() {
    this.model.get('dateSearch').setValue('');
    this.model.get('end').setValue('');
    this.model.get('start').setValue('');
    this.model.get('clientName').setValue('');
    this.model.get('idStatusServiceOrder').setValue('');
    this.model.get('plate').setValue('');
    this.model.get('brand').setValue('');
    this.model.get('vehicleModel').setValue('');
  }


  clearFilter(){
    this.clearFields()

    setTimeout(() => {
      this.search(false);
    }, 1000);
  }

  search(nextPage){
        
    let searchServiceOrderRequest:SearchServiceOrderRequest = new SearchServiceOrderRequest(); 

    searchServiceOrderRequest.plate = this.model.get("plate").value;
    searchServiceOrderRequest.brand = this.model.get("brand").value;
    searchServiceOrderRequest.vehicleModel = this.model.get("vehicleModel").value;

    if(nextPage){
      this.pageIndex = this.pageIndex + 1;
    }
    else {
      this.pageIndex = 0;
    }

    this.searchServiceOrderService.post(searchServiceOrderRequest).subscribe({
      next: (searchServiceOrderResponse: SearchServiceOrderResponse) => {

        if (searchServiceOrderResponse.isError) {
          this.alertService.show('Erro', searchServiceOrderResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        let serviceOrderSearchRequest:ServiceOrderSearchRequest = new ServiceOrderSearchRequest();

        serviceOrderSearchRequest.clientName = this.model.get("clientName").value;
        serviceOrderSearchRequest.dateSearch = this.model.get("dateSearch").value;
        serviceOrderSearchRequest.idStatusServiceOrder = parseInt(this.model.get("idStatusServiceOrder").value);
        serviceOrderSearchRequest.listIdPlate = searchServiceOrderResponse.listIdPlate;
        serviceOrderSearchRequest.pageIndex = this.pageIndex;
        serviceOrderSearchRequest.pageSize = this.pageSize;
      
        this.serviceOrderService.listServiceOrder(serviceOrderSearchRequest).subscribe({
          next: (listServiceOrderResponse: ListServiceOrderResponse) => {

            if (listServiceOrderResponse.isError) {
              this.alertService.show('Erro', listServiceOrderResponse.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            this.searchPlate.get(listServiceOrderResponse.listServiceOrderStruct.map(c=> c.idPlate)).subscribe({
              next: (plateVehicleNameResponse: PlateVehicleNameResponse) => {
    
                if (plateVehicleNameResponse.isError) {
                  this.alertService.show('Erro', plateVehicleNameResponse.errorDescription, AlertType.error);
                  this.isLoading = false;
                  return;
                }
               
                listServiceOrderResponse.listServiceOrderStruct.forEach(element => {
                  let plateinfo = plateVehicleNameResponse.listPlateVehicleNameStruct.find(c=> c.idPlate == element.idPlate);
                  // element.vehicleName = plateinfo.versionName;
                  // element.plateNumber = plateinfo.plateNumber;
                });

                if(nextPage == true)
                  this.listServiceOrderResponse.listServiceOrderStruct =  this.listServiceOrderResponse.listServiceOrderStruct.concat(listServiceOrderResponse.listServiceOrderStruct);
                else
                this.listServiceOrderResponse = listServiceOrderResponse;
                this.length = this.listServiceOrderResponse = listServiceOrderResponse
                this.length = this.length.listServiceOrderStruct
                this.length = this.length.length
                this.modalAberto = true;

              },
              error: (error) => {
                console.error(error);
                this.alertService.show('Erro inesperado', error, AlertType.error);
                this.isLoading = false;
              }
            });
          },
          error: (error) => {
            console.error(error);
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
          }
        });
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
    
  
    
  }

  openModalNewSO(){
    const dialogRefNew = this.dialog.open(ModalServiceOrderNewComponent, {
      data: {},
      panelClass: 'large-modal'
    });

    dialogRefNew.afterClosed().subscribe(result => {
      if (result && result.idServiceOrder) {
        this.router.navigate(['/workshop/quote-service'], { queryParams: { idServiceOrder: result.idServiceOrder} });
      }
      
    });
  }

  showLine(item: any): void{ 

    item.isDetails = !item.isDetails;
    item.idServiceOrder;
  }

  setIsDetails() {
    if (this.setDetails == true) {
      return this.setDetails = false
    }
    else {
      return this.setDetails = true
    }
  }

  redirect(idServiceOrder:number){
    this.router.navigate(['/workshop/service-order/details'], { queryParams: { idServiceOrder: idServiceOrder} });
  }

}
