import { Component, HostListener, OnInit } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { BranchDeliveryAddress } from 'src/app/shared/services/API/models/user/branch-delivery-address.model';
import { PopulatePurchaseService } from 'src/app/shared/services/API/services/orchestrator-purchase/populate-purchase.service';
import { GetPurchaseDataResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-data.response';
import { SupplierBranchStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-branch.struct';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryAddressModalComponent } from 'src/app/workshop/pages/purchase/delivery-address-modal/delivery-address-modal.component';
import { Location } from '@angular/common';
import { ModalAddressDeleteComponent } from '../delivery-address/modal-address-delete/modal-address-delete.component';
import { ModalPatnerWorkshopEditComponent } from './modal-patner-workshop-edit/modal-patner-workshop-edit.component';
import { WorkshopSupplierRelationshipResponse } from 'src/app/shared/services/API/responses/user/workshop-supplier-relationship.response';
import { WorkshopSupplierRelationshipRequest } from 'src/app/shared/services/API/requests/user/workshop-supplier-relationship.request';
import { WorkshopSupplierRelationshipService } from 'src/app/shared/services/API/services/user/workshop-supplier-relationship.service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';


@Component({
  selector: 'app-partner-workshops',
  templateUrl: './partner-workshops.component.html',
  styleUrls: ['./partner-workshops.component.css']
})
export class PartnerWorkshopsComponent {

  constructor(private alertService:AlertService,
    private workshopSupplierRelationshipService:WorkshopSupplierRelationshipService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private location: Location,
    private dialogRef: MatDialog,){

      this.model = this.formBuilder.group({
        idBranchAddress: ['', [Validators.required]],
      });
      this.onResize();

      this.populatePage()

  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.fornecedor;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.fornecedor_pedido;
  public workshopSupplierRelationshipResponse: WorkshopSupplierRelationshipResponse;
  public workshopSupplierRelationshipRequest: WorkshopSupplierRelationshipRequest;
  public listSupplierBranchStruct: SupplierBranchStruct[];
  public listDelivery: []= [];
  public isLoading: boolean;
  public model: FormGroup = null;
  public screenWidth: number;
  public token;
  public idBranch: number;
  public dataArray;
  public branchName: string[];


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  populatePage(){
    this.workshopSupplierRelationshipService.getAll().subscribe({
      next: (response: WorkshopSupplierRelationshipResponse) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }

        this.workshopSupplierRelationshipResponse = response;
        this.branchName = this.workshopSupplierRelationshipResponse.branchWorkshopName.map(x => x.branchName)
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
      },
    });
  }

  toggleStatus(item: any){
    const Status = item.isActive
    const idWorkshop = item.idWorkshopSupplierRelationship
    if(Status == true){
      this.workshopSupplierRelationshipService.DisableStatus(idWorkshop).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            this.isLoading = false;
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Status', 'Parceria Desativada', AlertType.success);
            // this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
        },
      });
    }else{
      this.workshopSupplierRelationshipService.ActivatedStatus(idWorkshop).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            this.isLoading = false;
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Parceria Ativada', AlertType.success);
            // this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
        },
      });
    }
  }

  backPage(){
    this.location.back();
  }

  openDialogDelete(item: any): void{
    const dialogRef = this.dialogRef.open(ModalAddressDeleteComponent,{
      data:{
        workshop: item.branchName
      },
    });

    const idWorkshopSupplierRelationship: number = item.idWorkshopSupplierRelationship;

    dialogRef.componentInstance.deleteConfirm.subscribe(() => {
      this.workshopSupplierRelationshipService.Delete(idWorkshopSupplierRelationship).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Excluído', 'Parceria Desativada', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  }

  openNewModal(){
    const dialogRef = this.dialogRef.open(ModalPatnerWorkshopEditComponent,{
      height: '80%',
      width: '80%',
      data: {
        branchWorkshop: this.branchName,
        newPartner: true
      },
    });

    dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
      const workshopRequest: WorkshopSupplierRelationshipRequest = new WorkshopSupplierRelationshipRequest();
      workshopRequest.creditLimit = Number(formData.creditLimit);
      workshopRequest.creditLimitResetDays = Number(formData.creditLimitResetDays);
      workshopRequest.partDiscount = Number(formData.partDiscount);
      workshopRequest.usedCredit = 1;
      workshopRequest.idBranchSupplier = 2;
      workshopRequest.listIdBranch = [];
      
      formData.listIdBranch.forEach((branchId: number) => {
        const individualRequest = { ...workshopRequest };
        individualRequest.idBranchWorkshop = branchId;
      this.workshopSupplierRelationshipService.Post(individualRequest).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.populatePage();
          if (this.alertService) {
            this.alertService.show('Sucesso', 'Parceria Cadastrada!', AlertType.success);
            this.dialogRef.closeAll();
          }
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          }
          this.isLoading = false;
        }
      });
    });
  });
  } 

  openDialogEdit(item: any): void{
      const dialogRef = this.dialogRef.open(ModalPatnerWorkshopEditComponent,{
        height: '80%',
        width: '80%',
        data: {
          branchWorkshop: item.branchName,
          creditLimit: item.creditLimit,
          creditLimitResetDays: item.creditLimitResetDays,
          partDiscount: item.partDiscount,
        },
      });  
      dialogRef.componentInstance.confirmRegister.subscribe((formData: any) => {
        const workshopRequest: WorkshopSupplierRelationshipRequest = new WorkshopSupplierRelationshipRequest();
        workshopRequest.creditLimit = Number(formData.creditLimit);
        workshopRequest.creditLimitResetDays = Number(formData.creditLimitResetDays);
        workshopRequest.partDiscount = Number(formData.partDiscount);
        workshopRequest.idBranchWorkshop = Number(formData.branchWorkshop);
    
        const idWorkshopSupplier: number = item.idWorkshopSupplierRelationship;
          
        this.workshopSupplierRelationshipService.Put(workshopRequest, idWorkshopSupplier).subscribe({
          next: (returnStruct: ReturnStruct) => {
            if (returnStruct.isError) {
              if (this.alertService) {
                this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
              }
              this.isLoading = false;
              return;
            }
            this.populatePage();
            this.alertService.show('Sucesso', 'Parceria editada', AlertType.success);
            this.dialogRef.closeAll();
          },
          error: (error) => {
            console.error(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
            this.isLoading = false;
          }
        });
      });
  
    }

}
