import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from './common-service';
import { catchError, retry } from 'rxjs/operators';
import { ReturnStruct } from '../structs/return.struct';
import { UserLoginResponse } from './API/responses/user/user-login.response';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends CommonService {

  constructor(private router: Router,
    private httpClient: HttpClient) {
    super();
  }

  isAuthenticated(): boolean {
    return sessionStorage.getItem("token") == null ? false : true;
  }

  public signIn(email: string, password: string, idBranch: number, ip: string): Observable<UserLoginResponse> {

    let userAuthRequest ={
      email: email,
      password: password,
      idBranch: idBranch,
      ip: ip
    };

    return this.httpClient.post<UserLoginResponse>(environment.urlApiUser + "Auth", userAuthRequest)
    .pipe(
      catchError(this.handleError)
    )
  }

  public getBranches(email: string): Observable<UserLoginResponse> {

    let url = `Auth/email/${email}`

    return this.httpClient.get<UserLoginResponse>(environment.urlApiUser + url)
    .pipe(
      catchError(this.handleError)
    )
  }

  updateToken(userLoginResponse: any) {
    sessionStorage.setItem("token", JSON.stringify(userLoginResponse));
  }

  updateDefaultModule(newDefaultModule: any) {
    let data = JSON.parse(sessionStorage.getItem("token")); 
    if (newDefaultModule != undefined && newDefaultModule != null) {
      data.idDefaultModule = newDefaultModule.idModule;
      data.uriDefaultModule = newDefaultModule.uri;
      data.defaultModuleName = newDefaultModule.nameModule;
    }
    else {
      data.idDefaultModule = null;
      data.uriDefaultModule = null;
      data.defaultModuleName = null;
    }
    sessionStorage.setItem("token", JSON.stringify(data));
  }

  logout() {
    sessionStorage.removeItem("token");
    this.router.navigate(['/'])
  }
  getTokenMenu() {
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));
    return userLoginResponse;
  }
  
  haveRolePermission(idRole: number) : boolean{
    let hasRole = false;
    let userLoginResponse: UserLoginResponse = JSON.parse(sessionStorage.getItem("token"));
    
    userLoginResponse.listRole.forEach(element => {
      if(element.idRole == idRole){
        hasRole = true;
      }
    });
    
    return hasRole;
  }
}
