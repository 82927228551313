import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { SearchPlateRequest } from '../../requests/autoparts/search-plate.request';
import { CreateUpdatePlateResponse } from '../../responses/autoparts/create-update-plate.response';
import { CreatePlateRequest } from '../../requests/autoparts/create-update-plate.request';
import { SerachPlateOsResponse } from '../../responses/autoparts/serach-plate-os.response';
import { PlateVehicleNameResponse } from '../../responses/serviceOrder/plate-vehicle-name.response';



@Injectable({
  providedIn: 'root'
})
export class SearchPlate extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public post(body: SearchPlateRequest): Observable<SerachPlateOsResponse> {

    let url = `SearchPlate/Search`

    return this.httpClient.post<SerachPlateOsResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public get(listIdPlate: number[]): Observable<PlateVehicleNameResponse> {

    let url = `SearchPlate/jsonListPlate/${JSON.stringify(listIdPlate)}`

    return this.httpClient.get<PlateVehicleNameResponse>(environment.urlApiAutoParts + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
