<app-menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>
<div class="container-body"
  *ngIf="branchCreditCardResponse != null && branchCreditCardResponse.listBranchCreditCard != null">

  <div class="col-md-3">
    <button class="button-back" (click)="backPage()">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>

  <div class="white-body">
    <div class="header">
      <h2>Formas de Pagamento</h2>
      <button mat-button type="submit" color="primary" id="add-cart" (click)="openDialogAdd()">
        <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus">add</mat-icon>
        <span>Adicione um Cartão de Crédito</span>
      </button>
    </div>
    <div class="row table-responsive" *ngIf="screenWidth >= 650">
      <table class="basic-table">
        <thead class="basic-table__head">
          <tr class="truncate">
            <th>
              <div class="icon-text">
                Cartão Principal
                <mat-icon mat-raised-button matTooltip="Selecione o cartão que você deseja como padrão em suas compras"
                  aria-label="Button that displays a tooltip when focused or hovered over" class="cart-icon">
                  info
                </mat-icon>
              </div>
            </th>
            <th>Cartão de Crédito/Débito</th>
            <th>Nome do Cartão</th>
            <th>Vencimento</th>
            <th></th>
          </tr>
        </thead>
        <tbody class="basic-table__body" *ngFor="let item of branchCreditCardResponse.listBranchCreditCard">
          <!-- INICIO MOCK -->
          <tr class="basic-table__body__row">
            <td class="truncate">
              <div class="icon-text">
                <button class="button-cart-principal" (click)="setCartaoPrincipal(item)">
                  <mat-icon class="mat-icon" *ngIf="item.isDefault; else notDefault">star</mat-icon>
                  <ng-template #notDefault>
                    <mat-icon>star_border</mat-icon>
                  </ng-template>
                </button>
              </div>            
            </td>
            <td class="truncate">
              <div class="icon-text">
                <mat-icon class="cart-icon mat-icon">credit_card</mat-icon>
                Terminado em {{item.creditCardDigits}}
              </div>
            </td>
            <td class="truncate">{{item.creditCardName}}</td>
            <td class="truncate">{{item.expirationMonth}}/{{item.expirationYear}}</td>
            <td class="truncate">
              <button mat-icon-button aria-label="Example icon button with a home icon" (click)="openDialogDelete(item)">
                <mat-icon class="mat-icon">delete</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="screenWidth < 650">
      <div class="row card-spaccing" *ngFor="let item of branchCreditCardResponse.listBranchCreditCard">
        <div class="col-sm-6 w-100">
          <div class="card">
            <div class="card-body">
              <div class="row card-title">
                <ul class="ul">
                  <li class="title">
                    <span class="second-title">Nome: </span>
                    <span>{{item.creditCardName}}</span>
                  </li>
                  <li class="title">
                    <span class="second-title">Cartão Principal: 
                    </span>
                    <div class="icon-text">
                      <button class="button-cart-principal" (click)="setCartaoPrincipal(item)">
                        <mat-icon class="mat-icon" *ngIf="item.isDefault; else notDefault">star</mat-icon>
                        <ng-template #notDefault>
                          <mat-icon>star_border</mat-icon>
                        </ng-template>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="row">
                <ul class="ul">
                  <li class="title">
                    <span class="second-title">
                      Cartão:  
                    </span>
                    <span>
                      {{item.creditCardDigits}}
                    </span>
                  </li>
                  <li class="title">
                    <span class="second-title">
                      Vencimento: 
                    </span>
                    <span>
                      {{item.expirationMonth}}/{{item.expirationYear}}
                    </span>
                  </li>
                </ul>
              </div>
              <div class="row button-card">
                <div class="button-os">
                  <div class="button" mat-dialog-actions>
                    <button style="background-color: red; color: white;" mat-button type="button" class="btn-primary" color="primary"
                      (click)="openDialogDelete(item)">
                      Deletar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>