export class BranchCreditCardRequest{
    public idBranchCreditCard: number;
    public creditCardDigits: string;
    public ccv: string;
    public creditCardName: string;
    public expirationMonth: number;
    public expirationYear: number;
    public holderName: string;
    public email: string;
    public cep: string;
    public addressNumber: string;
    public addressComplement: string;
    public phoneNumber: string;
    public cpfCnpj: string;
    public isDeleted: boolean;
    public remoteIp: string;
    public isDefault: boolean;
}