import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-vehicle-details-label',
  templateUrl: './vehicle-details-label.component.html',
  styleUrls: ['./vehicle-details-label.component.css']
})
export class VehicleDetailsLabelComponent {

  @Input() label: string;
  @Input() content: any;
  @Input() isFormField: boolean = false;
  @Output() passFormFieldValue = new EventEmitter<any>();

  public formFieldValue: any;

  onKeyUp(){
    this.passFormFieldValue.emit(this.formFieldValue);
  }
}
