<h2>Endereços de Entrega</h2>
<div class="button-container">
  <button mat-button color="primary" id="add-cart" (click)="openNewAddress(null)" type="button">
    <mat-icon class="material-symbols-outlined icon-add">add</mat-icon>
    Cadastrar Novo Endereço
  </button>
</div>
<div>
  <div class="row table-responsive">
    <table class="basic-table table-color">
      <thead class="basic-table__head">
        <tr class="truncate">
          <th>Nome</th>
          <th>Endereço</th>
          <th>Editar</th>
        </tr>
      </thead>
      <tbody class="basic-table__body">
        <ng-container>
          <ng-container>
            <tr class="basic-table__body__row" *ngFor="let item of listAdress">
              <td class="truncate">{{ item.addressName }}</td>
              <td class="truncate">
                <button 
                  mat-button 
                  (click)="updateAddress(item)"
                  [disabled]="item.isDefault === true" 
                  [class.disabled-button]="item.isDefault === true">
                  {{ item.street }}, {{ item.number }} - {{ item.neighborhood }} - {{ item.city }}
                </button>
              </td>
              <td class="truncate">
                <button mat-icon-button (click)="openNewAddress(item)">
                  <mat-icon>launch</mat-icon>
                </button>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
