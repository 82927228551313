import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { CreateUpdatePlateResponse } from '../../responses/autoparts/create-update-plate.response';
import { CreatePlateRequest } from '../../requests/autoparts/create-update-plate.request';
import { GetInfosResponse } from '../../responses/autoparts/Vehicle/get-infos.response';



@Injectable({
  providedIn: 'root'
})
export class GetInfosService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public post(idBrand:number, idVehicleModel:number, idVersion:number): Observable<GetInfosResponse> {

    let url = `GetInfos`

    let body = {
      idBrand: idBrand,
      idVehicleModel: idVehicleModel,
      idVersion: idVersion,
    }

    return this.httpClient.post<GetInfosResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
      retry(3)
    )
  }
}
