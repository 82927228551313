import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { UserComponent } from '../settings/pages/user/user.component';
import { PaymentComponent } from '../settings/pages/payment/payment.component';
import { ProfilePermissionsComponent } from '../settings/pages/profile-permissions/profile-permissions.component';
import { BusinessDataComponent } from './pages/business-data/business-data.component';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { DeliveryAddressComponent } from './pages/delivery-address/delivery-address.component';
import { ModalAddressDeleteComponent } from './pages/delivery-address/modal-address-delete/modal-address-delete.component';
import { PickUpAddressComponent } from './pages/pick-up-address/pick-up-address.component';
import { PartnerWorkshopsComponent } from './pages/partner-workshops/partner-workshops.component';
import { ModalPatnerWorkshopEditComponent } from './pages/partner-workshops/modal-patner-workshop-edit/modal-patner-workshop-edit.component';
import { RegistrationAddressModalComponent } from '../workshop/pages/welcome/registration-address-modal/registration-address-modal.component';
@NgModule({
  declarations: [
    UserComponent,
    PaymentComponent,
    ProfilePermissionsComponent,
    BusinessDataComponent,
    DeliveryAddressComponent,
    ModalAddressDeleteComponent,
    PickUpAddressComponent,
    PartnerWorkshopsComponent,
    ModalPatnerWorkshopEditComponent,
    RegistrationAddressModalComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    SettingsRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    HttpClientModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatRadioModule,
    SharedModule
  ],
  providers:[
    AuthGuard
  ],
})
export class SettingsModule { }
