import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-user-delete',
  templateUrl: './modal-user-delete.component.html',
  styleUrls: ['./modal-user-delete.component.css']
})
export class ModalUserDeleteComponent {

  @Output() deleteConfirm = new EventEmitter<boolean>();

  deleteProfile(){
    this.deleteConfirm.emit(true);
  }

}
