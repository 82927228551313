import { Component, OnInit, EventEmitter, Output, Inject } from '@angular/core';
import { FormsModule, UntypedFormBuilder, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MatFormFieldModule} from '@angular/material/form-field';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { GetProfileService } from 'src/app/shared/services/API/services/profile/get-profile.service';
import { GetProfileResponse } from 'src/app/shared/services/API/responses/profile/get-profile.reponse';
import { ProfileRequest } from 'src/app/shared/services/API/requests/profile/profile.request';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProfilePermissionsComponent } from '../profile-permissions.component';


@Component({
  selector: 'app-modal-profile-permissions-edit',
  templateUrl: './modal-profile-permissions-edit.component.html',
  styleUrls: ['./modal-profile-permissions-edit.component.css']
})
export class ModalProfilePermissionsEditComponent {

  constructor(public alertService:AlertService,
    private dialogRef: MatDialogRef<ModalProfilePermissionsEditComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.model = this.formBuilder.group({
        profileName: ['', [Validators.required]],
        description: ['', [Validators.required]],
      });

      if (data) {
        this.model.patchValue({
          profileName: data.profileName,
          description: data.description,
        });
      }
  }

  public FormFieldModuel: MatFormFieldModule;
  public model: FormGroup;
  public getProfileResponse: GetProfileResponse
  public isLoading: boolean;

  @Output() confirmRegister = new EventEmitter<FormGroup>();

registerProfile() {
  if (this.model.valid) {
    this.confirmRegister.emit(this.model.value);
  }
  else
  {
  }
}

  checkboxChanged(item: any) {
    item.selected = !item.selected;
    // console.log(`Status da opção ${item.title}: ${item.selected}`);
  }

  selectAll = false;
  checkboxesBudget = [
    {title: 'Abrir Orçamento', selected: false},
    {title: 'Enviar Orçamento para o Cliente', selected: false},
    {title: 'Exigir Aprovação', selected: false},
    {title: 'Deletar Orçamento', selected: false},
    {title: 'Editar Orçamento', selected: false},
  ]

  checkboxesBuy = [
    {title: 'Compra Simples', selected: false},
    {title: 'Editar Forma de Pagamento', selected: false},
    {title: 'Comprar Orçamento', selected: false},
    {title: 'Editar Endereço de Entrega', selected: false},
  ]

  checkboxesService = [
    {title: 'Abrir OS', selected: false},
    {title: 'Deletar OS', selected: false},
    {title: 'Finalizar OS', selected: false},
    {title: 'Reabrir OS', selected: false},
  ]

}
