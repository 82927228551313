import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { PartsDetailsRequest } from '../../requests/autoparts/parts-details.request';
import { PartsDetailsResponse } from '../../responses/autoparts/parts-details.response';



@Injectable({
  providedIn: 'root'
})
export class PartsDetailsService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public post(body: PartsDetailsRequest): Observable<PartsDetailsResponse> {

    let url = `PartsDetails/product`

    return this.httpClient.post<PartsDetailsResponse>(environment.urlApiAutoParts + url, body, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }
}
