import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { AuthGuard } from './shared/services/auth-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NgChartsModule } from 'ng2-charts';
import { NgxMaskModule } from 'ngx-mask';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WorkshopModule } from './workshop/workshop.module';
import { SupplierModule } from './supplier/supplier.module';
import { SettingsModule } from './settings/settings.module';
import { InterceptorModule } from './interceptors/interceptor.module';
import { MatDialogModule } from '@angular/material/dialog'; // Certifique-se de importar
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { PurchaseDetailsModalComponent } from 'src/app/workshop/pages/purchase-made/purchase-details-modal/purchase-details-modal.component';

@NgModule({
  declarations: [
    PurchaseDetailsModalComponent,  
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    DragDropModule,
    AuthModule,
    SupplierModule,
    WorkshopModule,
    NgChartsModule,
    NgxMaskModule,
    NgxChartsModule,
    SettingsModule,
    InterceptorModule,
    MatButtonModule,
    MatDialogModule,  
    MatTableModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production || environment.staging,
    }),
    BrowserAnimationsModule, 
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
