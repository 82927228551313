import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { SearchPlateRequest } from '../../services/API/requests/autoparts/search-plate.request';
import { VehicleService } from '../../services/API/services/autoparts/vehicle.service';
import { VersionModelStruct } from '../../services/API/structs/autoparts/version-model.struct';
import { SelectVehicleVersionModalComponent } from '../select-vehicle-version-modal/select-vehicle-version-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-search-plate-modal',
  templateUrl: './search-plate-modal.component.html',
  styleUrls: ['./search-plate-modal.component.css']
})
export class SearchPlateModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialog: MatDialog, 
    public matDialogRef: MatDialogRef<SearchPlateModalComponent>,
    private vehicleService: VehicleService,
    private alertService: AlertService,
    private maskService: MaskService,
    private router: Router,
  ) { 
    matDialogRef.disableClose = this.data.plateNumber ? false : true;
  }

  public isLoading:boolean;  
  public plateNumber: string;
  public masks: Masks;
  public listVersion: VersionModelStruct[] = [];
  public version: VersionModelStruct;
  public isMultiple: boolean = false;

  ngOnInit(): void {
    this.plateNumber = this.data.plateNumber;
    this.isLoading = false;
    this.masks = this.maskService.getMasks();
  }
  
  close(){
    if(!this.plateNumber){
      this.alertService.show('Erro!', "Preencha a placa para continuar", AlertType.error);
      return
    }
    
    this.matDialogRef.close(); 
  }

  clickSearch() {
    if(!this.plateNumber){
      this.alertService.show('Alerta', "Preencha a placa para continuar", AlertType.error);
      return
    }
    this.isLoading = true;
    let searchPlateRequest: SearchPlateRequest = new SearchPlateRequest();
    searchPlateRequest.plate = this.plateNumber;

    this.vehicleService.searchPlate(searchPlateRequest).subscribe({
      next: (response) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error)
          this.isLoading = false;
          return;
        }

        this.listVersion = response.version;
        if(!this.listVersion || this.listVersion.length ==0){
          this.alertService.show('Alerta!', "Nenhum veículo encontrado, busque outra placa ou cancele para voltar", AlertType.warning);
          return
        }
        else if(this.listVersion  && this.listVersion.length >1){
          this.openSelectVersionModal(this.listVersion)
          this.isLoading = false;
          return
        }
        let version = response.version[0]
        this.isLoading = false;
        if(version){
          this.matDialogRef.close({foundPlate: true, vehicleVersion: version, plateNumber: this.plateNumber}); 
          return
        }
        
        this.matDialogRef.close({foundPlate: false}); 
      },  
      error: (error) => {
        this.isLoading = false;
        console.log(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
      },
    });
  }

  openSelectVersionModal(listVersion){
    const dialogRef = this.dialog.open(SelectVehicleVersionModalComponent, {
      data: {
        listVersion : listVersion,
      },
    })
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.vehicleVersion)
        this.matDialogRef.close({foundPlate: true, vehicleVersion: result.vehicleVersion, plateNumber: this.plateNumber});
      }
    });
  }

  clickCancel(){
    if(this.isLoading){
      this.alertService.show('Alerta', "Aguarde a busca terminar para cancelar", AlertType.warning);
      return
    }
    this.router.navigate([
      '/workshop/welcome'
    ]);

    this.matDialogRef.close();
  }
}
