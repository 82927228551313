import { CdkAccordionItem } from '@angular/cdk/accordion';
import { formatDate } from '@angular/common';
import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertService, AlertType } from '../../services/alert.service';
import { UtilService } from '../../services/util.service';
import { UtilsSecondaryHeaderService } from './utils.service';
import { ServiceOrderService } from '../../services/API/services/serviceOrder/service-order.service';
import { GetServiceOrderResponse } from '../../services/API/responses/serviceOrder/get-service-order-response';
import { PlateService } from '../../services/API/services/autoparts/plate.service';
import { GetPlateResponse } from '../../services/API/responses/autoparts/get-plate.response';


@Component({
  selector: 'app-secondary-header',
  templateUrl: './secondary-header.component.html',
  styleUrls: ['./secondary-header.component.css']
})
export class SecondaryHeaderComponent implements OnInit {

  @ViewChild('accordionItem', { static: true }) accordionItemChild: CdkAccordionItem;

  serviceOrderData: any; // verificar retorno do backend
  secundaryHeaderInfos:SecundaryHeaderInfos;

  public carImgSource:string = "assets/images/car-stock-img.png"

  isLoading = false;
  formatedOsStart: string;

  constructor(
    // private humanizedHeaderService: HumanizedHeaderService,
    private alertService: AlertService,
    private utilsService: UtilService,
    private utilsSecondaryHeaderService: UtilsSecondaryHeaderService,
    private eRef: ElementRef,
    public dialog: MatDialog,
    private serviceOrderService: ServiceOrderService,
    private plateService:PlateService
  ) { }

  ngOnInit(): void {
  }

  rePopulate() {
    let serviceOrderData = this.utilsSecondaryHeaderService.getSessionServiceOrderData();
    this.serviceOrderData = serviceOrderData;
  }

  reloadHeader(idServiceOrder: number) {
  
    this.getServiceOrdereData(idServiceOrder)
    return;
  }

  getServiceOrdereData(idServiceOrder: number): void {
    this.isLoading = true;
    
    this.serviceOrderService.getById(idServiceOrder).subscribe({
      next: (getServiceOrderResponse: GetServiceOrderResponse) => {
        
        if (getServiceOrderResponse.isError) {
          this.alertService.show('Erro', getServiceOrderResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        if(getServiceOrderResponse.serviceOrderStruct == null || getServiceOrderResponse.serviceOrderStruct.length == 0){
          this.alertService.show('Erro', "OS não encontrada para popular a header", AlertType.error);
          this.isLoading = false;
          return;
        }
      
        let serviceOrderStruct = getServiceOrderResponse.serviceOrderStruct[0];
        
        this.plateService.get(serviceOrderStruct.idPlate).subscribe({
          next: (getPlateResponse: GetPlateResponse) => {

            if (getPlateResponse.isError) {
              this.alertService.show('Erro', getPlateResponse.errorDescription, AlertType.error);
              this.isLoading = false;
              return;
            }

            this.secundaryHeaderInfos = new SecundaryHeaderInfos();
            this.secundaryHeaderInfos.plateNumber = getPlateResponse.plateStruct.plateNumber;
            this.secundaryHeaderInfos.vehicleModelName = getPlateResponse.plateStruct.vehicleModelName;
            this.secundaryHeaderInfos.brandName = getPlateResponse.plateStruct.brandName;
            this.secundaryHeaderInfos.clientName = serviceOrderStruct.clientName;
            this.secundaryHeaderInfos.phone = serviceOrderStruct.phone;
            //this.secundaryHeaderInfos.osDuration = serviceOrderStruct.osDuration;
            this.secundaryHeaderInfos.datetimeInclusion = serviceOrderStruct.datetimeInclusion;
            this.secundaryHeaderInfos.statusName = serviceOrderStruct.statusName;
            this.secundaryHeaderInfos.idServiceOrder = serviceOrderStruct.idServiceOrder;
          },
          error: (error) => {
            console.error(error);
            this.alertService.show('Erro inesperado', error, AlertType.error);
            this.isLoading = false;
          }
        });

        this.isLoading = false;
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Erro inesperado', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.eRef.nativeElement.contains(event.target))
      this.accordionItemChild.close();
  }
}

export class SecundaryHeaderInfos{
  public plateNumber:string;
  public vehicleModelName:string;
  public brandName:string

  public clientName:string;
  public phone:string;
  public datetimeInclusion:Date;
  public osDuration:string;
  public statusName:string;
  public idServiceOrder:number;

}