import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetSupplierResponse } from '../../responses/user/get-supplier.response';
import { GetCSCSupplierResponse } from '../../responses/user/get-csc-supplier.response';

@Injectable({
  providedIn: 'root'
})
export class GetCscSupplierService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(idCity: number): Observable<GetCSCSupplierResponse> {

    let url = `GetCSC/idCity/${idCity}`

    return this.httpClient.get<GetCSCSupplierResponse>(environment.urlApiUser + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError),
    )
  }

}
