import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { GetQuoteServiceResponse } from '../../responses/serviceOrder/get-quote-service.response';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { PostQuoteServiceRequest } from '../../requests/serviceOrder/post-quote-service.request';
import { PostQuoteServiceResponse } from '../../responses/serviceOrder/post-quote-service.request';



@Injectable({
  providedIn: 'root'
})
export class QuoteServiceService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public get(idQuoteService: number): Observable<GetQuoteServiceResponse> {

    let url = `QuoteService/idQuoteService/${idQuoteService}`

    return this.httpClient.get<GetQuoteServiceResponse>(environment.urlApiServiceOrder + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public post(postQuoteServiceRequest:PostQuoteServiceRequest): Observable<PostQuoteServiceResponse> {

    return this.httpClient.post<PostQuoteServiceResponse>(environment.urlApiServiceOrder + "QuoteService", postQuoteServiceRequest, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public put(idQuoteService:number, idQuoteServiceStatus:number): Observable<ReturnStruct> {

    let url = `QuoteService/idQuoteService/${idQuoteService}/idQuoteServiceStatus/${idQuoteServiceStatus}`
    return this.httpClient.put<ReturnStruct>(environment.urlApiServiceOrder + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }

  public delete(idQuoteService:number): Observable<ReturnStruct> {

    let url = `QuoteService/idQuoteService/${idQuoteService}`
    return this.httpClient.delete<ReturnStruct>(environment.urlApiServiceOrder + url, this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
