<app-menu *ngIf="idServiceOrder != undefined" #menu [menuModuleEnum]='menuModuleEnum'
  [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecondaryHeader]=true></app-menu>
<app-menu *ngIf="idServiceOrder == undefined" #menu [menuModuleEnum]='menuModuleEnum'
  [menuFunctionalityEnum]='menuFunctionalityEnum'></app-menu>

<app-search-header [idServiceOrder]="idServiceOrder" (OutputClickFilter)="clickFilter()" [modelSearch]="modelSearch"
  (SearchClick)="searchHeard()" (SearchClick)="clearCollectedParts()" [getInfosResponse]="getInfosResponse"
  [plateNumber]="plateNumber" [year]="year"></app-search-header>

<div *ngIf="isLoading">
  <div class="container-body-search">
    <div class="vertical-aling-out">
      <div class="vertical-aling-inner">
        <img class="loading" src="/assets/images/loading-search.gif">
      </div>
    </div>
  </div>
</div>
<div *ngIf="searchPartsResponse != null">
  <div class="container-body-search" [ngClass]="{'container-body-search-big-top': idServiceOrder != undefined}">
    <div class="row" style="justify-content: end;">
      <!-- <div *ngIf="screenWidth > 950" style="display: none;" class="col-sm-12 col-md-3 col-lg-2">
        <app-search-filter *ngIf="!isFilterOpen" (clickChangeFilter)="applyFilter($event, item)" [openFilter]="openFilter" [collectedParts]="collectedParts"
          (clickClearFilter)="clearFilter()">></app-search-filter>
      </div>
      <div *ngIf="screenWidth < 950" class="col-sm-12 col-md-3 col-lg-2">
        <mat-icon style="position: fixed;" (click)="toggleFilter()">filter_alt</mat-icon>
        <app-search-filter *ngIf="isFilterOpen" (clickChangeFilter)="applyFilter($event, item)" [openFilter]="openFilter" [collectedParts]="collectedParts"
          (clickClearFilter)="clearFilter()">></app-search-filter>
      </div> -->
      <div class="col-sm-12 col-md-6 col-lg-8">
        <!-- <div class="loading-top" *ngIf="isLoading">
                    <mat-spinner [diameter]="30" class="mat-spinner-btn" ></mat-spinner>
                </div> -->

        <div class="row" *ngIf="filteredParts.length == 0">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4" *ngFor="let item of collectedParts">
            <app-search-list-item [partProductStruct]="item"
              (addItem)="clickAddItem($event, item)"></app-search-list-item>
          </div>
        </div>
        <div class="row" *ngIf="filteredParts.length > 0">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-4" *ngFor="let item of filteredParts">
              <app-search-list-item 
                [partProductStruct]="item"  
                (addItem)="clickAddItem($event, item)">
              </app-search-list-item>
            </div>
          </div>
        <div class="row">
          <div class="col-12 div-btn-next-page">
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading"></mat-spinner>
            <button mat-stroked-button (click)="nextPageSearch()" class="btn-next-page" *ngIf="!isLoading">
              Ver mais resultados
              <mat-icon class="material-symbols-outlined">expand_more</mat-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-2">
        <app-search-shopping-cart *ngIf="hasItemsInCart" [searchShoppingCartStuct]="searchShoppingCartStuct"
          [idServiceOrder]="idServiceOrder" [idQuoteService]="idQuoteService" (removeItem)="removeItem($event)"
          (addChangeItem)="clickChangeItem($event.amount, $event.searchShoppingCarItemtStuct)">
        </app-search-shopping-cart>
      </div>
    </div>
  </div>
</div>
