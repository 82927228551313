import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class UtilsSecondaryHeaderService {
    constructor() { }
    getSessionServiceOrderData() {
        let serviceOrderData: any = JSON.parse(sessionStorage.getItem("serviceOrderData"))
        return serviceOrderData;
    }
    createSessionServiceOrderData(serviceOrderData: any) {
        sessionStorage.setItem("serviceOrderData", JSON.stringify(serviceOrderData));
    }
    removeSessionServiceOrderData() {
        sessionStorage.removeItem("serviceOrderData");
    }
}