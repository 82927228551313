import { Component, OnDestroy, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { QuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/quote-service.service';
import { GetQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-quote-service.response';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { SupplierRequest } from 'src/app/shared/services/API/requests/user/supplier.request';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { SupplierOrdersDetailResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/supplier-orders-detail.response';
import { GetPurchaseHistoryDetailsResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.details.response';
import { PurchaseHistoryDetailsService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history-details.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PurchaseDetailsModalComponent } from 'src/app/workshop/pages/purchase-made/purchase-details-modal/purchase-details-modal.component';


@Component({
  selector: 'app-purchase-made',
  templateUrl: './purchase-made.component.html',
  styleUrls: ['./purchase-made.component.css']
})
export class PurchaseMadeComponent {

  constructor(private purchaseHistoryDetailsService: PurchaseHistoryDetailsService,
    private supplierService: SupplierService,
    private route: ActivatedRoute,
    private alertService:AlertService,
    private location: Location,
    public dialog: MatDialog,
    private router: Router,
    ){

  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public matButtonModule:MatButtonModule;
  public getPurchaseHistoryDetailsResponse:GetPurchaseHistoryDetailsResponse;
  public getSupplierResponse: GetSupplierResponse;
  public supplierOrdersDetailResponse: SupplierOrdersDetailResponse;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public isLoading: boolean;
  public idPurchase: number;
  public totalQuota: number;
  public totalDetail: number;
  public totalStock: number;
  public totalPrice: number;
  public array: any;
  public pagamento: any;
  public distinctSuppliers: any;
  public delivery: any;
  public deliveryTime: any;
  public isDeliveryType: boolean;
  public supplierParts: any;
  public listSupplierParts: any;
  public loading: boolean = false;
  public modalAberto = false;
  public loadedData: boolean = false;
  public isDetails: boolean[] = [];


  ngOnInit() {
    this.route.params.subscribe(params => {
        this.idPurchase = params['idPurchase'];
    });
    this.populatePage();
  }

  direct(url){
    this.router.navigate([
      url
    ]);
  }

  populatePage() {
    this.loading = true;
    if (this.purchaseHistoryDetailsService) {
      this.purchaseHistoryDetailsService.Get(this.idPurchase).subscribe({
        next: (getPurchaseHistoryDetailsResponse: GetPurchaseHistoryDetailsResponse) => {
          if (getPurchaseHistoryDetailsResponse.isError) {
            this.alertService.show('Erro', getPurchaseHistoryDetailsResponse.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }
          this.getPurchaseHistoryDetailsResponse = getPurchaseHistoryDetailsResponse;
          this.array = this.getPurchaseHistoryDetailsResponse.purchaseDeatailsStruct;
  
          this.modalAberto = true;
          this.loadedData = true;
  
          this.distinctSuppliers = this.array.listBuyOrderStruct.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.businessName === value.businessName
            ))
          );
          
          this.delivery = this.array.listBuyOrderStruct[0]
          if (this.delivery.estimatedDeliveryTime != null) {
            const hours = Math.floor(this.delivery.estimatedDeliveryTime / 3600);
            const minutes = Math.floor((this.delivery.estimatedDeliveryTime % 3600) / 60);
            this.deliveryTime = `Estimativa de ${hours} e ${minutes} minutos`;
          }
          
          this.supplierParts = this.array.listBuyOrderStruct;

          this.isDeliveryType = this.array.listVehicleDetailsStruct.filter(x => x.idBuyOrderPart === this.supplierParts.idBuyOrderPart).filterType
          console.log(this.isDeliveryType)

        },
        error: (error) => {
          this.isLoading = false;
          this.alertService.show("Ação não concluída.", error, AlertType.error);
        }
      });
    }
  }
  
  openModal(idBuyOrderPart) {
    const vehicleDetails = this.array?.listVehicleDetailsStruct || [];
    const selectedVehicleDetail = vehicleDetails.find(detail => detail?.idBuyOrderPart === idBuyOrderPart);  
    let filteredData = {};
    if (!selectedVehicleDetail) {
      filteredData = { message: 'Filtro não utilizado' };
    } else {
      const filterType = selectedVehicleDetail?.filterType;
      if (filterType === 'plate') {
        filteredData = {
          plateNumber: selectedVehicleDetail?.plateStruct?.plateNumber || 'N/A',
          vehicleModelName: selectedVehicleDetail?.plateStruct?.vehicleModelName || 'N/A'
        };
      } else if (filterType === 'vehicle_model') {
        filteredData = {
          brandName: selectedVehicleDetail?.vehicleStruct?.[0]?.brandName || 'N/A',
          modelYear: selectedVehicleDetail?.vehicleStruct?.[0]?.modelYear || 'N/A',
          modelName: selectedVehicleDetail?.vehicleStruct?.[0]?.modelName || 'N/A'
        };
      } else {
        filteredData = { message: 'Carro não selecionado' };
      }
    }
    this.dialog.open(PurchaseDetailsModalComponent, {
      height: 'auto',
      width: '40%',
      data: filteredData
    });
  }
  
  convertMinutesToHours(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    return `${hours} horas`;
  }

  toggleDetails(item: any): void {
    item.isDetails = !item.isDetails;
    item.idPurchase;
  }
  

  goBack(): void {
    this.location.back();
  }

  captureScreen() {
    window.print();
  }

}
