import { Supplier } from "../../models/user/supplier.model";

export class PurchaseSupplierStruct {
    
    public idBranchSupplier: number;
    public supplierName: string;
    public supplierPhone: string;
    public deliveryFee: number;
    public idQuotationLalamove: string;
    public idOrderLalamove: string;
    public idStopSenderLalamove: string;
    public idStopRecieverLalamove: string;
    public idDeliveryType: number;
}