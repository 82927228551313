import { Component, OnDestroy, OnInit, ViewChild, Input, Output, HostListener } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseService } from 'src/app/shared/services/API/services/purchase/purchase.service';
import { ListPurchaseReponse } from 'src/app/shared/services/API/responses/purchase/list-purchase.response';
import { ModalDeleteComponent } from 'src/app/supplier/pages/order-details-modal/modal-delete/modal-delete.component';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { GetPurchaseHistoryResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.response';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseHistoryStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/purchase-history.struct';
import { PurchaseHistoryDetailsService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history-details.service';
import { GetPurchaseHistoryDetailsResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/get-purchase-history.details.response';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.css']
})
export class PurchaseHistoryComponent implements OnInit{

  constructor(private dialogRef: MatDialog,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private supplierService: SupplierService,
    private purchaseService: PurchaseService,
    private purchaseHistoryService: PurchaseHistoryService,
    private purchaseHistoryDetailsService: PurchaseHistoryDetailsService,
    private sanitizer: DomSanitizer) {

      this.onResize();
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;
  public setDetails: Boolean = false;
  public getPurchase: any = [];
  public isLoading: boolean = false;
  public idPurchase: number;
  public idUser: number;
  public alertService: AlertService;
  public isDetails: boolean[] = [];
  public matPaginatorModule: MatPaginatorModule;
  public listPurchaseReponse: ListPurchaseReponse;
  public getPurchaseHistoryResponse: GetPurchaseHistoryResponse = null;
  public getPurchaseHistoryResponseFinished: GetPurchaseHistoryResponse = null;
  public getPurchaseHistoryDetailsResponse:GetPurchaseHistoryDetailsResponse;
  public getSupplierResponse: GetSupplierResponse;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public matDatepickerModule:MatDatepickerModule;
  public matFormFieldModule:MatFormFieldModule;
  public matInputModule:MatInputModule;
  public length: any;
  public lengthMonth: any;
  public data: any;
  public buyOrder: any;
  public buyOrderList: any;
  public filteredBuyOrder: any;
  public totalPrice: any;
  public pageSize: number = 5;
  public idBuyOrder: number;
  public model: FormGroup;
  public screenWidth: number;
  public modalAberto = false;
  public loading: boolean = false;
  public array: any;
  public distinctSuppliers: any;
  public populateInterval;
  public isPendingPurchase: boolean = true
  public purchaseTransit: any
  public lalamoveLink: SafeResourceUrl;
  public pageSizeOptions: number[] = [5, 10, 25, 100];


  ngOnInit(): void {

    this.model = this.formBuilder.group({
      start: [''],
      end: [''],
      text:[''],
      status:['']
    });

    this.populateInterval = setInterval(() => {
      if(!this.isPendingPurchase){
        this.populatePageFinished()
      }else{
        this.populatePagePending();
      }
      this.resetPaginator();
    }, 30000);

    
    this.model.get('status').valueChanges.subscribe(() => {
      this.applyFilters();
    });
    this.model.get('start').valueChanges.subscribe(() => {
      this.applyFilters();
    });
    this.model.get('end').valueChanges.subscribe(() => {
      this.applyFilters();
    });
    this.model.get('text').valueChanges.subscribe(() => {
      this.applyFilters();
    });

    this.populatePagePending();
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<PurchaseHistoryStruct>([]);
  dataSourceFinished = new MatTableDataSource<PurchaseHistoryStruct>([]);
  slicedData: PurchaseHistoryStruct[] = [];
  slicedDataFinished: PurchaseHistoryStruct[] = [];

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  ngOnDestroy(){
    clearInterval(this.populateInterval);
  }


  resetPaginator() {
    this.pageSize = 5;  // Sempre define o tamanho da página como 5
    this.paginator.pageSize = this.pageSize;  // Atualiza o paginator com o novo pageSize
    this.paginator.pageIndex = 0;  // (Opcional) Reinicia para a primeira página
    this.sliceData();  // Reexecuta a lógica de slice para refletir o novo pageSize
  }

  sliceData(pageEvent?: PageEvent) {
    const pageIndex = pageEvent ? pageEvent.pageIndex : this.paginator.pageIndex;
    const pageSize = pageEvent ? pageEvent.pageSize : this.paginator.pageSize;

    if (this.isPendingPurchase) {
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      this.slicedData = this.dataSource.data.slice(startIndex, endIndex);
    } else {
      const startIndex = pageIndex * pageSize;
      const endIndex = startIndex + pageSize;
      this.slicedDataFinished = this.dataSourceFinished.data.slice(startIndex, endIndex);
    }
  }

  clearFields(){
    this.model.get('start').setValue(null);
    this.model.get('end').setValue(null);
    this.model.get('text').setValue(null);
    this.model.get('status').setValue(null);
  }

  clearFilter(){
    this.clearFields();

    if(this.isPendingPurchase){
      setTimeout(() => {
        this.populatePagePending();
      }, 1000);
    }else{
      setTimeout(() => {
        this.populatePageFinished();
      }, 1000);
    }
  }

  setRefrash(){
    if(this.isPendingPurchase){
      this.populatePagePending()
    }else{
      this.populatePageFinished()
    }
  }

  populatePageFinished() {
    this.loading = true;

    if (this.purchaseHistoryService) {
      this.purchaseHistoryService.GetAllFinishedPurchases(0, 1000).subscribe({
        next: (getPurchaseHistoryResponse: GetPurchaseHistoryResponse) => {
          if (getPurchaseHistoryResponse.isError) {
            this.alertService.show('Erro', getPurchaseHistoryResponse.errorDescription, AlertType.error)
            this.isLoading = false;
            return;
          }
          this.getPurchaseHistoryResponseFinished = getPurchaseHistoryResponse;
          this.buyOrder = this.getPurchaseHistoryResponseFinished.listPurchase;
          this.buyOrderList = this.getPurchaseHistoryResponseFinished.listPurchase;
  
          // Define o mês e o ano atuais ou o desejado para filtrar
          // const currentMonth = new Date().getMonth();  // Mês atual (0-11)
          // const currentYear = new Date().getFullYear(); // Ano atual
  
          // // Filtra as compras pelo mês e ano
          // this.buyOrder = this.buyOrder.filter((purchase: any) => {
          //   const purchaseDate = new Date(purchase.datetimeInclusion);
          //   return purchaseDate.getMonth() === currentMonth && purchaseDate.getFullYear() === currentYear;
          // });
  
          this.totalPrice = this.buyOrder.listBuyOrderPart;
          this.idBuyOrder = this.buyOrder.idPurchase;
          this.data = this.buyOrderList;
          this.length = this.buyOrderList.length;
          this.lengthMonth = this.buyOrder.length;
          this.modalAberto = true;         
          this.dataSourceFinished.data = this.buyOrderList; 
          this.slicedDataFinished = this.dataSourceFinished.data.slice(0, this.pageSize);
        }
      });
    } else {
      console.error('listPurchaseService is undefined');
    }
  }

  populatePagePending() {
    this.loading = true;

    if (this.purchaseHistoryService) {
        this.purchaseHistoryService.GetAllPendingPurchases(0, 1000).subscribe({
            next: (getPurchaseHistoryResponse: GetPurchaseHistoryResponse) => {
                if (getPurchaseHistoryResponse.isError) {
                    this.alertService.show('Erro', getPurchaseHistoryResponse.errorDescription, AlertType.error);
                    this.isLoading = false;
                    return;
                }

                this.getPurchaseHistoryResponse = getPurchaseHistoryResponse;
                this.buyOrderList = this.getPurchaseHistoryResponse.listPurchase;

                // Verifica se a lista de compras contém dados antes de acessar o `lalamoveLink`
                if (this.buyOrderList && this.buyOrderList.length > 0) {
                    this.buyOrder = this.buyOrderList[0]; // Atribui a primeira compra ou ajuste conforme necessário
                    if (this.buyOrder.lalamoveLink) {
                        this.lalamoveLink = this.sanitizer.bypassSecurityTrustResourceUrl(this.buyOrder.lalamoveLink);
                    }
                }

                // Define o mês e o ano atuais ou o desejado para filtrar
                // const currentMonth = new Date().getMonth();  // Mês atual (0-11)
                // const currentYear = new Date().getFullYear(); // Ano atual

                // // Filtra as compras pelo mês e ano
                // this.buyOrderList = this.buyOrderList.filter((purchase: any) => {
                //     const purchaseDate = new Date(purchase.datetimeInclusion);
                //     return purchaseDate.getMonth() === currentMonth && purchaseDate.getFullYear() === currentYear;
                // });

                this.totalPrice = this.buyOrderList.reduce((sum: number, purchase: any) => sum + purchase.listBuyOrderPart, 0);
                this.idBuyOrder = this.buyOrderList.map((purchase: any) => purchase.idPurchase);
                this.data = this.buyOrderList;
                this.length = this.buyOrderList.length;
                this.lengthMonth = this.buyOrderList.length;
                this.modalAberto = true;
                this.dataSource.data = this.buyOrderList;
                this.slicedData = this.dataSource.data.slice(0, this.pageSize);
            },
            error: (err) => {
                console.error('Error fetching purchase history:', err);
                this.isLoading = false;
            }
        });
    } else {
        console.error('purchaseHistoryService is undefined');
    }
}


  setIsPurchase(){
    this.isPendingPurchase = true;
    this.clearFields()
    setTimeout(() => {
      this.populatePagePending();
    }, 1000);
  }

  setIsFinishedPurchase(){
    this.isPendingPurchase = false;
    this.clearFields()
    setTimeout(() => {
      this.populatePageFinished();
    }, 1000);
  }

  applyFilters(){
    const selectedStatus = this.model.get('status').value;
    const selectedStart = this.model.get('start').value;
    const selectedEnd = this.model.get('end').value;
    const searchText = this.model.get('text').value;

    let filteredList = this.buyOrderList;

    if (selectedStatus && selectedStatus != 0) {
      filteredList = filteredList.filter(purchase => purchase.idPurchaseStatus == selectedStatus);
    }

    if (selectedStart) {
      filteredList = filteredList.filter(purchase => new Date(purchase.date) >= new Date(selectedStart));
    }

    if (selectedEnd) {
      filteredList = filteredList.filter(purchase => new Date(purchase.date) <= new Date(selectedEnd));
    }

    if (searchText) {
      const searchNumber = Number(searchText); 
      if (!isNaN(searchNumber)) {
        filteredList = filteredList.filter(purchase => purchase.idPurchase === searchNumber);
      }
  }

  if (this.isPendingPurchase) {
    this.dataSource.data = filteredList;
    this.slicedData = this.dataSource.data.slice(0, this.pageSize);
    this.length = filteredList.length;
  } else {
    this.dataSourceFinished.data = filteredList;
    this.slicedDataFinished = this.dataSourceFinished.data.slice(0, this.pageSize);
  }
  }


  toggleDetails(item: any): void {
    item.isDetails = !item.isDetails;
    item.idPurchase;
  }

  setIsDetails() {
    if (this.setDetails == true) {
      return this.setDetails = false
    }
    else {
      return this.setDetails = true
    }
  }

}
