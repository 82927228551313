import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { ClosedQuoteResponse } from '../../responses/orchestrator-purchase/closed-quote.response';



@Injectable({
  providedIn: 'root'
})
export class ClosedQuoteService extends CommonService {

  constructor(
    private httpClient: HttpClient) {
    super();
  }

  public Get(idQuote: number): Observable<ClosedQuoteResponse> {

    let url = `ClosedQuote/idQuote/${idQuote}`

    return this.httpClient.get<ClosedQuoteResponse>(environment.urlApiOrchestratorPurchase + url,this.addHeaderToken())
    .pipe(
      catchError(this.handleError)
    )
  }
}
