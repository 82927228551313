import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { WorkshopRoutingModule } from './workshop-routing.module';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { WelcomeComponent } from './pages/welcome/welcome.component';

import { SearchComponent } from './pages/search/search.component';
import { SearchHeaderComponent } from './pages/search/search-header/search-header.component';
import { SearchFilterComponent } from './pages/search/search-filter/search-filter.component';
import { SearchListItemComponent } from './pages/search/search-list-item/search-list-item.component';
import { NgxMaskModule } from 'ngx-mask';
import { SearchShoppingCartComponent } from './pages/search/search-shopping-cart/search-shopping-cart.component';
import { ShoppingCartSummaryComponent } from './pages/shopping-cart-summary/shopping-cart-summary.component';
import { ModalChangeVehicleComponent } from './pages/search/search-header/modal-change-vehicle/modal-change-vehicle.component';
import { PurchaseComponent } from './pages/purchase/purchase.component';
import { PartnerDeliveryComponent } from './pages/purchase/partner-delivery/partner-delivery.component';
import { QuoteServiceComponent } from './pages/quote-service/quote-service.component';
import { ModelServiceOrderSearchComponent } from './pages/quote-service/model-service-order-search/model-service-order-search.component';
import { ModalServiceOrderNewComponent } from './pages/quote-service/modal-service-order-new/modal-service-order-new.component';
import { ModalServiceOrderApprovalComponent } from './pages/quote-service/modal-service-order-approval/modal-service-order-approval.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ClientQuoteServiceComponent } from './pages/client-quote-service/client-quote-service.component';
import { ProductComponent } from './pages/product/product.component';
import { UserComponent } from '../settings/pages/user/user.component';
import { ModalUserDeleteComponent } from '../settings/pages/user/modal-user-delete/modal-user-delete.component';
import { ModalUserEditComponent } from '../settings/pages/user/modal-user-edit/modal-user-edit.component';
import { ProfilePermissionsComponent } from '../settings/pages/profile-permissions/profile-permissions.component';
import { ModalProfilePermissionsEditComponent } from '../settings/pages/profile-permissions/modal-profile-permissions-edit/modal-profile-permissions-edit.component';
import { ModalProfilePermissionsDeleteComponent } from '../settings/pages/profile-permissions/modal-profile-permissions-delete/modal-profile-permissions-delete.component';
import { SettingsComponent } from '../settings/pages/settings.component';
import { ServiceOrderListComponent } from './pages/service-order-list/service-order-list.component';
import { MatChipsModule } from '@angular/material/chips';
import { ServiceOrderDetailsComponent } from './pages/service-order-details/service-order-details.component';
import { ServiceOrderCloseModalComponent } from './pages/service-order-details/service-order-close-modal/service-order-close-modal.component';
import { CreditCardModalComponent } from './pages/purchase/credit-card-modal/credit-card-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { DeliveryAddressModalComponent } from './pages/purchase/delivery-address-modal/delivery-address-modal.component';
import { ClosedBudgetComponent } from './pages/closed-budget/closed-budget.component';
import { PaymentComponent } from '../settings/pages/payment/payment.component';
import { ModalPaymentDeleteComponent } from '../settings/pages/payment/modal-payment-delete/modal-payment-delete.component';
import { ModalPaymentAddCartComponent } from '../settings/pages/payment/modal-payment-add-cart/modal-payment-add-cart.component';
import { PurchaseHistoryComponent } from './pages/purchase-history/purchase-history.component';
import { PurchaseMadeComponent } from './pages/purchase-made/purchase-made.component';
import { BusinessDataComponent } from '../settings/pages/business-data/business-data.component';

@NgModule({
  declarations: [
    WelcomeComponent,
    SearchComponent,
    SearchHeaderComponent,
    SearchFilterComponent,
    SearchListItemComponent,
    SearchShoppingCartComponent,
    ShoppingCartSummaryComponent,
    ModalChangeVehicleComponent,
    PurchaseComponent,
    PartnerDeliveryComponent,
    QuoteServiceComponent,
    ModelServiceOrderSearchComponent,
    ModalServiceOrderNewComponent,
    ModalServiceOrderApprovalComponent,
    ClientQuoteServiceComponent,
    ProductComponent,
    ModalUserDeleteComponent,
    ModalUserEditComponent,
    ModalProfilePermissionsEditComponent,
    ModalProfilePermissionsDeleteComponent,
    SettingsComponent,
    ServiceOrderListComponent,
    ServiceOrderDetailsComponent,
    ServiceOrderCloseModalComponent,
    ServiceOrderCloseModalComponent,
    DeliveryAddressModalComponent,
    CreditCardModalComponent,
    ClosedBudgetComponent,
    ModalPaymentDeleteComponent,
    ModalPaymentAddCartComponent,
    PurchaseHistoryComponent,
    PurchaseMadeComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }), 
    CommonModule,
    AuthRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDividerModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    HttpClientModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatSelectModule,
    MatRadioModule,
    SharedModule,
    MatPaginatorModule,
    WorkshopRoutingModule,
    CdkAccordionModule,
    MatFormFieldModule,
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    MatChipsModule,
  ],
  providers:[
    AuthGuard
  ],
})
export class WorkshopModule { }
