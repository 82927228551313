import { Component, Output, Inject, HostListener, EventEmitter } from '@angular/core';
import { MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { CancelBuyOrderService } from 'src/app/shared/services/API/services/purchase/cancel-buy-order.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { RefuseBuyOrderService } from 'src/app/shared/services/API/services/purchase/refuse-buy-order.service';
import { CancelLalamoveService } from 'src/app/shared/services/API/services/orchestrator-purchase/cancel-lalamove.service';
import { SupplierOrderComponent } from 'src/app/supplier/pages/supplier-order/supplier-order.component';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { RefuseBuyOrderRequest } from 'src/app/shared/services/API/requests/purchase/client-aproval-quote-service.request';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';



@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.css']
})
export class ModalDeleteComponent {

  public idBuyOrder: number;
  public idBuyOrderStatus: number;
  public entrega: string;
  public oficina: string;
  public isLoading: boolean;
  public matProgressSpinnerModule:MatProgressSpinnerModule;
  public screenWidth: number; 


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private cancelBuyOrderService: CancelBuyOrderService,
    private refuseBuyOrderService: RefuseBuyOrderService,
    private cancelLalamoveService: CancelLalamoveService,
    public dialogRef: MatDialogRef<ModalDeleteComponent>,
    private alertService: AlertService,
    private formBuilder: FormBuilder,) {
    this.idBuyOrder = data.idBuyOrder;
    this.idBuyOrderStatus = data.idBuyOrderStatus;
    this.entrega = data.entrega;
    this.oficina = data.oficina
    this.onResize();
  }
  
  @Output() refreshInfo: EventEmitter<void> = new EventEmitter<void>();
  public model: FormGroup = null;

  ngOnInit() {

    if(this.idBuyOrderStatus === 1){
      this.model = this.formBuilder.group({
        observation: ['', []],
        observationCancel: ['', []],
      });
    }else if(this.idBuyOrderStatus === 2){
      this.model = this.formBuilder.group({
        observation: ['', []],
        observationCancel: ['', [Validators.required]],
      });
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }


  cancelBuyOrder() {
    this.isLoading = true;
    if (this.idBuyOrderStatus === 1) {
      const refuseBuyOrderRequest: RefuseBuyOrderRequest = {
        idBuyOrder: this.data.idBuyOrder,
        observation: this.model.get('observation').value,
      }
      this.refuseBuyOrderService.Post(refuseBuyOrderRequest).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.refreshInfo.emit();
          if (this.alertService) {
            this.alertService.show('Recusada', 'Ordem de Compra Recusada.', AlertType.success);
          }
          this.dialogRef.close();
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          }
          this.dialogRef.close();
          this.isLoading = false;
        }
      });
    }
    else if (this.entrega === 'Meros' && this.idBuyOrderStatus === 9 || this.entrega === 'Meros' && this.idBuyOrderStatus === 3) {
      this.cancelLalamoveService.Get(this.data.idBuyOrder).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          // this.refreshInfo.emit();
          if (this.alertService) {
            this.alertService.show('Cancelada', 'Solicitação de entrega cancelada.', AlertType.success);
          }
          this.dialogRef.close();
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          }
          this.dialogRef.close();
          this.isLoading = false;
        }
      });
    } else {
      const refuseBuyOrderRequest: RefuseBuyOrderRequest = {
        idBuyOrder: this.data.idBuyOrder,
        observation: this.model.get('observationCancel').value,
      }
      if(!this.model.get('observationCancel').value){
        this.alertService.show('Erro', "Preencha o campo Obrigatório", AlertType.error)
        this.isLoading = false;
        return;
      }
      this.cancelBuyOrderService.Post(refuseBuyOrderRequest).subscribe({
        next: (returnStruct: ReturnStruct) => {
          if (returnStruct && returnStruct.isError) {
            if (this.alertService) {
              this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
            }
            return;
          }
          this.refreshInfo.emit();
          if (this.alertService) {
            this.alertService.show('Cancelada', 'Ordem de Compra Cancelada.', AlertType.success);
          }
          this.dialogRef.close();
          this.isLoading = false;
        },
        error: (error) => {
          console.log(error);
          if (this.alertService) {
            this.alertService.show('Ação não concluída.', error, AlertType.error);
          }
          this.dialogRef.close();
          this.isLoading = false;
        }
      });
    }
  }
}
