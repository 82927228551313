<div mat-dialog-tittle class="dialog-tittle">
  <h2 class="tittle">DETALHES DO CARRO</h2>
</div>

<div *ngIf="data.message">
  <p>{{ data.message }}</p>
</div>

<div *ngIf="data.plateNumber && data.vehicleModelName">
  <p><strong>Placa:</strong> {{ data.plateNumber }}</p>
  <p><strong>Modelo do Veículo:</strong> {{ data.vehicleModelName }}</p>
</div>

<div *ngIf="data.brandName && data.modelYear && data.modelName">
  <p><strong>Marca:</strong> {{ data.brandName }}</p>
  <p><strong>Ano do Modelo:</strong> {{ data.modelYear }}</p>
  <p><strong>Nome do Modelo:</strong> {{ data.modelName }}</p>
</div>

<div mat-dialog-actions [align]="'center'">
  <button class="button-cancel" mat-raised-button mat-dialog-close="false">Fechar</button>
</div>