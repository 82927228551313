<div mat-dialog-content>
    <div class="mat-dialog-content">
        <form class="form" [formGroup]="model" (ngSubmit)="save()">
            <h1>Aprovação</h1>
            <p>
                Gostaria de enviar o orçamento para o cliente? 
                <mat-slide-toggle formControlName="isMandatoryApproval">Exigir aprovação?</mat-slide-toggle>
            </p>

            <div class="buttons">
                <div class="row">
                    <div class="col-md-4">
                        <button mat-button type="button" class="btn btn-block btn-primary-blue" color="primary" (click)="save()">
                            <mat-icon class="material-symbols-outlined">add</mat-icon> 
                            <span>Enviar</span>
                        </button>  
                    </div>
                    <div class="col-md-4">
                        <button mat-button type="button" class="btn-secundary btn-block" color="primary" (click)="close()">
                            Cancelar
                        </button>    
                    </div>
                </div>
            </div>
            
            <h3>Proprietário</h3>
            <div class="row">
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Nome e sobrenome</mat-label>
                        <input matInput type="text" formControlName="name">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>Telefone</mat-label>
                        <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                    </mat-form-field>
                </div>
                <div class="col-md-4">
                    <mat-form-field appearance="outline">
                        <mat-label>WhatsApp</mat-label>
                        <input matInput type="text" formControlName="whatsAppPhone" [mask]="masks.phone">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>E-mail</mat-label>
                        <input matInput type="text" formControlName="email">
                    </mat-form-field>
                </div>
                <div class="col-md-6">
                    <mat-form-field appearance="outline">
                        <mat-label>CPF / CNPJ</mat-label>
                        <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
                    </mat-form-field>
                </div>
            </div>

            <h3>Contatos</h3>
            <button mat-button type="button" class="btn btn-primary-blue btn-add-list" color="primary" (click)="addListContact(null)">
                <mat-icon class="material-symbols-outlined">add</mat-icon> 
                <span>Adicionar contato</span>
            </button>  
                
            <div class="list-contacts">
                <div formArrayName="listContact" *ngFor="let item of model.get('listContact')['controls']; let i = index">
                    <div [formGroup]="item" class="row contacts">
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Nome e sobrenome</mat-label>
                                <input matInput type="text" formControlName="name">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>Telefone</mat-label>
                                <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>WhatsApp</mat-label>
                                <input matInput type="text" formControlName="whatsAppPhone" [mask]="masks.phone">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field appearance="outline">
                                <mat-label>E-mail</mat-label>
                                <input matInput type="text" formControlName="email">
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field appearance="outline">
                                <mat-label>CPF / CNPJ</mat-label>
                                <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
                            </mat-form-field>
                        </div>
                        <div class="col-md-2">
                            <button mat-icon-button aria-label="Remover contato" (click)="removeContact(i)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>