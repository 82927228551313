import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { listUserResponse } from '../../responses/user/list-user.response';

@Injectable({
    providedIn: 'root'
  })

export class ListUserService extends CommonService{

    constructor(
        private httpClient: HttpClient){
            super();
        }


        public get(): Observable<listUserResponse> {

            let url = `user/`
        
            return this.httpClient.get<listUserResponse>(environment.urlApiUser + url, this.addHeaderToken())
            .pipe(
              catchError(this.handleError)
            )
          }

}