<app-menu [menuModuleEnum]="menuModuleEnum" [menuFunctionalityEnum]="menuFunctionalityEnum" [hasSecondaryHeader]=false>
</app-menu>
<div class="spinner" *ngIf="!modalAberto">
  <mat-progress-spinner *ngIf="loading" mode="indeterminate"></mat-progress-spinner>
</div>
<div class="container-body" *ngIf="loadedData">

  <div class="col-md-3">
    <button class="button-back" (click)="direct('/workshop/purchase-history')">
      <mat-icon class="icon-back">keyboard_arrow_left</mat-icon>
      <span>Voltar</span>
    </button>
  </div>

  <div class="budget row">
    <div class="col-md-5">
      <h2>
        Compra #{{array?.purchaseNumber}} - {{array?.datetimeInclusion | date: 'd MMMM y, HH:mm'}}
      </h2>
    </div>
    <div class="button-actions col-md-7">
      <tr>
        <td>
          <button mat-button id="button" (click)="captureScreen()">Imprimir</button>
        </td>
        <!-- <td>
          <button mat-button id="button">Solicitar Pagamento</button>
        </td>
        <td>
          <button mat-button id="button">Emitir NF</button>
        </td> -->
      </tr>
    </div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <h2>
        Comprador: {{array?.userName}}
      </h2>
    </div>
  </div>
  <div class="details-budget">
    <tr class="tr-budget">
      <td class="white-budget">
        <div>
          <span>Peças</span>
          <h2>{{getPurchaseHistoryDetailsResponse?.purchaseDeatailsStruct?.totalPartsPrice | currency: 'BRL'}}</h2>
        </div>
      </td>
      <td class="white-budget">
        <div>
          <span>Entrega</span>
          <h2>{{getPurchaseHistoryDetailsResponse?.purchaseDeatailsStruct?.totalDeliveryFee | currency: 'BRL'}}</h2>
        </div>
      </td>
      <td class="white-budget-last">
        <div>
          <span>Total</span>
          <h2>{{getPurchaseHistoryDetailsResponse?.purchaseDeatailsStruct?.totalPrice | currency: 'BRL'}}</h2>
        </div>
      </td>
    </tr>
  </div>
  <div class="white-body table-responsive">
    <h2 class="header-table">Peças</h2>
    <div class="row table-responsive">
      <table class="basic-table table-color">
        <thead class="basic-table__head">
          <tr class="truncate">
            <th></th>
            <th>Fornecedor</th>
            <th>Nº de Produtos</th>
          </tr>
        </thead>
        <tbody class="basic-table__body" *ngFor="let item of supplierParts">
          <tr class="basic-table__body__row" (click)="toggleDetails(item)">
            <td class="truncate">
              <mat-icon>{{ item.isDetails ? 'expand_more' : 'chevron_right' }}</mat-icon>
            </td>
            <td class="truncate">{{item.businessName}} - {{item.supplierName}}</td>
            <td class="truncate">{{item.listBuyOrderPart.length}}</td>
          </tr>
          <tr class="basic-table__head second-head" *ngIf="item.isDetails">
            <td colspan="9">
              <table class="basic-table table-color">
                <thead class="">
                </thead>
                <tbody class="truncate">
                  <tr class="basic-table__body__row table-style" *ngFor="let details of item.listBuyOrderPart">
                    <td class="truncate">
                      <img src="assets/images/no-image.png" class="img-size" alt="..." *ngIf="details.partImage64 == null">
                      <img *ngIf="details.partImage64 != null" class="img-part"
                        [src]="'data:image/jpg;base64,' + details.partImage64" />
                    </td>
                    <td class="row grid">
                      <ul class="ul-format">
                        <li class="title">{{details.partName}}</li>
                        <li>{{details.brandName}} | {{details.partNumber}}</li>
                      </ul>
                    </td>
                    <td class="row grid">
                      <ul class="ul-format">
                        <li class="title">Preço Unitário</li>
                        <li>{{details.partPrice | currency : 'BRL' }}</li>
                      </ul>
                    </td>
                    <td class="row grid">
                      <ul class="ul-format">
                        <li class="title">Quantidade</li>
                        <li>{{details.quantity}}</li>
                      </ul>
                    </td>
                    <td class="row grid">
                      <ul class="ul-format">
                        <li class="title">Status</li>
                        <li>{{ details.buyOrderStatusName }}</li>
                      </ul>
                    </td>       
                    <td class="row grid" *ngIf="details.filterType != null">
                      <ul class="ul-format">
                        <li>
                          <button id="button" mat-button (click)="openModal(details.idBuyOrderPart)">Carro Selecionado</button>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="bottom">
      <h2>Entrega</h2>
      <ul class="ul-format">
        <li>
          <h3>
            Endereço da Entrega
          </h3>
        </li>
      </ul>
      <div class="delivery">
        <div class="address">
          <ul>
            <li>
              <label class="neighborhood" for="">{{array?.branchDeliveryAddress?.addressName}}</label>
            </li>
          </ul>
          <ul>
            <li>
              {{array?.branchDeliveryAddress?.street}},
              {{array?.branchDeliveryAddress?.number}} -
              {{array?.branchDeliveryAddress?.neighborhood}},
              {{array?.branchDeliveryAddress?.city}},
              {{array?.branchDeliveryAddress?.zipcode}}
            </li>
          </ul>
        </div>
      </div>
      <ul class="ul-format">
        <li>
          <h3>
            Método da Entrega
          </h3>
        </li>
      </ul>
      <div class="delivery-table">
        <table class="basic-table" *ngFor="let item of distinctSuppliers">
          <thead class="basic-table">
            <tr class="truncate">
              <th class="left-align">Fornecedor
              </th>
              <th class="left-align">Prazo da Entrega
              </th>
              <th class="left-align">Meio de Entrega
              </th>
              <th class="left-align">Valor
              </th>
            </tr>
          </thead>
          <tbody class="basic-table__body">
            <tr class="basic-table__body__row">
              <td class="truncate left-align">
                <label class="label-delivery" for="">{{item.businessName }}</label>
              </td>
              <td class="truncate left-align">
                <p *ngIf="item.idDeliveryType == 1 || item.idDeliveryType == 4">
                  {{ deliveryTime }}
                </p>
                <p *ngIf="item.idDeliveryType == 2 || item.idDeliveryType == 3">Sem Estimativa</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 1">
                <p>Entrega Meros</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 2">
                <p>Entrega do Fornecedor</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 3">
                <p>Retirada</p>
              </td>
              <td class="truncate left-align" *ngIf="item.idDeliveryType == 4">
                <p>Meros Express</p>
              </td>
              <td class="truncate left-align">
                {{ item.estimatedDeliveryFee | currency: 'BRL' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div *ngFor="let item of distinctSuppliers">
        <ng-container *ngIf="item.idDeliveryType === 1">
          <h2>Pagamento</h2>
          <ul class="ul-format">
            <li>
              O Pagamento do produto será efetuada pela plataforma
            </li>
          </ul>
          <ul class="ul-format">
            <li>
              O Pagamento da Entrega Meros é feito Separadamente
            </li>
          </ul>
          <div class="delivery-table">
            <table class="basic-table">
              <thead class="basic-table">
                <tr class="truncate">
                  <th class="left-align">Cartão de Crédito/Débito
                  </th>
                  <th class="left-align">Nome do Cartão
                  </th>
                  <th class="left-align">Vencimento
                  </th>
              </thead>
              <tbody class="basic-table__body">
                <tr class="basic-table__body__row">
                  <td class="truncate left-align">
                    <mat-icon>credit_card</mat-icon>
                    <label for="">Cartão terminado em {{array?.branchCreditCard?.creditCardDigits}}</label>
                  </td>
                  <td class="truncate left-align">
                    {{array?.branchCreditCard?.creditCardName}}
                  </td>
                  <td class="truncate left-align">
                    {{array?.branchCreditCard?.expirationMonth}}/{{array?.branchCreditCard?.expirationYear}}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
