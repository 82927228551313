<div class="filter-body" [ngClass]="{'filter-body-show': openFilter == true}">
    <h3>Filtros</h3>
    <a mat-flat-button color="accent" class="btn btn-small" (click)="clearFilter()">
        <mat-icon class="material-symbols-outlined">filter_alt_off</mat-icon> 
        <span class="btn-text">limpar filtro</span>
    </a>
    <!-- <div class="filter-body-itens">
        <h4>Grupos</h4>
        <div *ngIf="searchPartsResponse != null && searchPartsResponse.listSearchedGroups != null">
            <div *ngFor="let item of searchPartsResponse.listSearchedGroups">
                <mat-checkbox id="{{item.idGroup}}" [checked]="item.isChecked" (change)="item.isChecked = !item.isChecked; changeFilter()">{{item.groupName}}</mat-checkbox>
            </div>
        </div>
    </div> -->

    <div class="filter-body-itens">
        <h4>Marca</h4>
        <div *ngIf="collectedParts != null">
            <div *ngFor="let item of getUniqueParts()">
                <mat-checkbox id="{{item.idBrand}}" [(ngModel)]="item.checked" (change)="onCheckboxChange($event, item)">{{item.brandName}}</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="filter-body-itens">
        <h4>Preço</h4>
        <mat-form-field appearance="outline">
            <mat-label>Máximo</mat-label>
            <input matInput type="text" >
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Mínimo</mat-label>
            <input matInput type="text" >
        </mat-form-field>
    </div> 
    <!-- <div class="filter-body-itens">
        <h4>Fornecedor</h4>
        <mat-checkbox>Auto Buritis</mat-checkbox>
        <mat-checkbox>Auto Giro</mat-checkbox>
        <mat-checkbox>Perfil</mat-checkbox>
        <mat-checkbox>Real</mat-checkbox>
    </div> -->
</div>