import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  // Manipulação de erros
  handleError(error: HttpErrorResponse) {

    let errorMessage = '';

    if (error.error instanceof ErrorEvent) {
      // Erro ocorreu no lado do client
      errorMessage = error.error.message;
      return throwError(errorMessage);
    }

    if (error.status == 504) {
      errorMessage = `GATEWAY TIMEOUT 504- Acione sua TI local para verificar sua rede/roteador/proxy local.`;
      return throwError(errorMessage);
    }

    if (error.status == 502) {
      errorMessage = `GATEWAY TIMEOUT 502- Tente novamente!`;
      return throwError(errorMessage);
    }

    if (error.status == 0) {
      errorMessage = `Você esta sem conexão com a internet no momento.`;
      return throwError(errorMessage);
    }

    // Erro ocorreu no lado do servidor
    errorMessage = `Código do erro: ${error.status}, ` + `menssagem: ${error.message}`;

    if (error.status == 401) {
      sessionStorage.removeItem("token");
      window.location.href = '/';
    }

    return throwError(errorMessage);
  };

  addHeaderToken() {
    let token: string = JSON.parse(sessionStorage.getItem('token')).token;

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': token
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }

  addHeaderTokenUntorizred() {

    let headerDict = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    }

    let requestOptions = {
      headers: new HttpHeaders(headerDict),
    };

    return requestOptions;
  }
  
}