import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ListSupplierOrdersResponse } from 'src/app/shared/services/API/responses/orchestrator-purchase/list-supplier-orders.response';
import { PurchaseHistoryService } from 'src/app/shared/services/API/services/orchestrator-purchase/purchase-history.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SupplierOrderStruct } from 'src/app/shared/services/API/structs/orchestrator-purchase/supplier-order.struct';
import { ListPurchaseOrderRequest } from 'src/app/shared/services/API/requests/orchestrator-purchase/list-purchase-order.request';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderDetailsModalComponent } from 'src/app/supplier/pages/order-details-modal/order-details-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource } from '@angular/material/table';
import { SupplierService } from 'src/app/shared/services/API/services/user/supplier.service';
import { GetSupplierResponse } from 'src/app/shared/services/API/responses/user/get-supplier.response';
@Component({
  selector: 'app-supplier-order',
  templateUrl: './supplier-order.component.html',
  styleUrls: ['./supplier-order.component.css']
})
export class SupplierOrderComponent implements OnInit {

 
  constructor(public purchaseHistoryService:PurchaseHistoryService,
    public alertService:AlertService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private supplierService: SupplierService
    ) { 
      this.onResize();
    }

  menuFunctionalityEnum: MenuFunctionalityEnum
  menuModuleEnum: MenuModuleEnum

  public listSupplierOrdersResponse:ListSupplierOrdersResponse;
  public listSupplierOrdersResponseHistory:ListSupplierOrdersResponse;
  public listSupplierOrdersResponseFinished:ListSupplierOrdersResponse;
  public getSupplierResponse:GetSupplierResponse;
  public listPurchaseOrderRequest: ListPurchaseOrderRequest;
  public isLoading:boolean = false;
  public model: FormGroup;
  public length: any;
  public lengthFinished: any;
  public data: any;
  public pageSize: number = 5;
  public statusFormGroup : FormGroup;
  public populateInterval;
  public screenWidth: number;
  public isPendingOrders: boolean = true
  public matFormFieldModule:MatFormFieldModule;
  public matInputModule:MatInputModule;
  public matPaginatorModule: MatPaginatorModule;
  public listBranch: any;
  public idBranchList: any;
  public branchList: any;
  public token;

  

  ngOnInit(): void {
      this.menuModuleEnum = MenuModuleEnum.fornecedor;
      this.menuFunctionalityEnum = MenuFunctionalityEnum.fornecedor_pedido;

      const response = sessionStorage.getItem("token");
      this.token = response;
      this.listBranch = JSON.parse(response);
      this.idBranchList = this.listBranch.listBranch.map(c => c.idBranch);
      this.branchList = this.listBranch.listBranch.map(c => ({
        id: c.idBranch,
        name: c.branchName
      }));
      

      this.model = this.formBuilder.group({
        start: [null],
        end: [null],
        status: [null],
        text: [null],
        idSupplier: [null],
        supplierName: [null]
      });

      this.populateInterval = setInterval(() => {
        if(!this.isPendingOrders){
          this.populateFinished();
        }else{
          this.populate();
        }
      }, 30000);

      this.model.get('status').valueChanges.subscribe(newValue => {
        this.populate();
        if(!this.isPendingOrders){
          this.populateFinished();
        }
      });
      this.model.get('supplierName').valueChanges.subscribe(newValue => {
        this.populate();
        if(!this.isPendingOrders){
          this.populateFinished();
        }
      });
      this.model.get('start').valueChanges.subscribe(newValue => {
        this.populate();
        if(!this.isPendingOrders){
          this.populateFinished();
        }
      });
      this.model.get('end').valueChanges.subscribe(newValue => {
        this.populate();
        if(!this.isPendingOrders){
          this.populateFinished();
        }
      });

      if(this.isPendingOrders){
        this.populate();
      }else{
        this.populateFinished();
      }
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  dataSource = new MatTableDataSource<SupplierOrderStruct>([]);
  dataSourceFinished = new MatTableDataSource<SupplierOrderStruct>([]);
  slicedData: SupplierOrderStruct[] = [];
  slicedDataFinished: SupplierOrderStruct[] = [];


  ngOnDestroy(){
    clearInterval(this.populateInterval);
  }

  convertUTCToLocal(utcDate: string): Date {
    const date = new Date(utcDate);  // Parse the UTC timestamp
    const userTimeZoneOffset = date.getTimezoneOffset() * 60000;  // Get user's time zone offset in milliseconds
    const localDate = new Date(date.getTime() - userTimeZoneOffset);  // Adjust to local time
    return localDate;
  }
  

  setRefrash(){
    if(this.isPendingOrders){
      const end = this.pageSize;
      this.populate(end);
    }else{
      const end = this.pageSize;
      this.populateFinished(end);
    }
  }

  search(){
    if(this.isPendingOrders){
      this.populate();
    }else{
      this.populateFinished();
    }
  }

  setIsOrders(){
    this.isPendingOrders = true;
    this.clearFields()
    setTimeout(() => {
      this.populate();
    }, 1000);
  }

  setIsFinishedOrders(){
    this.isPendingOrders = false;
    this.clearFields()
    setTimeout(() => {
      this.populateFinished();
    }, 1000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }

  clearFields() {
    this.model.get('start').setValue(null);
    this.model.get('end').setValue(null);
    this.model.get('text').setValue(null);
    this.model.get('status').setValue(null);
  }

  clearFilter(){
    this.clearFields();

    if(this.isPendingOrders){
      setTimeout(() => {
        this.populate();
      }, 1000);
    }else{
      setTimeout(() => {
        this.populateFinished();
      }, 1000);
    }
  }

  sliceData(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    if(this.isPendingOrders){
      const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
      const endIndex = startIndex + pageEvent.pageSize;
      this.slicedData = this.dataSource.data.slice(startIndex, endIndex);
    }else{
      const startIndex = pageEvent.pageIndex * pageEvent.pageSize;
      const endIndex = startIndex + pageEvent.pageSize;
      this.slicedDataFinished = this.dataSourceFinished.data.slice(startIndex, endIndex);
    }
  }

  populate(end?: number){
    if(this.isLoading) return;
    this.isLoading = true;

    const listPurchaseOrderRequest: ListPurchaseOrderRequest = {
      minDateTimeOrder: this.model.get('start').value,
      maxDateTimeOrder: this.model.get('end').value,
      textSearch: this.model.get('text').value,
      idBuyOrderStatus: this.model.get('status').value,
      idBranchSupplier: this.model.get('supplierName').value,
      idBranchList: this.idBranchList
    };

    this.purchaseHistoryService.Post(listPurchaseOrderRequest).subscribe({
      next: (listSupplierOrdersResponse: ListSupplierOrdersResponse) => {

        if (listSupplierOrdersResponse.isError) {
          this.alertService.show('Erro', listSupplierOrdersResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listSupplierOrdersResponse = listSupplierOrdersResponse;
        this.length = this.listSupplierOrdersResponse.listSupplierOrder.length;
        this.data = this.listSupplierOrdersResponse.listSupplierOrder;
        this.dataSource.data = this.listSupplierOrdersResponse.listSupplierOrder;
        if(end){
          this.slicedData = this.dataSource.data.slice(0, end);
        }else{
          this.slicedData = this.dataSource.data.slice(0, this.pageSize);
        }

        //ToDo:
        //this.listSupplierOrdersResponse.listSupplierOrder = this.listSupplierOrdersResponse.listSupplierOrder.find(c=> c.idPaymentStatus == 1);
        //this.listSupplierOrdersResponseHistory = this.listSupplierOrdersResponse; 

        // console.log(this.listSupplierOrdersResponse);
        
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  populateFinished(end?: number){

    if(this.isLoading) return;
    this.isLoading = true;

    const listPurchaseOrderRequest: ListPurchaseOrderRequest = {
      minDateTimeOrder: this.model.get('start').value,
      maxDateTimeOrder: this.model.get('end').value,
      textSearch: this.model.get('text').value,
      idBuyOrderStatus: this.model.get('status').value,
      idBranchSupplier: this.model.get('supplierName').value,
      idBranchList: this.idBranchList
    };

    this.purchaseHistoryService.PostFinished(listPurchaseOrderRequest).subscribe({
      next: (listSupplierOrdersResponse: ListSupplierOrdersResponse) => {

        if (listSupplierOrdersResponse.isError) {
          this.alertService.show('Erro', listSupplierOrdersResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.isLoading = false;
        this.listSupplierOrdersResponseFinished = listSupplierOrdersResponse;
        this.lengthFinished = this.listSupplierOrdersResponseFinished.listSupplierOrder.length;
        this.data = this.listSupplierOrdersResponseFinished.listSupplierOrder;
        this.dataSourceFinished.data = this.listSupplierOrdersResponseFinished.listSupplierOrder;
        if(end){
          this.slicedDataFinished = this.dataSourceFinished.data.slice(0, end);
        }else{
          this.slicedDataFinished = this.dataSourceFinished.data.slice(0, this.pageSize);
        }

        //ToDo:
        //this.listSupplierOrdersResponse.listSupplierOrder = this.listSupplierOrdersResponse.listSupplierOrder.find(c=> c.idPaymentStatus == 1);
        //this.listSupplierOrdersResponseHistory = this.listSupplierOrdersResponse; 
        
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  modalDetails(item: any): void {
    if(this.screenWidth >= 650){
      const dialogRef = this.dialogRef.open(OrderDetailsModalComponent,{
        height: 'max-content',
        width: '100%',
        maxHeight: '90vh',
        data: {
          idBuyOrder: item.idBuyOrder,
        }
      })
      dialogRef.afterClosed().subscribe(() => {
        this.populate();
        this.populateFinished();
      });
    }else{
      const dialogRef = this.dialogRef.open(OrderDetailsModalComponent, {
        width: '100vw',
        height: '100vh',
        maxWidth: '100vw',
        maxHeight: '100vh',
        data: {
          idBuyOrder: item.idBuyOrder,
        }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.populate();
        this.populateFinished();
      });
    }
  }
}
