import { ContactRequestStruct } from "../../structs/serviceOrder/contact-request.struct";

export class ServiceOrderRequest{
    public idPlate: number;
    public cpfCnpj: string;

    public clientName: string;
    public phone: string;
    public whatsAppPhone: string;
    public email: string;
    public listContactRequestStruct: ContactRequestStruct[];
}