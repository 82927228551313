import { Component, EventEmitter, Output, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormsModule, UntypedFormBuilder, FormBuilder, FormGroup, Validators, AbstractControl} from '@angular/forms';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaskService, Masks } from 'src/app/shared/services/mask.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { BusinessService } from 'src/app/shared/services/API/services/user/business.service';
import { GetBusinessResponse } from 'src/app/shared/services/API/responses/user/get-business.response';
import { BusinessBranchStruct } from 'src/app/shared/services/API/structs/user/business-branch.struct';
import { WorkshopSupplierRelationshipRequest } from 'src/app/shared/services/API/requests/user/workshop-supplier-relationship.request';

@Component({
  selector: 'app-modal-patner-workshop-edit',
  templateUrl: './modal-patner-workshop-edit.component.html',
  styleUrls: ['./modal-patner-workshop-edit.component.css']
})
export class ModalPatnerWorkshopEditComponent {

  constructor(private formBuilder: FormBuilder,
    public maskService: MaskService,
    private alertService: AlertService,
    private businessService: BusinessService,
    private dialogRef: MatDialogRef<ModalPatnerWorkshopEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){

      this.model = this.formBuilder.group({
        // workshopName:['', [Validators.required]],
        // branchWorkshop: this.formBuilder.array([], [this.validatorCheckBox]),
        discount: ['', [Validators.required]],
        creditLimit: ['', [Validators.required]],
        creditLimitResetDays: ['', [Validators.required]],
      });

      if(!data.newPartner){
        this.model.patchValue({
          branchWorkshop: data.branchWorkshop,
          discount: data.discount,
          creditLimit: data.creditLimit,
          creditLimitResetDays: data.creditLimitResetDays,
        });
        this.workshop = true
        this.editWorkshop = true
      }else{
        this.model.patchValue({
          branchWorkshop: data.branchWorkshop,
        });
        this.workshop = false
        this.editWorkshop = false
        this.populate()
      }

    }

    public FormFieldModuel: MatFormFieldModule;
    public matCheckboxModule: MatCheckboxModule;
    public getBusinessResponse: GetBusinessResponse;
    public model: FormGroup;
    public abstractControl : AbstractControl ;
    public masks: Masks;
    public hide: boolean = false;
    public searchText: any;
    public isLoading: boolean;
    public businessWorkshop: any;
    public filteredBusinessWorkshop: any;
    public WorkshopsName: any;
    public workshop: boolean;
    public editWorkshop: boolean;
    public idBranchWorkshop: number;
    public idBranchSupplier: number;
    public selectedBranches: number[] = [];
    public branchNames: string[] = [];
  
    @Output() confirmRegister = new EventEmitter<any>();
    @ViewChild('inputSearch', { static: false }) inputSearch: ElementRef;
    

    ngOnInit(): void {
      this.masks = this.maskService.getMasks();
    }

    populate(){
      this.businessService.getAll().subscribe({
        next: (response: GetBusinessResponse) => {
          if(response.isError){
            this.alertService.show('Erro', response.errorDescription, AlertType.error)
            this.isLoading = false;
            return
          }
          this.getBusinessResponse = response;
          this.businessWorkshop = this.getBusinessResponse.listBusinessBranch.map(x => x.business)
          this.filteredBusinessWorkshop = [...this.businessWorkshop];
          this.isLoading = false;
        },
        error: (error) => {
          this.isLoading = false;
          console.log(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
        },
      });
    }

    onKey(value: string){
      this.searchText = value.trim().toLowerCase();
      if (!this.searchText) {
        // Se o campo estiver vazio, restaura a lista original
        this.filteredBusinessWorkshop = [...this.businessWorkshop];
      } else {
        // Filtra os itens com base no texto digitado
        this.filteredBusinessWorkshop = this.businessWorkshop.filter((item) =>
          item.businessName.toLowerCase().includes(this.searchText)
        );
      }
    }

    searchWokshop(item: any){
      const branch = item;
      this.businessService.get(branch).subscribe({
        next: (response : GetBusinessResponse) => {
          if(response.isError){
            this.alertService.show('Erro', response.errorDescription, AlertType.error)
            this.isLoading = false;
            return
          }
          this.getBusinessResponse = response;
          this.WorkshopsName = this.getBusinessResponse.listBusinessBranch[0].branches
          this.workshop = true;
        }
      });
    }
    
    checkboxChanged(item: any, isChecked: boolean) {
      if (isChecked) {
        this.selectedBranches.push(item.idBranch);
      } else {
        this.selectedBranches = this.selectedBranches.filter(
          (branchId) => branchId !== item.idBranch
        );
      }
    }

    focusInput() {
      if (this.inputSearch) {
        this.inputSearch.nativeElement.focus();
      }
    }

    validatorCheckBox(control: AbstractControl): { [key: string]: any } | null {
      const value = control.value;
      if (value && value.length > 0) {
        return null;
      }
      return { atLeastOneRequired: true };
    }
  
    registerProfile(){
      const workshopRequest: WorkshopSupplierRelationshipRequest = new WorkshopSupplierRelationshipRequest();
      if (this.model.valid) {
        workshopRequest.creditLimit = this.model.get("creditLimit").value;
        workshopRequest.creditLimitResetDays = this.model.get("creditLimitResetDays").value;
        workshopRequest.partDiscount = this.model.get("discount").value;
        workshopRequest.listIdBranch = this.selectedBranches;
        this.confirmRegister.emit(workshopRequest);
      }
      else{
        this.alertService.show('Erro', "Preencha corretamente todos os campos.", AlertType.error)
      }
    }

}
