<div class="spinner" *ngIf="isLoading">
  <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
</div>
<div *ngIf="!isLoading">
  <div class="mat-dialog-tittle">
    <h2 *ngIf="idBuyOrderStatus === 1 || idBuyOrderStatus === 8">NEGAR PEDIDO?</h2>
    <h2 *ngIf="idBuyOrderStatus === 2 || idBuyOrderStatus === 3 || idBuyOrderStatus === 9">CANCELAR PEDIDO?</h2>
  </div>

  <div mat-dialog-content>
    <form [formGroup]="model">
      <div class="alert alert-danger d-flex align-items-center" role="alert">
        <mat-icon class="icon-delete" color="warn">warning</mat-icon>
        <div class="text" *ngIf="idBuyOrderStatus === 1 || idBuyOrderStatus === 8 || idBuyOrderStatus === 2">
          Essa ação não pode ser desfeita. Avisaremos a Oficina <strong>{{this.oficina}}</strong> que o pedido foi
          <strong>NEGADO</strong>.
        </div>
        <div class="text" *ngIf="idBuyOrderStatus === 3 || idBuyOrderStatus === 9">
          Cancelar a chamada do entregador?
        </div>
        <div class="text" *ngIf="idBuyOrderStatus === 4">
          Cancelar o pedido de entrega? O cancelamento será cobrado uma taxa de R$ 00,00
        </div>
      </div>
      <mat-form-field *ngIf="idBuyOrderStatus === 1">
        <mat-label>Motivo da Recusa (Opcional)</mat-label>
        <textarea matInput formControlName="observation"></textarea>
      </mat-form-field>
      <mat-form-field *ngIf="idBuyOrderStatus === 2">
        <mat-label>Motivo da Recusa</mat-label>
        <textarea matInput formControlName="observationCancel"></textarea>
      </mat-form-field>
    </form>
  </div>

  <div mat-dialog-actions [align]="'center'">
    <button mat-raised-button mat-dialog-close="false">Não</button>
    <button mat-raised-button (click)="cancelBuyOrder()" color="warn">Sim</button>
  </div>
</div>
