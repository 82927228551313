<app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
  [hasSecondaryHeader]=true></app-menu>

<div class="container-body-out">

  <div class="container-body">
    <div class="white-body">

      <button mat-button type="button" class="btn btn-primary-blue close" color="primary" (click)="openModalClose()"
        *ngIf="statusOpen">
        <span>Fechar OS</span>
      </button>

      <button mat-button type="button" class="btn-secundary close" color="primary" (click)="openReopenModal()"
        *ngIf="statusClose">
        <span>Reabrir OS</span>
      </button>

      <button mat-button type="button" class="btn-secundary close delete" color="primary" (click)="openDeleteModal()"
        *ngIf="statusClose">
        <span>Deletar OS</span>
      </button>


      <h1>Ordem de serviço</h1>
      <form class="form" [formGroup]="model" (ngSubmit)="save()">

        <div class="infos-os" *ngIf="vehicleVersion != null">
          <div class="row">
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'PLACA'" [content]="vehicleVersion.plateNumber">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'MARCA'" [content]="vehicleVersion.brandName">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'MODELO'" [content]="vehicleVersion.modelName">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'VERSÃO'" [content]="vehicleVersion.versionName">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'ANO'" [content]="year"></app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'MOTOR'" [content]="vehicleVersion.engine">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'CÂMBIO'" [content]="vehicleVersion.transmission">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'FIPE ID'" [content]="vehicleVersion.fipeCode">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'COMBUSTÍVEL'" [content]="vehicleVersion.fuel">
              </app-vehicle-details-label>
            </div>
            <div class="col-md-12 col-lg-4">
              <app-vehicle-details-label [label]="'KM Atual'" [isFormField]="true"
                (passFormFieldValue)="catchValue($event)"></app-vehicle-details-label>
            </div>
          </div>

          <h2>Proprietário</h2>
          <div class="row">
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Nome e sobrenome</mat-label>
                <input matInput type="text" formControlName="clientName">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>Telefone</mat-label>
                <input matInput type="text" formControlName="phone" [mask]="masks.phone">
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <mat-form-field appearance="outline">
                <mat-label>WhatsApp</mat-label>
                <input matInput type="text" formControlName="whatsAppPhone" [mask]="masks.phone">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>E-mail</mat-label>
                <input matInput type="text" formControlName="email">
              </mat-form-field>
            </div>
            <div class="col-md-6">
              <mat-form-field appearance="outline">
                <mat-label>CPF / CNPJ</mat-label>
                <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
              </mat-form-field>
            </div>
          </div>

          <h2>Contatos</h2>

          <button *ngIf="statusOpen" mat-button type="button" class="btn btn-primary-blue btn-add-list" color="primary"
            (click)="addListContact(null)">
            <mat-icon class="material-symbols-outlined">add</mat-icon>
            <span>Adicionar contato</span>
          </button>

          <div class="list-contacts">
            <div formArrayName="listContact" *ngFor="let item of model.get('listContact')['controls']; let i = index">
              <div [formGroup]="item" class="row contacts">
                <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Nome e sobrenome</mat-label>
                    <input matInput type="text" formControlName="name">
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>WhatsApp</mat-label>
                    <input matInput type="text" formControlName="whatsAppPhone" [mask]="masks.phone">
                  </mat-form-field>
                </div>
                <div class="col-md-6">
                  <mat-form-field appearance="outline">
                    <mat-label>E-mail</mat-label>
                    <input matInput type="text" formControlName="email">
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>CPF / CNPJ</mat-label>
                    <input matInput type="text" formControlName="cpfCnpj" [mask]="masks.cpfCnpj">
                  </mat-form-field>
                </div>
                <div class="col-md-2">
                  <button *ngIf="statusOpen" mat-icon-button aria-label="Remover contato" (click)="removeContact(i)">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button mat-button type="submit" class="btn btn-primary-blue" color="primary" *ngIf="statusOpen">
            <mat-icon class="material-symbols-outlined">save</mat-icon>
            <span>Salvar</span>
          </button>

          <hr />

          <h2>Orçamento</h2>

          <button *ngIf="statusOpen" mat-button type="button" class="btn btn-primary-blue btn-add-list" color="primary"
            (click)="newQuoteService()">
            <mat-icon class="material-symbols-outlined">add</mat-icon>
            <span>Novo orçamento</span>
          </button>
          <div class="row table-responsive">
            <table class="basic-table table-color"
              *ngIf="getServiceOrderDetailsResponse != null && getServiceOrderDetailsResponse.listQuoteServiceStruct != null && getServiceOrderDetailsResponse.listQuoteServiceStruct.length > 0">
              <thead class="basic-table__head">
                <tr class="truncate">
                  <th></th>
                  <th>Orçamento ID</th>
                  <th>Status</th>
                  <th>Valor carrinho</th>
                  <th>Valor estoque</th>
                  <th>Valor serviço</th>
                  <th>Total</th>
                  <td></td>
                </tr>
              </thead>
              <tbody class="basic-table__body">
                <ng-container
                  *ngFor="let itemservice of getServiceOrderDetailsResponse.listQuoteServiceStruct; let i = index">
                  <tr class="basic-table__body__row" (click)="showLine(i)">
                    <td class="truncate">
                      <a>
                        <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus"
                          *ngIf="!itemservice.showDetails">keyboard_arrow_right</mat-icon>
                        <mat-icon class="vertical-align" aria-hidden="false" aria-label="Plus"
                          *ngIf="itemservice.showDetails">keyboard_arrow_down</mat-icon>
                      </a>
                    </td>
                    <td class="truncate">{{itemservice.idQuoteService}}</td>
                    <td class="truncate">{{itemservice.descriptionQuoteServiceStatus}}</td>
                    <td class="truncate">{{itemservice.cartPrice | currency: 'BRL'}}</td>
                    <td class="truncate">{{itemservice.stockPrice | currency: 'BRL'}}</td>
                    <td class="truncate">{{itemservice.servicePrice | currency: 'BRL'}}</td>
                    <td class="truncate">{{itemservice.totalPrice | currency: 'BRL'}}</td>
                    <td class="truncate">
                      <a (click)="editQuoteService(itemservice.idQuoteService)">
                        <mat-icon style="color: #001F38;">arrow_circle_right</mat-icon>
                      </a>
                    </td>
                  </tr>
                  <tr class="basic-table__head second-head" *ngIf="itemservice.showDetails">
                    <td colspan="8">
                      <table class="basic-table table-color"
                        *ngIf="itemservice.listQuoteServicePart != null && itemservice.listQuoteServicePart.length > 0">
                        <thead>
                          <tr class="truncate">
                            <th>Produto</th>
                            <th>Marca</th>
                            <th>Código</th>
                            <th>Fornecedor</th>
                            <th>Valor</th>
                            <th>Quantidade</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="basic-table__body__row" *ngFor="let itemservicePart of itemservice.listQuoteServicePart; let i = index">
                            <td class="truncate">{{itemservicePart.partProductStruct.productName}}</td>
                            <td class="truncate">{{itemservicePart.partProductStruct.brandName}}</td>
                            <td class="truncate">{{itemservicePart.idPart}}</td>
                            <td class="truncate">{{itemservicePart.idSupplier}}</td>
                            <td class="truncate">{{itemservicePart.price | currency: 'BRL'}}</td>
                            <td class="truncate">{{itemservicePart.amount}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <p
                        *ngIf="itemservice.listQuoteServicePart == null || itemservice.listQuoteServicePart.length == 0">
                        Não há peças neste orçamento
                      </p>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
            <p
              *ngIf="getServiceOrderDetailsResponse.listQuoteServiceStruct == null || getServiceOrderDetailsResponse.listQuoteServiceStruct.length == 0">
              Não há orçamentos nesta ordem de serviço
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
