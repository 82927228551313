import { UntypedFormBuilder } from '@angular/forms';
import { ReturnStruct } from 'src/app/shared/structs/return.struct';
import { GetClientAprovalQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-client-aproval-quote-service.response';


import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuComponent } from 'src/app/shared/menu/menu.component';
import { MenuFunctionalityEnum } from 'src/app/shared/menu/menu.functionality.enum';
import { MenuModuleEnum } from 'src/app/shared/menu/menu.module.enum';
import { ServiceOrderStruct } from 'src/app/shared/services/API/structs/autoparts/service-order.struct';
import { ModelServiceOrderSearchComponent } from './model-service-order-search/model-service-order-search.component';
import { ModalServiceOrderNewComponent } from './modal-service-order-new/modal-service-order-new.component';
import { SearchShoppingCarItemStruct, SearchShoppingCartService, SearchShoppingCartStruct } from '../search/search-shopping-cart/search-shopping-cart.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PostQuoteServiceRequest } from 'src/app/shared/services/API/requests/serviceOrder/post-quote-service.request';
import { QuoteServiceService } from 'src/app/shared/services/API/services/serviceOrder/quote-service.service';
import { AlertService, AlertType } from 'src/app/shared/services/alert.service';
import { QuoteServicePart } from 'src/app/shared/services/API/models/serviceOrder/quote-service-part.model';
import { StockPart } from 'src/app/shared/services/API/models/serviceOrder/stock-part.model';
import { ServiceDetail } from 'src/app/shared/services/API/models/serviceOrder/service-detail.model';
import { GetQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/get-quote-service.response';
import { QuoteServiceStatusEnum } from 'src/app/shared/enums/serviceOrder/quote-service-status-enum';
import { ModalServiceOrderApprovalComponent } from './modal-service-order-approval/modal-service-order-approval.component';
import { PostQuoteServiceResponse } from 'src/app/shared/services/API/responses/serviceOrder/post-quote-service.request';
import { PartsNamesService } from 'src/app/shared/services/API/services/autoparts/parts-names.service';
import { PartsNamesRequest } from 'src/app/shared/services/API/requests/autoparts/parts-names.request';
import { PartsNamesResponse } from 'src/app/shared/services/API/responses/autoparts/parts-names.response';
import { PartProductStruct } from 'src/app/shared/services/API/structs/autoparts/part-product.struct';
import { EmptyError } from 'rxjs';



@Component({
  selector: 'app-quote-service',
  templateUrl: './quote-service.component.html',
  styleUrls: ['./quote-service.component.css']
})
export class QuoteServiceComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public searchShoppingCartService: SearchShoppingCartService,
    private formBuilder: FormBuilder,
    private quoteServiceService: QuoteServiceService,
    public alertService: AlertService,
    private router: Router,
    private partsNamesService:PartsNamesService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.idServiceOrder = params.idServiceOrder;
      this.idQuoteService = params.idQuoteService;
      this.addnewitens = params.addnewitens;

      if(params.openModalClientApproval == 'true')
        this.openModalClientApproval();

        if(params.redirectToBuy == 'true')
        this.clickBtnBuy();
    });

    this.model = this.formBuilder.group({
      listQuoteServicePart: this.formBuilder.array([]),
      listStockPart: this.formBuilder.array([]),
      listServiceDetail: this.formBuilder.array([]),
    });

    this.populate();
    this.controlPage()
  }

  public menuModuleEnum: MenuModuleEnum = MenuModuleEnum.oficina;
  public menuFunctionalityEnum: MenuFunctionalityEnum = MenuFunctionalityEnum.oficina_dashboard;

  public idServiceOrder: number = 6;
  public idQuoteService: number = null;

  public serviceOrderStruct: ServiceOrderStruct = new ServiceOrderStruct();
  public model: FormGroup;
  public isLoading: boolean;
  public loadingCalled: boolean = false;

  public statusName: string;
  public idStatus: number;

  public priceCart: number;
  public priceStock: number;
  public priceServices: number;
  public priceTotal: number;
  public amountTotal: number;

  public addnewitens:boolean = false;

  @ViewChild('menu') menu: MenuComponent;

  public showBtnSendClient = true;
  public showBtnBuy = true;
  public showBtnSave = true;
  public showBtnDelete = true;
  public showBtnSearch = true;
  public showBtnAddPart = true;
  public showBtnAddService = true;

  populate() {

    if (this.idServiceOrder == null) {
      this.idQuoteService = null;
      //Abre modal de pesquisa de OS
      const dialogRefSearch = this.dialog.open(ModelServiceOrderSearchComponent, {
        data: {},
        panelClass: 'large-modal',
        disableClose: true
      });

      dialogRefSearch.afterClosed().subscribe(result => {
        if (result && result.idServiceOrder) {
          this.idServiceOrder = result.idServiceOrder;
          this.populate();
        }


        if (result && result.openNewService) {
          const dialogRefNew = this.dialog.open(ModalServiceOrderNewComponent, {
            data: {},
            panelClass: 'large-modal',
            disableClose: true
          });

          dialogRefNew.afterClosed().subscribe(result => {
            if (result && result.idServiceOrder) {
              this.idServiceOrder = result.idServiceOrder;
            }
            this.populate();

          });
        }
      });
    }
    else {
      this.populateHeader();
      this.populatePage();
    }

  }

  onInput(event: any) {
    const amountControl = event.target.value;

    if (amountControl == 0){
      event.target.value = null;
      this.updatePrices()
    }else{
      this.updatePrices()
    }
}

  updatePrices() {

    this.priceCart = 0;
    this.priceStock = 0;
    this.priceServices = 0;

    this.model.get('listQuoteServicePart').value.forEach(x => {
      let price = (x.amount * x.price) * ((x.markup / 100) + 1);

      this.priceCart = this.priceCart + price;
    });

    this.model.get('listStockPart').value.forEach(x => {
      let price = x.amount * x.price;

      this.priceStock = this.priceStock + price;
    });

    this.model.get('listServiceDetail').value.forEach(x => {
      let price = x.amount * x.price;

      this.priceServices = this.priceServices + price;
    });

    this.priceTotal = this.priceCart + this.priceStock + this.priceServices;
  }

  populateHeader() {
    setTimeout(() => {
      this.menu.reloadHeader(this.idServiceOrder);
    }, 1000);

  }

  statusRefesh(){
    this.quoteServiceService.get(this.idQuoteService).subscribe({
      next: (getQuoteServiceResponse: GetQuoteServiceResponse) => {

        if (getQuoteServiceResponse.isError) {
          this.alertService.show('Erro', getQuoteServiceResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

          this.idStatus = getQuoteServiceResponse.idQuoteServiceStatus;
          this.statusName = getQuoteServiceResponse.statusName;
        },
          error: (error) => {
            console.error(error);
            this.alertService.show('Ação não concluída.', error, AlertType.error);
            this.isLoading = false;
          }
        });
      }

      isTableEmpty(): boolean {
        const listQuoteServicePart = this.model.get('listQuoteServicePart') as FormArray;
        return listQuoteServicePart.length === 0;
      }

  populatePage() {
    let searchShoppingCartStuct: SearchShoppingCartStruct = this.searchShoppingCartService.getParts();

    if(this.idQuoteService == null || this.addnewitens){
      searchShoppingCartStuct.searchShoppingCarItemStruct.forEach(element => {
        (this.model.controls['listQuoteServicePart'] as FormArray).push(
          this.newItemOnListQuoteServicePart(element, '50')
          );
      });

      this.idStatus = QuoteServiceStatusEnum.aberto;
      this.statusName = "Aberto";
    }

    //Obtem os valores para popular a pagina
    if (this.idQuoteService != null) {
      this.quoteServiceService.get(this.idQuoteService).subscribe({
        next: (getQuoteServiceResponse: GetQuoteServiceResponse) => {

          if (getQuoteServiceResponse.isError) {
            this.alertService.show('Erro', getQuoteServiceResponse.errorDescription, AlertType.error);
            this.isLoading = false;
            return;
          }

          getQuoteServiceResponse.listServiceDetail.forEach(item => {
            this.addListServiceDetail(item);
          });

          getQuoteServiceResponse.listStockPart.forEach(item => {
            this.addListStockPart(item);
          });


          //Obtem o partProductStruct
          let partsNamesRequest:PartsNamesRequest = new PartsNamesRequest();
          partsNamesRequest.listPartID = getQuoteServiceResponse.listQuoteServicePart.map(c=> c.idPart);

          this.partsNamesService.post(partsNamesRequest).subscribe({
            next: (partsNamesResponse: PartsNamesResponse) => {

              if (partsNamesResponse.isError) {
                this.alertService.show('Erro', partsNamesResponse.errorDescription, AlertType.error);
                this.isLoading = false;
                return;
              }

              //Insere todas as peças na lista de peças de estoque para imprimir na tela
              getQuoteServiceResponse.listQuoteServicePart.forEach(element => {
                //Se for adicionar itens do carrinho, remove os já existentes
                if(this.addnewitens){
                  var itenRepeted = searchShoppingCartStuct.searchShoppingCarItemStruct.find(c=> c.partProductStruct.idPart == element.idPart);
                  if(itenRepeted)
                    return;
                }

                let partProductStruct:PartProductStruct = partsNamesResponse.listPartProduct.find(c=> c.idPart == element.idPart);

                let searchShoppingCarItemtStuct: SearchShoppingCarItemStruct = new SearchShoppingCarItemStruct();
                searchShoppingCarItemtStuct.partProductStruct = partProductStruct;
                searchShoppingCarItemtStuct.partProductStruct.priceInvoice = element.price;
                searchShoppingCarItemtStuct.amount = element.amount;
                searchShoppingCarItemtStuct.partProductStruct.idBusinessSupplier = element.idSupplier;
                searchShoppingCarItemtStuct.partProductStruct.partImage64 = null;

                (this.model.controls['listQuoteServicePart'] as FormArray).push(
                  this.newItemOnListQuoteServicePart(searchShoppingCarItemtStuct, element.markup)
                );
                this.updatePrices();
              });
            },
            error: (error) => {
              console.error(error);
              this.alertService.show('Ação não concluída.', error, AlertType.error);
              this.isLoading = false;
            }
          });

          this.idStatus = getQuoteServiceResponse.idQuoteServiceStatus;
          this.statusName = getQuoteServiceResponse.statusName;
          this.updatePrices();
          this.controlPage();

        },
        error: (error) => {
          console.error(error);
          this.alertService.show('Ação não concluída.', error, AlertType.error);
          this.isLoading = false;
        }
      });
    }
    this.updatePrices();
  }

  newItemOnListQuoteServicePart(element: SearchShoppingCarItemStruct, markup) {
    return this.formBuilder.group({
      partImage64: new FormControl(element.partProductStruct.partImage64),
      idPart: new FormControl(element.partProductStruct.idPart),
      productName: new FormControl(element.partProductStruct.productName),
      // eanCode: new FormControl(element.partProductStruct.eanCode),
      brandName: new FormControl(element.partProductStruct.brandName),
      supplierName: new FormControl(element.partProductStruct.supplierName),
      markup: new FormControl(markup, Validators.required),
      price: new FormControl(element.partProductStruct.priceInvoice),
      amount: new FormControl(element.amount + ''),
      idSupplier: new FormControl(element.partProductStruct.idBusinessSupplier),
    });
  }


  removeListQuoteServicePart(i) {
    (this.model.controls['listQuoteServicePart'] as FormArray).removeAt(i);
    this.updatePrices();
  }

  addListStockPart(stockPart: StockPart) {
    (this.model.controls['listStockPart'] as FormArray).push(
      this.newItemOnListStockPart(stockPart)
    );
  }

  removeListStockPart(i) {
    (this.model.controls['listStockPart'] as FormArray).removeAt(i);
    this.updatePrices();
  }

  newItemOnListStockPart(stockPart: StockPart) {
    return this.formBuilder.group({
      productName: new FormControl(stockPart?.productName, Validators.required),
      eanCode: new FormControl(stockPart?.productCode),
      brandName: new FormControl(stockPart?.productBrand, Validators.required),
      price: new FormControl(stockPart?.price, Validators.required),
      amount: new FormControl(stockPart?.amount, Validators.required)
    });
  }

  addListServiceDetail(serviceDetail: ServiceDetail) {
    (this.model.controls['listServiceDetail'] as FormArray).push(
      this.newListServiceDetail(serviceDetail)
    );
  }

  newListServiceDetail(serviceDetail: ServiceDetail) {
    return this.formBuilder.group({
      description: new FormControl(serviceDetail?.description, Validators.required),
      price: new FormControl(serviceDetail?.price, Validators.required),
      amount: new FormControl(serviceDetail?.amount, Validators.required)
    });
  }

  removetServiceDetail(i) {
    (this.model.controls['listServiceDetail'] as FormArray).removeAt(i);
    this.updatePrices();
  }

  save(openModalClientApproval, redirectToBuy, backToOs) {
    if (this.model.invalid) return;
    if (this.isLoading) return;

    let postQuoteServiceRequest: PostQuoteServiceRequest = new PostQuoteServiceRequest();

    postQuoteServiceRequest.listQuoteServicePart = [];
    this.model.get('listQuoteServicePart').value.forEach(x => {
      let quoteServicePart: QuoteServicePart = new QuoteServicePart();

      quoteServicePart.idQuoteServicePart = 0;
      quoteServicePart.idQuoteService = 0;
      quoteServicePart.idPart = x.idPart;
      quoteServicePart.markup = x.markup;
      quoteServicePart.amount = x.amount;
      quoteServicePart.price = x.price;
      quoteServicePart.idSupplier = x.idSupplier == null ? 0 : x.idSupplier;

      postQuoteServiceRequest.listQuoteServicePart.push(quoteServicePart);
    });

    postQuoteServiceRequest.listStockPart = [];
    this.model.get('listStockPart').value.forEach(x => {
      let stockPart: StockPart = new StockPart();

      stockPart.idQuoteService = 0;
      stockPart.idStockPart = 0;
      stockPart.productName = x.productName;
      stockPart.productCode = x.eanCode;
      stockPart.productBrand = x.brandName;
      stockPart.price = x.price;
      stockPart.amount = x.amount;

      postQuoteServiceRequest.listStockPart.push(stockPart);
    });

    postQuoteServiceRequest.listServiceDetail = [];
    this.model.get('listServiceDetail').value.forEach(x => {
      let serviceDetail: ServiceDetail = new ServiceDetail();

      serviceDetail.idQuoteService = 0;
      serviceDetail.idServiceDetail = 0;
      serviceDetail.description = x.description;
      serviceDetail.price = x.price;
      serviceDetail.amount = x.amount;

      postQuoteServiceRequest.listServiceDetail.push(serviceDetail);
    });

    postQuoteServiceRequest.idQuoteService = this.idQuoteService;
    postQuoteServiceRequest.idServiceOrder = this.idServiceOrder;

    this.isLoading = true;
    this.quoteServiceService.post(postQuoteServiceRequest).subscribe({
      next: (postQuoteServiceResponse: PostQuoteServiceResponse) => {

        if (postQuoteServiceResponse.isError) {
          this.alertService.show('Erro', postQuoteServiceResponse.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.idQuoteService = postQuoteServiceResponse.idQuoteService;

        this.alertService.show('Sucesso', "Orçamento salvo com sucesso", AlertType.success);

        if(backToOs){
          this.router.navigate(['/workshop/service-order/details'], { queryParams: { idServiceOrder: this.idServiceOrder} });
          return;
        }

        if(redirectToBuy == true){
          this.router.navigate(['/workshop/purchase'], { queryParams: { idServiceOrder: this.idServiceOrder, idQuoteService: this.idQuoteService } });
          return;
        }

        if(openModalClientApproval){
          const dialogRefSearch = this.dialog.open(ModalServiceOrderApprovalComponent, {
            data: {
              idQuoteService: this.idQuoteService
            },
            panelClass: 'large-modal'
          });
          dialogRefSearch.afterClosed().subscribe(result => {
            this.router.navigate(['/workshop/quote-service'], { queryParams: { idServiceOrder: this.idServiceOrder, idQuoteService: this.idQuoteService } });
            this.statusRefesh()
          });
        }

      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  openModalClientApproval() {
    this.save(true, false, false);
  }

  searchParts() {
    this.router.navigate(['/workshop/search'], { queryParams: { idServiceOrder: this.idServiceOrder, idQuoteService: this.idQuoteService } });
  }

  controlPage() {
    if(this.idStatus == QuoteServiceStatusEnum.aberto){
        this.showBtnSendClient = true;
        this.showBtnBuy = true;
        this.showBtnSave = true;
        this.showBtnDelete = true;
        this.showBtnSearch = true;
        this.showBtnAddPart = true;
        this.showBtnAddService = true;

    }else if(this.idStatus == QuoteServiceStatusEnum.aguardandoAprovacao){
        this.showBtnSendClient = true;
        this.showBtnBuy = true;
        this.showBtnSave = true;
        this.showBtnDelete = true;
        this.showBtnSearch = true;
        this.showBtnAddPart = true;
        this.showBtnAddService = true;

    }else if(this.idStatus == QuoteServiceStatusEnum.aguardandoAprovacaoObrigatoria){
        this.showBtnSendClient = false;
        this.showBtnBuy = false;
        this.showBtnSave = true;
        this.showBtnDelete = true;
        this.showBtnSearch = true;
        this.showBtnAddPart = true;
        this.showBtnAddService = true;

    }else if(this.idStatus == QuoteServiceStatusEnum.aprovado){
        this.showBtnSendClient = false;
        this.showBtnBuy = true;
        this.showBtnSave = false;
        this.showBtnDelete = true;
        this.showBtnSearch = false;
        this.showBtnAddPart = false;
        this.showBtnAddService = false;

    }else if(this.idStatus == QuoteServiceStatusEnum.rejeitado){
        this.showBtnSendClient = true;
        this.showBtnBuy = true;
        this.showBtnSave = true;
        this.showBtnDelete = true;
        this.showBtnSearch = true;
        this.showBtnAddPart = true;
        this.showBtnAddService = true;
    }else if(this.idStatus == QuoteServiceStatusEnum.cancelado){
        this.showBtnSendClient = false;
        this.showBtnBuy = false;
        this.showBtnSave = false;
        this.showBtnDelete = true;
        this.showBtnSearch = false;
        this.showBtnAddPart = false;
        this.showBtnAddService = false;
    }
    else if(this.idStatus == QuoteServiceStatusEnum.fechado){
        this.showBtnSendClient = false;
        this.showBtnBuy = false;
        this.showBtnSave = false;
        this.showBtnDelete = true;
        this.showBtnSearch = false;
        this.showBtnAddPart = false;
        this.showBtnAddService = false;
    }
  }

  clickBtnBuy(){
    this.save(false, true, false);
  }

  clickDelete(){
    if(!confirm("Tem certeza que seja deletar este orçamento")) {
      return;
    }

    if(this.idQuoteService == null || this.idQuoteService == undefined){

      this.alertService.show('Sucesso', "Orçamento deletado com sucesso", AlertType.success);

      if(this.idServiceOrder != null && this.idServiceOrder != undefined){
        this.router.navigate(['/workshop/service-order/details'], { queryParams: { idServiceOrder: this.idServiceOrder} });
        return;
      }

      this.router.navigate(['/workshop/service-order']);
      return;
    }

    this.quoteServiceService.delete(this.idQuoteService).subscribe({
      next: (returnStruct: ReturnStruct) => {

        if (returnStruct.isError) {
          this.alertService.show('Erro', returnStruct.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }

        this.alertService.show('Sucesso', "Orçamento " + this.idQuoteService + " deletado com sucesso", AlertType.success);

        if(this.idServiceOrder != null && this.idServiceOrder != undefined){
          this.router.navigate(['/workshop/service-order/details'], { queryParams: { idServiceOrder: this.idServiceOrder} });
          return;
        }

        this.router.navigate(['/workshop/service-order']);
      },
      error: (error) => {
        console.error(error);
        this.alertService.show('Ação não concluída.', error, AlertType.error);
        this.isLoading = false;
      }
    });
  }
}
