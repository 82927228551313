<app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum'
  [hasSecondaryHeader]=hasSecondaryHeader></app-menu>

<div class="container-body" [ngClass]="{'container-body-header': hasSecondaryHeader}" *ngIf="model != null">
  <form [formGroup]="model">
    <div class="row">
      <div class="col-md-9">
        <div class="content-purchase">
          <div class="content-purchase-resume-small">
            <h1>RESUMO DO PEDIDO</h1>
            <div class="row">
              <div class="col-6">
                <label>Itens total</label>
              </div>
              <div class="col-6">
                <label class="label-right">{{this.formattedPartsPrice}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label>Taxa de entrega</label>
              </div>
              <div class="col-6">
                <label class="label-right">{{selectedDeliveryPrice}}</label>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <label class="total-label">Total</label>
              </div>
              <div class="col-6">
                <label class="label-right total-price">{{this.totalPrice | currency: 'BRL'}}</label>
              </div>
            </div>
          </div>

          <h1>DETALHES</h1>
          <div class="content-purchase-inner">
            <div class="row">
              <div class="col-6">
                <p><b>Comprador:</b> {{this.getPurchaseDataResponse.userName}}</p>
                <p *ngIf="idServiceOrder"><b>Placa:</b> {{this.getPurchaseDataResponse.plateNumber}}</p>
              </div>
              <div class="col-6">
                <p><b>Oficina:</b> {{this.getPurchaseDataResponse.branchName}}</p>
                <p *ngIf="idServiceOrder"><b>Veículo:</b> {{this.getPurchaseDataResponse.vehicleModelName}}</p>
              </div>
            </div>
          </div>

          <h1>ENTREGA</h1>
          <div class="content-purchase-inner">
            <h2>Endereço de entrega</h2>

            <div class="content-purchase-inner-box">
              <div class="list-header">
                <div class="row">
                  <div class="col-md-12">
                    <label>Endereços ativos</label>
                  </div>
                </div>
              </div>
              <mat-radio-group aria-label="Select an option" formControlName="idBranchAddress"
                (change)="changeAddress($event)">
                <div class="row" *ngFor="let item of listBranchDeliveryAddress">
                  <div class="col-md-3">
                    <mat-radio-button [checked]="item.isChecked" value="{{item.idBranchDeliveryAddress}}">
                      {{item.addressName}} </mat-radio-button>
                  </div>
                  <div class="col-md-6">
                    <div class="vertical-aling-out">
                      <div class="vertical-aling-inner">
                        <label>{{item.street}}, {{item.number}}, {{item.neighborhood}}, {{item.city}}, {{item.state}},
                          {{item.zipcode}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="vertical-aling-out">
                      <div class="vertical-aling-inner">
                        <a class="address-btn" (click)="openAddressModal(item, true)">Editar endereço</a>
                      </div>
                    </div>
                  </div>
                  <hr>
                </div>
              </mat-radio-group>
              <button type="button" mat-flat-button (click)="openAddressModal(null)">
                <mat-icon class="material-symbols-outlined">add</mat-icon> Adicionar um novo endereço
              </button>
            </div>

            <h2 class="sub-title">Método de entrega</h2>

            <div class="content-purchase-inner-box">
              <app-partner-delivery *ngFor="let item of listSupplierBranchStruct" [supplier]="item"
                (change)="changeDelivery($event)">
              </app-partner-delivery>
            </div>

            <ng-container *ngIf="paymentMeros">
              <h2 class="sub-title">Contato de entrega</h2>
              <div class="row">
                <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Nome</mat-label>
                    <input matInput type="text" formControlName="name">
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="outline">
                    <mat-label>Telefone</mat-label>
                    <input matInput type="text" formControlName="phone" [mask]="masks.phone">
                  </mat-form-field>
                </div>
              </div>
            </ng-container>

          </div>

          <h1>PAGAMENTO DO PRODUTO</h1>
          <div class="content-purchase-inner">
            <p>O pagamento dos itens será faturado por cada distribuidor e pago por fora da plataforma</p>

          </div>

                    <ng-container *ngIf="paymentMeros">
                        <h1>PAGAMENTO DA ENTREGA</h1>
                        <div class="content-purchase-inner">
                            <p>O pagamento da entrega Meros é feito separadamente. Selecione um método de pagamento</p>
    
                            <div class="content-purchase-inner-box">
    
                                <div class="list-header">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <label>Cartão de crédito</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label>Nome do cartão</label>
                                        </div>
                                        <div class="col-md-3">
                                            <label>Vencimento</label>
                                        </div>
                                    </div>
                                </div>
    
                                <mat-radio-group aria-label="Select an option" formControlName="idCreditCard">
    
                                    <div class="row" *ngFor="let item of listBranchCreditCard">
                                        <div class="col-md-6">
                                            <mat-radio-button value="{{item.idBranchCreditCard}}">(Credito) {{item.cardBrand}} terminado em {{item.creditCardDigits}} </mat-radio-button>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="vertical-aling-out">
                                                <div class="vertical-aling-inner">
                                                    <label>{{item.creditCardName}}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="vertical-aling-out">
                                                <div class="vertical-aling-inner">
                                                    <a>{{item.expirationMonth}}/{{item.expirationYear}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" mat-flat-button (click)="openCreditCardModal(null)"> <mat-icon class="material-symbols-outlined">add</mat-icon>  Adicionar cartão de crédito</button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </ng-container>
                    <h1>PRODUTOS</h1>
                    <div *ngFor="let item of listParts">
                        <div class="row part-item-div">
                            <div class="col-12 col-sm-3 img-div">
                                <ng-container *ngIf="item.partProductStruct?.partImage64; else noImage">
                                  <img class="img-part" [src]="'data:image/jpg;base64,' + item.partProductStruct.partImage64"/>
                                </ng-container>
                                <ng-template #noImage>
                                  <img src="assets/images/no-image.png" class="img-size" alt="...">
                                </ng-template>
                              </div>
                            <div class="col-12 col-sm-9">
                                <div class="row">
                                    <div class="col-12 col-sm-3 col-md-3" *ngIf="item.partProductStruct.partNumber != null">
                                        <div class="part-item-title">{{item.partProductStruct.productName}}</div>
                                        <div>{{item.partProductStruct.brandName}} - {{item.partProductStruct.partNumber}}</div>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3" *ngIf="item.partProductStruct.partNumber == null">
                                      <div class="part-item-title">{{item.partProductStruct.supplierPartDescription}}</div>
                                      <div>{{item.partProductStruct.brandName}} | {{item.partProductStruct.supplierPartNumber}}</div>
                                    </div>
                                    <div class="col-12 col-sm-3 col-md-3">
                                        <div class="part-item-title">Preço Unidade</div>
                                        <div>{{item.partProductStruct.priceInvoice | currency: 'BRL'}}</div>
                                    </div>
                                    <div class="col-12 col-sm-2 col-md-2">
                                        <div class="part-item-title">Quantidade</div>
                                        <div>{{item.amount}}</div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3">
                                        <span class="part-item-title">Fornecedor: </span>
                                        <span>{{item.partProductStruct.supplierName ? item.partProductStruct.supplierName : '-'}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3 details">
                <div class="content-purchase-resume">
                    <h1>Resumo do pedido</h1>
                    <div class="infos-price">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Itens total</label>
                            </div>
                            <div class="col-md-6">
                                <label class="label-right">{{this.formattedPartsPrice}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label>Taxa de entrega</label>
                            </div>
                            <div class="col-md-6">
                                <label class="label-right"l>{{this.formatedSelectedDeliveryPrice}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="total-label">Total</label>
                            </div>
                            <div class="col-md-6">
                                <label class="label-right total-price">{{this.totalPrice | currency: 'BRL'}}</label>
                            </div>
                        </div>
                    </div>

          <button mat-flat-button type="button" class="btn btn-block" color="primary" (click)="buy()">
            <span *ngIf="!isLoading" class="next-btn">Finalizar Compra</span>
            <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
          </button>
        </div>
      </div>
    </div>

  </form>
</div>
