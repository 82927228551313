import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { json } from 'express';
import { stringify } from 'querystring';
import { Observable, catchError } from 'rxjs';
import { CommonService } from './common-service';

@Injectable({
  providedIn: 'root'
})
export class UtilService extends CommonService {

  constructor(
    private httpClient: HttpClient
  ) {
    super();
  }

  public getIP(): Observable<IPReturn> {
    
    return this.httpClient.get<IPReturn>("https://api.ipify.org/?format=json")
      .pipe(
        catchError(this.handleError)
    )
  }
}

export class IPReturn{
  public ip:string
}