<div mat-dialog-content>
    <div class="mat-dialog-content">
        <div i18n class="head-modal-list">
            <h2>Pesquise a placa do veículo</h2>
         </div>
         
         <div i18n class="body-modal-list">
            <div class="col-12">
                <mat-form-field appearance="outline">
                    <mat-label>Placa</mat-label>
                    <input matInput type="text" id="plateNumber" name="plateNumber" [(ngModel)]="plateNumber" 
                    [mask]="masks.plateNumber"
                    oninput="this.value = this.value.toUpperCase()">
                </mat-form-field>
                <img src="assets/images/search-plate.png"/>
            </div>         
         </div>
         <div i18n class="footer-modal-list">
             <div class="row" >
                <div class="col-12 col-sm-6">
                    <button mat-flat-button color="primary" class="btn-block" (click)="clickSearch()">
                        <span *ngIf="isLoading == false">Pesquisar</span>
                        <mat-spinner [diameter]="30" class="mat-spinner-btn" *ngIf="isLoading == true"></mat-spinner>
                    </button>
                </div>
                <div class="col-12 col-sm-6">
                    <button mat-flat-button color="accent" class="btn-block" (click)="clickCancel()">
                        <span>Cancelar</span>
                    </button>
                </div>
            </div> 
        </div>
    </div>
</div>

<!-- [mask]="masks.plateNumber.mask" [pattern]="masks.plateNumber.pattern" -->

