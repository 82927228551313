export class BranchDeliveryAdressRequest{
    public idBranchDeliveryAdress: number;
    public latitude: string;
    public longitude: string;
    public zipcode: string;
    public number: string;
    public city: string;
    public state: string;
    public street: string;
    public country: string;
    public neighborhood: string;
    public isDefault: boolean;
    public addressName: string;
}