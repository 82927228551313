import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError, retry } from 'rxjs/operators';
import { CommonService } from '../../../common-service';
import { ListProfileReponse } from '../../responses/profile/list-profile.reponse';

@Injectable({
    providedIn: 'root'
  })

export class SeachProfileService extends CommonService{

    constructor(
        private httpClient: HttpClient) {
        super();
      }

    public get(seachText?: string): Observable<ListProfileReponse> {

        let url = `Profile?seachText=${seachText}`
    
        return this.httpClient.get<ListProfileReponse>(environment.urlApiUser + url, this.addHeaderToken())
        .pipe(
          catchError(this.handleError)
        )
      }
}