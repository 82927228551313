import { Component, OnDestroy, OnInit, HostListener, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDeleteComponent } from '../../modal-delete/modal-delete.component';

@Component({
  selector: 'app-searching-for-driver',
  templateUrl: './searching-for-driver.component.html',
  styleUrls: ['./searching-for-driver.component.css']
})
export class SearchingForDriverComponent {

  constructor(private dialog: MatDialog,){
    this.onResize();
  }

  @Input() idBuyOrder: number | undefined;
  @Input() idBuyOrderStatus: string | undefined;
  @Input() entrega: string | undefined;
  @Input() oficina: string | undefined;
  @Output() refreshInfo: EventEmitter<void> = new EventEmitter<void>();

  public screenWidth: number; 


  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenWidth = window.innerWidth;
  }


  modalDelete(){
    if(this.screenWidth >= 650){
      const dialogRef = this.dialog.open(ModalDeleteComponent,{
        width:'45%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          entrega: this.entrega,
        }
      })
    }else{
      const dialogRef = this.dialog.open(ModalDeleteComponent,{
        width:'70%',
        data: {
          idBuyOrder: this.idBuyOrder,
          idBuyOrderStatus: this.idBuyOrderStatus,
          entrega: this.entrega,
        }
      })
    }
  }

}
