<app-menu #menu [menuModuleEnum]='menuModuleEnum' [menuFunctionalityEnum]='menuFunctionalityEnum' [hasSecondaryHeader]=true></app-menu>

<div class="container-body-quote-out">
    <div class="row">
        <div class="col-md-9">
            <div class="container-body-quote">
                <form class="form" [formGroup]="model" (ngSubmit)="save(false, false, false)">
                    <div class="section">
                        <h1>Peças do carrinho</h1>
                        <button *ngIf="showBtnSearch" mat-button type="button" class="btn btn-primary-blue btn-add-list" color="primary" (click)="searchParts()">
                            <mat-icon class="material-symbols-outlined">search</mat-icon> 
                            <span>Pesquisar mais peças</span>
                        </button>    
                        <table>
                            <thead class="basic-table__head">
                                <tr>
                                    <th></th>
                                    <th>Produto</th>
                                    <th>Código</th>
                                    <th>Marca</th>
                                    <th>Fornecedor</th>
                                    <th>% markup</th>
                                    <th>Preço Unidade</th>
                                    <th>Quantidade</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body">
                                <ng-container formArrayName="listQuoteServicePart" *ngFor="let item of model.get('listQuoteServicePart')['controls']; let i = index">
                                  <tr class="basic-table__body__row" [formGroup]="item">
                                    <td>
                                      <img *ngIf="item.get('partImage64')?.value != null" src="{{'data:image/jpg;base64,' + item.get('partImage64').value}}">
                                      <img *ngIf="item.get('partImage64')?.value == null" src="{{'/assets/images/no-image.png'}}">
                                    </td>
                                    <td>{{item.get('productName')?.value}}</td>
                                    <td>{{item.get('eanCode')?.value}}</td>
                                    <td>{{item.get('brandName')?.value}}</td>
                                    <td>{{item.get('supplierName')?.value}}</td>
                                    <td>
                                      <mat-form-field appearance="outline" (change)="updatePrices()">
                                        <mat-label>Markup</mat-label>
                                        <input matInput type="number" formControlName="markup">
                                      </mat-form-field>
                                    </td>
                                    <td>{{item.get('price')?.value | currency: 'BRL'}}</td>
                                    <td>
                                      <select>
                                        <option [value]="amount">{{item.get('amount')?.value}}</option>
                                      </select>
                                    </td>
                                    <td><a class="btn-list" (click)="removeListQuoteServicePart(i)"><mat-icon class="material-symbols-outlined">delete</mat-icon></a></td>
                                  </tr>
                                </ng-container>
                              </tbody>
                              
                        </table>
                        <div class="price">
                            <label class="label-price">Total em serviços <span>-----------</span></label>
                            <label class="label-value"> {{priceCart | currency: 'BRL'}}</label>
                        </div>
                    </div>
    
                    <div class="section">
                        <h1>Peças do estoque</h1>
                        <button *ngIf="showBtnAddPart"  mat-button type="button" class="btn btn-primary-blue btn-add-list" color="primary" (click)="addListStockPart(null)">
                            <mat-icon class="material-symbols-outlined">add</mat-icon> 
                            <span>Adicionar peça</span>
                        </button>     
                        <table>
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Produto</th>
                                    <th>Código</th>
                                    <th>Marca</th>
                                    <th>Preço Unidade</th>
                                    <th>Quantidade</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body">
                                <ng-container  formArrayName="listQuoteServicePart" *ngFor="let item of model.get('listStockPart')['controls']; let i = index">
                                    <tr class="basic-table__body__row"  [formGroup]="item" >
                                        <td>  
                                             <mat-form-field appearance="outline">
                                                <mat-label>Produto</mat-label>
                                                <input matInput type="text"  formControlName="productName">
                                                <mat-error *ngIf="item.get('productName').invalid">Informe o nome do produto</mat-error>
                                            </mat-form-field>
                                        </td>
                                        <td>  
                                            <mat-form-field appearance="outline">
                                               <mat-label>Código</mat-label>
                                               <input matInput type="text"  formControlName="eanCode">
                                               <mat-error *ngIf="item.get('eanCode').invalid">Informe o código</mat-error>
                                           </mat-form-field>
                                       </td>
                                       <td>  
                                            <mat-form-field appearance="outline">
                                                <mat-label>Marca</mat-label>
                                                <input matInput type="text"  formControlName="brandName">
                                                <mat-error *ngIf="item.get('brandName').invalid">Informe o marca</mat-error>
                                            </mat-form-field>
                                        </td>
                                        <td>  
                                            <mat-form-field appearance="outline">
                                                <mat-label>Preço</mat-label>
                                                <input matInput type="text"  formControlName="price" (keyup)="updatePrices()" currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',' }">
                                                <mat-error *ngIf="item.get('price').invalid">Informe o Preço</mat-error>
                                            </mat-form-field>
                                        </td>
    
                                        <td>
                                            <!-- <mat-form-field appearance="outline">
                                                <mat-label>Quantidade</mat-label>
                                                <mat-select formControlName="amount" (selectionChange)="updatePrices()">
                                                    <mat-option value="1">1</mat-option>
                                                    <mat-option value="2">2</mat-option>
                                                    <mat-option value="3">3</mat-option>
                                                    <mat-option value="4">4</mat-option>
                                                    <mat-option value="5">5</mat-option>
                                                </mat-select>
                                            </mat-form-field> -->
                                            <mat-form-field appearance="outline">
                                                <mat-label>Quantidade</mat-label>
                                                <input matInput formControlName="amount" type="number" min="1" (input)="onInput($event)">
                                            </mat-form-field>
                                        </td>
                                        <td><a class="btn-list" (click)="removeListStockPart(i)"> <mat-icon class="material-symbols-outlined">delete</mat-icon> </a></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div *ngIf="model.get('listStockPart')['controls'].length == 0">
                            <p class="text-center">Lista vazia</p>
                        </div>
                        <div class="price">
                            <label class="label-price">Total em serviços <span>-----------</span></label>
                            <label class="label-value"> {{priceStock | currency: 'BRL'}}</label>
                        </div>
                    </div>
    
    
                    <div class="section">
                        <h1>Detalhamento serviço</h1>
                        <button  *ngIf="showBtnAddService"  mat-button type="button" class="btn btn-primary-blue btn-add-list" color="primary" (click)="addListServiceDetail(null)">
                            <mat-icon class="material-symbols-outlined">add</mat-icon> 
                            <span>Adicionar serviço</span>
                        </button>     
                        <table>
                            <thead class="basic-table__head">
                                <tr>
                                    <th>Descrição do serviço</th>
                                    <th>Preço Unidade</th>
                                    <th>Quantidade</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody class="basic-table__body">
                                <ng-container  formArrayName="listServiceDetail" *ngFor="let item of model.get('listServiceDetail')['controls']; let i = index">
                                    <tr class="basic-table__body__row"  [formGroup]="item" >
                                        <td>  
                                             <mat-form-field appearance="outline">
                                                <mat-label>Descrição do serviço</mat-label>
                                                <input matInput type="text"  formControlName="description">
                                                <mat-error *ngIf="item.get('description').invalid">Descrição do serviço</mat-error>
                                            </mat-form-field>
                                        </td>
                                       
                                        <td>  
                                            <mat-form-field appearance="outline">
                                                <mat-label>Preço</mat-label>
                                                <input matInput type="text"  formControlName="price" (keyup)="updatePrices()" currencyMask [options]="{ prefix: ' ', thousands: '.', decimal: ',' }">
                                                <mat-error *ngIf="item.get('price').invalid">Informe o Preço</mat-error>
                                            </mat-form-field>
                                        </td>
    
                                        <td>
                                            <mat-form-field appearance="outline" (selectionChange)="updatePrices()">
                                                <mat-label>Quantidade</mat-label>
                                                <mat-select formControlName="amount">
                                                    <mat-option value="1">1</mat-option>
                                                    <mat-option value="2">2</mat-option>
                                                    <mat-option value="3">3</mat-option>
                                                    <mat-option value="4">4</mat-option>
                                                    <mat-option value="5">5</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td><a class="btn-list" (click)="removetServiceDetail(i)"> <mat-icon class="material-symbols-outlined">delete</mat-icon> </a></td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <div *ngIf="model.get('listServiceDetail')['controls'].length == 0">
                            <p class="text-center">Lista vazia</p>
                        </div>
                        <div class="price">
                            <label class="label-price">Total em serviços <span>-----------</span></label>
                            <label class="label-value"> {{priceServices | currency: 'BRL'}}</label>
                        </div>
            
                       <div class="price total">
                            <label class="label-price">Total <span>-----------</span></label>
                            <label class="label-value"> {{priceTotal | currency: 'BRL'}}</label>
                       </div>
                    </div>
                </form>
    
            </div>
        </div>
        <div class="col-md-3">
            <div class="container-body-quote resume">
               <h1>Resumo do orçamento</h1>
               <div class="item-row" style="margin-bottom: 25px;">
                    <div class="item-col-2">
                        <span>Status</span>
                    </div>
                    <div class="item col-2"  *ngIf="this.statusName === 'Aguardando aprovação obrigatória'">
                        <span class="value statusName">{{statusName}}</span>
                    </div>
                    <div class="item-col-2"  *ngIf="this.statusName != 'Aguardando aprovação obrigatória'">
                        <span class="value statusName">{{statusName}}</span>
                    </div>
                </div>
                <div class="item-row">
                    <div class="item-col-2">
                        <span>Carrinho</span>
                    </div>
                    <div class="item-col-2">
                        <span class="value"> {{priceCart | currency: 'BRL'}}</span>
                    </div>
                </div>
                <div class="item-row">
                    <div class="item-col-2">
                        <span>Estoque</span>
                    </div>
                    <div class="item-col-2">
                        <span class="value"> {{priceStock | currency: 'BRL'}}</span>
                    </div>
                </div>
                <div class="item-row">
                    <div class="item-col-2">
                        <span>Serviço</span>
                    </div>
                    <div class="item-col-2">
                        <span class="value"> {{priceServices | currency: 'BRL'}}</span>
                    </div>
                </div>
                <div class="item-row total">
                    <div class="item-col-2">
                        <span>Total</span>
                    </div>
                    <div class="item-col-2">
                        <span class="value"> {{priceTotal | currency: 'BRL'}}</span>
                    </div>
                </div>

                <button *ngIf="showBtnSendClient" mat-button type="button" class="btn-primary-blue btn-block" color="primary" (click)="openModalClientApproval()" [disabled]="isTableEmpty()">
                    <span>Enviar para o cliente</span>
                </button>   

                <button *ngIf="showBtnBuy" id="buy-Button" mat-button type="button" class="btn-secundary btn-block" color="primary" (click)="clickBtnBuy()" [disabled]="isTableEmpty()">
                    <span>Comprar peças</span>
                </button>   

                <button *ngIf="showBtnSave" mat-button type="button" class="btn-primary btn-block" color="primary" (click)="save(false, false, true)">
                    <span>Salvar & voltar a OS</span>
                </button>   


                <button *ngIf="showBtnDelete" mat-button type="button" class="btn-secundary-danger btn-block" color="primary" (click)="clickDelete()">
                    <span>Deletar orçamento</span>
                </button>   
            </div>
        </div>
    </div>
    
</div>
