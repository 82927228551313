import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/services/auth-guard.service';
import { UserComponent } from './pages/user/user.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ProfilePermissionsComponent } from './pages/profile-permissions/profile-permissions.component';
import { BusinessDataComponent } from './pages/business-data/business-data.component';
import { SettingsComponent } from './pages/settings.component';
import { DeliveryAddressComponent } from './pages/delivery-address/delivery-address.component';
import { PickUpAddressComponent } from './pages/pick-up-address/pick-up-address.component';

const routes: Routes = [
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard]},
  { path: 'user', component: UserComponent, canActivate: [AuthGuard]},
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard]},
  { path: 'profile-permissions', component: ProfilePermissionsComponent, canActivate: [AuthGuard]},
  { path: 'business-data', component: BusinessDataComponent, canActivate: [AuthGuard]},
  { path: 'delivery-address', component: DeliveryAddressComponent, canActivate: [AuthGuard]},
  { path: 'pick-up-address', component: PickUpAddressComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
